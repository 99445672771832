<template>
	<div class="page-payments-settings">
		<div class="row align-items-center mb-3">
			<div class="col">
				<h3 class="my-0">
					<router-link :to="`/${j.slug}/payments`">HeyGov Pay</router-link>
					<font-awesome-icon :icon="['fas', 'angle-right']" class="text-muted mx-2" />
					<router-link :to="`/${j.slug}/payments/${$route.params.departmentId}`">{{
						department ? department.name : '...'
					}}</router-link>
					<font-awesome-icon :icon="['fas', 'angle-right']" class="text-muted mx-2" />
					Settings
				</h3>
			</div>
			<div class="col-auto text-end">
				-
			</div>
		</div>

		<div v-if="states.department === 'loaded'">
			<div class="card mb-4">
				<div class="card-body">
					<form @submit.prevent="saveDepartmentPaymetsSettings">
						<div class="row">
							<div class="col-lg-6 mb-3">
								<label class="form-label" for="account-name">Department legal name</label>

								<input type="text" class="form-control" v-model="department.legal_name" />
								<small class="form-text">Appears on payment receipts and financial reports</small>
							</div>

							<div class="col-lg-6 mb-3">
								<label class="form-label" for="account-name">Statement descriptor</label>

								<div class="input-group">
									<span class="input-group-text">HG-</span>
									<input
										type="text"
										class="form-control"
										readonly
										:placeholder="j.slug.toUpperCase()"
									/>
								</div>
								<small class="form-text">Text that appears on payer's bank statement</small>
							</div>

							<div class="col-lg-6 mb-3">
								<label class="form-label" for="account-name">Account visibility</label>

								<select class="form-select">
									<option>Only HeyGov Admins</option>
									<option selected>"{{ department.name }}" department admins</option>
									<option>All "{{ department.name }}" members</option>
									<option>Public to all {{ j.type }} staff</option>
								</select>
								<small class="form-text">
									Select who has access to see payment lists and create payments in this department
								</small>
							</div>

							<div class="col-lg-6 mb-3">
								<label class="form-label" for="dept-billing-accounts">Billing Accounts</label>

								<select
									class="form-select"
									id="dept-billing-accounts"
									v-model="department.billing_accounts"
								>
									<option :value="null">Not enabled</option>
									<option
										v-for="(integration, integrationName) in paymentIntegrations"
										:key="`pi-${integrationName}`"
										:value="integrationName"
										>{{ integration.name }}</option
									>
								</select>

								<p class="form-text">Enable usage of account numbers &amp; bills</p>
							</div>
						</div>

						<p class="card-text text-end">
							<button class="btn btn-primary px-5">Save settings</button>
						</p>
					</form>
				</div>
			</div>

			<template v-if="(isStaff && department.payments_platform === 'stripe') || department.stripe_account">
				<h4 class="mb-2">Bank deposits</h4>

				<div class="card mb-4">
					<div class="card-body">
						[Stripe settings]
					</div>
				</div>
			</template>

			<h4 class="mb-2">Integrations</h4>

			<div class="row">
				<div class="col-12 col-lg-6 mb-4">
					<div class="card">
						<div class="card-header">
							<div class="row">
								<div class="col">
									<h5 class="card-title mb-0">
										Workhorse Utility Billing
										<span
											v-if="
												department.workhorse_integration &&
													department.workhorse_integration.enabled
											"
											class="badge bg-success-50 text-success-400"
											>✅ Active</span
										>
										<span
											v-else-if="
												department.workhorse_integration &&
													department.workhorse_integration.enabled == false
											"
											class="badge bg-danger-50 text-danger-400"
											>🚫 Disabled</span
										>
									</h5>
								</div>
								<div class="col-auto">
									<a href="https://www.workhorsewi.com/customer-support" target="_blank">
										<img
											src="https://edge.heygov.com/integrations/workhorse-logo.jpg"
											alt="Workhorse Software"
											height="25"
											class="rounded-1"
									/></a>
								</div>
							</div>
						</div>
						<div class="card-body">
							<div v-if="department.workhorse_integration">
								<p class="card-text mb-2">
									The integration between HeyGov and Workhorse Utility takes place through FTP. Your
									Workhorse application needs to be connected to the server below.
								</p>

								<p class="mb-1">
									FTP server: <code>super-secure-ftp.heygov.com</code>, Port: <code>21</code>
								</p>
								<p class="mb-1">
									FTP user:
									<code v-if="department.workhorse_integration.ftp_user">{{
										department.workhorse_integration.ftp_user
									}}</code
									><span v-else class="text-neutral-400"
										><span class="spinner-border spinner-border-sm"></span> FTP account is being
										created</span
									>
								</p>
								<p class="mb-1">
									FTP password:
									<code v-if="department.workhorse_integration.ftp_pass">{{
										department.workhorse_integration.ftp_pass
									}}</code
									><span v-else class="text-neutral-400">You'll receive an email when ready</span>
								</p>
								<p class="mb-1">Active or passive: <code>Passive</code></p>
								<p class="mb-2">Input path or directory: <code>/</code> or leave empty</p>
								<!-- <p class="mb-2">Security: <code>No security</code></p> -->
								<p class="card-text">
									If you need help with the integration, please contact your HeyGov account manager or
									<a href="https://www.workhorsewi.com/customer-support/" target="_blank"
										>Workhorse customer support</a
									>.
								</p>

								<hr class="bg-neutral-300" />

								<p class="mb-2">
									<strong>Bills import:</strong> Head over to the
									<router-link :to="`/${j.slug}/payments/${department.id}/billing-accounts`"
										>Billing Accounts page</router-link
									>
									to import a billing cycle, or enable auto import.
								</p>

								<div>
									<div class="form-check form-switch">
										<input
											class="form-check-input"
											type="checkbox"
											role="switch"
											id="workhorse-bills-auto-import"
											v-model="department.workhorse_integration.bills_auto_import"
										/>
										<label class="form-check-label" for="workhorse-bills-auto-import"
											>Enable bills auto import</label
										>
									</div>
								</div>

								<hr class="bg-neutral-300" />

								<p class="card-text mb-2">
									<strong>Payment receipts:</strong> When a successful payment is made, HeyGov uploads
									automatically
									<select v-model="department.workhorse_integration.payments">
										<option value="payconf">new payments (use PayConf)</option>
										<option value="count-10">last 10 payments</option>
										<option value="count-50">last 50 payments</option>
										<option value="count-100">last 100 payments</option>
										<option value="days-1">payments in last 24h</option>
										<option value="days-7">payments in last 7 days</option>
										<option value="days-30">payments in last 30 days</option>
									</select>
									in the <code>PayBillIn.csv</code> file.
								</p>

								<div v-if="department.workhorse_integration.ftp_error" class="alert alert-danger">
									⚠️ {{ department.workhorse_integration.ftp_error }}
								</div>
								<p v-else-if="department.workhorse_integration.ftp_updated_at" class="card-text">
									Last upload:
									<code>{{
										new Date(department.workhorse_integration.ftp_updated_at).toLocaleString()
									}}</code>
									<span class="text-success-300 ms-1">✔</span>
								</p>
							</div>

							<p v-else class="card-text">
								Use this integration to automatically import bills into HeyGov for online or in person
								card payments, then automatically send payment receipts to Workhorse Utility.
							</p>
						</div>
						<div class="card-footer d-flex align-items-center justify-content-between">
							<a
								href="https://townweb.notion.site/HeyGov-Workhorse-billing-reconciliation-integration-00bb26a277934c9284069964d75a3f85"
								target="_blank"
								>ℹ️ Learn more</a
							>

							<span v-if="department.workhorse_integration">
								<a
									:href="
										`${apiUrl + j.slug}/payments/export-payments-workhorse?department_id=${
											department.id
										}`
									"
									target="_blank"
									class="btn btn-sm btn-primary me-2"
									>Download <code>PayBillIn.csv</code></a
								>
							</span>

							<button v-else class="btn btn-sm btn-outline-primary" @click="startWorkhorse">
								Start integration
							</button>
						</div>
					</div>
				</div>
			</div>

			<div
				v-if="isStaff && department.stripe_account"
				class="card"
				@mouseenter="dangerZoneAudio(true)"
				@mouseleave="dangerZoneAudio(false)"
			>
				<div class="card-header bg-danger-50">
					<h3 class="my-0">🎸 Danger zone</h3>
				</div>
				<div class="card-body">
					<div v-if="department.stripe_account">
						<p class="card-text">
							Disconnect Stripe account
							<a
								:href="
									`https://dashboard.stripe.com/${department.stripe_account}/${
										j.livemode ? '' : 'test/'
									}dashboard`
								"
								target="_blank"
								><strong>{{ department.stripe_account }}</strong></a
							>
							from <strong>{{ department.name }}</strong> department. Another payment processor can be
							connected after.
						</p>
						<p class="card-text">
							<button class="btn btn-sm btn-outline-danger" @click="unlinkStripeAccount">
								Unlink Stripe account
							</button>
						</p>
					</div>
				</div>
			</div>

			<audio src="https://edge.heygov.com/app-assets/danger-zone.mp3" ref="danger-zone-audio"></audio>
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import Vue from 'vue'

import heyGovApi from '@/api.js'
import { handleResponseError, sendEvent } from '@/utils.js'
import { paymentIntegrations } from '@/actions/payments.js'

export default {
	name: 'PaymentsSettings',
	metaInfo() {
		return {
			title: `${this.department?.name} payments settings`,
		}
	},
	components: {},
	data() {
		return {
			states: {
				department: 'loading',
			},
			paymentIntegrations,
			department: null,
		}
	},
	computed: {
		...mapState(['j', 'apiUrl', 'people', 'departments']),
		...mapGetters(['currentRole', 'isStaff', 'activeDepartments']),
	},
	created() {
		if (this.currentRole === 'CITIZEN') {
			this.$router.push(`/${this.j.slug}/quick-pay`)
		} else {
			this.$store.dispatch('loadDepartments').then(departments => {
				this.department = departments.find(d => d.id == this.$route.params.departmentId)

				sendEvent('View Payment dept settings', {
					department_id: this.$route.params.departmentId,
					department: this.department.name,
					feature: 'payments',
				})

				this.states.department = this.department ? 'loaded' : 'error'
			})
		}
	},
	methods: {
		saveDepartmentPaymetsSettings() {
			heyGovApi
				.put(`${this.j.slug}/departments/${this.department.id}`, {
					legal_name: this.department.legal_name,
					billing_accounts: this.department.billing_accounts,
				})
				.then(() => {
					Vue.toasted.success(`Payments settings are updated`)
				})
				.catch(handleResponseError('Error updating settings ({error})'))
		},

		updateWorkhorseSettings() {
			heyGovApi
				.put(`${this.j.slug}/departments/${this.department.id}`, {
					workhorse_integration: this.department.workhorse_integration,
				})
				.then(() => {
					Vue.toasted.success('Workhorse settings updated')
				})
				.catch(handleResponseError('Error updating Workhorse settings ({error})'))
		},
		startWorkhorse() {
			if (confirm('Do you have the Workhorse Utility Billing application ready?')) {
				this.$set(this.department, 'workhorse_integration', {
					enabled: true,
					payments: 'payconf',
					bills_auto_import: false,
				})

				this.updateWorkhorseSettings()

				if (!this.department.billing_accounts) {
					this.department.billing_accounts = 'workhorse'
					this.saveDepartmentPaymetsSettings()
				}
			}
		},

		unlinkStripeAccount() {
			if (confirm('⚠️ Are you sure??')) {
				heyGovApi
					.put(`${this.j.slug}/departments/${this.department.id}`, {
						stripe_account: null,
						stripe_account_status: null,
					})
					.then(() => {
						Vue.toasted.show(`Stripe account is unlinked from ${this.department.name}`)
						this.$router.replace(`/${this.j.slug}/payments`)
						window.location.reload()
					})
					.catch(handleResponseError('Error unlinking Stripe account ({error})'))
			}
		},

		dangerZoneAudio(play) {
			//console.log('danger-zone', play, this.$refs['danger-zone-audio'])

			if (play) {
				this.$refs['danger-zone-audio'].play()
			} else {
				this.$refs['danger-zone-audio'].pause()
			}
		},
	},
	watch: {
		'department.workhorse_integration.payments'(payments, paymentsOld) {
			if (paymentsOld) {
				this.updateWorkhorseSettings()
			}
		},
		'department.workhorse_integration.bills_auto_import'(bills, billsOld) {
			console.log('bills', billsOld)
			if (billsOld !== undefined) {
				this.updateWorkhorseSettings()
			}
		},
	},
}
</script>
