<template>
	<nav class="mobile-nav fixed-bottom">
		<ul class="row gx-1 text-center">
			<li class="col">
				<router-link :to="`/${j.slug}/`"
					><img src="/images/icon-menu-home.svg" height="24" alt="Home" /> <small>Home</small></router-link
				>
			</li>
			<li v-if="j.features && j.features.includes('forms')" class="col">
				<router-link :to="`/${j.slug}/forms`"
					><img src="/images/icon-menu-clipboard.svg" height="24" alt="Home" />
					<small>Licenses</small></router-link
				>
			</li>
			<li v-if="j.features && j.features.includes('issues')" class="col">
				<router-link :to="`/${j.slug}/threads`"
					><img src="/images/icon-menu-exclamation.svg" height="24" alt="Home" />
					<small>Issues</small></router-link
				>
			</li>
			<li v-if="j.features && j.features.includes('venues')" class="col">
				<router-link :to="`/${j.slug}/venues`"
					><img src="/images/icon-menu-chat.svg" height="24" alt="Home" /> <small>Venues</small></router-link
				>
			</li>
			<li v-if="j.features && j.features.includes('payments')" class="col">
				<router-link :to="`/${j.slug}/quick-pay`"
					><img src="/images/icon-menu-dollar-sign.svg" height="24" alt="Home" />
					<small>Pay</small></router-link
				>
			</li>
		</ul>
	</nav>
</template>

<style scoped lang="scss">
.mobile-nav {
	bottom: 0;
	background-color: rgba(#5e81f4, 0.9);
	border-radius: 2rem;
	margin: 0.5rem;
	z-index: 50;
	backdrop-filter: blur(10px);

	ul {
		margin: 0;
		padding: 0;

		li {
			list-style: none;

			a {
				display: inline-block;
				color: #fff;
				font-size: 1.5rem;
				padding: 6px 10px;
				line-height: 1.2;
			}

			small {
				display: block;
				font-size: 12px;
				font-weight: 200;
			}
		}
	}
}
</style>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
	name: 'MobileNav',
	components: {},
	data() {
		return {}
	},
	computed: {
		...mapGetters(['auth', 'isStaff', 'currentRole']),
		...mapState(['j']),
	},
	created() {},
	methods: {},
}
</script>
