<template>
	<div
		class="hg-events-page"
		:style="{
			...generalStyle,
			'--hg-border-radius': generalStyle ? generalStyle.borderRadius || '16px' : '16px',
			'--hg-color': generalStyle ? generalStyle.color : '#5E81F4',
			'--hg-secondary-color': generalStyle ? generalStyle.secondaryColor : '#FCAB1C',
		}"
	>
		<div class="row justify-content-center mb-4">
			<div class="col-lg-4 col-md-8">
				<div class="row align-items-center">
					<div class="col-auto">
						<button class="calendar-back py-1 px-3" @click="changeMonth(-1)">
							<font-awesome-icon :icon="['fas', 'chevron-left']" />
						</button>
					</div>
					<div class="col text-center">
						<h5 class="my-0">
							{{ format(date, 'MMMM') }}
							<span class="fw-normal">{{ format(date, 'yyyy') }}</span>
						</h5>
					</div>
					<div class="col-auto">
						<button class="calendar-forward py-1 px-3" @click="changeMonth(1)">
							<font-awesome-icon :icon="['fas', 'chevron-right']" />
						</button>
					</div>
				</div>
			</div>
		</div>

		<div class="row align-items-center">
			<div class="col-12 col-md mb-3">
				<input
					class="form-control form-control-sm"
					:style="generalStyle ? { color: generalStyle.color, borderRadius: generalStyle.borderRadius } : ''"
					type="search"
					v-model="filters.q"
					placeholder="🔍 Search..."
				/>
			</div>
			<div class="col-6 col-md mb-3">
				<select
					class="form-select form-select-sm"
					v-model="filters.category"
					:style="generalStyle ? { color: generalStyle.color, borderRadius: generalStyle.borderRadius } : ''"
				>
					<option value="">All categories</option>
					<optgroup v-if="type === 'all' || type === 'events'" label="Events">
						<option v-for="cat in eventCategories" :key="`event-cat-${cat.id}`" :value="`ev-${cat.id}`">{{
							cat.name
						}}</option>
					</optgroup>
					<optgroup v-if="(type === 'all' || type === 'meetings') && !j.livemode" label="Meetings">
						<option
							v-for="cat in meetingCategories"
							:key="`meeting-cat-${cat.id}`"
							:value="`me-${cat.id}`"
							>{{ cat.name }}</option
						>
					</optgroup>
				</select>
			</div>
			<div class="col-6 col-md-auto mb-3">
				<div class="btn-group btn-events-view" role="group" aria-label="Calendar or List view">
					<button
						type="button"
						class="btn btn-sm"
						:class="view === 'calendar' ? 'btn-active' : ''"
						@click="view = 'calendar'"
					>
						<font-awesome-icon :icon="['fa', 'calendar-days']" class="me-2" />
						<span class="btn-text">Calendar</span>
					</button>
					<button
						type="button"
						class="btn btn-sm"
						:class="view === 'list' ? 'btn-active' : ''"
						@click="view = 'list'"
					>
						<font-awesome-icon :icon="['fa', 'list']" class="me-2" />
						<span class="btn-text">List</span>
					</button>
				</div>
			</div>
		</div>

		<!-- List view -->
		<div v-if="view === 'list'">
			<table class="table table-borderless table-hover">
				<tbody :style="generalStyle ? { color: generalStyle.color } : ''">
					<!--Current month events -->

					<tr v-for="(event, index) in eventsForMonth(date)" :key="`${event.pid}-event`">
						<td>
							<template
								v-if="
									!index ||
										eventsForMonth(date)[index - 1].starts_at_local_day !==
											event.starts_at_local_day
								"
							>
								<span class="text-uppercase d-flex">
									{{
										event.starts_at | dateLocal('en-US', { timeZone: j.timezone, weekday: 'short' })
									}}
								</span>
								<h3>
									{{ event.starts_at | dateLocal('en-US', { timeZone: j.timezone, day: 'numeric' }) }}
								</h3>
							</template>
						</td>
						<td>
							<p class="event-metdata">
								<!-- Event date -->
								<span>
									{{
										new Date(event.starts_at).toLocaleString('default', {
											timeZone: j.timezone,
											dateStyle: 'medium',
											timeStyle: 'short',
										})
									}}

									<span v-if="event.ends_at && event.starts_at !== event.ends_at">
										<template v-if="event.starts_at_local_day !== event.ends_at_local_day">
											→
											{{
												new Date(event.ends_at).toLocaleString('default', {
													timeZone: j.timezone,
													dateStyle: 'medium',
													timeStyle:
														event.metadata && !event.metadata.hide_end_time
															? 'short'
															: undefined,
												})
											}}
										</template>
										<template
											v-else-if="
												!event.metadata || (event.metadata && !event.metadata.hide_end_time)
											"
										>
											→
											{{
												new Date(event.ends_at).toLocaleString('default', {
													timeZone: j.timezone,
													timeStyle: 'short',
												})
											}}
										</template>
									</span>
								</span>

								<span
									v-if="event.all_day && !event.multi_day"
									class="badge bg-neutral-50 text-dark mx-1"
									>All day</span
								>

								<!-- Event icon -->
								<span
									v-if="(event.parent && event.parent[0]) || event.type === 'recurrent'"
									class="badge bg-success-50 text-success-400 mx-1"
								>
									<font-awesome-icon :icon="['fas', 'arrows-rotate']" />
									{{
										event.parent && event.parent.length > 0
											? JSON.parse(event.parent[0].recurrence).frequency
											: event.recurrence.frequency
									}}
								</span>

								<!-- Event categories -->
								<template v-if="event.pid.startsWith('ev_') && event.categories">
									<span
										v-for="cat in event.categories"
										:key="`event-category-id-${cat}`"
										class="badge bg-primary-50 text-primary-400 mx-1"
										>{{ eventCategoryValue(cat, 'name') || `[${cat}]` }}</span
									>
								</template>
								<!-- Meeting categories -->
								<template v-else-if="event.pid.startsWith('me_') && event.categories">
									<span
										v-for="cat in event.categories"
										:key="`meeting-category-id-${cat}`"
										class="badge bg-primary-50 text-primary-400 mx-1"
										>{{ meetingCategoryValue(cat, 'name') || `[${cat}]` }}</span
									>
								</template>
							</p>

							<h2 class="mt-0 mb-2">
								<router-link
									:to="{
										name: event.pid.startsWith('ev_') ? 'EventEmbed' : 'MeetingEmbedPage',
										params: { jurisdiction: j.slug, id: event.pid },
										query: $route.query,
									}"
									:style="generalStyle ? { color: generalStyle.color } : ''"
									>{{ event.name || event.title }}
								</router-link>
							</h2>
						</td>
					</tr>
				</tbody>
				<tfoot>
					<tr
						v-if="
							this.eventsMonthsStatus[format(date, 'yyyy-MM')] === 'loading' ||
								this.meetingsMonthsStatus[format(date, 'yyyy-MM')] === 'loading'
						"
					>
						<td colspan="2" class="text-center py-3">
							<span class="spinner-border spinner-border-sm" role="state"></span> Loading events
						</td>
					</tr>
					<tr v-else-if="!eventsForMonth(date).length">
						<td colspan="2" class="text-center py-3">
							<div>
								<img
									src="https://edge.heygov.com/illustrations/arms-documents.jpg"
									width="300"
									class="mb-3"
								/>
								<p class="lead">No events scheduled for {{ format(date, 'LLLL yyyy') }}</p>
							</div>
						</td>
					</tr>
				</tfoot>
			</table>
		</div>
		<div v-else-if="view === 'calendar'">
			<div class="hg-calendar">
				<div
					v-for="day in weekDays"
					class="hg-week-days fw-bolder text-center"
					:style="generalStyle ? { color: generalStyle.secondaryColor } : ''"
					:key="`week-day-${day}`"
				>
					{{ day }}
				</div>

				<div
					v-for="day in calendarDays()"
					:key="day.fullDate"
					class="hg-calendar-day"
					:class="{
						[`hg-calendar-day-${day.type}`]: true,
						'hg-calendar-day-weekend': [0, 6].includes(getDay(day.date)),
						'hg-calendar-day-weekday': ![0, 6].includes(getDay(day.date)),
						'hg-calendar-day-today': day.fullDate === format(new Date(), 'yyyy-MM-dd'),
					}"
					@click="date = day.date"
				>
					<span class="hg-calendar-day-number my-1">{{ format(day.date, 'd') }}</span>

					<!-- Display calendar day events -->
					<div class="hg-calendar-events d-none d-md-block">
						<router-link
							v-for="event in eventsForDay(day.date).slice(0, 4)"
							:key="event.pid"
							:to="{
								name: event.pid.startsWith('ev_') ? 'EventEmbed' : 'MeetingEmbedPage',
								params: { jurisdiction: j.slug, id: event.pid },
								query: $route.query,
							}"
							class="hg-calendar-event mb-1"
							:class="{
								'hg-calendar-event-all-day': event.all_day,
								'hg-calendar-event-multi-day': event.multi_day,
								'hg-calendar-event-multi-day-first':
									event.multi_day && day.fullDate === event.starts_at_local_day,
								'hg-calendar-event-multi-day-last':
									event.multi_day && day.fullDate === event.ends_at_local_day,
								'type-event': event.pid.startsWith('ev_'),
								'type-meeting': event.pid.startsWith('me_'),
							}"
							:style="{ '--hg-event-category-color': getEventCategoryColor(event) }"
						>
							<span class="hg-calendar-event-dot"></span>
							<span class="hg-calendar-event-time">{{
								new Date(event.starts_at)
									.toLocaleTimeString('default', {
										timeZone: j.timezone,
										timeStyle: 'short',
									})
									.replace(':00', '')
							}}</span>
							<span class="hg-calendar-event-name">{{ event.name || event.title }}</span>
						</router-link>
					</div>

					<!-- Mobile view: Show event indicator -->
					<div v-if="eventsForDay(day.date).length" class="hg-calendar-events-mobile d-md-none">
						<button
							class="events-indicator"
							:style="{ '--hg-event-category-color': getEventCategoryColor(eventsForDay(day.date)[0]) }"
							@click="
								if (eventsForDay(day.date).length === 1) {
									$router.push({
										name: eventsForDay(day.date)[0].pid.startsWith('ev_') ? 'EventEmbed' : 'MeetingEmbedPage',
										params: { jurisdiction: j.slug, id: eventsForDay(day.date)[0].pid },
										query: $route.query,
									})
								} else {
									date = day.date
									view = 'day'
								}
							"
						>
							{{ eventsForDay(day.date).length }}
						</button>
					</div>

					<button
						v-if="eventsForDay(day.date).length > 3"
						class="heygov-calendar-day-show-more"
						@click="
							date = day.date
							view = 'day'
						"
					>
						Show more events
					</button>
				</div>
			</div>

			<div class="d-sm-block d-md-none py-3">
				<h4>Events on {{ date.toLocaleDateString('default', { dateStyle: 'medium' }) }}</h4>

				<template v-if="eventsForDay(date).length">
					<event-details v-for="event in eventsForDay(date)" :key="event.pid" :event="event"></event-details>
				</template>
				<p v-else class="text-center text-neutral-400">
					No events scheduled for {{ date.toLocaleDateString('default', { dateStyle: 'medium' }) }}
				</p>
			</div>
		</div>
		<div v-else-if="view === 'day'" class="row justify-content-center py-3">
			<div class="col-xl-6 col-lg-7 col-md-8">
				<div class="row align-items-center mb-3">
					<div class="col">
						<h5 class="text-center my-0">
							Events on {{ date.toLocaleDateString('default', { dateStyle: 'full' }) }}
						</h5>
					</div>
					<div class="col-auto">
						<button class="btn btn-sm" @click="view = 'calendar'">Show calendar</button>
					</div>
				</div>

				<div v-for="event in eventsForDay(date)" :key="event.pid" class="heygov-calendar-list-event">
					<p class="event-metdata">
						<!-- Event date -->
						<span>
							{{
								new Date(event.starts_at).toLocaleString('default', {
									timeZone: j.timezone,
									dateStyle: 'medium',
									timeStyle: 'short',
								})
							}}

							<span v-if="event.ends_at && event.starts_at !== event.ends_at">
								<template v-if="event.starts_at_local_day !== event.ends_at_local_day">
									→
									{{
										new Date(event.ends_at).toLocaleString('default', {
											timeZone: j.timezone,
											dateStyle: 'medium',
											timeStyle:
												event.metadata && !event.metadata.hide_end_time ? 'short' : undefined,
										})
									}}
								</template>
								<template
									v-else-if="!event.metadata || (event.metadata && !event.metadata.hide_end_time)"
								>
									→
									{{
										new Date(event.ends_at).toLocaleString('default', {
											timeZone: j.timezone,
											timeStyle: 'short',
										})
									}}
								</template>
							</span>
						</span>

						<span v-if="event.all_day && !event.multi_day" class="badge bg-neutral-50 text-dark mx-1"
							>All day</span
						>

						<!-- Event icon -->
						<span
							v-if="(event.parent && event.parent[0]) || event.type === 'recurrent'"
							class="badge bg-success-50 text-success-400 mx-1"
						>
							<font-awesome-icon :icon="['fas', 'arrows-rotate']" />
							{{
								event.parent && event.parent.length > 0
									? JSON.parse(event.parent[0].recurrence).frequency
									: event.recurrence.frequency
							}}
						</span>

						<!-- Event categories -->
						<template v-if="event.pid.startsWith('ev_') && event.categories">
							<span
								v-for="cat in event.categories"
								:key="`event-category-id-${cat}`"
								class="badge bg-primary-50 text-primary-400 mx-1"
								>{{ eventCategoryValue(cat, 'name') || `[${cat}]` }}</span
							>
						</template>
						<!-- Meeting categories -->
						<template v-else-if="event.pid.startsWith('me_') && event.categories">
							<span
								v-for="cat in event.categories"
								:key="`meeting-category-id-${cat}`"
								class="badge bg-primary-50 text-primary-400 mx-1"
								>{{ meetingCategoryValue(cat, 'name') || `[${cat}]` }}</span
							>
						</template>
					</p>

					<h2 class="mt-0 mb-2">
						<router-link
							:to="{
								name: event.pid.startsWith('ev_') ? 'EventEmbed' : 'MeetingEmbedPage',
								params: { jurisdiction: j.slug, id: event.pid },
								query: $route.query,
							}"
							:style="generalStyle ? { color: generalStyle.color } : ''"
							>{{ event.name || event.title }}
						</router-link>
					</h2>
				</div>
			</div>
		</div>
		<div v-else class="py-6">
			<p>
				Invalid view <code>{{ view }}</code>
			</p>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import Vue from 'vue'
import { format, parseISO, getMonth, getDaysInMonth, getDay, addMonths, sub, add, startOfMonth } from 'date-fns'
import { Tooltip } from 'bootstrap'

import heyGovApi, { handleResponseError } from '@/api.js'

import EventDetails from '@/components/events/EventDetails.vue'

export default {
	name: 'EventsEmbed',
	components: { EventDetails },
	data() {
		//todo check if month in query is valid
		const date = this.$route.query.month ? parseISO(`${this.$route.query.month}-01`) : new Date()

		return {
			// calendar state
			view: this.$route.query.view || 'calendar',
			type: this.$route.query.type || 'all',
			date: date,

			eventCategories: [],
			meetingCategories: [],
			filters: {
				q: this.$route.query.q || '',
				category: this.$route.query.category || '',
				categoryExclude: this.$route.query.categoryExclude || '',
			},
			eventsMonthsStatus: {},
			meetingsMonthsStatus: {},
			events: [],
			meetings: [],
			months: [
				'January',
				'February',
				'March',
				'April',
				'May',
				'June',
				'July',
				'August',
				'September',
				'October',
				'November',
				'December',
			],
			weekDays: ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],

			month: getMonth(new Date()),
			year: '',

			generalStyle: null,
		}
	},
	computed: {
		...mapState(['j']),
		currentDay() {
			return format(new Date(), 'yyyy-MM-dd')
		},
	},
	created() {
		this.type = this.$route.query.type || (this.j.livemode ? 'events' : 'all')

		// set general style
		if (this.j.style) {
			this.generalStyle = this.j.style.events || this.j.style
		}

		if (this.type === 'all' || this.type === 'events') {
			// load categories
			this.loadEventCategories()

			// load events
			this.loadEvents(this.date)
			this.loadEvents(addMonths(this.date, -1))
			this.loadEvents(addMonths(this.date, 1))
		}
		if (this.type === 'all' || this.type === 'meetings') {
			// load categories
			this.loadMeetingCategories()

			// load meetings
			this.loadMeetings(this.date)
			this.loadMeetings(addMonths(this.date, -1))
			this.loadMeetings(addMonths(this.date, 1))
		}

		//this.loadTooltips()
	},
	updated() {
		this.setIframeHeight()
	},
	mounted() {
		window.addEventListener('load', this.setIframeHeight)
	},
	methods: {
		getDay,
		format,

		loadEventCategories() {
			heyGovApi.get(`/${this.j.slug}/events/categories`).then(({ data }) => {
				this.eventCategories.push(...data)
			}, handleResponseError(`Error loading event categories ({error})`))
		},
		loadMeetingCategories() {
			heyGovApi.get(`/${this.j.slug}/meetings/categories`).then(({ data }) => {
				this.meetingCategories.push(...data)
			}, handleResponseError(`Error loading meeting categories ({error})`))
		},

		loadEvents(month) {
			const params = {
				month: format(month, 'yyyy-MM'),
				source: 'calendar-embed',
			}

			if (!(params.month in this.eventsMonthsStatus)) {
				this.eventsMonthsStatus[params.month] = 'loading'

				heyGovApi.get(`/${this.j.slug}/events?expand=parent`, { params }).then(
					({ data }) => {
						const loadedEvents = data.map(event => {
							event.starts_at_local_day = event.starts_at_local.slice(0, 10)
							event.ends_at_local_day = event.ends_at_local.slice(0, 10)
							event.multi_day = event.starts_at_local_day !== event.ends_at_local_day

							event.all_day =
								(new Date(event.starts_at).toLocaleTimeString('en-US', { timeZone: this.j.timezone }) ==
									'12:00:00 AM' &&
									new Date(event.ends_at).toLocaleTimeString('en-US', {
										timeZone: this.j.timezone,
									})) == '11:59:00 PM'

							return event
						})

						this.events.push(...loadedEvents)
						this.eventsMonthsStatus[params.month] = 'loaded'
					},
					error => {
						Vue.toasted.error(`Error loading events (${error.message})`)
					}
				)
			}
		},

		loadMeetings(month) {
			const params = {
				month: format(month, 'yyyy-MM'),
			}

			if (!(params.month in this.meetingsMonthsStatus)) {
				this.meetingsMonthsStatus[params.month] = 'loading'

				heyGovApi.get(`/${this.j.slug}/meetings`, { params }).then(({ data }) => {
					const loadedMeetings = data.map(meeting => {
						meeting.starts_at_local_day = meeting.starts_at.slice(0, 10)
						//meeting.ends_at_local_day = meeting.ends_at.slice(0, 10)

						return meeting
					})

					this.meetings.push(...loadedMeetings)
					this.meetingsMonthsStatus[params.month] = 'loaded'
				}, handleResponseError(`Error loading meetings ({error})`))
			}
		},

		updatePageUrl() {
			let query = {
				month: format(this.date, 'yyyy-MM'),
			}

			if (!['calendar', 'day'].includes(this.view)) {
				query.view = this.view
			}

			for (const filter in this.filters) {
				if (this.filters[filter]) {
					query[filter] = this.filters[filter]
				}
			}

			this.$router.replace({ path: `/${this.j.slug}/events-embed`, query }).catch(() => {})
		},
		changeMonth(months = 1) {
			// pre-load events
			if (this.type === 'all' || this.type === 'events') this.loadEvents(addMonths(this.date, 2 * months))
			if (this.type === 'all' || this.type === 'meetings') this.loadMeetings(addMonths(this.date, 2 * months))

			this.date = addMonths(this.date, months)

			this.updatePageUrl()
		},
		calendarDays() {
			const month = startOfMonth(this.date)
			const days = []

			// add previous month days
			const prevMonthDays = getDay(month)

			for (let i = prevMonthDays; i > 0; i--) {
				const monthDate = sub(month, { days: i })

				days.push({
					date: monthDate,
					type: 'previous-month',
					fullDate: format(monthDate, 'yyyy-MM-dd'),
					class: 'hg-prev-month-day',
				})
			}

			const daysInMonth = getDaysInMonth(month)

			for (let i = 1; i <= daysInMonth; i++) {
				const monthDate = add(month, { days: i - 1 })

				days.push({
					date: monthDate,
					type: 'current-month',
					fullDate: format(monthDate, 'yyyy-MM-dd'),
					class: 'hg-current-month-day',
				})
			}

			const lastDay = days[days.length - 1].date
			const nextMonthDays = 6 - getDay(lastDay)

			for (let i = 1; i <= nextMonthDays; i++) {
				const monthDate = add(lastDay, { days: i })

				days.push({
					date: monthDate,
					type: 'next-month',
					fullDate: format(monthDate, 'yyyy-MM-dd'),
					class: 'hg-next-month-day',
				})
			}

			return days
		},

		eventsForDay(date) {
			const day = format(date, 'yyyy-MM-dd')

			const events = this.events.filter(event => {
				const dateMatch =
					event.starts_at_local_day === day ||
					event.ends_at_local_day === day ||
					(event.starts_at_local_day < day && event.ends_at_local_day > day)

				return dateMatch
			})
			const meetings = this.meetings.filter(meeting => meeting.starts_at_local_day === day)

			let filteredEvents = this.applyFilters([...events, ...meetings])

			if (filteredEvents.length > 1) {
				filteredEvents.sort((event1, event2) => {
					// show multi-day events first
					if (event1.multi_day || event2.multi_day) {
						return event1.multi_day ? -1 : 1
					}

					return event1.starts_at < event2.starts_at ? -1 : 1
				})
			}

			return filteredEvents
		},
		eventsForMonth(date) {
			const day = format(date, 'yyyy-MM-')

			const events = this.events.filter(event => {
				return event.starts_at_local_day.startsWith(day)
			})
			const meetings = this.meetings.filter(meeting => {
				return meeting.starts_at_local_day.startsWith(day)
			})

			let filteredEvents = this.applyFilters([...events, ...meetings])

			filteredEvents.sort((event1, event2) => {
				// sort by title if the start date is the same
				if (event1.starts_at == event2.starts_at) {
					const event1Name = (event1.name || event1.title).toLowerCase()
					const event2Name = (event2.name || event2.title).toLowerCase()

					return event1Name < event2Name ? -1 : 1
				}

				return event1.starts_at < event2.starts_at ? -1 : 1
			})

			return filteredEvents
		},
		applyFilters(events) {
			// apply the category filter
			if (this.filters.category) {
				const categoryFilters = this.filters.category.split(',');

				// Fetch category IDs based on slugs
				const categoryIds = categoryFilters.flatMap(filter => {
					if (filter.startsWith('ev-') || filter.startsWith('me-')) {
						return filter; // Keep the filter as is for ev- and me-
					} else {
						const ids = [];
						const category = this.eventCategories.find(cat => cat.slug === filter);
						if (category) {
							ids.push(`ev-${category.id}`);
						}
						const meetingCategory = this.meetingCategories.find(cat => cat.slug === filter);
						if (meetingCategory) {
							ids.push(`me-${meetingCategory.id}`);
						}
						return ids; // Return array with both IDs if found
					}
				}).filter(Boolean);

				// Assign the category ID if only one category slug/id is found
				if (categoryIds.length === 1) {
					this.filters.category = categoryIds[0];
				}

				events = events.filter(event => {
					return categoryIds.some(filter => {
						const type = filter.startsWith('ev-') ? 'ev_' : 'me_';
						return (
							event.pid.startsWith(type) && event.categories.includes(Number(filter.slice(3)))
						);
					});
				});
			}

			if (this.filters.categoryExclude) {
				const excludeFilters = this.filters.categoryExclude.split(',');

				const excludeIds = excludeFilters.flatMap(filter => {
					if (filter.startsWith('ev-') || filter.startsWith('me-')) {
						return filter; // Keep the filter as is for ev- and me-
					} else {
						const ids = [];
						const category = this.eventCategories.find(cat => cat.slug === filter);
						if (category) {
							ids.push(`ev-${category.id}`);
						}
						const meetingCategory = this.meetingCategories.find(cat => cat.slug === filter);
						if (meetingCategory) {
							ids.push(`me-${meetingCategory.id}`);
						}
						return ids; // Return array with both IDs if found
					}
				}).filter(Boolean);

				// Remove excluded categories from eventCategories and meetingCategories
				excludeFilters.forEach(filter => {
					const categoryIndex = this.eventCategories.findIndex(cat => cat.slug === filter);
					if (categoryIndex > -1) {
						this.eventCategories.splice(categoryIndex, 1);
					}

					const meetingCategoryIndex = this.meetingCategories.findIndex(cat => cat.slug === filter);
					if (meetingCategoryIndex > -1) {
						this.meetingCategories.splice(meetingCategoryIndex, 1);
					}
				});

				events = events.filter(event => {
					return excludeIds.every(filter => {
						const type = filter.startsWith('ev-') ? 'ev_' : 'me_';
						return !(
							event.pid.startsWith(type) && event.categories.includes(Number(filter.slice(3)))
						);
					});
				});
			}

			// apply the search query filter
			const searchQuery = this.filters.q.trim().toLowerCase();
			if (searchQuery.length) {
				events = events.filter(event => {
					return (event.name || event.title).toLowerCase().includes(searchQuery);
				});
			}

			return events;
		},

		getEventCategoryColor(event) {
			let color = event.pid.startsWith('ev_') ? '#5E81F4' : '#FCAB1C'

			if (event.categories.length) {
				const category = this.eventCategories.find(cat => cat.id === event.categories[0])

				return category?.style?.backgroundColor || color
			}

			return color
		},

		eventTooltipText(event) {
			let eventText = `<div class="rounded rounded-0 px-2 py-1 text-start"> ${event.name} </br>`
			if (this.eventCategories.length) {
				eventText += `<small class="text-muted mb-2"> ${
					event.categories ? this.eventCategoryValue(event?.categories[0], 'name') : 'No event category'
				} </small> </br>`
			}
			eventText += `<small>`
			if (event.all_day) {
				eventText += `All day </small>`
			}
			if (event.multi_day) {
				eventText += `<small>${this.getDate(event.starts_at)} → ${this.getDate(event.ends_at)}</small>`
				eventText += `</br><small>${this.getTime(event.starts_at)} → ${this.getTime(event.ends_at)}</small>`
			}
			if (event.metadata && event.metadata.hide_end_time) {
				eventText += `<small>${this.getTime(event.starts_at)}</small>`
			} else if (event.metadata && !event.metadata.hide_end_time && !event.all_day && !event.multi_day) {
				eventText += `<small>${this.getTime(event.starts_at)} → ${this.getTime(event.ends_at)}</small>`
			}

			eventText += `</small></br></div>`

			return eventText
		},
		resetPostMessage() {
			const targetWindow = window.opener || window.parent

			return targetWindow.postMessage(
				{
					heygov_action: 'url-params',
					heygov_url_params: {
						view: 'heygov-events-month',
						year: this.year,
						month: format(this.date, 'MM'),
						category: this.filters.category,
						q: this.filters.q,
						jurisdiction: this.j.slug,
					},
				},
				'*'
			)
		},
		setIframeHeight() {
			const targetWindow = window.opener || window.parent

			return targetWindow.postMessage(
				{
					heygov_action: 'set-element-style',
					element: '.heygov-events',
					styles: {
						height: `${document.body.scrollHeight}px`,
					},
				},
				'*'
			)
		},
		getTime(time) {
			return new Date(time).toLocaleTimeString(undefined, {
				timeZone: this.j.timezone,
				hour: 'numeric',
				minute: 'numeric',
			})
		},
		getDate(date) {
			return new Date(date).toLocaleDateString(undefined, {
				timeZone: this.j.timezone,
				month: 'numeric',
				day: 'numeric',
			})
		},
		loadTooltips() {
			setTimeout(() => {
				document.querySelectorAll('[data-bs-toggle="tooltip"]').forEach(el => {
					new Tooltip(el)
				})
			}, 500)
		},
		eventCategoryValue(cat, value) {
			const category = this.eventCategories.find(category => category.id == cat)
			return category?.[value]
		},
		meetingCategoryValue(cat, value) {
			const category = this.meetingCategories.find(category => category.id == cat)
			return category?.[value]
		},
	},
	beforeDestroy() {
		const tooltips = document.querySelectorAll('.hg-events-tooltip')

		tooltips.forEach(el => {
			el.classList.add('d-none')
		})
	},
	watch: {
		view: {
			handler: 'updatePageUrl',
		},
		filters: {
			handler: 'updatePageUrl',
			deep: true,
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/assets/variables';

.hg-events-page {
	//padding-top: 80px;

	.btn-events-view {
		border: 1px solid #dedede;
		border-radius: var(--hg-border-radius);

		.btn:first-child {
			border: 0;
			border-radius: var(--hg-border-radius) 0 0 var(--hg-border-radius);
		}

		.btn:last-child {
			border: 0;
			border-radius: 0 var(--hg-border-radius) var(--hg-border-radius) 0;
		}

		.btn {
			&:hover {
				background-color: #f8f9fa;
			}

			&.btn-active {
				background-color: var(--hg-secondary-color);
				color: #fff;
			}
		}

		.btn-text {
			@media (max-width: 575.98px) {
				display: none;
			}
		}
	}

	.calendar-back,
	.calendar-forward {
		background-color: transparent;
		border: none;
		border-radius: 0.5rem;

		&:disabled {
			cursor: not-allowed;
			color: #898c90;
		}

		&:hover {
			background-color: $neutral-50;
		}
	}

	.hg-calendar {
		display: grid;
		grid-template-columns: repeat(7, 1fr);

		.hg-week-days {
			border-bottom: 1px solid #dedede;
		}

		.hg-calendar-day {
			position: relative;
			border-left: 1px solid #dedede;
			border-bottom: 1px solid #dedede;
			aspect-ratio: 5 / 4;
			transition: background-color 0.15s;
			overflow: hidden;

			&:nth-child(7n) {
				border-right: 1px solid #dedede;
			}

			.hg-calendar-day-number {
				display: block;
				margin: 0 auto;
				width: 24px;
				text-align: center;
				font-size: 0.9rem;
				font-weight: 500;
				line-height: 24px;
				border-radius: 50%;
			}

			.heygov-calendar-day-show-more {
				position: absolute;
				display: block;
				bottom: 0;
				left: 0;
				right: 0;
				background-color: $neutral-50;
				border: 0;
				border-radius: 0.25rem;
				font-size: 0.8rem;
				color: $neutral-400;
			}

			&:hover {
				background-color: #f8fafc;

				.heygov-calendar-day-show-more {
					background-color: $neutral-100;
					color: $neutral-500;
				}
			}
		}

		.hg-calendar-day.hg-calendar-day-previous-month,
		.hg-calendar-day.hg-calendar-day-next-month {
			.hg-calendar-day-number {
				color: $neutral-300;
			}
		}

		.hg-calendar-day.hg-calendar-day-today {
			.hg-calendar-day-number {
				background-color: $primary-200;
				color: #fff;
			}
		}

		.hg-calendar-day.hg-calendar-day-weekend {
			background-color: #f8fafc;

			&:hover {
				background-color: #f1f5f9;
			}
		}

		.hg-calendar-event {
			display: flex;
			gap: 0.3rem;
			align-items: center;
			border-radius: 0.25rem;
			transition: background-color 0.15s;
			padding-left: 6px;

			&:hover {
				background-color: $neutral-200;
				text-decoration: none;
			}

			.hg-calendar-event-dot {
				width: 6px;
				height: 6px;
				border-radius: 50%;
				background-color: var(--hg-event-category-color);
				flex: none;
			}

			&.type-meeting .hg-calendar-event-dot {
				border-radius: 25%;
			}

			.hg-calendar-event-time {
				flex: none;
				font-size: 0.8rem;
				font-weight: bold;
				color: #475569;
			}

			.hg-calendar-event-name {
				flex-grow: 1;
				font-size: 0.9rem;
				color: #334155;
				overflow: hidden;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 1;
			}

			&.hg-calendar-event-multi-day {
				background-color: var(--hg-event-category-color);
				border-radius: 0;

				&.hg-calendar-event-multi-day-first {
					border-top-left-radius: 0.35rem;
					border-bottom-left-radius: 0.35rem;
				}

				&.hg-calendar-event-multi-day-last {
					border-top-right-radius: 0.35rem;
					border-bottom-right-radius: 0.35rem;
				}

				.hg-calendar-event-dot {
					display: none;
				}

				&:not(.hg-calendar-event-multi-day-first) .hg-calendar-event-time {
					display: none;
				}

				.hg-calendar-event-time {
					color: #f8fafc;
				}

				.hg-calendar-event-name {
					color: #f1f5f9;
				}
			}
		}
	}

	.heygov-calendar-list-event {
		border-bottom: 1px solid $neutral-100;
		padding: 0.5rem 0.5rem 0;
		transition: background-color 0.2s;

		&:hover {
			background-color: $neutral-50;
			border-radius: 0.5rem;
		}
	}
}

.hg-calendar-text-sm {
	font-size: 0.6rem;
	border-radius: 5px;
}
.hg-calendar-text-md {
	font-size: 0.8rem;
	border-radius: 5px;
	padding: 2px 0;
}
.current-day {
	padding: 2px;
}
.hg-events-underline:hover {
	text-decoration: underline;
}
.hg-prev-month-day,
.hg-next-month-day {
	color: rgb(139, 131, 131, 0.5);
}

@media (max-width: 575.98px) {
	.hg-calendar-day div span,
	.hg-week-days {
		font-weight: normal;
		font-size: 0.4rem;
	}
	.current-day {
		padding: 2px 1px !important;
	}
}

.hg-calendar {
	@media (max-width: 575.98px) {
		.hg-calendar-day {
			aspect-ratio: 1 / 1.8 !important;
			padding: 4px;

			.events-indicator {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 24px;
				height: 24px;
				border-radius: 50%;
				background-color: var(--hg-event-category-color) !important;
				color: white;
				margin: 8px auto;
				text-decoration: none;
				border: none;
				padding: 0;
				cursor: pointer;
			}

			&:hover {
				opacity: 0.8;
				transform: scale(1.05);
				transition: all 0.2s ease;
			}
		}
	}
}
</style>
