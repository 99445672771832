<template>
	<div class="page-category-edit">
		<h3 class="mb-3">
			<router-link :to="`/${j.slug}/meetings`">Meetings</router-link>
			<font-awesome-icon :icon="['fas', 'angle-right']" class="text-muted mx-2" />
			<router-link :to="`/${j.slug}/meetings/categories`">Categories</router-link>
			<font-awesome-icon :icon="['fas', 'angle-right']" class="text-muted mx-2" />
			{{ category ? category.name : 'Edit Category' }}
		</h3>

		<div v-if="states.category === 'loading'" class="text-center py-5">
			<span class="spinner-border spinner-border-sm"></span> Loading category...
		</div>
		<div v-else-if="states.category === 'error'" class="text-center py-5">
			<p class="text-danger">{{ errorMessage }}</p>
		</div>
		<div v-else>
			<!-- Category Name -->
			<div class="card mb-4">
				<form @submit.prevent="saveCategory">
					<div class="card-header">
						<h5 class="my-0">Category Details</h5>
					</div>
					<div class="card-body">
						<div class="row">
							<div class="col-md-6">
								<div class="form-group">
									<label class="form-label" for="category-name"
										>Name <span class="text-danger">*</span></label
									>
									<input
										type="text"
										class="form-control"
										id="category-name"
										v-model="category.name"
										required
									/>
								</div>
							</div>
						</div>
					</div>
					<div class="card-footer text-end">
						<button type="submit" class="btn btn-primary px-5" :disabled="states.save === 'loading'">
							<span v-if="states.save === 'loading'" class="spinner-border spinner-border-sm me-1"></span>
							Save Category
						</button>
					</div>
				</form>
			</div>

			<!-- Template Settings -->
			<div class="card mb-5">
				<form @submit.prevent="saveTemplateSettings">
					<div class="card-header">
						<h5 class="my-0">Template Settings</h5>
					</div>
					<div class="card-body">
						<!-- Header Settings Row 1 -->
						<div class="row mb-3">
							<div class="col-md-4">
								<label for="logo" class="form-label">Municipality Logo</label>
								<select v-model="category.template_logo" id="logo" class="form-select">
									<option :value="null">No Logo</option>
									<option value="department">Department Logo</option>
									<option value="jurisdiction">Municipality Logo</option>
								</select>
							</div>
							<div class="col-md-4">
								<label for="header_alignment" class="form-label">Header Alignment</label>
								<select
									v-model="category.template_header_alignment"
									id="header_alignment"
									class="form-select"
								>
									<option value="left">Left</option>
									<option value="center">Center</option>
								</select>
							</div>
							<div class="col-md-4">
								<label for="titles_font" class="form-label">Titles Font</label>
								<select v-model="category.template_titles_font" id="titles_font" class="form-select">
									<option value="arimo">Arimo (Arial)</option>
									<option value="times">Times New Roman</option>
									<option value="calibri">Calibri</option>
									<option value="helvetica">Helvetica</option>
									<option value="verdana">Verdana</option>
								</select>
							</div>
						</div>

						<div class="mb-3">
							<label for="header_note" class="form-label">Header Note</label>
							<textarea
								v-model="category.template_agenda_header_note"
								id="header_note"
								class="form-control"
							></textarea>
						</div>

						<!-- Content Settings -->
						<div class="row mb-3">
							<div class="col-md-4">
								<label for="content_font" class="form-label">Content Font</label>
								<select v-model="category.template_content_font" id="content_font" class="form-select">
									<option value="arimo">Arimo (Arial)</option>
									<option value="times">Times New Roman</option>
									<option value="calibri">Calibri</option>
									<option value="helvetica">Helvetica</option>
									<option value="verdana">Verdana</option>
								</select>
							</div>
							<div class="col-md-4">
								<label for="main_items_numbering" class="form-label">Items Numbering Format</label>
								<select
									v-model="category.template_items_numbering_style"
									id="main_items_numbering"
									class="form-select"
								>
									<option value="alphabetic">A, B, C</option>
									<option value="numeric">1, 2, 3</option>
									<option value="roman">I, II, III</option>
								</select>
							</div>
							<div class="col-md-4">
								<label for="sub_items_numbering" class="form-label">Sub-items Numbering Format</label>
								<select
									v-model="category.template_subitems_numbering_style"
									id="sub_items_numbering"
									class="form-select"
								>
									<option value="alphabetic">a, b, c</option>
									<option value="numeric">1, 2, 3</option>
									<option value="roman">i, ii, iii</option>
								</select>
							</div>
						</div>

						<div class="row mb-3">
							<div class="col-auto me-4">
								<div class="form-check">
									<input
										type="checkbox"
										v-model="category.template_agenda_item_text"
										id="show_item_description"
										class="form-check-input"
									/>
									<label for="show_item_description" class="form-check-label"
										>Show Item Description</label
									>
								</div>
							</div>
							<div class="col-auto">
								<div class="form-check">
									<input
										type="checkbox"
										v-model="category.template_agenda_item_speaker"
										id="show_speaker_name"
										class="form-check-input"
									/>
									<label for="show_speaker_name" class="form-check-label">Show Speaker Name</label>
								</div>
							</div>
						</div>

						<div class="mb-3">
							<label for="footer_note" class="form-label">Footer Note</label>
							<textarea
								v-model="category.template_agenda_footer_note"
								id="footer_note"
								class="form-control"
							></textarea>
						</div>
					</div>
					<div class="card-footer text-end">
						<button
							type="submit"
							class="btn btn-primary px-5"
							:disabled="states.saveTemplate === 'loading'"
						>
							<span
								v-if="states.saveTemplate === 'loading'"
								class="spinner-border spinner-border-sm me-1"
							></span>
							Save Template Settings
						</button>
					</div>
				</form>
			</div>

			<!-- Danger Zone -->
			<div class="card bg-danger-lighter">
				<div class="card-header">
					<h5 class="my-0">Danger zone</h5>
				</div>
				<div class="card-body">
					<h5>Delete Category</h5>
					<p class="card-text">
						This will permanently delete this category and all associated template settings. This action
						cannot be undone.
					</p>
					<button type="button" class="btn btn-danger" @click="deleteCategory">
						Delete Category
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import Vue from 'vue'
import { hgApi } from '@/api.js'

export default {
	name: 'CategoryEdit',
	data() {
		return {
			states: {
				category: 'loading',
				save: 'idle',
				saveTemplate: 'idle',
			},
			category: null,
			errorMessage: '',
		}
	},
	computed: {
		...mapState(['j']),
	},
	created() {
		this.loadCategory()
	},
	methods: {
		async loadCategory() {
			this.states.category = 'loading'
			try {
				const response = await hgApi(`${this.j.slug}/meetings/categories/${this.$route.params.categoryId}`)
				if (!response.ok) {
					throw new Error(response.statusText || 'Server error')
				}
				this.category = await response.json()
				this.states.category = 'loaded'
			} catch (error) {
				this.errorMessage = error.message
				this.states.category = 'error'
				Vue.toasted.error(`Couldn't load category (${error})`)
			}
		},

		async saveCategory() {
			this.states.save = 'loading'
			try {
				const response = await hgApi(`${this.j.slug}/meetings/categories/${this.category.id}`, {
					method: 'PUT',
					body: { name: this.category.name },
				})
				if (!response.ok) {
					throw new Error(response.statusText || 'Server error')
				}
				Vue.toasted.success('Category updated successfully')
			} catch (error) {
				Vue.toasted.error(`Couldn't update category (${error})`)
			} finally {
				this.states.save = 'idle'
			}
		},

		async saveTemplateSettings() {
			this.states.saveTemplate = 'loading'
			try {
				const templateSettings = {
					template_logo: this.category.template_logo,
					template_header_alignment: this.category.template_header_alignment,
					template_titles_font: this.category.template_titles_font,
					template_agenda_header_note: this.category.template_agenda_header_note,
					template_content_font: this.category.template_content_font,
					template_items_numbering_style: this.category.template_items_numbering_style,
					template_subitems_numbering_style: this.category.template_subitems_numbering_style,
					template_agenda_item_text: Boolean(this.category.template_agenda_item_text),
					template_agenda_item_speaker: Boolean(this.category.template_agenda_item_speaker),
					template_agenda_footer_note: this.category.template_agenda_footer_note,
				}

				const response = await hgApi(`${this.j.slug}/meetings/categories/${this.category.id}`, {
					method: 'PUT',
					body: templateSettings,
				})

				if (!response.ok) {
					throw new Error(response.statusText || 'Server error')
				}

				Vue.toasted.success('Template settings updated successfully')
			} catch (error) {
				Vue.toasted.error(`Couldn't update template settings (${error})`)
			} finally {
				this.states.saveTemplate = 'idle'
			}
		},

		async deleteCategory() {
			if (!confirm('Are you sure you want to delete this category?')) {
				return
			}

			try {
				const response = await hgApi(`${this.j.slug}/meetings/categories/${this.category.id}`, {
					method: 'DELETE',
				})
				if (!response.ok) {
					throw new Error(response.statusText || 'Server error')
				}
				Vue.toasted.success('Category deleted successfully')
				this.$router.push(`/${this.j.slug}/meetings/categories`)
			} catch (error) {
				Vue.toasted.error(`Couldn't delete category (${error})`)
			}
		},
	},
}
</script>
