<template>
	<div class="page-form-builder">
		<div v-if="form">
			<form-page-head :form="form"></form-page-head>

			<div v-if="this.getActiveSteps().length" class="card">
				<div class="card-body">
					<div class="row">
						<!-- Form Steps Sidebar - (Listed all form steps on left side) -->
						<div class="col-md-4 mb-4">
							<div class="steps-left-sidebar">
								<draggable :list="form.steps" @end="updateStepsOrder(form.steps)" :sort="true">
									<template v-for="(step, stepIndex) in this.form.steps">
										<div
											:key="`div-${stepIndex}`"
											class="step-block mb-2"
											@click="setActiveStep(step)"
											v-if="!step.removed"
										>
											<div
												class="step-wrapper rounded-1"
												:class="{
													active: activeStep.id === step.id,
												}"
											>
												<div class="step-number me-3">
													<h5 class="step-circle">{{ stepIndex + 1 }}</h5>
												</div>
												<div>
													<h5>{{ step.name }}</h5>
													<p :title="step.description" class="mb-0">
														{{ truncateString(step.description, 200) }}
													</p>
												</div>
											</div>
										</div>
									</template>
								</draggable>

								<p class="mb-4">
									<button class="btn btn-sm btn-big-icon btn-outline-primary" @click="createStep">
										Create Step <font-awesome-icon :icon="['fas', 'plus-circle']" />
									</button>
								</p>

								<p>
									<a
										href="https://townweb.notion.site/How-to-build-forms-9d8c54f93a434ee5b013e97d01d11812"
										target="heygov-help"
										>🙋 How to build forms</a
									>
								</p>

								<!-- Trashed steps switch -->
								<div
									class="form-check form-switch ms-5 mt-3 d-inline-block"
									v-if="getRemovedSteps().length > 0"
								>
									<input
										role="button"
										type="checkbox"
										class="form-check-input"
										v-model="displayRemovedSteps"
										:id="'trashedSteps-' + activeStep.id"
									/>
									<label
										role="button"
										class="form-check-label ms-2"
										:for="'trashedSteps-' + activeStep.id"
										>show trashed steps</label
									>
								</div>
								<div
									class="bg-danger-lighter mt-3 py-3 ps-3 rounded-3"
									v-if="displayRemovedSteps && getRemovedSteps().length > 0"
								>
									<h6>
										<font-awesome-icon :icon="['fas', 'trash']" class="fa-fw text-danger" />
										Trashed steps
									</h6>
									<hr class="me-3" />
									<small class="d-flex"
										>⚠️ Trashed steps are stored and not deleted permanently because they contain
										elements with data from some form request</small
									>
									<template v-for="(step, stepIndex) in getRemovedSteps()">
										<div :key="`div-${stepIndex}`" class="step-block mt-3">
											<div class="row">
												<div class="col-md-12 position-relative">
													<a
														class="btn btn-sm btn-icon-info position-absolute"
														style="top: -20px"
														title="Restore step"
														data-bs-toggle="tooltip"
														@click="restoreStep(step)"
													>
														<font-awesome-icon :icon="['fas', 'undo']" class="fa-fw" />
													</a>
													<div class="step-wrapper ms-1 me-3 bg-light shadow-sm">
														<div>
															<h5>{{ step.name }}</h5>
															<p>
																{{ step.description }}
															</p>
														</div>
													</div>
												</div>
											</div>
										</div>
									</template>
								</div>
							</div>
						</div>
						<!-- ### Form Steps Sidebar -->

						<!-- Form Builder - (Editing step and building elements for step) -->
						<div class="col">
							<div class="step-fields-wrapper">
								<div class="bg-neutral-50 p-3 rounded-1" v-if="activeStep && activeStep.fields">
									<h4
										id="step-title"
										class="my-0"
										data-bs-toggle="collapse"
										data-bs-target="#inline-edit-step"
										@click="editStep(activeStep)"
									>
										{{ activeStep.name }}

										<span class="btn btn-sm btn-icon-info ms-2">
											<font-awesome-icon :icon="['fas', 'pencil-alt']" />
										</span>
									</h4>

									<div class="step-edit collapse" id="inline-edit-step">
										<!-- Update Step - (Accordion dialog for updating active step) -->
										<form
											class="pt-2"
											v-show="!isRemovingStep"
											@submit.prevent="inlineUpdateStep(activeStep)"
										>
											<div class="row">
												<div class="col-md-6">
													<div class="form-group mb-3">
														<label
															@dblclick="displayRemovedElements = !displayRemovedElements"
															:for="'stepName-' + activeStep.id"
															class="form-label"
														>
															Step Name
															<span title="Required" class="file-required text-danger"
																>*</span
															>
														</label>
														<input
															type="text"
															v-model="activeStep.name"
															class="form-control"
															name="stepName"
															:id="'stepName-' + activeStep.id"
															required
														/>
													</div>

													<div class="form-group mb-3">
														<label
															class="form-label"
															:for="'stepDescription-' + activeStep.id"
															>Step description</label
														>
														<textarea
															v-model="activeStep.description"
															class="form-control"
															name="stepDescription"
															:id="'stepDescription-' + activeStep.id"
															rows="2"
														></textarea>
													</div>
												</div>

												<div class="col-md-6">
													<div class="form-group mb-3">
														<label for="stepVisibleTo" class="form-label">
															Who needs to fill?
															<span title="Required" class="file-required text-danger"
																>*</span
															>
														</label>
														<select class="form-select" v-model="activeStep.visible_to">
															<option value="EVERYONE" selected>Applicant</option>
															<option value="DEPARTMENT">Department person</option>
														</select>
													</div>

													<div class="form-group mb-3">
														<div class="form-check ps-0">
															<input
																type="checkbox"
																class="form-check-input ms-1"
																v-model="activeStep.review_required"
																id="step_review_required"
															/>
															<label class="form-label ms-1" for="step_review_required">
																step need review?
															</label>

															<select
																:required="activeStep.review_required"
																class="form-select"
																id="review_department_id"
																v-model="activeStep.review_department_id"
																:disabled="!activeStep.review_required"
															>
																<option :value="null" selected>
																	- Select department -</option
																>
																<option
																	v-for="(department, index) in departments"
																	:key="index"
																	:value="department.id"
																>
																	{{ department.name }}
																</option>
															</select>
														</div>
													</div>
												</div>

												<div class="col-md-6">
													<a
														class="btn btn-link text-danger ps-0"
														@click="isRemovingStep = true"
														>Remove step
													</a>
													<!-- Trashed elements switch - (Switch to show trashed elements of step) -->
													<div
														class="form-check form-switch ms-3"
														v-if="getRemovedStepElements(activeStep).length > 0"
													>
														<input
															role="button"
															type="checkbox"
															class="form-check-input"
															v-model="displayRemovedElements"
															:id="'trashedElements-' + activeStep.id"
														/>
														<label
															role="button"
															class="form-check-label ms-2"
															:for="'trashedElements-' + activeStep.id"
															>trashed elements</label
														>
													</div>
												</div>

												<div class="col-md-6 text-end">
													<a
														class="btn btn-link"
														data-bs-toggle="collapse"
														data-bs-target="#inline-edit-step"
														aria-expanded="false"
														aria-controls="inline-edit-step"
														id="btn-cancel-inline-edit"
														@click="cancelUpdatingStep(activeStep)"
													>
														Cancel
													</a>
													<button class="btn btn-primary">Update step</button>
												</div>
											</div>
										</form>
										<!-- ### Update Step -->

										<!-- Remove Step - (Confirmation dialog for removing step) -->
										<div class="pt-2" v-if="isRemovingStep">
											<div class="py-3">
												<h5 class="text-center">Are you sure you want to remove this step?</h5>
												<br />
												<p class="text-center" v-if="!safeToRemoveStep(activeStep)">
													This step has elements been used in previous requests and may impair
													their functionality.<br />
													Type
													<span class="font-weight-bold text-uppercase">
														{{ activeStep.id }}
													</span>
													into next field to remove this step
													<input
														type="text"
														name="removeOptionForce"
														id="removeOptionForce"
														v-model="removeStepInput"
													/>
												</p>
											</div>

											<div class="text-end">
												<a class="btn btn-link" @click="isRemovingStep = false">Cancel</a>
												<button
													v-if="safeToRemoveStep(activeStep)"
													@click="removeStep(activeStep)"
													class="btn btn-danger"
												>
													Remove
												</button>
												<button
													v-else
													@click="removeStep(activeStep)"
													:disabled="isStepRemoveBtnDisabled"
													class="btn btn-danger"
												>
													Remove
												</button>
											</div>
										</div>
										<!-- ### Remove Step -->
									</div>
								</div>

								<div
									class="trashed-elements mt-3 bg-danger-lighter p-3 rounded shadow-sm"
									v-if="displayRemovedElements && getRemovedStepElements(activeStep).length > 0"
								>
									<h6>
										<font-awesome-icon :icon="['fas', 'trash']" class="text-danger" />
										Trashed elements
									</h6>
									<hr />
									<small class="ms-3"
										>⚠️ Trashed elements are stored and not deleted permanently because they contain
										data from some form request</small
									>
									<div class="p-3">
										<template v-for="(field, index) in getRemovedStepElements(activeStep)">
											<div
												:key="index"
												v-bind="field"
												:class="'form-group form-group-builder  col-' + field.col"
											>
												<div class="element-wrapper bg-light shadow-sm rounded p-3 mt-3">
													<label
														class="element-label form-label"
														:for="'fsf-' + field.id"
														v-if="displayLabel(field)"
													>
														{{ field.label }}
														<span
															title="Required"
															v-if="field.required"
															class="file-required text-danger"
															>*</span
														>
														<div
															title="Redacted"
															v-if="field.redacted"
															class="file-redacted"
														>
															*
														</div>
													</label>

													<component
														:is="field.type"
														class="element"
														:currentField="field"
														mode="edit"
														v-on:change="$emit('change', $event.target.value)"
													></component>

													<small
														v-if="field.type !== 'Paragraph' || field.type !== 'Heading'"
														class="element-help"
													>
														{{ field.help }}
													</small>

													<!-- Actions list -->
													<div class="element-action-buttons">
														<a
															class="btn btn-sm btn-icon-info"
															title="Restore element"
															@click="restoreElement(field)"
														>
															<font-awesome-icon :icon="['fas', 'undo']" class="fa-fw" />
														</a>
													</div>
												</div>
											</div>
										</template>
									</div>
								</div>
								<div class="active-elements px-3 mt-3" v-if="activeStep && activeStep.fields">
									<draggable
										:list="activeStep.fields"
										class="row"
										@end="updateElementsOrder(activeStep.fields)"
										:sort="true"
									>
										<!-- The form elements starts (on the right) -->
										<template v-for="(field, index) in activeStep.fields">
											<div
												:key="index"
												v-bind="field"
												:class="
													'form-group form-group-builder p-2 pt-3 mt-1 position-relative rounded col-' +
														field.col +
														' ' +
														visibleField(field)
												"
												v-if="!field.removed"
											>
												<div
													class="
														element-infos
														d-flex
														align-items-center
														justify-content-start
														fs-6
													"
												>
													<span class="element-display bg-info">
														<font-awesome-icon
															:icon="['fas', 'clipboard-list']"
															class="fa-fw"
															v-if="field.display === 'both' || field.display === 'form'"
															title="This element is displayed on the form"
														/>

														<font-awesome-icon
															:icon="['fas', 'file-pdf']"
															class="fa-fw"
															v-if="field.display === 'both' || field.display === 'pdf'"
															title="This element is displayed in pdf"
														/>
													</span>
													<span class="element-type bg-secondary">{{ field.name }}</span>
													<span
														class="element-conditions bg-info"
														v-if="drawElementConditions(field).length > 0"
													>
														<a
															role="button"
															:title="drawElementConditions(field)"
															data-bs-toggle="tooltip"
															data-bs-html="true"
														>
															<font-awesome-icon
																:icon="['fas', 'microchip']"
																class="fa-fw"
															/>
														</a>
													</span>
												</div>

												<div class="element-wrapper">
													<label
														class="element-label form-label"
														:for="'fsf-' + field.id"
														v-if="displayLabel(field)"
													>
														{{ field.label }}
														<span
															title="Required"
															v-if="field.required"
															class="file-required text-danger"
															>*</span
														>
														<div
															title="Redacted"
															v-if="field.redacted"
															class="file-redacted"
														>
															*
														</div>
													</label>

													<component
														:is="field.type"
														class="element"
														:currentField="field"
														mode="edit"
														v-on:change="$emit('change', $event.target.value)"
													></component>

													<small
														v-if="field.type !== 'Paragraph' || field.type !== 'Heading'"
														class="element-help"
													>
														{{ field.help }}
													</small>

													<!-- Actions list -->

													<div class="element-action-buttons">
														<a
															class="btn btn-sm btn-icon-info"
															style=""
															title="Update element"
															@click="editElement(field)"
														>
															<font-awesome-icon
																:icon="['fas', 'pencil-alt']"
																class="fa-fw"
															/>
														</a>
														<a
															class="btn btn-sm btn-icon-warning"
															@click="cloneElement(field, activeStep.fields)"
															v-if="!field.isUnique"
															title="Duplicate this element"
														>
															<font-awesome-icon :icon="['fas', 'clone']" class="fa-fw" />
														</a>
													</div>
												</div>
											</div>
										</template>
									</draggable>
								</div>
								<div
									v-else
									class="
										mb-2
										w-100
										text-primary
										h-100
										text-center
										d-flex
										justify-content-center
										align-items-center
									"
								>
									<h1>
										🏗️ In order to start building your form elements you need first to create form
										step
									</h1>
								</div>
								<div v-if="activeStep && activeStep.fields" class="text-center mt-3">
									<button @click="displayModalWithElements(activeStep)" class="btn btn-primary">
										<font-awesome-icon :icon="['fas', 'plus-circle']" class="fa-fw mr-2" />
										Add field
									</button>
								</div>
							</div>
						</div>
						<!-- ### Form Builder -->
					</div>
				</div>
			</div>
			<div v-else class="card">
				<div class="card-body">
					<div class="mb-2">
						<h5 class="mb-3">👷 Now let's build the form!</h5>

						<p class="mb-2">
							Forms are made up of steps, and each step is made up of elements. You can add as many steps
							and fields as needed.
						</p>
						<p>
							<a
								href="https://townweb.notion.site/How-to-build-forms-9d8c54f93a434ee5b013e97d01d11812"
								target="heygov-help"
								>Learn more about building forms</a
							>
							and create the first step now.
						</p>

						<p class="card-text">
							<button @click="createStep" type="button" class="btn btn-big-icon btn-primary">
								Create Step <font-awesome-icon :icon="['fas', 'plus-circle']" />
							</button>
						</p>

						<!-- <button
							@click="cloneExistingForm()"
							type="button"
							class="btn btn-small-icon btn-sm btn-primary m-1 float-end"
						>
							Clone existing form <font-awesome-icon :icon="['fas', 'copy']" />
						</button> -->
					</div>
				</div>

				<div class="bg-danger-lighter mt-3 py-3 ps-3 rounded-3" v-if="getRemovedSteps().length > 0">
					<h6><font-awesome-icon :icon="['fas', 'trash']" class="fa-fw text-danger" /> Trashed steps</h6>
					<hr class="me-3" />
					<small class="d-flex"
						>⚠️ Trashed steps are stored and not deleted permanently because they contain elements with data
						from some form request</small
					>
					<div class="row">
						<template v-for="(step, stepIndex) in getRemovedSteps()">
							<div :key="`div-${stepIndex}`" class="col-6 position-relative mt-3">
								<div class="">
									<a
										class="btn btn-sm btn-icon-info position-absolute"
										style="top: -15px"
										title="Restore step"
										@click="restoreStep(step)"
									>
										<font-awesome-icon :icon="['fas', 'undo']" class="fa-fw" />
									</a>
									<div class="step-wrapper ms-1 me-3 bg-light shadow-sm rounded p-4">
										<div>
											<h5>{{ step.name }}</h5>
											<p>
												{{ step.description }}
											</p>
										</div>
									</div>
								</div>
							</div>
						</template>
					</div>
				</div>
			</div>
		</div>

		<div v-else class="text-center">
			<div class="spinner-border" role="status"></div>
		</div>

		<!-- Step Modal - (Modal for creating new steps) -->
		<div class="modal fade" id="modal-step" data-bs-keyboard="false" tabindex="-1">
			<div class="modal-dialog modal-lg">
				<div class="modal-content">
					<div class="modal-header bg-primary">
						<h5 class="modal-title text-white">Add step</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<form @submit.prevent="saveStep">
						<div class="modal-body">
							<div class="form-group mb-3">
								<label for="stepName" class="form-label">
									Step Name
									<small title="Required" class="text-danger">*</small>
								</label>
								<input
									type="text"
									v-model="selectedStep.name"
									class="form-control"
									name="stepName"
									id="stepName"
									required
								/>
							</div>
							<div class="form-group mb-3">
								<label for="stepDescription" class="form-label">Step Description</label>
								<textarea
									v-model="selectedStep.description"
									class="form-control"
									name="stepDescription"
									id="stepDescription"
									rows="2"
								></textarea>
							</div>

							<div class="row">
								<div class="form-group mb-3 col-6">
									<label for="stepVisibleTo" class="form-label">
										Who needs to fill this step?
										<span title="Required" class="file-required text-danger">*</span>
									</label>
									<select class="form-select" id="stepVisibleTo" v-model="selectedStep.visible_to">
										<option value="EVERYONE" selected>Applicant</option>
										<option value="DEPARTMENT">Department person</option>
									</select>
								</div>

								<div class="form-group col-6 mb-3">
									<div class="form-check">
										<input
											type="checkbox"
											class="form-check-input ms-1"
											v-model="selectedStep.review_required"
											id="step_review_required"
										/>
										<label class="form-label ms-1" for="step_review_required">
											Does this step need review?
										</label>

										<select
											:required="selectedStep.review_required"
											class="form-select"
											id="review_department_id"
											v-model="selectedStep.review_department_id"
											:disabled="!selectedStep.review_required"
										>
											<option :value="null" selected> - Select department -</option>
											<option
												v-for="(department, index) in departments"
												:key="index"
												:value="department.id"
											>
												{{ department.name }}
											</option>
										</select>
									</div>
								</div>
							</div>
						</div>
						<div class="modal-footer">
							<button type="submit" v-if="!updateSelectedStep" class="btn btn-primary pull-right">
								Add step
							</button>
							<button
								v-if="updateSelectedStep"
								@click="updateStep(selectedStep)"
								class="btn btn-primary pull-right"
							>
								Update
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
		<!-- ### Step Modal -->

		<!-- Element Modal - (Modal for adding, editing and working with element properties) -->
		<div class="modal fade" id="modal-element" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
			<div class="modal-dialog modal-lg modal-dialog-centered">
				<!-- Elements List -->
				<div class="modal-content" v-if="isNewElement && !isEditElement">
					<div class="modal-header bg-primary">
						<h5 class="modal-title text-white">Add field</h5>
						<button
							type="button"
							@click="cancelAddingElement()"
							class="btn-close"
							data-b-dismiss="modal"
							aria-label="Close"
						></button>
					</div>

					<div class="modal-body p-4">
						<ElementsList :enabledElements="['all']" v-on:addElement="addElementToStep($event)" />
					</div>

					<div class="modal-footer justify-content-between">
						<button class="btn btn-link text-danger" @click="cancelAddingElement()">Cancel</button>
						<button
							:disabled="!('col' in selectedElement)"
							@click="editAddedElement()"
							class="btn btn-primary ms-3"
						>
							Next
						</button>
					</div>
				</div>
				<!-- ### Elements List -->

				<!-- Element Properties -->
				<div class="modal-content" v-if="isEditElement">
					<div class="modal-header bg-primary">
						<h5
							v-if="!isNewElement"
							class="modal-title text-white"
							@dblclick="livePreviewFlag = !livePreviewFlag"
						>
							<font-awesome-icon :icon="['fas', 'pencil-alt']" class="fa-fw font-12" />
							Edit field: <span class="text-secondary">{{ selectedElement.name }}</span>
						</h5>
						<h5
							v-if="isNewElement"
							class="modal-title text-white"
							@dblclick="livePreviewFlag = !livePreviewFlag"
						>
							<font-awesome-icon :icon="['fas', 'plus-circle']" class="fa-fw font-12" />
							Add field
						</h5>
						<button
							type="button"
							v-if="isNewElement"
							@click="cancelAddingElement()"
							class="btn-close"
							data-bs-dismiss="modal"
							aria-label="Close"
						></button>
						<button
							type="button"
							v-if="!isNewElement"
							@click="cancelUpdatingElement(selectedElement)"
							class="btn-close"
							data-bs-dismiss="modal"
							aria-label="Close"
						></button>
					</div>

					<div class="modal-body">
						<ElementProperties
							:current="selectedElement"
							:formElements="formElements"
							:stepElements="activeStep.fields"
							:form="form"
							:isNewElement="isNewElement"
							:livePreviewFlag="livePreviewFlag"
							:steps="form.steps"
							v-on:elementRemoved="cancelAddingElement()"
							v-on:moveToStep="moveElementToStep($event)"
							v-bind="form.steps"
						/>
					</div>

					<div class="modal-footer justify-content-between">
						<button v-if="isNewElement" @click="cancelAddingElement()" class="btn btn-link text-danger">
							Cancel
						</button>
						<button
							v-if="!isNewElement"
							@click="cancelUpdatingElement(selectedElement)"
							class="btn btn-link text-danger"
						>
							Cancel
						</button>

						<button v-if="!isNewElement" @click="updateElement()" class="btn btn-primary ms-2">
							Update
						</button>
						<button v-if="isNewElement" @click="addSelectedElement()" class="btn btn-primary ms-2">
							Save
						</button>
					</div>
				</div>
				<!-- ### Element Properties -->
			</div>
		</div>
		<!-- ### Element Modal -->

		<!-- Cloning form Modal -->
		<div class="modal fade" id="modal-clone-form" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
			<div class="modal-dialog modal-lg">
				<div class="modal-content">
					<div class="modal-header bg-primary">
						<h5 class="modal-title text-white">Clone existing form</h5>
						<button
							v-if="!loadingCloning"
							type="button"
							@click="closeCloningModal()"
							class="btn-close"
							data-bs-dismiss="modal"
							aria-label="Close"
						></button>
					</div>

					<div class="modal-body" :style="loadingCloning ? 'background-color:#9e514d' : ''">
						<div v-if="!loadingCloning" class="row">
							<div class="col-12">
								<div class="form-group mb-2">
									<label class="form-label" for="formForCloning">Choose form to clone</label>
									<select
										ref="cloningSelectForm"
										name="formCloneId"
										@change="onFormCloneChange($event)"
										class="form-select"
										id="formForCloning"
									>
										<option value="">Choose form ...</option>
										<template v-for="(f, i) in formsForCloning">
											<option :key="i" :value="f.slug">{{ f.name }}</option>
										</template>
									</select>
								</div>
								<div class="card mb-2" id="coningFormDetails">
									<div v-if="!loadingFormForClone && formClone && formClone.name" class="card-body">
										<h5 class="mb-2 text-center">
											{{ formClone.name }}
											<button
												v-if="cloneFormHaveChanges(formClone)"
												@click="resetFormChanges(formClone)"
												class="btn btn-primary btn-sm"
											>
												Reset
											</button>
										</h5>
										<hr />
										<p>
											Total steps: {{ formClone.steps.length }} (<template
												v-for="(step, i) in formClone.steps"
											>
												<a :key="i" @click="displayStepElements(step)" class="btn btn-link p-0"
													><span
														:class="step.removeNew ? 'text-decoration-line-through' : ''"
														>{{ step.name }}</span
													>
												</a>
												({{ step.fields.filter(e => !e.removeNew).length }})
												<template v-if="i < formClone.steps.length - 1">,</template> </template
											>)
										</p>
										<p>Total Fields: {{ totalFieldsInForm(formClone) }}</p>
									</div>
									<div
										v-if="loadingFormForClone && formClone !== ''"
										class="card-body d-flex align-items-center justify-content-center"
										style="min-height:180px"
									>
										<div class="spinner-border text-primary" role="status">
											<span class="sr-only">Loading...</span>
										</div>
									</div>
								</div>

								<div class="card" v-if="stepClone.fields.length > 0">
									<div class="card-body">
										<div class="row">
											<div class="col-10">
												<h5 :class="stepClone.removeNew ? 'text-decoration-line-through' : ''">
													{{ stepClone.name }}
												</h5>
											</div>
											<div class="col-2">
												<div class="form-check">
													<input
														class="form-check-input"
														type="checkbox"
														v-model="stepClone.removeNew"
														:id="'remove-step'"
													/>
													<label class="form-check-label" :for="'remove-step'">
														remove
													</label>
												</div>
											</div>
										</div>

										<div class="row" v-if="!stepClone.removeNew">
											<div class="col-12 py-1 table-hover">
												<b>Element name</b> -
												<small
													>If you want to delete an element from the new form, activate the
													switch</small
												>
											</div>

											<hr />
										</div>
										<template v-for="(e, i) in stepClone.fields">
											<div :key="i" class="row" v-if="!stepClone.removeNew">
												<div class="col-10 py-1 table-hover">
													<b :class="e.removeNew ? 'text-decoration-line-through' : ''">{{
														e.label
													}}</b>
													-
													<small
														class="text-muted"
														:class="e.removeNew ? 'text-decoration-line-through' : ''"
														><b>({{ e.type }})</b></small
													>
													<br />
													<span
														v-if="e.type === 'TextElement'"
														:class="e.removeNew ? 'text-decoration-line-through' : ''"
														v-html="e.value"
													></span>
												</div>
												<div class="col-2">
													<div class="form-check">
														<input
															class="form-check-input"
															type="checkbox"
															v-model="e.removeNew"
															:id="'remove-' + i"
														/>
														<label class="form-check-label" :for="'remove-' + i">
															remove
														</label>
													</div>
												</div>
											</div>
										</template>
									</div>
								</div>
							</div>
							<div class="col-12 mt-2" v-if="formClone.steps.length > 0">
								<button @click="createClonedForm()" class="btn btn-primary float-end">
									Start Cloning
								</button>
							</div>
						</div>
						<div v-else class="row">
							<div class="text-center">
								<h5>Clonning in progress please wait</h5>
								<div class="spinner-border" role="status">
									<span class="visually-hidden">Loading...</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- #Step Modal -->
	</div>
</template>

<style lang="scss">
@import '@/assets/form-builder';
</style>

<script>
import { Modal, Tooltip } from 'bootstrap'
import Vue from 'vue'
import draggable from 'vuedraggable'
import { mapState, mapGetters } from 'vuex'

import heyGovApi from '@/api.js'
import { handleResponseError, truncateString, tracker } from '@/utils.js'
import {
	elementIsVisible,
	handlePayment,
	updateYearElements,
	updateDatePickerElements,
	updateNumberInputElement,
} from '../../actions/forms.js'

import ElementProperties from '@/components/form-builder/ElementProperties'
import TextInputElement from '@/components/form-builder/TextInputElement'
import TextareaInputElement from '@/components/form-builder/TextareaInputElement'
import NumberInputElement from '@/components/form-builder/NumberInputElement'
import SelectListElement from '@/components/form-builder/SelectListElement'
import RadioButtonElement from '@/components/form-builder/RadioButtonElement'
import CheckboxElement from '@/components/form-builder/CheckboxElement'
import DatePickerElement from '@/components/form-builder/DatePickerElement'
import FileUploadElement from '@/components/form-builder/FileUploadElement'
import EmailInputElement from '@/components/form-builder/EmailInputElement'
import PhoneInputElement from '@/components/form-builder/PhoneInputElement'
import TableElement from '@/components/form-builder/TableElement'
import YearElement from '@/components/form-builder/YearElement'
import SignatureElement from '@/components/form-builder/SignatureElement'
import PaymentElement from '@/components/form-builder/PaymentElement'
import AddressInputElement from '@/components/form-builder/AddressInputElement'
import NameInputElement from '@/components/form-builder/NameInputElement'
import ElementsList from '@/components/form-builder/ElementsList'
import TimeElement from '@/components/form-builder/TimeElement'
import TextElement from '@/components/form-builder/TextElement'
import FormPageHead from '@/views/Forms/FormPageHead.vue'

export default {
	name: 'FormBuilder',
	components: {
		draggable,
		TextInputElement,
		TextareaInputElement,
		NumberInputElement,
		SelectListElement,
		RadioButtonElement,
		CheckboxElement,
		DatePickerElement,
		FileUploadElement,
		EmailInputElement,
		PhoneInputElement,
		TableElement,
		YearElement,
		SignatureElement,
		PaymentElement,
		AddressInputElement,
		ElementProperties,
		NameInputElement,
		ElementsList,
		TimeElement,
		FormPageHead,
		TextElement,
	},
	data() {
		return {
			state: 'loading',
			form: null,
			activeStep: {},
			selectedStep: {},
			oldStep: {},
			updateSelectedStep: false,
			currentStep: {},
			selectedElement: {
				data: {},
			},
			oldElement: {
				data: {},
			},
			inlineEditStep: false,
			isEditElement: false,
			isNewElement: false,
			livePreviewFlag: false,
			formRequests: [],
			displayRemovedElements: false,
			isRemovingStep: false,
			removeStepInput: '',
			isStepRemoveBtnDisabled: true,
			displayRemovedSteps: false,
			$modalStep: null,
			$modalElement: null,
			$modalCloneForm: null,
			loadingForms: false,
			formsForCloning: [],
			formClone: {
				steps: [],
			},
			stepClone: {
				fields: [],
			},
			loadingFormForClone: false,
			loadingCloning: false,
		}
	},
	created() {
		tracker.event('form-builder-open', this.$route.params.formSlug)
		this.$store.dispatch('loadDepartments')
		this.getForm()
	},
	mounted() {
		this.$modalStep = new Modal(document.getElementById('modal-step'))
		this.$modalElement = new Modal(document.getElementById('modal-element'))
		this.$modalCloneForm = new Modal(document.getElementById('modal-clone-form'))
		Array.from(document.querySelectorAll('[data-bs-toggle="tooltip"]')).forEach(
			tooltipNode => new Tooltip(tooltipNode)
		)
	},
	watch: {
		'selectedElement.data.multiple'() {
			if (this.selectedElement.data.multiple) {
				this.selectedElement.value = ''
			} else {
				this.selectedElement.value = ''
			}
		},
	},
	computed: {
		...mapState(['j', 'departments']),
		...mapGetters(['currentRole']),

		// Display form fields for selected step
		displayStepId: function() {
			if (this.activeStep) {
				return this.activeStep.id
			}
			return this.getActiveSteps()[0]
		},

		// Getting all form elements without form steps, just all elements
		formElements: function() {
			return (this.form?.steps || []).map(step => step.fields).flat()
		},

		// Find payment element on form and use it for Payment
		paymentElement: function() {
			let paymentElement = { data: {} }
			paymentElement = this.formElements.find(item => {
				return item.type === 'PaymentElement'
			})
			return paymentElement
		},
	},

	updated() {
		let quantity = handlePayment(this.paymentElement, this.formElements)

		if (this.paymentElement) {
			this.paymentElement.data.quantity = quantity
		}

		this.checkRemoveWord()
		Array.from(document.querySelectorAll('[data-bs-toggle="tooltip"]')).forEach(
			tooltipNode => new Tooltip(tooltipNode)
		)

		// Update step
		if (this.activeStep && this.activeStep.visible_to === 'DEPARTMENT') {
			this.activeStep.review_required = 1
		}

		if (this.activeStep && !this.activeStep.review_required) {
			this.activeStep.review_department_id = null
		}

		// Creating new step
		if (this.selectedStep && this.selectedStep.visible_to === 'DEPARTMENT') {
			this.selectedStep.review_required = 1
		}

		if (this.selectedStep && !this.selectedStep.review_required) {
			this.selectedStep.review_department_id = null
		}

		updateYearElements(this.formElements)
		updateDatePickerElements(this.formElements)
		updateNumberInputElement(this.formElements)
	},

	methods: {
		truncateString,

		// Get Form with Steps and Fields
		getForm() {
			this.state = 'loading'

			heyGovApi.get(`${this.j.slug}/forms/${this.$route.params.formSlug}?expand=steps`).then(
				({ data }) => {
					// Parse options and data of fields
					data.steps = data.steps.map(step => {
						step.fields = step.fields.map(field => {
							//todo remove this when api returns fields data&options in JSON format
							if (!field.data || typeof field.data === 'string') {
								field.data = JSON.parse(field.data || '{}')
							}

							if (field.options && typeof field.options === 'string') {
								field.options = JSON.parse(field.options)
							}

							if (field.type === 'CheckboxElement' || field.type === 'FileUploadElement') {
								field.value = JSON.parse(field.value)
							}

							return field
						})

						return step
					})

					this.form = data
					this.loadFormRequests(this.form.id)

					if (this.getActiveSteps().length > 0) {
						this.activeStep = this.getActiveSteps()[0]
					}

					this.state = 'loaded'
				},
				error => {
					Vue.toasted.error(`Couldn't load the form (${error.message})`)
					this.state = 'error'
				}
			)
		},

		// Clone Element inside of active step
		cloneElement(field, formStepFields) {
			heyGovApi
				.post(`/${this.j.slug}/form-fields/clone-field/`, {
					...field,
				})
				.then(
					response => {
						formStepFields.push({
							...response.data,
							data: JSON.parse(response.data.data),
							options: JSON.parse(response.data.options),
						})

						Vue.toasted.success(`Element successfully clonned`)
					},
					error => {
						Vue.toasted.error(`Couldn't clone the element (${error.message})`)
					}
				)
		},

		// Cancel adding new Element
		cancelAddingElement() {
			this.selectedElement = {
				data: {},
			}
			this.isEditElement = false
			this.isNewElement = false
			this.$modalElement.hide()
		},

		// Cancel updating Element
		cancelUpdatingElement(element) {
			element = { ...this.oldElement }
			this.form.steps.forEach(step => {
				step.fields.forEach(e => {
					if (e.id === element.id) {
						Object.keys(e).map(function(key) {
							e[key] = element[key]
						})
					}
				})
			})
			this.isEditElement = false
			this.isNewElement = false
			this.$modalElement.hide()
			this.selectedElement = {
				data: {},
			}
			this.oldElement = {
				data: {},
			}
		},

		// Edit Element
		editElement(element) {
			this.oldElement = JSON.parse(JSON.stringify(element))
			this.selectedElement = element
			this.isEditElement = true
			this.$modalElement.show()
		},

		// Cancel updating step
		cancelUpdatingStep(step) {
			if (!this.inlineEditStep || JSON.stringify(this.oldStep) !== JSON.stringify(step)) {
				step = { ...this.oldStep }
				this.form.steps.forEach(s => {
					if (s.id === step.id) {
						Object.keys(s).map(function(key) {
							s[key] = step[key]
						})
					}
				})
				this.oldStep = {}
			}
		},

		// Edit step
		editStep(step) {
			if ('id' in this.oldStep) {
				this.cancelUpdatingStep(this.oldStep)
				this.oldStep = {}
			} else {
				this.oldStep = { ...step }
			}
		},

		// Create new Step
		createStep() {
			this.updateSelectedStep = false
			this.selectedStep = {
				visible_to: 'EVERYONE',
				removed: false,
				review_department_id: null,
				review_required: false,
			}
			this.$modalStep.show()
		},

		// Add Step to form
		saveStep() {
			if (this.selectedStep.review_required === true && !this.selectedStep.review_department_id) {
				Vue.toasted.error('You need to choose the department that needs to review this step!')
			} else {
				heyGovApi
					.post(`/${this.j.slug}/form-steps/`, {
						...this.selectedStep,
						form_id: this.form.id,
						review_status: '',
					})
					.then(
						response => {
							let newFormStep = { ...response.data, fields: [] }
							this.activeStep = newFormStep
							this.form.steps.push(newFormStep)
							this.$modalStep.hide()
							this.selectedStep.name = ''
							this.selectedStep.description = ''
							Vue.toasted.success(`Step successfully added`)
						},
						error => {
							let msg = error.response.data.message || error.message || 'Server error, please try again'

							if (error.response?.data?.fields) {
								msg += ': '
								msg += error.response.data.fields
									.map(field => `${field.param} - ${field.msg}`)
									.join(', ')
							}

							Vue.toasted.error(msg)
						}
					)
			}
		},

		// Edit step
		inlineUpdateStep(step) {
			this.updateStep(step)
			this.oldStep = {}
			this.inlineEditStep = !this.inlineEditStep
		},

		// Update Form Step
		updateStep(step) {
			heyGovApi
				.put(`/${this.j.slug}/form-steps/${step.id}`, {
					form_id: this.form.id,
					name: step.name,
					description: step.description,
					visible_to: step.visible_to,
					review_required: step.review_required,
					review_department_id: step.review_department_id,
					review_status: step.review_status,
				})
				.then(
					() => {
						this.updateSelectedStep = false
						this.selectedStep = {}
						// Click on button cancel to toggle InlineEditForm off
						document.getElementById('btn-cancel-inline-edit').click()
						Vue.toasted.success(`Step successfully updated`)
					},
					error => {
						Vue.toasted.error(error)
					}
				)
		},

		// Remove Form Step
		removeStep(step) {
			if (this.safeToRemoveStep(step)) {
				heyGovApi.delete(`/${this.j.slug}/form-steps/${step.id}`).then(
					() => {
						Vue.toasted.show(`"${step.name}" is removed`)
						this.form.steps.splice(step.order_number, 1)
						this.activeStep = this.getActiveSteps(this.form.steps)[0]
						this.updateSelectedStep = false
						// Click on button cancel to toggle InlineEditForm off
						document.getElementById('btn-cancel-inline-edit').click()
						this.isRemovingStep = false
					},
					error => {
						Vue.toasted.show(error, { type: 'error' })
					}
				)
			} else {
				// Update
				heyGovApi
					.put(`/${this.j.slug}/form-steps/${step.id}`, {
						form_id: this.form.id,
						name: step.name,
						description: step.description,
						visible_to: step.visible_to,
						review_required: step.review_required,
						review_department_id: step.review_department_id,
						review_status: step.review_status,
						removed: true,
					})
					.then(
						() => {
							this.updateSelectedStep = false
							// Click on button cancel to toggle InlineEditForm off
							document.getElementById('btn-cancel-inline-edit').click()
							this.isRemovingStep = false
							step.removed = true
							this.removeStepInput = ''
							this.activeStep = this.getActiveSteps(this.form.steps)[0]
							Vue.toasted.show(`"${step.name}" is removed`)
						},
						error => {
							Vue.toasted.show(error, { type: 'error' })
						}
					)
			}
		},

		// Display available elements for adding
		displayModalWithElements(formStep) {
			this.isNewElement = true
			//jQuery('#form-step-fields-modal').modal('show')
			this.$modalElement.show()
			this.currentStep = formStep
		},

		// Add selected Element to step
		addSelectedElement() {
			//fix: set FileUploadElement value as empty Array
			//todo Fix by setting default value in element properties
			if (this.selectedElement.type === 'FileUploadElement') {
				this.selectedElement.value = []
			}

			if (this.selectedElement.type === 'PaymentElement' && this.selectedElement.data.paymentType.length === 0) {
				Vue.toasted.error('You must select at least one payment method!')
			} else if (
				this.selectedElement.type === 'DatePickerElement' &&
				((this.selectedElement.data.maxDate === '' && !this.selectedElement.data.useDynamicMaxDate) ||
					(this.selectedElement.data.minDate === '' && !this.selectedElement.data.useDynamicMinDate))
			) {
				if (this.selectedElement.data.maxDate === '' && !this.selectedElement.data.useDynamicMaxDate) {
					Vue.toasted.error('You need to select maxDate')
				}
				if (this.selectedElement.data.minDate === '' && !this.selectedElement.data.useDynamicMinDate) {
					Vue.toasted.error('You need to select minDate')
				}
			} else if (
				this.selectedElement.type === 'YearElement' &&
				((this.selectedElement.data.maxYear === '' && !this.selectedElement.data.useDynamicMaxYear) ||
					(this.selectedElement.data.minYear === '' && !this.selectedElement.data.useDynamicMinYear))
			) {
				if (this.selectedElement.data.minYear === '' && !this.selectedElement.data.useDynamicMinYear) {
					Vue.toasted.error('You need to add minYear')
				}
				if (this.selectedElement.data.maxYear === '' && !this.selectedElement.data.useDynamicMaxYear) {
					Vue.toasted.error('You need to add maxYear')
				}
			} else if (
				this.selectedElement.type === 'NameInputElement' &&
				!this.selectedElement.data.firstName &&
				!this.selectedElement.data.lastName &&
				!this.selectedElement.data.middleName
			) {
				Vue.toasted.error('You need to enable atleast one input (first name, last name or middle name)')
			} else if (this.selectedElement.type !== 'TextElement' && this.selectedElement.label.length < 1) {
				Vue.toasted.error('Field label is required')
			} else {
				heyGovApi
					.post(`/${this.j.slug}/form-fields/`, {
						...this.selectedElement,
						form_step_id: this.currentStep.id,
					})
					.then(
						response => {
							let value = response.data.value
							if (
								response.data.type === 'CheckboxElement' ||
								response.data.type === 'FileUploadElement'
							) {
								value = JSON.parse(response.data.value)
							}
							this.currentStep.fields.push({
								...response.data,
								data: JSON.parse(response.data.data),
								value: value,
								options: JSON.parse(response.data.options),
							})

							this.selectedElement = {
								data: {},
							}
							this.isEditElement = false
							this.isNewElement = false
							//jQuery('#form-step-fields-modal').modal('hide')
							this.$modalElement.hide()

							Vue.toasted.success(`Element successfully added`)
						},
						error => {
							let msg = error.response?.data?.message || error.message || 'Server error, please try again'

							if (error.response?.data?.fields) {
								msg += '. '
								msg += error.response.data.fields
									.map(field => `${field.param}: ${field.msg}`)
									.join(', ')
							}

							Vue.toasted.error(msg)
						}
					)
			}
		},

		// Update edited Element
		updateElement() {
			if (this.selectedElement.type === 'PaymentElement' && this.selectedElement.data.paymentType.length === 0) {
				Vue.toasted.error('You must select at least one payment method!')
			} else if (
				this.selectedElement.type === 'DatePickerElement' &&
				((this.selectedElement.data.maxDate === '' && !this.selectedElement.data.useDynamicMaxDate) ||
					(this.selectedElement.data.minDate === '' && !this.selectedElement.data.useDynamicMinDate))
			) {
				if (this.selectedElement.data.maxDate === '' && !this.selectedElement.data.useDynamicMaxDate) {
					Vue.toasted.error('You need to select maxDate')
				}
				if (this.selectedElement.data.minDate === '' && !this.selectedElement.data.useDynamicMinDate) {
					Vue.toasted.error('You need to select minDate')
				}
			} else if (
				this.selectedElement.type === 'YearElement' &&
				((this.selectedElement.data.maxYear === '' && !this.selectedElement.data.useDynamicMaxYear) ||
					(this.selectedElement.data.minYear === '' && !this.selectedElement.data.useDynamicMinYear))
			) {
				if (this.selectedElement.data.minYear === '' && !this.selectedElement.data.useDynamicMinYear) {
					Vue.toasted.error('You need to add minYear')
				}
				if (this.selectedElement.data.maxYear === '' && !this.selectedElement.data.useDynamicMaxYear) {
					Vue.toasted.error('You need to add maxYear')
				}
			} else if (
				this.selectedElement.type === 'NameInputElement' &&
				!this.selectedElement.data.firstName &&
				!this.selectedElement.data.lastName &&
				!this.selectedElement.data.middleName
			) {
				Vue.toasted.error('You need to enable atleast one input (first name, last name or middle name)')
			} else if (this.selectedElement.type !== 'TextElement' && this.selectedElement.label.length < 1) {
				Vue.toasted.error('You need to enter label for element')
			} else {
				heyGovApi.put(`/${this.j.slug}/form-fields/${this.selectedElement.id}`, this.selectedElement).then(
					() => {
						//jQuery('#form-step-fields-modal').modal('hide')
						this.$modalElement.hide()
						this.isEditElement = false
						this.selectedElement = {
							data: {},
						}
						Vue.toasted.show(`Element successfully updated!`)
					},
					error => {
						let msg = error.response?.data?.message || error.message || 'Server error, please try again'

						if (error.response?.data?.fields) {
							msg += '. '
							msg += error.response.data.fields.map(field => `${field.param}: ${field.msg}`).join(', ')
						}

						Vue.toasted.error(msg)
					}
				)
			}
		},

		// Reordering elements in step
		updateElementsOrder: function(list) {
			// Get order of elements
			const items = list.map(function(item, index) {
				return { id: item.id, order: index }
			})

			heyGovApi
				.post(`/${this.j.slug}/form-fields/update-order`, {
					list: JSON.stringify(items),
				})
				.then(() => {
					Vue.toasted.success(`Field position is saved`)
				}, handleResponseError('Error saving field position ({error})'))
		},

		// Reordering steps in form
		updateStepsOrder: function(list) {
			// Get order of elements
			var items = list.map(function(item, index) {
				return { id: item.id, order: index }
			})
			heyGovApi
				.post(`/${this.j.slug}/form-steps/update-order`, {
					list: JSON.stringify(items),
				})
				.then(
					() => {
						Vue.toasted.show(`Steps reordered!`)
					},
					error => {
						Vue.toasted.show(error, { type: 'error' })
					}
				)
		},

		// Set active step
		setActiveStep(step) {
			this.activeStep = step
			this.isEditElement = false
			this.isNewElement = false
			this.scrollToStepStart()
		},

		// Call function from actions/forms to check is field visible or not
		checkIsElementVisible(field) {
			return elementIsVisible(field, this.formElements)
		},

		// Add element to step from ElementList
		addElementToStep(element) {
			this.selectedElement = element
		},

		// Switch to ElementProperties when new element is choosen
		editAddedElement() {
			if (this.selectedElement.name === 'Payment' && this.paymentElement) {
				Vue.toasted.error('Payment element already exist in form!')
			} else {
				this.isEditElement = true
			}
		},

		// Available step fields
		availableFields(fields) {
			return fields.filter(field => field.removed === 0)
		},

		// Removed elements
		removedFields(fields) {
			return fields.filter(field => field.removed !== 0)
		},

		// Load form requests
		loadFormRequests(id) {
			heyGovApi.get(`/${this.j.slug}/form-requests?form_id=${id}`).then(
				({ data }) => {
					this.formRequests = data
				},
				error => {
					Vue.toasted.error(`Error loading form request ~ ${error}`)
				}
			)
		},

		// Restore removed element
		restoreElement(element) {
			heyGovApi
				.put(`/${this.j.slug}/form-fields/${element.id}`, {
					...element,
					removed: false,
				})
				.then(
					() => {
						element.removed = false
						Vue.toasted.show(`"${element.label}" element is restored!`)
					},
					error => {
						Vue.toasted.show(error, { type: 'error' })
					}
				)
		},

		// Element condition label for fields
		drawElementConditions(field) {
			let conditionString = ''
			if (field.data?.conditions?.length > 0) {
				field.data.conditions.forEach(condition => {
					const label = condition.element ? this.getElementById(condition.element).label : ''

					conditionString +=
						conditionString +
						condition.type +
						' ' +
						truncateString(label, 48) +
						' ' +
						condition.condition +
						' ' +
						condition.conditionValue
				})
			}
			return conditionString
		},

		// Get element by ID from form elements
		getElementById(id) {
			return this.formElements.find(element => element.id === id)
		},

		//Visible field
		visibleField(field) {
			let cl = ''
			if (field.data?.conditions?.length > 0) {
				cl = 'bg-light'
			}
			return cl
		},

		// Check is there elements from this step that have requests already created
		safeToRemoveStep(step) {
			let safeToRemove = true
			if (step.fields?.length === 0) {
				safeToRemove = true
			} else {
				// Check is elements from this step used in requests
				if (this.isStepElementsUsedInRequest(step.fields)) {
					safeToRemove = false
				}
			}
			return safeToRemove
		},

		// Check confirmation delete word
		checkRemoveWord() {
			if (parseInt(this.removeStepInput) === this.activeStep?.id) {
				this.isStepRemoveBtnDisabled = false
			} else {
				this.isStepRemoveBtnDisabled = true
			}
		},

		// Check is element for removing used in form requests
		isStepElementsUsedInRequest(elements) {
			let elementExist = false
			elements.forEach(element => {
				this.formRequests.forEach(request => {
					Object.keys(request.answers).map(function(key) {
						if (element.id === parseInt(key)) {
							elementExist = true
						}
					})
				})
			})
			return elementExist
		},

		// Restore removed step
		restoreStep(step) {
			heyGovApi
				.put(`/${this.j.slug}/form-steps/${step.id}`, {
					form_id: this.form.id,
					name: step.name,
					description: step.description,
					visible_to: step.visible_to,
					review_required: step.review_required,
					review_department_id: step.review_department_id,
					review_status: step.review_status,
					removed: false,
				})
				.then(
					() => {
						step.removed = false
						this.activeStep = step
						Vue.toasted.show(`"${step.name}" is restored`)
					},
					error => {
						Vue.toasted.show(error, { type: 'error' })
					}
				)
		},

		// Get list of removed step elements
		getRemovedStepElements(step) {
			return step.fields.filter(element => element.removed)
		},

		// Get active step elements
		getActiveStepElements(step) {
			return step.fields.filter(element => !element.removed)
		},

		// Get list of removed steps
		getRemovedSteps() {
			return this.form.steps.filter(step => step.removed)
		},

		// Get active steps
		getActiveSteps() {
			return this.form.steps.filter(step => !step.removed)
		},

		scrollToStepStart() {
			const $start = document.querySelector('#step-title')
			$start.scrollIntoView({ behavior: 'smooth', block: 'center' })
		},

		// Get Year from DatePicker value
		getDatePickerYearValue(element) {
			if (element.value) {
				return element.value.substring(0, 4)
			} else return 1900
		},

		// Create new Step
		cloneExistingForm() {
			this.getFormsForCloning()
			this.$modalCloneForm.show()
		},

		// Get Form with Steps and Fields
		getFormsForCloning() {
			this.loadingForms = true
			//Todo: Get forms from all jurisdictions not only from current
			heyGovApi.get(`${this.j.slug}/forms`).then(
				({ data }) => {
					this.formsForCloning = data.filter(form => form.slug !== this.$route.params.formSlug)
					this.loadingForms = false
				},
				error => {
					Vue.toasted.show(error, { type: 'error' })
					this.state = 'error'
					this.loadingForms = false
				}
			)
		},
		getFormCloneSteps(formId) {
			heyGovApi
				.get(`/${this.j.slug}/form-steps`, {
					params: {
						form_id: formId,
					},
				})
				.then(
					({ data }) => {
						this.formClone.steps = data.map.forEach(step => {
							step.fields.forEach(field => {
								if (!field.data || typeof field.data === 'string') {
									field.data = JSON.parse(field.data)
								}
								if (field.options && typeof field.options === 'string') {
									field.options = JSON.parse(field.options)
								}

								if (field.type === 'CheckboxElement' || field.type === 'FileUploadElement') {
									field.value = JSON.parse(field.value)
								}
							})
						})
						this.loadingFormForClone = false
					},
					error => {
						Vue.toasted.error(`Error loading form steps (${error.message})`)
						this.loadingFormForClone = false
					}
				)
		},

		// On form clone select change find that form and steps of that form
		onFormCloneChange(event) {
			if (event.target.value !== '') {
				this.loadingFormForClone = true
				heyGovApi.get(`${this.j.slug}/forms/${event.target.value}`).then(
					({ data }) => {
						this.formClone = { ...data, steps: [] }
						this.getFormCloneSteps(this.formClone.id)
						this.stepClone = { fields: [] }
					},
					error => {
						Vue.toasted.show(error, { type: 'error' })
						this.state = 'error'
					}
				)
			} else {
				this.formClone = { steps: [] }
				this.stepClone = { fields: [] }
			}
		},

		totalFieldsInForm(form) {
			let totalFields = 0
			form.steps.forEach(step => {
				if (!step.removeNew) {
					totalFields += step.fields.filter(f => !f.removeNew).length
				}
			})
			return totalFields
		},

		displayStepElements(step) {
			this.stepClone = step
		},

		resetFormChanges(form) {
			this.formClone = { steps: [] }
			this.stepClone = { fields: [] }
			let event = {
				target: {
					value: form.slug,
				},
			}
			this.onFormCloneChange(event)
		},

		cloneFormHaveChanges(form) {
			let modified = false
			form.steps.forEach(step => {
				if (step.removeNew) {
					modified = true
				}
				step.fields.forEach(e => {
					if (e.removeNew) {
						modified = true
					}
				})
			})
			return modified
		},

		closeCloningModal() {
			this.formClone = { steps: [] }
			this.stepClone = { fields: [] }
			this.$refs.cloningSelectForm.value = ''
		},

		/**
		 * Send all data to backend and create from, maybe step field by field
		 */
		createClonedForm() {
			this.loadingCloning = true
			this.$modalCloneForm.hide()
			let newSteps = []
			newSteps = this.formClone.steps.filter(s => !s.removeNew)
			newSteps.forEach(step => {
				step.fields = step.fields.filter(e => !e.removeNew)
			})

			// Get only ids from step and fields
			let stepsToClone = []
			newSteps.forEach(newStep => {
				let cloningStep = {}
				cloningStep.id = newStep.id
				cloningStep.fields = []
				newStep.fields.forEach(field => {
					cloningStep.fields.push(field.id)
				})
				stepsToClone.push(cloningStep)
			})

			heyGovApi
				.post(`/${this.j.slug}/forms/clone`, {
					form_id: this.form.id,
					cloneSteps: stepsToClone,
				})
				.then(
					response => {
						this.getForm()
						this.formClone = { steps: [] }
						this.stepClone = { fields: [] }
						this.loadingCloning = false
						Vue.toasted.show('Form successfuly cloned in ' + response.data.time)
					},
					error => {
						this.loadingCloning = false
						Vue.toasted.show(error, { type: 'error' })
					}
				)
		},

		// Move element from one step to another - ElementProperties emmit
		moveElementToStep(event) {
			let stepToMove = this.form.steps.find(step => step.id === event.stepId)
			// Update lement in Database (update form_step_id for this element)
			event.element.form_step_id = stepToMove.id
			heyGovApi.put(`/${this.j.slug}/form-fields/${event.element.id}`, event.element).then(
				() => {
					this.$modalElement.hide()
					this.isEditElement = false
					this.activeStep.fields = this.activeStep.fields.filter(field => field.id !== event.element.id)
					stepToMove.fields.push(event.element)
					Vue.toasted.show(`Element successfully moved to "${stepToMove.name}"`)
				},
				error => {
					Vue.toasted.error(error)
				}
			)
		},

		// Display element label
		displayLabel(field) {
			return !['TextElement'].includes(field.type)
		},
	},
}
</script>

<style lang="scss">
.form-check-input-danger:checked {
	background-color: var(--bs-red);
	border-color: var(--bs-primary);
}
</style>
