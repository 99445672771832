<template>
	<div class="modal-content">
		<div class="modal-header">
			<h5 class="modal-title">
				{{ categoryId ? 'Meeting Settings' : 'Meeting Category' }}
			</h5>
			<button @click="$emit('close')" type="button" class="btn-close" aria-label="Close"></button>
		</div>
		<form @submit.prevent="handleSubmit">
			<div v-if="loading" class="modal-body text-center">
				<div class="spinner-border" role="status">
					<span class="visually-hidden">Loading...</span>
				</div>
			</div>
			<div v-else class="modal-body">
				<div v-if="categoryId" class="alert alert-info mb-3">
					The agenda template settings are shared across all meetings in this category:
					<strong>{{ categories.find(c => c.id == categoryId).name }}</strong>
				</div>

				<div v-if="!categoryId" class="mb-3">
					<p class="alert alert-warning">
						To change the settings for the meeting template, please assign the meeting to a category first.
					</p>
					<div class="mb-3">
						<label for="category" class="form-label">Select a category</label>
						<div class="d-flex align-items-center gap-2">
							<select v-model="selectedCategory" id="category" class="form-select form-select-sm">
								<option value="">- Select a category</option>
								<option v-for="category in categories" :key="category.id" :value="category.id">
									{{ category.name }}
								</option>
							</select>
							<button
								type="button"
								class="btn btn-primary"
								@click="setCategory"
								:disabled="!selectedCategory"
							>
								Save
							</button>
						</div>
					</div>
				</div>

				<!-- Header Settings Row 1 -->
				<div class="row mb-3">
					<div class="col">
						<label for="logo" class="form-label">Municipality Logo</label>
						<select
							v-model="form.template_logo"
							id="logo"
							class="form-select form-select-sm"
							:disabled="!categoryId"
						>
							<option :value="null">No Logo</option>
							<option value="department">Department Logo</option>
							<option value="jurisdiction">Municipality Logo</option>
						</select>
					</div>
					<div class="col">
						<label for="header_alignment" class="form-label">Header Alignment</label>
						<select
							v-model="form.template_header_alignment"
							id="header_alignment"
							class="form-select form-select-sm"
							:disabled="!categoryId"
						>
							<option value="left">Left</option>
							<option value="center">Center</option>
						</select>
					</div>
					<div class="col">
						<label for="titles_font" class="form-label">Titles Font</label>
						<select
							v-model="form.template_titles_font"
							id="titles_font"
							class="form-select form-select-sm"
							:disabled="!categoryId"
						>
							<option value="arimo">Arimo (Arial)</option>
							<option value="times">Times New Roman</option>
							<option value="calibri">Calibri</option>
							<option value="helvetica">Helvetica</option>
							<option value="verdana">Verdana</option>
						</select>
					</div>
				</div>

				<!-- Header Settings Row 2 -->
				<div class="mb-3">
					<label for="header_note" class="form-label">Header Note</label>
					<textarea
						v-model="form.template_agenda_header_note"
						id="header_note"
						class="form-control"
						:disabled="!categoryId"
					></textarea>
				</div>

				<!-- Content Settings Row 1 -->
				<div class="row mb-3">
					<div class="col">
						<label for="content_font" class="form-label">Content Font</label>
						<select
							v-model="form.template_content_font"
							id="content_font"
							class="form-select form-select-sm"
							:disabled="!categoryId"
						>
							<option value="arimo">Arimo (Arial)</option>
							<option value="times">Times New Roman</option>
							<option value="calibri">Calibri</option>
							<option value="helvetica">Helvetica</option>
							<option value="verdana">Verdana</option>
						</select>
					</div>
					<div class="col">
						<label for="main_items_numbering" class="form-label">Items Numbering Format</label>
						<select
							v-model="form.template_items_numbering_style"
							id="main_items_numbering"
							class="form-select form-select-sm"
							:disabled="!categoryId"
						>
							<option value="alphabetic">A, B, C</option>
							<option value="numeric">1, 2, 3</option>
							<option value="roman">I, II, III</option>
						</select>
					</div>
					<div class="col">
						<label for="sub_items_numbering" class="form-label">Sub-items Numbering Format</label>
						<select
							v-model="form.template_subitems_numbering_style"
							id="sub_items_numbering"
							class="form-select form-select-sm"
							:disabled="!categoryId"
						>
							<option value="alphabetic">a, b, c</option>
							<option value="numeric">1, 2, 3</option>
							<option value="roman">i, ii, iii</option>
						</select>
					</div>
				</div>

				<!-- Content Settings Row 2 -->
				<div class="row mb-3">
					<div class="col-auto me-4">
						<div class="form-check">
							<input
								type="checkbox"
								v-model="form.template_agenda_item_text"
								id="show_item_description"
								class="form-check-input"
								:disabled="!categoryId"
							/>
							<label for="show_item_description" class="form-check-label">Show Item Description</label>
						</div>
					</div>
					<div class="col-auto">
						<div class="form-check">
							<input
								type="checkbox"
								v-model="form.template_agenda_item_speaker"
								id="show_speaker_name"
								class="form-check-input"
								:disabled="!categoryId"
							/>
							<label for="show_speaker_name" class="form-check-label">Show Speaker Name</label>
						</div>
					</div>
				</div>

				<!-- Footer Row -->
				<div class="mb-3">
					<label for="footer_note" class="form-label">Footer Note</label>
					<textarea
						v-model="form.template_agenda_footer_note"
						id="footer_note"
						class="form-control"
						:disabled="!categoryId"
					></textarea>
				</div>

				<!-- Preview section at the bottom -->
				<div class="row mb-4">
					<div class="col">
						<h6 class="mb-3">Preview</h6>
						<div class="border rounded" style="height: 400px;">
							<iframe
								v-if="previewUrl"
								:src="previewUrl"
								style="width: 100%; height: 100%; border: none;"
								title="Agenda Preview"
							></iframe>
							<div v-else class="d-flex align-items-center justify-content-center h-100 text-muted">
								Select a category to preview the agenda
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<button v-if="categoryId" type="submit" class="btn btn-primary" :disabled="loading || !categoryId">
					Save changes
				</button>
			</div>
		</form>
	</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'MeetingSettingsForm',
	props: {
		categoryId: {
			type: Number,
			default: null,
		},
		categories: {
			type: Array,
			default: () => [],
		},
		settings: {
			type: Object,
			default: () => ({}),
		},
		loading: {
			type: Boolean,
			required: true,
		},
		meetingPid: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			form: this.settings,
			selectedCategory: '',
			previewUrl: null,
		}
	},
	computed: {
		...mapState(['j', 'apiUrl']),
	},
	watch: {
		// update local state and preview after new props arrive
		settings(newVal) {
			this.form = { ...newVal }
			this.updatePreview()
		},
		// update preview after categoryId changes
		categoryId: {
			immediate: true,
			handler(newVal) {
				if (newVal) {
					this.updatePreview()
				} else {
					this.previewUrl = null
				}
			},
		},
		// update preview after form changes
		form: {
			handler() {
				this.updatePreview()
			},
			deep: true,
		},
	},
	methods: {
		handleSubmit() {
			this.$emit('submit', this.form)
		},
		setCategory() {
			this.$emit('setCategory', this.selectedCategory)
		},
		updatePreview() {
			if (this.categoryId) {
				const params = new URLSearchParams({
					logo: this.form.template_logo,
					header_alignment: this.form.template_header_alignment,
					titles_font: this.form.template_titles_font,
					content_font: this.form.template_content_font,
					main_items_numbering: this.form.template_items_numbering_style,
					sub_items_numbering: this.form.template_subitems_numbering_style,
					show_item_description: String(this.form.template_agenda_item_text),
					show_speaker_name: String(this.form.template_agenda_item_speaker),
					header_note: this.form.template_agenda_header_note || '',
					footer_note: this.form.template_agenda_footer_note || '',
					t: Date.now().toString(),
				})

				this.previewUrl = `${this.apiUrl + this.j.slug}/meetings/${this.meetingPid}/agenda-html?${params}`
			}
		},
	},
}
</script>
