<template>
	<div class="page-meetings-categories">
		<div class="row align-items-center gx-3 mb-3">
			<div class="col">
				<h3 class="my-0">
					<router-link :to="`/${j.slug}/meetings`">Meetings</router-link>
					<font-awesome-icon :icon="['fas', 'angle-right']" class="text-muted ms-2" />
					Categories
				</h3>
			</div>
			<div class="col-auto">
				<button class="btn btn-sm btn-big-icon btn-primary" @click="showAddCategoryModal">
					<font-awesome-icon :icon="['fas', 'plus-circle']" /> Add category
				</button>
			</div>
		</div>

		<div class="card">
			<div class="card-body">
				<p>All meeting categories in your {{ j.type }}</p>

				<hr class="bg-primary-50" />

				<div class="card-table">
					<table class="table table-hover">
						<thead>
							<tr>
								<th>Category</th>
								<th>Meetings</th>
								<th>Created by</th>
								<th>Created on</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="category in categories" :key="category.id">
								<td>
									<router-link :to="`/${j.slug}/meetings/categories/${category.id}`">{{
										category.name
									}}</router-link>
								</td>
								<td>
									<router-link :to="`/${j.slug}/meetings?category=${category.id}`">{{
										category.meetings_count.toLocaleString()
									}}</router-link>
								</td>
								<td><PersonLink :id="category.created_by" :avatar="16"></PersonLink></td>
								<td>{{ category.created_at | dateLocal }}</td>
							</tr>

							<tr v-if="states.categories === 'loading'">
								<td colspan="4" class="text-center py-5">
									<span class="spinner-border spinner-border-sm"></span> Loading categories...
								</td>
							</tr>
							<tr v-else-if="states.categories === 'error'">
								<td colspan="4" class="text-center py-5 text-danger">
									Error loading meeting categories ({{ errorMessage }})
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>

		<!-- Add Category Modal -->
		<div class="modal fade" id="modal-add-category" tabindex="-1">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">Add Category</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal"></button>
					</div>
					<form @submit.prevent="createCategory">
						<div class="modal-body">
							<div class="form-group">
								<label class="form-label" for="category-name"
									>Name <span class="text-danger">*</span></label
								>
								<input
									type="text"
									class="form-control"
									id="category-name"
									v-model="newCategory.name"
									required
									placeholder="Enter category name"
								/>
							</div>
						</div>
						<div class="modal-footer">
							<button
								type="submit"
								class="btn btn-primary"
								:disabled="states.createCategory === 'loading'"
							>
								<span
									v-if="states.createCategory === 'loading'"
									class="spinner-border spinner-border-sm me-1"
								></span>
								Create Category
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import { Modal } from 'bootstrap'
import { mapState } from 'vuex'

import { hgApi } from '@/api.js'

import PersonLink from '@/components/PersonLink.vue'

export default {
	name: 'MeetingCategories',
	components: { PersonLink },
	data() {
		return {
			states: {
				categories: 'loading',
				createCategory: 'idle',
			},
			categories: [],
			errorMessage: '',
			newCategory: {
				name: '',
			},
			$modalAddCategory: null,
		}
	},
	computed: {
		...mapState(['j']),
	},
	created() {
		this.loadCategories()
	},
	mounted() {
		this.$modalAddCategory = new Modal(document.getElementById('modal-add-category'))
	},
	methods: {
		loadCategories() {
			this.states.categories = 'loading'

			hgApi(`${this.j.slug}/meetings/categories`)
				.then(response => {
					if (response.ok) {
						return response.json()
					}
					throw new Error(response.statusText || 'Server error')
				})
				.then(categories => {
					this.categories = categories
					this.states.categories = 'loaded'
				})
				.catch(error => {
					this.errorMessage = error.message
					this.states.categories = 'error'
				})
		},

		showAddCategoryModal() {
			this.newCategory.name = ''
			this.$modalAddCategory.show()
		},

		async createCategory() {
			this.states.createCategory = 'loading'

			try {
				const response = await hgApi(`${this.j.slug}/meetings/categories`, {
					body: this.newCategory,
				})

				if (!response.ok) {
					const error = await response.json()
					throw new Error(error.message || response.statusText || 'Server error')
				}

				const category = await response.json()
				Vue.toasted.success('Category created successfully')
				this.$modalAddCategory.hide()

				// Navigate to edit page
				this.$router.push(`/${this.j.slug}/meetings/categories/${category.id}`)
			} catch (error) {
				Vue.toasted.error(error.message || `Couldn't create category`)
			} finally {
				this.states.createCategory = 'idle'
			}
		},
	},
}
</script>
