<template>
	<div class="page-payments">
		<div class="row align-items-center mb-3">
			<div class="col">
				<h3 class="my-0">HeyGov Pay</h3>
			</div>
			<div v-if="currentRole === 'ADMIN' && paymentDepartments.length" class="col-auto">
				<!-- <button
					class="btn btn-sm btn-outline-info ms-2 d-none d-md-inline-block"
					data-bs-toggle="modal"
					data-bs-target="#payments-account-connect"
				>
					Connect another account
				</button> -->
				<router-link
					:to="`/${j.slug}/payments/quickpay-settings`"
					class="btn btn-sm btn-outline-dark ms-2"
					:disabled="!paymentDepartments.length"
				>
					⚙️ Payments portal settings
				</router-link>
				<router-link :to="`/${j.slug}/quick-pay`" class="btn btn-sm btn-outline-primary ms-2">
					💵 View payments portal
				</router-link>
			</div>
		</div>

		<div v-if="states.departments === 'loaded' && paymentDepartments.length">
			<ul class="nav nav-tabs nav-payment-accounts on-parent mb-1">
				<li class="nav-item" v-for="department in paymentDepartments" :key="`department-${department.id}`">
					<router-link
						class="nav-link"
						:class="{ active: $route.params.departmentId == department.id }"
						:to="`/${j.slug}/payments/${department.id}`"
						@click.native="changeDepartment(department.id)"
						>{{ department.name }}
					</router-link>
				</li>
				<li class="nav-item" :class="{ 'show-on-hover': paymentDepartments.length > 2 }">
					<router-link
						class="nav-link text-neutral-400"
						:class="{ active: $route.name === 'PaymentsConnectDepartment' }"
						:to="`/${j.slug}/payments/connect-department`"
						title="Add payments account"
						><font-awesome-icon :icon="['fas', 'plus']" /><span v-if="paymentDepartments.length < 4">
							Connect an account</span
						>
					</router-link>
				</li>
			</ul>

			<div v-if="$route.query.message" class="alert alert-success my-3">
				{{ $route.query.message }}
			</div>
			<div v-else-if="$route.query.error" class="alert alert-danger text-dark my-3">
				{{ $route.query.error }}
			</div>

			<div v-if="$route.query.stripeRefresh" class="alert alert-success my-3">
				<span
					>👍 Onboarding complete. After Stripe verifies the information, the department will be ready to
					accept card payments.</span
				>
			</div>
			<div
				v-else-if="deptPaymentPlatform === 'stripe' && selectedDepartment.stripe_account_status === 'started'"
				class="alert alert-warning d-flex justify-content-between align-items-center my-3"
			>
				<span class="text-dark"
					>⚠️ To start accepting card payments, this account needs business and representative
					verification.</span
				>
				<a :href="stripeConnectUrl" class="btn btn-primary" :class="{ disabled: !stripeConnectUrl }"
					>Continue verification →</a
				>
			</div>
			<div
				v-else-if="account && selectedDepartment.stripe_account && account.requirements.errors.length"
				class="alert alert-danger my-3"
			>
				<p class="lead mb-2">⚠️ Verification error ({{ account.requirements.currently_due.join(', ') }})</p>
				<p v-for="error in account.requirements.errors" :key="error.code" class="mb-2">{{ error.reason }}</p>
				<p class="mb-0">
					<a :href="stripeConnectUrl" class="btn btn-sm btn-primary" :class="{ disabled: !stripeConnectUrl }"
						>Update required information</a
					>
					<span v-if="account.requirements.current_deadline" class="ms-2"
						>Deadline: {{ account.requirements.current_deadline | dateLocal }}</span
					>
				</p>
			</div>
			<div
				v-else-if="account && selectedDepartment.stripe_account && account.future_requirements.errors.length"
				class="alert alert-warning text-dark my-3"
			>
				<p class="lead mb-2">
					ℹ️ Please provide this additional information in order to keep the payment account in good standing.
				</p>
				<ul>
					<li v-for="error in account.future_requirements.errors" :key="error.code">{{ error.reason }}</li>
				</ul>
				<p class="mb-0">
					<a :href="stripeConnectUrl" class="btn btn-sm btn-primary" :class="{ disabled: !stripeConnectUrl }"
						>Continue verification</a
					>
					<span v-if="account.future_requirements.current_deadline" class="text-danger-400 ms-2"
						>Deadline: {{ account.future_requirements.current_deadline | dateLocal }}. Payments and payouts
						will be paysed after this date.</span
					>
				</p>
			</div>
			<div
				v-else-if="
					account &&
						selectedDepartment.stripe_account &&
						account.requirements.disabled_reason === 'under_review'
				"
				class="alert alert-warning my-3"
			>
				Payments and deposits are paused while Stripe reviews this account.
			</div>

			<div
				v-if="
					deptPaymentPlatform === 'stripe' &&
						account &&
						account.type === 'custom' &&
						!account.external_accounts.data.length
				"
				class="alert alert-warning text-dark d-flex justify-content-between align-items-center my-3"
			>
				🏦 To receive deposits from HeyGov, add your bank account details.
				<button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#stripe-account-bank">
					Add bank account
				</button>
			</div>

			<div class="row gx-3 mb-3">
				<div class="col my-2">
					<div class="card rounded-1">
						<div class="card-body p-3 text-center">
							<p class="mb-1 text-success">Balance</p>
							<!-- <p class="mb-1 text-secondary">Future Payouts</p> -->
							<p class="card-text mb-0 fs-5">
								<strong
									v-if="balance"
									:title="
										`Payout available: ${numberToCurrency(
											balance.available[0].amount / 100
										)}, Pending: ${numberToCurrency(balance.pending[0].amount / 100)}`
									"
								>
									{{ ((balance.available[0].amount + balance.pending[0].amount) / 100) | currency }}
								</strong>
								<span v-else>-</span>
							</p>
						</div>
					</div>
				</div>

				<div class="col my-2">
					<div class="card cursor-pointer rounded-1" @click="tab = 'payouts'">
						<div class="card-body p-3 text-center">
							<p class="mb-1 text-secondary">In transit to bank</p>
							<p class="card-text mb-0 fs-5">
								<strong v-if="balance">
									{{ (balanceInTransit / 100) | currency }}
								</strong>
								<span v-else>-</span>
							</p>
						</div>
					</div>
				</div>

				<div v-if="selectedDepartment.billing_accounts" class="col my-2 d-none d-lg-block">
					<router-link
						:to="`/${j.slug}/payments/${selectedDepartment.id}/billing-accounts`"
						class="card card-link rounded-1"
					>
						<div class="card-body p-3 text-center">
							<p class="mb-1 text-primary">Billing accounts</p>
							<p class="card-text mb-0 fs-5">
								<span v-if="billingAccountsCount === null" class="text-netural-400">-</span
								><strong v-else>{{ billingAccountsCount.toLocaleString() }}</strong> accounts
							</p>
						</div>
					</router-link>
				</div>

				<!-- <div class="col-12 col-md-4 my-2 d-none d-lg-block">
					<div
						class="card rounded-1"
						:class="{
							'border border-danger':
								account && account.type === 'custom' && !account.external_accounts.data.length,
						}"
					>
						<div class="card-body p-3 text-center">
							<p
								v-if="account && account.type === 'custom' && !account.external_accounts.data.length"
								class="mb-1 text-dark"
							>
								⚠️ Add bank account to enable deposits
							</p>
							<p v-else class="mb-1 text-dark">Bank Account</p>

							<p class="card-text mb-0 fs-5">
								<strong
									v-if="account && account.type === 'custom' && account.external_accounts.data.length"
									class="text-danger"
								>
									{{ account.external_accounts.data[0].routing_number }} ****
									{{ account.external_accounts.data[0].last4 }}
								</strong>
								<button
									v-else-if="account && account.type === 'custom'"
									class="btn btn-sm"
									:class="stripeConnectUrl ? 'btn-outline-primary' : 'btn-primary'"
									data-bs-toggle="modal"
									data-bs-target="#stripe-account-bank"
								>
									Add bank account
								</button>
								<a
									v-else-if="account && account.type === 'standard'"
									href="https://dashboard.stripe.com"
									target="_blank"
									class="btn btn-sm btn-outline-primary"
								>
									Manage in Stripe Dashboard
								</a>
								<span v-else>-</span>
							</p>
						</div>
					</div>
				</div> -->

				<div class="col-2 my-2 d-none d-lg-block">
					<router-link
						:to="`/${j.slug}/payments/${selectedDepartment.id}/settings`"
						class="card card-link bg-neutral-50 rounded-1 h-100"
					>
						<div class="card-body d-flex align-items-center py-2 text-center">
							⚙️ Settings &amp; Integrations
						</div>
					</router-link>
				</div>
			</div>

			<div v-if="showBillingAccountsPromo" class="bg-success-50 border p-3 rounded-1 mb-4">
				<button
					type="button"
					class="btn-close float-end"
					aria-label="Close"
					@click="hideBillingAccountsPromo"
				></button>
				🧾 Do you need Utility Payments or Billing Accounts for this account?
				<router-link :to="`/${j.slug}/payments/${selectedDepartment.id}/settings`">Enable it now</router-link>
			</div>

			<div
				v-if="['stripe', 'justifi', 'municipay', 'paygov', 'bridgepay'].includes(deptPaymentPlatform)"
				class="card rounded-1 border-0 bg-info-lighter mb-4"
			>
				<div class="card-body py-2">
					<div class="row align-items-center gx-3">
						<div class="d-none d-md-block col-auto me-auto">
							<p class="my-0">Create payment →</p>
						</div>

						<div v-if="['stripe'].includes(deptPaymentPlatform)" class="col-12 col-lg-3">
							<div class="d-grid py-1">
								<button
									class="btn btn-primary"
									:disabled="!chargesEnabled"
									data-bs-toggle="modal"
									data-bs-target="#card-reader-payment"
									@click="stripeTerminalStart"
								>
									<font-awesome-icon :icon="['far', 'credit-card']" class="me-1" />With Card Reader
								</button>
							</div>
						</div>
						<div class="col-12 col-lg-3">
							<div class="d-grid py-1">
								<button
									class="btn btn-primary"
									:disabled="!chargesEnabled"
									data-bs-toggle="modal"
									data-bs-target="#payment-request"
									@click="startPaymentLink"
								>
									<font-awesome-icon :icon="['fas', 'comment-dollar']" class="me-1" /> Request payment
								</button>
							</div>
						</div>
						<div class="col-12 col-lg-3">
							<div class="d-grid py-1">
								<button
									class="btn btn-primary"
									data-bs-toggle="modal"
									data-bs-target="#modal-payment-cash-check"
								>
									🧾 Cash/check payment
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="card mb-3">
				<div class="card-body">
					<h3 class="card-title mb-2">Account activity</h3>

					<div v-if="selectedDepartment">
						<ul class="nav nav-tabs mb-3" v-if="this.departments">
							<li class="nav-item">
								<a
									class="nav-link"
									:class="{ active: tab === 'payments' }"
									@click="loadPayments(selectedDepartment.id)"
								>
									Payments</a
								>
							</li>
							<li class="nav-item">
								<a class="nav-link" :class="{ active: tab === 'links' }" @click="loadPaymentLinks()">
									Payment Links</a
								>
							</li>
							<li class="nav-item">
								<a class="nav-link" :class="{ active: tab === 'payouts' }" @click="tab = 'payouts'"
									>Deposits</a
								>
							</li>
						</ul>

						<div v-if="tab == 'payouts'">
							<div v-if="['stripe', 'justifi'].includes(deptPaymentPlatform)">
								<button
									v-if="
										account &&
											account.type === 'custom' &&
											account.settings.payouts.schedule.interval === 'manual'
									"
									class="btn btn-sm btn-outline-primary float-end"
									data-bs-toggle="modal"
									data-bs-target="#modal-payout-balance"
									@click="states.createPayout = 'idle'"
								>
									<span class="me-1">💸</span> Pay out funds
								</button>

								<p class="mb-2">
									Deposits are transfers from HeyGov Pay balance to your linked bank account.
								</p>
								<template
									v-if="deptPaymentPlatform === 'stripe' && account && account.type === 'custom'"
								>
									<p class="mb-2">
										Bank account:
										<strong
											v-for="bank in account.external_accounts.data.filter(
												b => b.default_for_currency
											)"
											class="me-2"
											:key="`d-${selectedDepartment.id}-ba-${bank.id}`"
											>{{ bank.bank_name }} - {{ bank.routing_number }} ****
											{{ bank.last4 }}</strong
										>
										<i v-if="!account.external_accounts.data.length" class="text-danger-400"
											>(no bank account added)</i
										>

										<small
											v-if="currentRole === 'ADMIN'"
											class="text-neutral-400 cursor-pointer ms-2"
											@click="contactHG('Change payouts bank account')"
											>Edit</small
										>
									</p>
									<p>
										Deposits schedule:
										<strong v-if="account.settings.payouts.schedule.interval === 'manual'"
											>Manual payouts enabled
										</strong>
										<strong v-else-if="account.settings.payouts.schedule.interval === 'daily'"
											>Daily, on a rolling {{ account.settings.payouts.schedule.delay_days }} days
											basis
										</strong>
										<strong v-else-if="account.settings.payouts.schedule.interval === 'weekly'"
											>Weekly on {{ account.settings.payouts.schedule.weekly_anchor }}, on a
											rolling {{ account.settings.payouts.schedule.delay_days }} days basis
										</strong>
										<strong v-else-if="account.settings.payouts.schedule.interval === 'monthly'"
											>Monthly on the
											{{ formatOrdinals(account.settings.payouts.schedule.monthly_anchor) }}, on a
											rolling {{ account.settings.payouts.schedule.delay_days }} days basis
										</strong>
										<code v-else>{{ account.settings.payouts.schedule }} </code>

										<small
											v-if="currentRole === 'ADMIN'"
											class="text-neutral-400 cursor-pointer ms-2"
											@click="startPayoutsScheduleEdit"
											data-bs-toggle="modal"
											data-bs-target="#modal-payout-schedule"
											>Edit</small
										>
									</p>
								</template>

								<!-- Payouts table-->
								<div class="card-table mb-3">
									<table class="table table-hover">
										<thead>
											<tr>
												<th>Amount</th>
												<th>Description</th>
												<th>Status</th>
												<th>Sent on</th>
												<th>Deposited on</th>
												<th>Bank account</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="payout in payouts" :key="payout.id">
												<td>
													{{ (payout.amount / 100) | currency }}
												</td>
												<td>
													<span v-if="payout.automatic">
														<span
															class="badge rounded-pill bg-success-50 text-success cursor-pointer me-1"
															data-bs-toggle="modal"
															data-bs-target="#payout-details"
															@click="payoutSelected = payout"
															>{{ pluralize('payment', payout.paymentsCount, true) }}
														</span>

														<span
															v-if="payout.refundsCount"
															class="badge rounded-pill bg-primary-50 text-info cursor-pointer me-1"
															data-bs-toggle="modal"
															data-bs-target="#payout-details"
															@click="payoutSelected = payout"
															>{{ pluralize('refund', payout.refundsCount, true) }}
														</span>

														<span
															v-if="
																payout.transactions.filter(
																	t =>
																		!['refund', 'charge', 'payment'].includes(
																			t.type
																		)
																).length
															"
															class="badge rounded-pill bg-danger-50 text-danger cursor-pointer me-1"
															data-bs-toggle="modal"
															data-bs-target="#payout-details"
															@click="payoutSelected = payout"
															>{{
																pluralize(
																	'other',
																	payout.transactions.filter(
																		t =>
																			!['refund', 'charge', 'payment'].includes(
																				t.type
																			)
																	).length,
																	true
																)
															}}
														</span>
													</span>
													<span v-else>{{ payout.description }}</span>
												</td>
												<td>
													<span class="badge" :class="payoutStatuses[payout.status].class"
														>{{ payoutStatuses[payout.status].name }}
													</span>
												</td>
												<td>{{ payout.created || payout.createdAt | dateLocal }}</td>
												<td>{{ payout.arrival_date || payout.depositsAt | dateLocal }}</td>
												<td>
													🏦

													<span v-if="payout.bankAccount">
														{{ payout.bankAccount.bankName }} ****
														{{ payout.bankAccount.accountNumberLast4 }}
													</span>
													<span
														v-else-if="
															account.external_accounts.data.find(
																ba => ba.id === payout.destination
															)
														"
													>
														{{
															account.external_accounts.data.find(
																ba => ba.id === payout.destination
															).bank_name
														}}
														****
														{{
															account.external_accounts.data.find(
																ba => ba.id === payout.destination
															).last4
														}}
													</span>
												</td>
											</tr>
										</tbody>
										<tfoot>
											<tr v-if="states.payouts === 'loading'">
												<td colspan="6">
													<p class="text-center">loading..</p>
												</td>
											</tr>
											<tr v-else-if="states.payouts === 'idle' && !payouts.length">
												<td colspan="6">
													<p class="text-center">There are no deposits yet 🤷</p>
												</td>
											</tr>
										</tfoot>
									</table>
								</div>

								<p v-if="states.payoutsHasMore" class="card-text">
									<button
										class="btn btn-sm btn-outline-dark"
										@click="loadPayouts({ starting_after: payouts[payouts.length - 1].id })"
									>
										Load more deposits
									</button>
								</p>
							</div>
							<div v-else-if="deptPaymentPlatform === 'paygov'">
								<p class="my-5 text-center">
									Head over to
									<a href="https://reports2.paygov.us/login.aspx" target="_blank">PayGov dashboard</a>
									to see and manage deposits.
								</p>
							</div>
							<div v-else-if="deptPaymentPlatform === 'municipay'">
								<p class="my-5 text-center">
									Head over to
									<a href="https://secure.municipay.com/" target="_blank">MuniciPay dashboard</a>
									to see and manage deposits.
								</p>
							</div>
							<div v-else-if="deptPaymentPlatform === 'bridgepay'">
								<p class="my-5 text-center">
									Head over to
									<a href="https://www.mybridgepay.com/" target="_blank">myBridgePay dashboard</a>
									to see and manage deposits.
								</p>
							</div>
							<pre v-else>{{ deptPaymentPlatform }}</pre>
						</div>

						<div v-else-if="tab == 'payments'">
							<!-- Payments table -->
							<div class="card-table mb-3">
								<table class="table table-hover">
									<thead>
										<tr>
											<th>Amount</th>
											<th>Fee</th>
											<th>Date</th>
											<th>Status</th>
											<th>Deposit date</th>
											<th>Payment method</th>
											<th>Payer</th>
											<th>Note</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="payment in payments" :key="payment.id" class="on-parent">
											<td>
												{{ payment.amount | currency }}
											</td>
											<td>
												<span :class="{ 'text-muted': !payment.fee }">{{
													payment.fee | currency
												}}</span>
											</td>
											<td>{{ payment.created_at | dateLocal }}</td>
											<td>
												<payment-status :payment="payment" :tooltip="true" />
											</td>
											<td>
												<span v-if="payment.deposit_status === 'paid'">
													<!-- <span class="badge bg-success-lighter text-success">✓</span> -->
													{{ payment.deposit_date | dateLocal }}</span
												>
												<span
													v-else-if="payment.deposit_status"
													class="badge bg-light text-gray"
													>{{ payment.deposit_status }}</span
												>
											</td>
											<td>
												<payment-method
													v-if="payment.payment_method"
													:payment-method="payment.payment_method"
												/>
											</td>
											<td>
												<person-link
													v-if="payment.person_id"
													:id="payment.person_id"
													:avatar="18"
												/>
												<span v-else>{{ payment.email_or_phone }}</span>
											</td>
											<td>
												<entity-link
													v-if="payment.source && payment.source.includes('_')"
													:entity="payment.source"
												></entity-link>
												<span v-else>
													<font-awesome-icon
														v-if="payment.source === 'link'"
														:icon="['fas', 'comment-dollar']"
													/>
													<font-awesome-icon
														v-else-if="payment.source === 'quick-pay'"
														icon="fa-solid fa-forward"
													/>
													<font-awesome-icon
														v-else-if="payment.source === 'card-reader'"
														:icon="['far', 'credit-card']"
													/>
													<font-awesome-icon
														v-else-if="payment.source === 'cash-check'"
														:icon="['fas', 'money-bill']"
													/>
													<pre v-else>{{ payment.source }}</pre>
													{{ payment.description }}
												</span>
											</td>
											<td class="px-0">
												<div class="dropdown">
													<span
														class="hover p-1 px-2 rounded-1"
														type="button"
														data-bs-toggle="dropdown"
														aria-expanded="false"
													>
														<font-awesome-icon :icon="['fas', 'ellipsis-h']" />
													</span>
													<ul class="dropdown-menu">
														<template
															v-if="
																['succeeded', 'requires_capture', 'canceled'].includes(
																	payment.status
																) && payment.platform !== 'paygov'
															"
														>
															<li>
																<a
																	class="dropdown-item"
																	:href="
																		`${apiUrl + j.slug}/payments/${
																			payment.uuid
																		}/receipt`
																	"
																	target="_blank"
																	>🧾 View receipt</a
																>
															</li>
															<li>
																<a
																	class="dropdown-item"
																	:href="
																		`${apiUrl + j.slug}/payments/${
																			payment.uuid
																		}/receipt-html?print=1`
																	"
																	target="_blank"
																	>🖨️ Print receipt</a
																>
															</li>
														</template>

														<li v-if="payment.platform === 'paygov'">
															<a
																href="https://reports2.paygov.us/login.aspx"
																target="_blank"
																class="dropdown-item"
															>
																⏎ Refund
															</a>
														</li>
														<li
															v-else-if="
																['heygov', 'stripe', 'municipay', 'bridgepay'].includes(
																	payment.platform
																)
															"
														>
															<router-link
																:to="
																	`/${j.slug}/payments/${selectedDepartment.id}/payments?refund=${payment.uuid}`
																"
																class="dropdown-item"
																:class="{
																	disabled:
																		payment.status !== 'succeeded' ||
																		[4479].includes(auth.id),
																}"
															>
																⏎ Refund
															</router-link>
														</li>

														<li v-if="isStaff && payment.platform === 'stripe'">
															<a
																:href="
																	`https://dashboard.stripe.com/${selectedDepartment.stripe_account}/payments/${payment.platform_id}`
																"
																target="_blank"
																class="dropdown-item text-danger-100"
															>
																View in Stripe
															</a>
														</li>
													</ul>
												</div>
											</td>
										</tr>
									</tbody>
									<tfoot>
										<tr v-if="states.payments === 'loading'">
											<td colspan="9">
												<p class="text-center">loading..</p>
											</td>
										</tr>
										<tr v-else-if="states.payments === 'idle' && !payments.length">
											<td colspan="9" class="text-center py-5">
												<img
													src="https://files.heygov.com/illustrations/illustration-payments.jpg"
													alt="HeyGov payments"
													class="mb-3 rounded"
													width="300"
												/>
												<p class="lead text-center mb-0">Awaiting payments</p>
											</td>
										</tr>
									</tfoot>
								</table>
							</div>

							<p v-if="payments.length" class="card-text text-end">
								<router-link :to="`/${j.slug}/payments/${selectedDepartment.id}/payments`"
									>See all payments →</router-link
								>
							</p>
						</div>

						<div v-else-if="tab == 'links'" class="card-table">
							<!-- Payments table -->
							<table class="table table-hover">
								<thead>
									<tr>
										<th>Amount</th>
										<th>Fee</th>
										<th>Date</th>
										<th>Status</th>
										<th>Sent by</th>
										<th>Payer</th>
										<th>Note</th>
										<th>Link</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="payment in paymentLinks" :key="payment.id" class="on-parent">
										<td>
											{{ payment.amount | currency }}
										</td>
										<td>
											{{ payment.fee | currency }}
										</td>
										<td>{{ payment.created_at | dateLocal }}</td>
										<td>
											<payment-status :payment="payment" :tooltip="true" />
										</td>
										<td>
											<person-link
												v-if="payment.created_by"
												:id="payment.created_by"
												:avatar="18"
											/>
											<small v-else class="text-neutral-400">-</small>
										</td>
										<td>
											<person-link :id="payment.person_id" :avatar="18" />
										</td>
										<td>
											{{ payment.description }}
										</td>
										<td>
											<router-link
												v-if="['requires_payment_method', 'pending'].includes(payment.status)"
												:to="`/${j.slug}/quick-pay/payments/${payment.uuid}`"
												target="_blank"
												>Payment link ❐</router-link
											>
										</td>
										<td>
											<button
												v-if="['requires_payment_method', 'pending'].includes(payment.status)"
												class="btn btn-sm btn-icon-danger show-on-hover"
												title="Cancel payment"
												@click="paymentLinkCancel(payment)"
											>
												<font-awesome-icon :icon="['fas', 'times']" />
											</button>
										</td>
									</tr>
								</tbody>
								<tfoot>
									<tr v-if="states.paymentLinks === 'loading'">
										<td colspan="8">
											<p class="text-center">loading..</p>
										</td>
									</tr>
									<tr v-else-if="states.paymentLinks === 'idle' && !paymentLinks.length">
										<td colspan="8">
											<p class="text-center">There are no payment links yet 🤷</p>
										</td>
									</tr>
								</tfoot>
							</table>
						</div>

						<!-- <div v-else-if="selectedDepartment.stripe_account_status === 'waiting'">
							<p class="text-center">Payments account is awaiting verification from Stripe.</p>
						</div>
						<div v-else-if="selectedDepartment.stripe_account_status === 'started'">
							<p class="text-center">
								This payment account is started, please add the necessary information in
								<strong>Departments</strong> page.
							</p>
						</div> -->
					</div>
				</div>
			</div>

			<p class="text-muted" @dblclick="states.details = !states.details">
				Payment account using
				<a
					v-if="selectedDepartment.paygov_account"
					:href="
						`https://pay.paygov.us/EndUser/PaymentAgency.aspx?ttid=${selectedDepartment.paygov_account
							.split(':')
							.shift()}`
					"
					target="_blank"
					>PayGov.us {{ selectedDepartment.paygov_account.split(':').shift() }}</a
				>
				<template v-else-if="selectedDepartment.justifi_account">
					<a
						v-if="isStaff"
						:href="`https://app.justifi.ai/account/${selectedDepartment.justifi_account}/dashboard`"
						target="_blank"
						>JustiFi {{ selectedDepartment.justifi_account }}
						<span v-if="account" class="badge bg-neutral-50 text-dark ms-1">{{ account.status }}</span></a
					><code v-else>JustiFi {{ selectedDepartment.justifi_account }}</code>
				</template>
				<template v-else-if="selectedDepartment.municipay_account">
					MuniciPay <code>{{ selectedDepartment.municipay_account }}</code>
				</template>
				<template v-else-if="selectedDepartment.stripe_account">
					<a
						v-if="isStaff"
						:href="
							`https://dashboard.stripe.com/${selectedDepartment.stripe_account}/${
								j.livemode ? '' : 'test/'
							}dashboard`
						"
						target="_blank"
						>Stripe {{ selectedDepartment.stripe_account }}
						<span class="badge bg-neutral-50 text-dark ms-1">{{
							selectedDepartment.stripe_account_status
						}}</span></a
					><code v-else>Stripe {{ selectedDepartment.stripe_account }}</code>
				</template>
				<template v-else-if="selectedDepartment.payments_platform === 'bridgepay'">
					<a
						:href="j.livemode ? '`https://www.mybridgepay.com/' : 'https://www.mybridgepaytest.com/'"
						target="_blank"
						>BridgePay {{ selectedDepartment.bridgepay_account }}
					</a>
				</template>
				<code v-else>🤷</code>
			</p>

			<div v-if="states.details && isStaff" class="card border-danger">
				<div v-if="selectedDepartment.stripe_account && account" class="card-body">
					<p class="mb-2">
						Account type: <strong>{{ account.type }}</strong>
					</p>
					<p class="mb-2">
						Business type: <strong>{{ account.business_type }}</strong>
					</p>
					<p class="mb-2">
						Capabilities:
						<span
							v-for="(val, cap) in account.capabilities"
							:key="cap"
							class="badge bg-light text-dark me-2"
							>{{ cap }} = {{ val }}</span
						>
					</p>
					<p class="mb-2">
						Charges enabled: <strong>{{ account.charges_enabled ? 'Yes' : 'No' }}</strong>
					</p>
					<p class="mb-2">
						Payouts enabled: <strong>{{ account.payouts_enabled ? 'Yes' : 'No' }}</strong>
					</p>
					<p class="mb-0">Requirements: <code>to be added later</code></p>
				</div>
				<div v-else class="card-body">
					<p class="card-text">Can't find more account details</p>
				</div>
			</div>

			<div class="modal fade" id="card-reader-pair" tabindex="-1" aria-hidden="true">
				<div class="modal-dialog">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title">💳 Pair a card reader</h5>
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body">
							<div class="bg-info-lighter px-3 py-2 rounded mb-3">
								<a href="https://www.youtube.com/watch?v=l-ASh4LIRfM" target="_blank"
									>▶️ Watch an explainer video for card reader pairing</a
								>
							</div>

							<form v-if="terminalLocations.length" @submit.prevent="addTerminal">
								<p>Now turn on the card reader, and fill the code below:</p>

								<div class="form-group mb-3">
									<label class="form-label">Registration code</label>
									<input
										class="form-control"
										type="text"
										v-model="newTerminalReader.registration_code"
										required
										placeholder="Code that appears on card reader"
									/>
								</div>

								<div class="form-group mb-3">
									<label class="form-label">Terminal name</label>
									<input
										class="form-control"
										type="text"
										v-model="newTerminalReader.label"
										required
										placeholder="Give the terminal a name, e.g. 'Cashier 1'"
									/>
								</div>

								<p class="text-center">
									<button class="btn btn-primary">Connect card reader</button>
								</p>
							</form>

							<form v-else @submit.prevent="addTerminalLocation">
								<p>First, where will this card reader be used?</p>

								<div class="form-group mb-3">
									<label class="form-label">Location</label>
									<input
										class="form-control"
										type="text"
										v-model="newTerminalLocation.line1"
										required
										placeholder="Street address"
									/>
								</div>

								<div class="row">
									<div class="col-6 form-group mb-3">
										<label class="form-label">{{ j.type }}</label>
										<input
											class="form-control"
											type="text"
											v-model="newTerminalLocation.city"
											required
										/>
									</div>
									<div class="col-3 form-group mb-3">
										<label class="form-label">State</label>
										<input
											class="form-control"
											type="text"
											v-model="newTerminalLocation.state"
											required
										/>
									</div>
									<div class="col-3 form-group mb-3">
										<label class="form-label">ZIP</label>
										<input
											class="form-control"
											type="text"
											v-model="newTerminalLocation.postal_code"
											required
										/>
									</div>
								</div>

								<p class="text-center">
									<button class="btn btn-primary">Continue</button>
								</p>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-else-if="states.departments === 'loading'" class="text-center py-5">
			<span class="spinner-border"></span>
		</div>
		<div v-else>
			<div class="card text-center">
				<div class="card-body py-5">
					<h5>💰 Accept online or in-person payments</h5>
					<p>
						Connect a payment provider to handle payments for permits, licenses, venue reservation, bills
						and more.
					</p>
					<p class="mb-0">
						<router-link :to="`/${j.slug}/payments/connect-department`" class="btn btn-primary">
							Connect payment provider
						</router-link>
					</p>
				</div>
			</div>
		</div>

		<!-- Card reader payment -->
		<div class="modal fade" id="card-reader-payment" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">
							Payment in Card Reader
						</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						<div v-if="terminalError" class="alert alert-danger">{{ terminalError }}</div>

						<p v-if="terminalReaders.length">
							Card reader: <strong>{{ terminalReaders[0].label }}</strong>
						</p>

						<div
							v-if="['discovering', 'connecting'].includes(terminalReaderState)"
							class="text-center py-4"
						>
							<span class="spinner-border"></span>
						</div>

						<div v-if="terminalReaderState === 'no-readers'" class="">
							<p class="text-center card-text my-5">There are no connected card readers.</p>
							<p class="text-center card-text">
								<a
									class="btn btn-sm btn-outline-dark mx-2"
									href="https://heygov.com/heygov-shop#POS-Terminal"
									target="_blank"
									>Buy a card reader</a
								>
								or
								<button
									class="btn btn-sm btn-outline-info mx-2"
									data-bs-toggle="modal"
									data-bs-target="#card-reader-pair"
									@click="loadTerminalLocations"
								>
									Pair card reader
								</button>
							</p>
						</div>

						<div
							v-else-if="states.paymentCardReader === 'paid'"
							class="bg-success-lighter rounded-1 text-center py-5"
						>
							<h3>Payment successful 🎉</h3>
							<p>
								Paid <strong>{{ paymentCardReader.amount | currency }}</strong> +
								{{ paymentCardReaderFee | currency }} fee for "{{ paymentCardReader.description }}"
							</p>
							<p class="mb-0">
								<button class="btn btn-sm btn-outline-dark" @click="stripeTerminalStart">
									Start another payment
								</button>
							</p>
						</div>

						<div
							v-else-if="
								['creating', 'collect-payment-method', 'process-payment', 'capture-payment'].includes(
									states.paymentCardReader
								)
							"
						>
							<div class="bg-light rounded-2 px-3 py-2 mb-3">
								<p class="mb-1">
									Amount:
									<strong>{{ paymentCardReader.amount | currency }}</strong>
								</p>
								<p class="mb-1">
									Payment fee:
									<strong>{{ paymentCardReaderFee | currency }}</strong>
								</p>
								<p class="lead mb-0">
									Total:
									<strong>{{
										(Number(paymentCardReader.amount) + paymentCardReaderFee) | currency
									}}</strong>
								</p>
							</div>

							<ol v-if="states.paymentCardReader === 'capture-payment'" class="mb-3">
								<li>Creating payment ✅</li>
								<li>Awaiting card ✅</li>
								<li>Processing payment ✅</li>
								<li>
									Capturing payment
									<span
										v-if="!paymentCardReaderError"
										class="spinner-border spinner-border-sm"
									></span>
								</li>
							</ol>
							<ol v-else-if="states.paymentCardReader === 'process-payment'" class="mb-3">
								<li>Creating payment ✅</li>
								<li>Awaiting card ✅</li>
								<li>
									Processing payment
									<span
										v-if="!paymentCardReaderError"
										class="spinner-border spinner-border-sm"
									></span>
								</li>
								<li>Capturing payment</li>
							</ol>
							<ol v-else-if="states.paymentCardReader === 'collect-payment-method'" class="mb-3">
								<li>Creating payment ✅</li>
								<li>
									Awaiting card
									<span
										v-if="!paymentCardReaderError"
										class="spinner-border spinner-border-sm"
									></span>
									<button
										v-if="!paymentCardReaderError"
										class="btn btn-sm btn-outline-dark ms-2"
										@click="cancelPaymentInTerminal"
									>
										Cancel
									</button>
								</li>
								<li>Processing payment</li>
								<li>Capturing payment</li>
							</ol>
							<ol v-else class="mb-3">
								<li>
									Creating payment
									<span
										v-if="!paymentCardReaderError"
										class="spinner-border spinner-border-sm"
									></span>
								</li>
								<li>Awaiting card</li>
								<li>Processing payment</li>
								<li>Capturing payment</li>
							</ol>

							<div v-if="paymentCardReaderError">
								<div class="alert alert-danger" v-html="paymentCardReaderError"></div>
								<p class="card-text text-center">
									<button class="btn btn-primary" @click="stripeTerminalStart">
										Restart payment
									</button>
								</p>
							</div>
						</div>

						<form v-else-if="terminalReaderState === 'connected'" @submit.prevent="startPaymentInTerminal">
							<div class="form-group mb-3">
								<label for="payment-card-reader-person" class="form-label">Name of payer</label>
								<people-search
									@person="setPaymentCardReaderPerson"
									@personQuery="setPaymentCardReaderPersonQuery"
									:limit="5"
									id="payment-card-reader-person"
									:placeholder="'Type name, email address or phone'"
								></people-search>
								<small class="form-text">Optional, used for receipt &amp; payment logs</small>
							</div>

							<div v-if="paymentCardReader.person_id" class="alert alert-info mb-3 py-2">
								Receipt will be sent to
								<person-link
									v-if="people[paymentCardReader.person_id]"
									:id="paymentCardReader.person_id"
									:avatar="16"
								></person-link>
							</div>

							<div class="form-group mb-3">
								<label class="form-label" for="payment-card-reader-description"
									>Payment for <span class="text-danger">*</span></label
								>

								<div v-if="paymentCardReaderDescriptions.length" class="input-group">
									<select
										id="payment-card-reader-description"
										class="form-select"
										v-model="paymentCardReader.category"
										style="max-width: 200px"
									>
										<option
											v-for="desc in paymentCardReaderDescriptions"
											:key="`payment-cr-${desc.label}`"
											>{{ desc.label }}</option
										>
									</select>

									<input
										type="text"
										class="form-control"
										id="payment-card-reader-description2"
										list="payment-card-reader-texts"
										v-model="paymentCardReader.description"
										required
										placeholder="Item name"
									/>
								</div>
								<input
									v-else
									type="text"
									class="form-control"
									id="payment-card-reader-description"
									v-model="paymentCardReader.description"
									required
									placeholder="Will appear in card reader and accounting logs"
								/>
							</div>

							<datalist v-if="paymentCardReaderDescriptionsSelected" id="payment-card-reader-texts">
								<option
									v-for="text in paymentCardReaderDescriptionsSelected.texts"
									:key="`payment-card-reader-texts-${text}`"
									:value="text"
								>
								</option>
							</datalist>

							<div class="form-group mb-3">
								<label class="form-label" for="payment-card-reader-amount"
									>Amount <span class="text-danger">*</span></label
								>

								<div class="input-group mb-3">
									<span class="input-group-text">$</span>
									<input
										type="number"
										class="form-control"
										id="payment-card-reader-amount"
										v-model="paymentCardReader.amount"
										step="0.01"
										required
									/>
								</div>
							</div>

							<div class="bg-light rounded-2 p-3 mb-3">
								<p class="mb-1">
									Payment fee:
									<strong v-if="paymentCardReader.amount">{{
										paymentCardReaderFee | currency
									}}</strong>
									<small class="text-muted"> (2.7% + 5¢)</small>
								</p>
								<p class="lead mb-0">
									Total:
									<strong v-if="paymentCardReader.amount">{{
										(Number(paymentCardReader.amount) + paymentCardReaderFee) | currency
									}}</strong
									><span v-else class="text-muted">-</span>
								</p>
							</div>

							<div
								v-if="paymentCardReaderError"
								class="alert alert-danger"
								v-html="paymentCardReaderError"
							></div>

							<p class="card-text text-center">
								<button
									class="btn btn-primary btn-block"
									:disabled="states.paymentCardReader === 'loading'"
								>
									<span
										v-if="states.paymentCardReader === 'loading'"
										class="spinner-border spinner-border-sm"
									></span
									><span v-else>💳</span> Send payment to Card Reader
								</button>
							</p>
						</form>
					</div>
				</div>
			</div>
		</div>

		<!-- Payment Request -->
		<div class="modal fade" id="payment-request" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">
							Request payment by email or phone
						</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						<div v-if="states.paymentLink === 'sent'" class="bg-success-lighter rounded-1 text-center py-5">
							<h3 class="mb-4">Payment request is sent ✅</h3>
							<p class="mb-3">
								<strong>{{ paymentLink.amount | currency }}</strong> +
								{{ paymentLinkFee | currency }} fee for "{{ paymentLink.description }}" is sent to
								<person-link
									v-if="paymentLink.person_id"
									:id="paymentLink.person_id"
									:avatar="16"
								></person-link
								><code v-else>{{ paymentLink.emailOrPhone }}</code>
							</p>
							<p class="mb-0">
								<a
									:href="`/${j.slug}/quick-pay/payments/${this.paymentIntentLink.uuid}`"
									target="_blank"
									>Preview payment link ❐</a
								>
							</p>
						</div>

						<form v-else @submit.prevent="sendPaymentLink">
							<p>
								The recipient will receive an email or text with a link where online card payment can be
								collected securely.
							</p>

							<div class="form-group mb-3">
								<label class="form-label">Where to send?</label>
								<people-search
									@person="setPaymentLinkPerson"
									@personQuery="setPaymentLinkPersonQuery"
									:limit="5"
									:required="true"
									:placeholder="'Email or phone'"
								></people-search>
							</div>

							<div v-if="paymentLink.person_id" class="alert bg-primary-50 mb-3">
								Sending request to:
								<person-link
									v-if="people[paymentLink.person_id]"
									:id="paymentLink.person_id"
									:avatar="16"
								></person-link>
							</div>
							<div
								v-else-if="validEmailOrPhone(paymentLink.emailOrPhone)"
								class="alert bg-success-50 mb-3"
							>
								Sending request to: <code>{{ paymentLink.emailOrPhone }}</code>
							</div>
							<div v-else-if="paymentLink.emailOrPhone" class="alert alert-danger mb-3">
								"<code>{{ paymentLink.emailOrPhone }}</code
								>" is not a valid email or phone number.
							</div>

							<div class="form-group mb-3">
								<label class="form-label" for="payment-link-name">Payment for</label>
								<input
									type="text"
									class="form-control"
									id="payment-link-name"
									v-model="paymentLink.description"
									required
									placeholder="Item name to show in message"
								/>
							</div>
							<div class="form-group mb-3">
								<label class="form-label" for="payment-link-amount">Amount</label>

								<div class="input-group mb-3">
									<span class="input-group-text">$</span>
									<input
										type="number"
										class="form-control"
										id="payment-link-amount"
										v-model="paymentLink.amount"
										step="0.01"
										inputmode="numeric"
										required
									/>
								</div>
							</div>

							<div class="bg-light rounded-2 p-3 mb-3">
								<p class="mb-2">
									Payment fee:
									<strong v-if="paymentLink.amount">{{ paymentLinkFee | currency }}</strong>
									<small class="text-muted ms-1"
										>({{ paymentFeeText(deptPaymentPlatform, 'card') }})</small
									>
								</p>
								<p class="lead mb-0">
									Total:
									<strong v-if="paymentLink.amount">{{
										(Number(paymentLink.amount) + paymentLinkFee) | currency
									}}</strong>
									<small v-else class="text-muted">-</small>
								</p>
							</div>

							<div v-if="paymentLinkError" class="alert alert-danger">
								{{ paymentLinkError }}
							</div>

							<p class="card-text text-center">
								<button class="btn btn-primary btn-block" :disabled="states.paymentLink === 'loading'">
									<span
										v-if="states.paymentLink === 'loading'"
										class="spinner-border spinner-border-sm"
									></span
									><span v-else class="me-2">💸</span> Send payment request
								</button>
							</p>
						</form>
					</div>
				</div>
			</div>
		</div>

		<div class="modal fade" id="stripe-account-bank" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<div v-if="selectedDepartment" class="modal-content">
					<form @submit.prevent="stripeAddBankAccount">
						<div class="modal-header">
							<h5 class="modal-title">
								Add bank account for deposits
							</h5>
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body">
							<p>
								To enable deposits from HeyGov Pay balance to your bank account, add the account info:
							</p>

							<div class="form-group mb-3">
								<label for="bank-holder" class="form-label">Account holder name</label>
								<input
									type="text"
									class="form-control"
									id="bank-holder"
									required
									v-model="stripeBank.account_holder_name"
									placeholder="Jane Doe"
								/>
							</div>

							<div class="form-group mb-3">
								<label for="bank-routing-number" class="form-label">Routing Number</label>
								<input
									type="text"
									inputmode="numeric"
									class="form-control"
									id="bank-routing-number"
									required
									v-model="stripeBank.routing_number"
									placeholder="110000000"
								/>
							</div>

							<div class="form-group mb-3">
								<label for="bank-account-number" class="form-label">Account Number</label>
								<input
									type="text"
									inputmode="numeric"
									class="form-control"
									id="bank-account-number"
									required
									v-model="stripeBank.account_number"
									placeholder="000999999991"
								/>
							</div>
						</div>
						<div class="modal-footer justify-content-end">
							<button class="btn btn-primary" :disabled="states.bankAccount === 'loading'">
								Add bank account
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>

		<div class="modal fade" id="modal-payment-cash-check" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<div v-if="selectedDepartment" class="modal-content">
					<form @submit.prevent="paymentSaveCashCheck">
						<div class="modal-header">
							<h5 class="modal-title">
								Record a cash/check payment
							</h5>
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body">
							<div class="form-group mb-3">
								<label for="payment-cash-check-name" class="form-label">Payer</label>
								<input
									type="text"
									class="form-control"
									id="payment-cash-check-name"
									required
									v-model="paymentCashCheck.emailOrPhone"
									placeholder="Who is paying?"
								/>
							</div>

							<div class="form-group mb-3">
								<label for="payment-cash-check-text" class="form-label">Payment description</label>
								<input
									type="text"
									class="form-control"
									id="payment-cash-check-text"
									required
									v-model="paymentCashCheck.description"
									placeholder="What is the payment for?"
								/>
							</div>

							<div class="form-group mb-3">
								<label for="payment-cash-check-amount" class="form-label">Amount</label>
								<input
									type="number"
									class="form-control"
									id="payment-cash-check-amount"
									required
									step="0.01"
									min="0.5"
									max="999999"
									v-model="paymentCashCheck.amount"
									placeholder="100.00"
								/>
							</div>

							<div class="form-group mb-3">
								<label for="payment-cash-check-type-cash" class="form-label">Payment type</label>

								<div>
									<div class="form-check form-check-inline">
										<input
											class="form-check-input"
											type="radio"
											id="payment-cash-check-type-cash"
											v-model="paymentCashCheck.payment_method"
											value="cash"
										/>
										<label class="form-check-label" for="payment-cash-check-type-cash">Cash</label>
									</div>
									<div class="form-check form-check-inline">
										<input
											class="form-check-input"
											type="radio"
											id="payment-cash-check-type-check"
											v-model="paymentCashCheck.payment_method"
											value="check"
										/>
										<label class="form-check-label" for="payment-cash-check-type-check"
											>Check</label
										>
									</div>
								</div>
							</div>

							<div class="form-group mb-3">
								<label for="payment-cash-check-note" class="form-label"
									>{{ paymentCashCheck.payment_method }} details</label
								>
								<input
									type="text"
									class="form-control"
									id="payment-cash-check-note"
									v-model="paymentCashCheck.payment_method_note"
									:placeholder="
										paymentCashCheck.payment_method === 'check'
											? 'Check number and other details'
											: 'How many notes, serial numbers, etc'
									"
								/>
							</div>

							<!-- <div class="form-group mb-3">
								<div class="form-check form-switch">
									<input
										class="form-check-input"
										type="checkbox"
										role="switch"
										id="payment-cash-check-receipt"
										v-model="paymentCashCheck.send_receipt"
									/>
									<label class="form-check-label" for="payment-cash-check-receipt"
										>Send receipt</label
									>
								</div>
							</div> -->
						</div>
						<div class="modal-footer justify-content-end">
							<button class="btn btn-primary" :disabled="states.paymentCashCheck === 'loading'">
								Add payment
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>

		<div class="modal fade" id="payout-details" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog modal-lg">
				<div v-if="payoutSelected" class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">
							Payout details
						</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						<p>
							This payout of <strong>{{ (payoutSelected.amount / 100) | currency }}</strong> was initiated
							on {{ payoutSelected.created | dateLocal }} and is expected to arrive on
							{{ payoutSelected.arrival_date | dateLocal }} in the bank account.
						</p>

						<div class="card-table">
							<table class="table table-hover">
								<thead>
									<tr>
										<th></th>
										<th>Amount</th>
										<th>Date</th>
										<th>Payer</th>
										<th>Description</th>
									</tr>
								</thead>
								<tbody>
									<tr
										v-for="transaction in payoutSelected.transactions"
										:key="`payout-trx-${transaction.id}`"
									>
										<td>
											<span
												v-if="['charge', 'payment'].includes(transaction.type)"
												class="badge bg-success-50 text-success"
												>Payment</span
											>
											<span
												v-else-if="transaction.type === 'refund'"
												class="badge bg-primary-50 text-info"
												>Refund</span
											>
											<span v-else class="badge bg-danger-50 text-danger text-capitalize">{{
												transaction.type
											}}</span>
										</td>
										<td>
											{{ (transaction.net / 100) | currency }}
										</td>
										<td>{{ transaction.created | dateLocal }}</td>
										<td>
											<person-link
												v-if="
													['charge', 'payment', 'refund'].includes(transaction.type) &&
														transaction.source.metadata.person_id
												"
												:id="transaction.source.metadata.person_id"
											></person-link>
										</td>
										<td>
											<entity-link
												v-if="
													['charge', 'payment'].includes(transaction.type) &&
														transaction.source.metadata.entity &&
														transaction.source.metadata.entity.includes('_')
												"
												:entity="transaction.source.metadata.entity"
											></entity-link>
											<small v-else>{{ transaction.description }}</small>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<div class="modal-footer justify-content-between">
						<button class="btn btn-sm text-dark" data-bs-dismiss="modal">
							Close
						</button>
						<div>
							<a
								v-if="account && isStaff"
								:href="`https://dashboard.stripe.com/${account.id}/payouts/${payoutSelected.id}`"
								target="_blank"
								class="btn btn-sm btn-outline-danger me-2"
							>
								View in Stripe
							</a>
							<a
								:href="
									`${apiUrl + j.slug}/departments/${this.selectedDepartment.id}/payouts/${
										payoutSelected.id
									}/export-csv?person_id=${auth.id}`
								"
								class="btn btn-sm btn-outline-dark"
							>
								<font-awesome-icon :icon="['fas', 'file-export']" class="me-1" /> Export CSV
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="modal fade" id="modal-payout-schedule" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<div v-if="payoutsSchedule" class="modal-content">
					<form @submit.prevent="savePayoutsSchedule">
						<div class="modal-header">
							<h5 class="modal-title my-0">
								Payout schedule
							</h5>
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body">
							<p>
								Set the schedule to receive payouts from HeyGov balance to your bank account.
							</p>

							<div class="row">
								<div class="col-lg-7 form-row mb-3">
									<label class="form-label">Automatic payouts</label>

									<select class="form-select" v-model="payoutsSchedule.interval">
										<option value="manual">Off, switch to manual</option>
										<option value="daily">Daily</option>
										<option value="weekly">Weekly</option>
										<option value="monthly">Monthly</option>
									</select>
								</div>
								<div v-if="payoutsSchedule.interval === 'weekly'" class="col-lg-5 form-row mb-3">
									<label class="form-label">On</label>

									<select class="form-control" v-model="payoutsSchedule.weekly_anchor">
										<option value="monday">Monday</option>
										<option value="tuesday">Tuesday</option>
										<option value="wednesday">Wednesday</option>
										<option value="thursday">Thursday</option>
										<option value="friday">Friday</option>
									</select>
								</div>
								<div v-else-if="payoutsSchedule.interval === 'monthly'" class="col-lg-5 form-row mb-3">
									<label class="form-label">On</label>

									<input
										type="number"
										class="form-control"
										required
										min="1"
										max="31"
										v-model="payoutsSchedule.monthly_anchor"
									/>
								</div>
							</div>

							<p class="card-text">
								When automatic payouts are turned off, money will accumulate in the HeyGov balance. You
								can either request a payout at any time, or turn on automatic payouts again.
							</p>
						</div>
						<div class="modal-footer">
							<button class="btn btn-dark px-5">Save schedule</button>
						</div>
					</form>
				</div>
			</div>
		</div>

		<div class="modal fade" id="modal-payout-balance" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<div v-if="balance" class="modal-content">
					<form @submit.prevent="createPayout">
						<div class="modal-header">
							<h5 class="modal-title my-0">
								Pay out funds to your bank account
							</h5>
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div v-if="states.createPayout === 'created'" class="modal-body">
							<div class="alert alert-success mb-3">
								✅ It is done. Your payout is on the way to your bank account.
							</div>

							<p class="mb-2">
								Amount: <strong>{{ numberToCurrency(payout.amount / 100) }}</strong>
							</p>
							<p class="mb-2">
								Expected arrival:
								<strong>{{ payout.arrival_date | dateLocal }}</strong>
							</p>
							<p class="mb-2">
								Note: <strong>{{ payout.description }}</strong>
							</p>
							<p class="mb-2">
								Statement descriptor: <strong>{{ payout.statement_descriptor }}</strong>
							</p>
						</div>
						<div v-else class="modal-body">
							<p class="mb-2">
								There are
								<strong>{{ (balance.available[0].amount / 100) | currency }}</strong> available funds to
								pay out, and <strong>{{ (balance.pending[0].amount / 100) | currency }}</strong> pending
								(payments from last 2 days).
							</p>

							<p>
								Please note: Manual payouts do not include a payment breakdown in the Deposits tab, as
								you manually choose the $ amount sent to your bank.
							</p>

							<div v-if="Number(balance.available[0].amount) < 100" class="alert alert-warning text-dark">
								ℹ️ No pay out available. The minimum amount to create a payout is $1.00
							</div>

							<template v-else>
								<div class="form-row mb-3">
									<label class="form-label"
										>Amount to pay out <small class="text-danger-400">*</small></label
									>

									<input
										type="number"
										class="form-control"
										step="0.01"
										min="1"
										:max="balance.available[0].amount / 100"
										required
										v-model="payoutNew.amount"
									/>
								</div>

								<div class="form-row mb-3">
									<label class="form-label">Description</label>

									<input
										type="text"
										class="form-control"
										placeholder="A note about this payout"
										v-model="payoutNew.description"
									/>
								</div>

								<div class="form-row mb-3">
									<label class="form-label">Statement descriptor</label>

									<input
										type="text"
										class="form-control"
										required
										placeholder="This will appear on your bank statement"
										v-model="payoutNew.statement_descriptor"
										maxlength="22"
									/>

									<span class="form-text">This will appear on your bank statement</span>
								</div>
							</template>
						</div>
						<div v-if="states.createPayout !== 'created'" class="modal-footer">
							<button
								class="btn btn-dark px-5"
								:disabled="
									states.createPayout === 'loading' || Number(balance.available[0].amount) < 100
								"
							>
								Pay out {{ numberToCurrency(payoutNew.amount) }}
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
@import '@/assets/variables';

.nav-payment-accounts {
	.nav-link {
		padding-bottom: 0.3rem;
		color: #212529; // dark

		&.active {
			font-weight: bold;
			color: $primary-400;
		}
	}
}
</style>

<script>
import { mapState, mapGetters } from 'vuex'
import Vue from 'vue'
import { Modal } from 'bootstrap'
import pluralize from 'pluralize'

import heyGovApi from '@/api.js'
import { validEmailOrPhone, handleResponseError, sendEvent, numberToCurrency } from '@/utils.js'
import { stripeFee, paymentFee, paymentFeeText } from '@/actions/payments.js'
import { formatOrdinals } from '@/lib/strings.js'

import PersonLink from '@/components/PersonLink.vue'
import PeopleSearch from '@/components/PeopleSearch.vue'
import EntityLink from '@/components/EntityLink.vue'
import PaymentMethod from '@/components/payments/PaymentMethod.vue'
import PaymentStatus from '@/components/payments/PaymentStatus.vue'

export default {
	name: 'HeyGovPay',
	metaInfo: {
		title: 'HeyGov Pay',
	},
	components: { EntityLink, PersonLink, PeopleSearch, PaymentMethod, PaymentStatus },
	data() {
		return {
			states: {
				departments: 'loading',
				payouts: 'idle',
				payments: 'idle',
				paymentCardReader: 'idle',
				paymentLink: 'idle',
				paymentLinks: 'loading',
				bankAccount: 'idle',
				payoutsHasMore: false,
				paymentCashCheck: 'idle',
				payoutsSchedule: 'idle',
				createPayout: 'idle',
				details: false,
			},
			accountReqController: null,
			account: null,
			stripeConnectUrl: null,
			balance: null,
			payouts: [],
			payments: [],
			paymentLinks: [],

			// Stripe terminal
			terminal: null,
			terminalError: '',
			terminalReaderConnected: false,
			terminalReaderState: 'discovering',
			terminalReaders: [],
			terminalLocations: [],
			newTerminalLocation: {
				line1: '',
				city: '',
				state: '',
				postal_code: '',
				country: 'US',
			},
			newTerminalReader: {
				registration_code: '',
				label: '',
			},
			paymentCardReaderError: '',
			paymentCardReader: {
				person_id: null,
				emailOrPhone: '',
				category: '',
				description: '',
				amount: null,
			},

			paymentLinkError: '',
			paymentIntentLink: null,
			paymentLink: {
				person_id: null,
				description: '',
				amount: null,
				emailOrPhone: '',
			},

			payoutSelected: null,
			payoutStatuses: {
				in_transit: {
					name: 'In transit',
					class: 'bg-info-lighter text-info',
				},
				pending: {
					name: 'Pending',
					class: 'bg-warning-lighter text-warning',
				},
				paid: {
					name: 'Paid',
					class: 'bg-success-lighter text-success',
				},
				canceled: {
					name: 'Canceled',
					class: 'bg-danger-lighter text-danger',
				},
				failed: {
					name: 'Failed',
					class: 'bg-danger-lighter text-danger',
				},
			},
			tab: 'payments',

			// Payouts schedule settings
			payoutsSchedule: {
				interval: 'daily',
				weekly_anchor: 'monday',
				monthly_anchor: 1,
			},
			payoutNew: {
				amount: 0,
				description: '',
				statement_descriptor: 'HEYGOV PAYOUT',
			},
			payout: null,

			// Stripe bank account
			$modalStripeBank: null,
			stripeBank: {
				country: 'US',
				currency: 'usd',
				account_holder_name: '',
				account_holder_type: 'company',
				routing_number: '',
				account_number: '',
			},

			$modalPaymentCashCheck: null,
			paymentCashCheck: {
				person_id: null,
				emailOrPhone: '',
				description: '',
				amount: null,
				payment_method: 'cash',
				payment_method_note: '',
				send_receipt: false,
			},

			billingAccountsCount: null,
			billingAccounts: [],
		}
	},
	computed: {
		...mapState(['j', 'apiUrl', 'departments', 'forms', 'venues', 'people']),
		...mapGetters(['currentRole', 'isStaff', 'auth']),
		paymentDepartments() {
			let depts = (this.departments || []).filter(
				d =>
					d.payments_platform ||
					d.stripe_account ||
					d.justifi_account ||
					d.paygov_account ||
					d.municipay_account
			)

			if (this.j.role === 'ADMIN' || this.isStaff) {
				// HeyGov admins have access to all depts
			} else {
				depts = depts.filter(dept => {
					let hasAccess = false

					// TODO read from dept settings
					dept.payments_visibility = 'dept-admins'

					if (dept.payments_visibility === 'public') {
						hasAccess = true
					} else if (dept.payments_visibility === 'dept-admins') {
						const deptRole = this.j.roles.find(role => role.department_id === dept.id)
						hasAccess = deptRole?.role === 'EDITOR'
					} else if (dept.payments_visibility === 'dept-members') {
						const deptRole = this.j.roles.find(role => role.department_id === dept.id)
						hasAccess = ['EDITOR', 'USER'].includes(deptRole?.role)
					}

					return hasAccess
				})
			}

			return depts
		},
		selectedDepartment() {
			return (this.departments || []).find(d => d.id == this.$route.params.departmentId)
		},

		balanceInTransit() {
			return this.payouts.filter(p => p.status == 'in_transit').reduce((sum, p) => sum + p.amount, 0)
		},

		deptPaymentPlatform() {
			if (this.selectedDepartment?.payments_platform) {
				return this.selectedDepartment.payments_platform
			} else if (this.selectedDepartment?.stripe_account) {
				return 'stripe'
			} else if (this.selectedDepartment?.justifi_account) {
				return 'justifi'
			} else if (this.selectedDepartment?.paygov_account) {
				return 'paygov'
			} else if (this.selectedDepartment?.municipay_account) {
				return 'municipay'
			} else {
				return null
			}
		},
		chargesEnabled() {
			return (
				Boolean(this.account?.charges_enabled) ||
				Boolean(this.account?.processingReady) ||
				this.selectedDepartment.municipay_account ||
				this.selectedDepartment.paygov_account ||
				this.selectedDepartment.bridgepay_account
			)
		},

		// Stripe terminal
		paymentCardReaderFee() {
			return stripeFee(this.paymentCardReader.amount, 'in-person')
		},
		paymentLinkFee() {
			let rate

			if (this.deptPaymentPlatform === 'paygov') {
				rate = this.selectedDepartment.paygov_account.split(':')[2]
			}

			return paymentFee(this.deptPaymentPlatform, 'card', this.paymentLink.amount, rate)
		},

		paymentCardReaderDescriptions() {
			const categories = []

			if (this.selectedDepartment && [4, 13].includes(this.selectedDepartment.id)) {
				categories.push({
					label: 'City',
					texts: [
						'Building Permit',
						'Cigarette License',
						'Liquor Operator’s License',
						'Liquor Retail License',
					],
				})
				categories.push({
					label: 'Fire Department',
					texts: ['Fundraiser', 'General donation', 'Other'],
				})
				categories.push({
					label: 'Parks & Rec',
					texts: [
						'Aquatic Center Membership',
						'Fair Park Rental',
						'Shelter Rental - LCP Upper',
						'Shelter Rental - LCP Lower',
						'Shelter Rental - LCP Band Shell',
						'Shelter Rental - Lions Shelter',
						'Shelter Rental - Al Young Park',
						'Shelter Rental - Milt Lunda Memorial Arena',
						'Shelter Rental - JC Shelter',
						'Sig Peterson Park (Girl Scout Cabin)',
					],
				})
				categories.push({
					label: 'Police',
					texts: [
						'CSO donation',
						'Fines/Violations',
						'General donation',
						'K9 donation',
						'Parking Ticket',
						'Other/Customer',
					],
				})
				categories.push({
					label: 'Street Department',
					texts: ['Equipment & Operations', 'Gutter Permit', 'Street Permit'],
				})
			}

			return categories
		},
		paymentCardReaderDescriptionsSelected() {
			return this.paymentCardReaderDescriptions.find(c => c.label == this.paymentCardReader.category)
		},
		paymentCardReaderDescriptionWithCategory() {
			let description = this.paymentCardReader.description

			if (this.paymentCardReaderDescriptionsSelected) {
				description = `${this.paymentCardReaderDescriptionsSelected.label} - ${description}`
			}

			return description
		},
		showBillingAccountsPromo() {
			const deptName = this.selectedDepartment?.name.toLowerCase()

			const billingEnabled = this.selectedDepartment?.billing_accounts
			const shouldHaveBa =
				deptName.includes('administration') || deptName.includes('water') || deptName.includes('utility')
			const hiddenPromo = localStorage.getItem(`hg-billing-accounts-promo-${this.selectedDepartment.id}`)

			return !billingEnabled && shouldHaveBa && !hiddenPromo
		},
	},
	created() {
		if (this.currentRole === 'CITIZEN') {
			this.$router.push(`/${this.j.slug}/quick-pay`)
		} else {
			this.$store.dispatch('getForms')
			this.$store.dispatch('getVenues')

			this.$store.dispatch('loadDepartments', this.$route.query.reloadDepartments == 1).then(() => {
				let urlDepartment = this.$route.params.departmentId

				if (urlDepartment && this.paymentDepartments.map(d => d.id).includes(Number(urlDepartment))) {
					this.changeDepartment(urlDepartment)
				} else if (this.paymentDepartments.length) {
					this.$router.replace({
						path: `/${this.j.slug}/payments/${this.paymentDepartments[0].id}`,
						query: this.$route.query,
					})
					this.changeDepartment(this.paymentDepartments[0].id)
				}

				this.states.departments = 'loaded'
			})
		}
	},
	mounted() {
		if (!('StripeTerminal' in window)) {
			let stripeScript = document.createElement('script')
			stripeScript.setAttribute('src', 'https://js.stripe.com/terminal/v1/')
			document.head.appendChild(stripeScript)
		}

		this.$modalStripeBank = new Modal(document.getElementById('stripe-account-bank'))
		this.$modalPaymentCashCheck = new Modal(document.getElementById('modal-payment-cash-check'))
	},
	methods: {
		validEmailOrPhone,
		pluralize,
		formatOrdinals,
		paymentFeeText,
		paymentFee,
		numberToCurrency,

		contactHG(event) {
			sendEvent(event, {
				feature: 'payments',
				department_id: this.selectedDepartment.id,
				implemented: 'no',
			})

			alert('Contact Atanas @ HeyGov to change this setting')
		},

		changeDepartment(id) {
			this.payments = []
			this.billingAccounts = []
			this.billingAccountsCount = null
			this.paymentLinks = []
			this.payouts = []
			this.tab = 'payments'
			this.terminalReaderState = 'discovering'

			if (this.accountReqController) {
				this.accountReqController.abort()
			}

			this.accountReqController = new AbortController()

			this.loadAccountInfo(id)
			this.loadPayouts()
			this.loadPayments(id)

			if (this.selectedDepartment.billing_accounts) {
				this.loadBillingAccounts(id)
			}

			sendEvent('View payments department', {
				department_id: id,
				department: this.selectedDepartment.name,
				feature: 'payments',
			})
		},
		loadAccountInfo(id) {
			this.stripeConnectUrl = null
			this.states.details = false
			this.account = null
			this.balance = null
			this.terminalLocations = []

			if (this.selectedDepartment.paygov_account) {
				//
			} else if (this.selectedDepartment.justifi_account) {
				heyGovApi
					.get(`/${this.j.slug}/departments/${id}/justifi-account`, {
						signal: this.accountReqController.signal,
					})
					.then(
						({ data }) => {
							this.account = data.account
							this.balance = data.balance
						},
						error => {
							if (error.message !== 'canceled') {
								Vue.toasted.error(
									error.response?.data?.message || `Error loading account (${error.message})`
								)
							}
						}
					)
			} else if (this.selectedDepartment.stripe_account) {
				heyGovApi
					.get(`/${this.j.slug}/departments/${id}/stripe-account`, {
						signal: this.accountReqController.signal,
					})
					.then(
						({ data }) => {
							this.account = data.account
							this.balance = data.balance

							this.payoutNew.amount = this.balance.available[0].amount / 100

							if (data.account.requirements.currently_due.length) {
								this.loadStripeOnboardingUrl()
							} else if (data.account.future_requirements.currently_due.length) {
								this.loadStripeOnboardingUrl(true)
							}
						},
						error => {
							if (error.message !== 'canceled') {
								Vue.toasted.error(
									error.response?.data?.message || `Error loading account info ${error.message}`
								)
							}
						}
					)
			}
		},
		loadStripeOnboardingUrl(includeFutureRequirements = false) {
			this.stripeConnectUrl = null
			const body = {}

			if (includeFutureRequirements) {
				body.future_requirements = true
			}

			heyGovApi.post(`/${this.j.slug}/departments/${this.selectedDepartment.id}/stripe-connect-url`, body).then(
				({ data }) => {
					this.stripeConnectUrl = data.stripe_connect_url
				},
				error => {
					Vue.toasted.error(`Error loading Stripe Onboarding url (${error.message})`)
				}
			)
		},

		stripeTerminalStart() {
			sendEvent('Start card reader', {
				department_id: this.selectedDepartment.id,
				feature: 'payments',
			})

			this.states.paymentCardReader = 'idle'
			this.paymentCardReaderError = ''
			this.paymentCardReader = {
				person_id: null,
				category: '',
				description: '',
				amount: null,
				email: '',
			}

			if (this.paymentCardReaderDescriptions.length) {
				this.paymentCardReader.category = this.paymentCardReaderDescriptions[0].label
			}

			if (this.terminalReaderState !== 'connected') {
				this.terminal = window.StripeTerminal.create({
					onFetchConnectionToken: this.stripeTerminalConnectionToken,
					onUnexpectedReaderDisconnect() {
						// In this function, your app should notify the user that the reader disconnected.
						// You can also include a way to attempt to reconnect to a reader.
						this.terminalError = 'Disconnected from reader'
					},
					onConnectionStatusChange(status) {
						console.log('terminal status changed', status)
					},
				})

				this.stripeTerminalDiscoverReaders()
			}
		},
		async stripeTerminalConnectionToken() {
			const re = await heyGovApi(
				`${this.j.slug}/departments/${this.selectedDepartment.id}/stripe-terminal-connection-token`
			)

			return re.data.secret
		},
		stripeTerminalDiscoverReaders() {
			var config = {
				simulated: window.location.hostname === 'localhost',
			}

			this.terminalError = ''
			this.terminalReaders = []
			this.terminalReaderState = 'discovering'

			this.terminal.discoverReaders(config).then(discoverResult => {
				if (discoverResult.error) {
					this.terminalError = discoverResult.error
				} else if (discoverResult.discoveredReaders.length === 0) {
					this.terminalReaderState = 'no-readers'
				} else {
					this.terminalReaders = discoverResult.discoveredReaders
					this.stripeTerminalConnectReader(this.terminalReaders[0])
				}
			})
		},
		stripeTerminalConnectReader(selectedReader) {
			this.terminalReaderState = 'connecting'

			this.terminal.connectReader(selectedReader).then(connectResult => {
				if (connectResult.error) {
					this.terminalReaderState = 'error'
					this.terminalError = `Error connecting to "${selectedReader.label}". ${connectResult.error.message}`
				} else {
					this.terminalReaderState = 'connected'
					this.terminalReaderConnected = selectedReader.id
					console.log('terminal.connectReader', connectResult)
				}
			})
		},
		loadTerminalLocations() {
			heyGovApi(`/${this.j.slug}/departments/${this.selectedDepartment.id}/stripe-terminal-locations`).then(
				({ data }) => {
					this.terminalLocations = data.data
				},
				handleResponseError('Error loading terminal locations ({error})')
			)
		},
		addTerminalLocation() {
			heyGovApi
				.post(
					`/${this.j.slug}/departments/${this.selectedDepartment.id}/stripe-terminal-locations`,
					this.newTerminalLocation
				)
				.then(({ data }) => {
					this.terminalLocations.push(data)
					Vue.toasted.success('Card reader location is saved')
				}, handleResponseError('Error adding terminal location ({error})'))
		},
		addTerminal() {
			this.newTerminalReader.location = this.terminalLocations[0].id

			heyGovApi
				.post(
					`/${this.j.slug}/departments/${this.selectedDepartment.id}/stripe-terminal-readers`,
					this.newTerminalReader
				)
				.then(
					() => {
						this.stripeTerminalDiscoverReaders()
						Vue.toasted.success('Card reader successfully added')
					},
					error => {
						Vue.toasted.error(error.response?.data?.error || error.message)
					}
				)
		},
		setPaymentCardReaderPerson(person) {
			this.paymentCardReader.person_id = person ? person.id : null

			if (person) {
				this.$store.dispatch('loadPerson', person.id)
			}
		},
		setPaymentCardReaderPersonQuery(personQuery) {
			this.paymentCardReader.emailOrPhone = personQuery
		},
		setPaymentLinkPerson(person) {
			this.paymentLink.person_id = person ? person.id : null
			if (person) {
				this.$store.dispatch('loadPerson', person.id)
			}
		},
		setPaymentLinkPersonQuery(personQuery) {
			this.paymentLink.emailOrPhone = personQuery
		},
		startPaymentInTerminal() {
			//this.terminal.setSimulatorConfiguration({ testCardNumber: '4242424242424242' })
			this.paymentCardReaderError = ''

			if (!this.paymentCardReader.amount || this.paymentCardReader.amount < 0.5) {
				this.paymentCardReaderError = 'The minimum amount to create a charge is $0.50'
				return
			}

			this.states.paymentCardReader = 'creating'

			heyGovApi
				.post(`${this.j.slug}/payments?by=staff`, {
					department_id: this.selectedDepartment.id,
					...this.paymentCardReader,
					description: this.paymentCardReaderDescriptionWithCategory,
					amount: this.paymentCardReader.amount,
					fee: this.paymentCardReaderFee,
					source: 'card-reader',
					payment_method_types: 'card_present',
					capture_method: 'manual',
				})
				.then(
					({ data: payment }) => {
						this.states.paymentCardReader = 'collect-payment-method'

						this.terminal.collectPaymentMethod(payment.paymentIntent.client_secret).then(result => {
							if (result.error) {
								console.log('terminal.collectPaymentMethod error', result.error)
								this.paymentCardReaderError = result.error
							} else {
								this.states.paymentCardReader = 'process-payment'
								console.log('terminal.collectPaymentMethod', result.paymentIntent)

								this.terminal.processPayment(result.paymentIntent).then(result => {
									if (result.error) {
										console.log(result.error)
										let errorCode = ''

										/* if (result.error.decline_code) {
											errorCode += ` (${result.error.decline_code})`
										}

										if (result.error.doc_url) {
											errorCode += ` <a href="${result.error.doc_url}" target="_blank">See detailed error info</a>`
										} */

										this.paymentCardReaderError = result.error.message + errorCode
									} else if (result.paymentIntent) {
										console.log('terminal.processPayment', result.paymentIntent)
										const cardCountry =
											result.paymentIntent.charges.data[0].payment_method_details.card_present
												.country

										if (cardCountry === 'US') {
											this.terminalCapturePayment(result.paymentIntent.id)
										} else {
											this.states.paymentCardReader = 'error'
											this.paymentCardReaderError =
												'The card used in Card Reader is not issued in the US, and charges a 1% extra fee. Please restart the payment with option for international card enabled'
										}
									}
								})
							}
						})
					},
					error => {
						this.paymentCardReaderError = error.message
					}
				)
		},
		cancelPaymentInTerminal() {
			this.terminal.cancelCollectPaymentMethod().then(() => {
				this.paymentCardReaderError = 'Payment was canceled'
			})
		},
		terminalCapturePayment(payment_intent_id) {
			this.states.paymentCardReader = 'capture-payment'

			heyGovApi.post(`/${this.j.slug}/payments/${payment_intent_id}/capture`).then(
				() => {
					this.states.paymentCardReader = 'paid'
					this.loadPayments()
				},
				error => {
					this.paymentCardReaderError = error.message
				}
			)
		},

		startPaymentLink() {
			sendEvent('Start payment request', {
				department_id: this.selectedDepartment.id,
				feature: 'payments',
			})
			this.states.paymentLink = 'idle'
			this.paymentIntentLink = null
			this.paymentLinkError = ''
			this.paymentLink.person_id = null
			this.paymentLink.amount = null
			this.paymentLink.emailOrPhone = ''
		},
		sendPaymentLink() {
			this.paymentLinkError = ''
			this.states.paymentLink = 'loading'

			heyGovApi
				.post(`${this.j.slug}/payments`, {
					department_id: this.selectedDepartment.id,
					...this.paymentLink,
					amount: this.paymentLink.amount,
					fee: this.paymentLinkFee,
					source: 'link',
					sendLink: true,
				})
				.then(
					({ data }) => {
						this.states.paymentLink = 'sent'
						this.paymentIntentLink = data
					},
					error => {
						const msg = error.response?.data?.message || error.message

						this.states.paymentLink = 'error'
						this.paymentLinkError = msg
					}
				)
		},
		paymentLinkCancel(payment) {
			if (confirm('For sure cancel this payment?')) {
				payment.status = 'canceled'

				heyGovApi.post(`${this.j.slug}/payments/${payment.uuid}/cancel`)
			}
		},

		loadPayouts(params = {}) {
			this.tab = 'payouts'
			this.states.payouts = 'loading'
			this.states.payoutsHasMore = false

			heyGovApi
				.get(`/${this.j.slug}/departments/${this.selectedDepartment.id}/payouts`, {
					params,
					signal: this.accountReqController.signal,
				})
				.then(
					({ data, headers }) => {
						this.states.payoutsHasMore = headers['x-has-more'] === 'true'
						this.payouts.push(...data)

						this.states.payouts = 'idle'
					},
					error => {
						if (error.message !== 'canceled') {
							this.states.payouts = 'error'
							Vue.toasted.error(`Error loading payouts list (${error.message})`)
						}
					}
				)
		},
		loadPayments() {
			this.tab = 'payments'
			this.states.payments = 'loading'
			this.payments = []

			const params = {
				department_id: this.selectedDepartment.id,
				limit: 10,
			}

			heyGovApi
				.get(`/${this.j.slug}/payments`, {
					params,
					signal: this.accountReqController.signal,
				})
				.then(
					({ data }) => {
						this.payments.push(...data)

						this.states.payments = 'idle'
					},
					error => {
						if (error.message !== 'canceled') {
							this.states.payments = 'error'
							Vue.toasted.error(`Error loading payments (${error.message})`)
						}
					}
				)
		},
		loadPaymentLinks() {
			this.tab = 'links'
			this.states.paymentLinks = 'loading'
			this.paymentLinks = []

			heyGovApi
				.get(`/${this.j.slug}/payments`, {
					params: {
						department_id: this.selectedDepartment.id,
						source: 'link',
						status: 'any',
					},
				})
				.then(
					({ data }) => {
						this.paymentLinks.push(...data)
						this.states.paymentLinks = 'idle'
					},
					error => {
						this.states.paymentLinks = 'error'
						Vue.toasted.error(`Error loading payment links (${error.message})`)
					}
				)
		},

		loadBillingAccounts(department_id) {
			const params = {
				department_id,
				limit: 10,
			}

			heyGovApi(`${this.j.slug}/billing-accounts`, {
				params,
			}).then(({ data, headers }) => {
				this.billingAccounts.push(...data)
				this.billingAccountsCount = Number(headers['x-total'])
			}, handleResponseError(`Couldn't load billing accounts ({error})`))
		},

		stripeAddBankAccount() {
			this.states.bankAccount = 'loading'

			heyGovApi
				.post(`/${this.j.slug}/departments/${this.selectedDepartment.id}/stripe-bank-account`, this.stripeBank)
				.then(
					() => {
						this.stripeBank.account_holder_name = ''
						this.stripeBank.account_number = ''
						this.stripeBank.routing_number = ''

						Vue.toasted.info(`Bank account added and will go through Stripe verification`)
						this.loadAccountInfo(this.selectedDepartment.id)
						this.$modalStripeBank.hide()
						this.states.bankAccount = 'idle'
					},
					error => {
						const msg = `There was an error adding the bank account (${error.message})`
						Vue.toasted.error(error.response?.data?.message || msg)
						this.states.bankAccount = 'error'
					}
				)
		},

		paymentSaveCashCheck() {
			this.states.paymentCashCheck = 'loading'

			const payment_method = {
				type: this.paymentCashCheck.payment_method,
			}
			payment_method[this.paymentCashCheck.payment_method] = {
				note: this.paymentCashCheck.payment_method_note,
			}

			heyGovApi
				.post(`${this.j.slug}/payments?by=staff`, {
					platform: 'heygov',
					department_id: this.selectedDepartment.id,
					amount: this.paymentCashCheck.amount,
					source: 'cash-check',
					person_id: this.paymentCashCheck.person_id,
					description: this.paymentCashCheck.description,
					emailOrPhone: this.paymentCashCheck.emailOrPhone,
					payment_method,
				})
				.then(() => {
					Vue.toasted.success('💰 Payment added')
					this.$modalPaymentCashCheck.hide()
					this.loadPayments()
				}, handleResponseError(`Error saving payment ({error})`))
				.finally(() => {
					this.states.paymentCashCheck = 'idle'
				})
		},

		startPayoutsScheduleEdit() {
			this.payoutsSchedule = {
				...this.payoutsSchedule,
				...this.account.settings.payouts.schedule,
			}
		},
		savePayoutsSchedule() {
			this.states.payoutsSchedule = 'loading'

			const account = {
				settings: {
					payouts: {
						schedule: {
							interval: this.payoutsSchedule.interval,
						},
					},
				},
			}

			if (this.payoutsSchedule.interval === 'weekly') {
				account.settings.payouts.schedule.weekly_anchor = this.payoutsSchedule.weekly_anchor
			} else if (this.payoutsSchedule.interval === 'monthly') {
				account.settings.payouts.schedule.monthly_anchor = this.payoutsSchedule.monthly_anchor
			}

			heyGovApi.put(`/${this.j.slug}/departments/${this.selectedDepartment.id}/stripe-account`, account).then(
				() => {
					this.account.settings.payouts.schedule = this.payoutsSchedule
					this.states.payoutsSchedule = 'idle'
					Vue.toasted.success('✅ Payouts settings are saved')
				},
				error => {
					this.states.payoutsSchedule = 'error'
					Vue.toasted.error(`Error saving payout schedule (${error.message})`)
				}
			)
		},
		createPayout() {
			this.states.createPayout = 'loading'

			heyGovApi.post(`/${this.j.slug}/departments/${this.selectedDepartment.id}/payouts`, this.payoutNew).then(
				({ data }) => {
					this.states.createPayout = 'created'
					this.payoutNew.amount = 0
					this.payoutNew.description = ''

					this.payout = data

					//this.loadPayouts()
					this.loadAccountInfo(this.selectedDepartment.id)
					Vue.toasted.success('✅ Payout created')
				},
				error => {
					this.states.createPayout = 'error'
					Vue.toasted.error(error.message)
				}
			)
		},

		hideBillingAccountsPromo($event) {
			localStorage.setItem(`hg-billing-accounts-promo-${this.selectedDepartment.id}`, '1')
			$event.target.parentNode.remove()
		},
	},
	watch: {},
}
</script>
