import store from './store'
import { Storage } from '@capacitor/storage'
import OpenReplay from '@openreplay/tracker'
import Vue from 'vue'
import { isValidPhoneNumber } from 'libphonenumber-js'
import mixpanel from 'mixpanel-browser'
import * as Sentry from '@sentry/vue'

// OpenReplay tracker
const tracker = new OpenReplay({
	projectKey: 'kxqkY5c4QY90giBXA5Aw',
	obscureTextEmails: false,
	obscureInputEmails: false,
	defaultInputMode: 0,
	capturePerformance: false,
})

// MixPanel tracker
mixpanel.init('8d585365f67abbfa4bd4067a3dd14c1d')

export function identify(person) {
	tracker.setUserID(person.email)

	mixpanel.identify(person.id)
	mixpanel.people.set({
		$name: person.name,
		$email: person.email,
	})

	Sentry.setUser({
		id: person.id,
		email: person.email,
	})
}

const sendEvent = (event, data) => {
	if (store.state.j !== 'development') {
		if (store.state.j) {
			data.jurisdiction = store.state.j.slug
		}

		tracker.event(event, data)
		mixpanel.track(event, data)
		//posthog.capture(event, data)
	}
}

const heyGovFeatures = {
	issues: {
		name: '311 requests',
		description:
			'Citizens can send questions, issues and other requests, and clerks can see and responsd from a central dashboard.',
		logo: 'https://files.heygov.com/assets/icon-hey311.png',
	},
	forms: {
		name: 'HeyLicense',
		description: 'Citizens can apply for licenses and permits, and staff can approve or reject them.',
		logo: 'https://files.heygov.com/assets/icon-heylicense.png',
		actionText: 'Add permit or license',
		actionLink: 'forms/create',
	},
	venues: {
		name: 'HeyReserve',
		description: 'Make local venues available for reservation.',
		logo: 'https://files.heygov.com/assets/icon-heyreserve.png',
		actionText: 'Add venue',
		actionLink: 'venues?action=add',
	},
	payments: {
		name: 'HeyGov Pay',
		description: 'Accept online or in person payments for bills, form applications, donations and more.',
		logo: 'https://files.heygov.com/assets/icon-heygovpay.png',
	},
	chatbot: {
		name: 'AI Chatbot',
		description: 'Automatically answer common questions with the all-knowingly government super AI chatbot.',
		logo: 'https://files.heygov.com/assets/heygov-logo.png',
	},
	meetings: {
		name: 'ClerkMinutes',
		description: 'Agenda management, auto meeting minutes, and more.',
		logo: 'https://edge.heygov.com/app-assets/feature-clerkminutes.png',
	},
	clerkminutes: {
		name: 'ClerkMinutes',
		description: 'Agenda management, auto meeting minutes, and more.',
		logo: 'https://edge.heygov.com/app-assets/feature-clerkminutes.png',
	},
}

const copyToClipboard = async (str, success) => {
	try {
		await navigator.clipboard.writeText(str)
	} catch ($e) {
		const el = document.createElement('textarea')
		el.value = str
		document.body.appendChild(el)
		el.select()
		document.execCommand('copy')
		document.body.removeChild(el)
	}

	Vue.toasted.show(success || `📋 Copied`, {
		position: 'top-center',
	})
}

const shadeColor = (color, percent) => {
	if (!color) {
		return color
	}

	var R = parseInt(color.substring(1, 3), 16)
	var G = parseInt(color.substring(3, 5), 16)
	var B = parseInt(color.substring(5, 7), 16)

	R = parseInt((R * (100 + percent)) / 100)
	G = parseInt((G * (100 + percent)) / 100)
	B = parseInt((B * (100 + percent)) / 100)

	R = R < 255 ? R : 255
	G = G < 255 ? G : 255
	B = B < 255 ? B : 255

	var RR = R.toString(16).length == 1 ? '0' + R.toString(16) : R.toString(16)
	var GG = G.toString(16).length == 1 ? '0' + G.toString(16) : G.toString(16)
	var BB = B.toString(16).length == 1 ? '0' + B.toString(16) : B.toString(16)

	return '#' + RR + GG + BB
}

const jLink = path => {
	return `/${store.state.j?.slug || '_'}/${path}`
}

const setting = async (key, value) => {
	if (value === undefined) {
		const s = await Storage.get({ key })
		return s.value === null ? s.value : JSON.parse(s.value)
	} else {
		return Storage.set({ key, value: JSON.stringify(value) })
	}
}

const urlIsImage = url => {
	url = String(url).toLowerCase()

	return (
		url.endsWith('.jpg') ||
		url.endsWith('.jpeg') ||
		url.endsWith('.png') ||
		url.endsWith('.gif') ||
		url.endsWith('.heic') ||
		url.endsWith('.svg')
	)
}

const urlIsDocument = url => {
	url = String(url).toLowerCase()

	return (
		url.endsWith('.pdf') ||
		url.endsWith('.doc') ||
		url.endsWith('.docx') ||
		url.endsWith('.xls') ||
		url.endsWith('.xlsx') ||
		url.endsWith('.csv')
	)
}

const urlIsVideo = url => {
	url = String(url).toLowerCase()

	return url.endsWith('.mp4') || url.endsWith('.mov') || url.endsWith('.m4v')
}

const appUrl = (url = '') => {
	let host = 'app.heygov.com'
	const allowedHosts = ['app.heygov.com', 'beta.heygov.com']

	if (allowedHosts.includes(location.host) || location.host.includes('heygov-app.pages.dev')) {
		host = location.host
	}

	return `https://${host}/${url}`
}

const isEmail = email => /\S+@\S+\.\S+/.test(email)

const validEmailOrPhone = value => {
	return isEmail(value) || isValidPhoneNumber(value) || isValidPhoneNumber(`+1${value}`)
}

// truncate a string to a max length
// if length is longer than limit, add ellipsis symbol at the end
const truncateString = (str, length = 25) => {
	if (str && str.length > length) {
		str = str.slice(0, length) + '…'
	}

	return str
}

// truncate a text to a set words length
// if words number is longer than limit, add ellipsis symbol at the end
const truncateWords = (text, wordsLimit = 15, separator = '…') => {
	const words = text.split(' ')

	if (words.length > wordsLimit) {
		text = words.slice(0, wordsLimit).join(' ') + separator
	}

	return text
}

const firstParagraphOrWords = (text, wordsLimit) => {
	const paragraphs = text.split('.')

	return truncateWords(`${paragraphs[0]}.`, wordsLimit)
}

// transform urls into clickable links
// replace \n with
const enrichContent = text => {
	// make links clickable
	text = text.replace(/(http[s]?:\/\/[^\s]+)/g, '<a href="$1" target="_blank">$1</a>')

	// convert \n to <br>
	text = text.replace(/\n/g, '<br>')

	return text
}

const generateId = (prefix, length) => {
	if (!prefix) {
		throw new Error('prefix is required')
	}

	const unique = (Math.random() + 1).toString(36).substring(2)

	return `${prefix}_${unique.slice(0, length || 20)}`
}

/**
 * Validate the format for a HeyGov ID
 * @param {string} id - the HeyGov ID to validate
 * @param {string} [prefix] - optional prefix to check for
 * @returns boolean
 */
export function isHeyGovIdValid(id, prefix) {
	if (typeof id !== 'string' || id.length !== 29 || id.toLowerCase() !== id) {
		return false
	}

	return /[a-z]{2}_[a-z0-9]{26}/.test(id) && (!prefix || id.startsWith(prefix))
}

const numberToCurrency = (number, currency = 'usd', locale = 'en-US') => {
	return Number(number).toLocaleString(locale, {
		style: 'currency',
		currency,
	})
}

const handleResponseError = (messageTemplate = 'Error encountered {error}') => {
	return error => {
		console.error(error)

		// generic error message
		let msg = messageTemplate.replace('{error}', error.message)

		// specific error message for this endpoint
		if (error.response?.data?.message) {
			msg = error.response.data.message

			if (error.response.data.fields) {
				msg += ': '
				msg += error.response.data.fields.map(field => `${field.param} - ${field.msg}`).join(', ')
			}
		}

		Vue.toasted.error(msg)
	}
}

/**
 * Format address to human readable format
 * @param {object} addressParts
 * @returns {string}
 * @example
 * formatAddress({
 *  line1: '123 Main St',
 *  line2: 'Apt 1',
 *  city: 'San Francisco',
 *  state: 'CA',
 *  postal_code: '94105',
 *  country: 'US',
 * })
 * // => '123 Main St, Apt 1, San Francisco, CA 94105, US'
 */
const formatAddress = (addressParts, options = {}) => {
	if (!addressParts) {
		return ''
	}

	options = {
		country: false,
		...options,
	}

	let formattedAddress = ''
	let { name, line1, line2, city, locality, state, postal_code, country } = addressParts

	//todo remove "locality" after migration
	if (!city && locality) {
		city = locality
	}

	if (!country || country.toLowerCase() === 'us') {
		const stateAndZip = [state, postal_code].filter(Boolean).join(' ')
		formattedAddress = [name, line1, line2, city, stateAndZip]
	} else {
		formattedAddress = [name, line1, line2, city, state, postal_code]
	}

	if (options.country || country !== 'US') {
		formattedAddress.push(country)
	}

	return formattedAddress.filter(Boolean).join(', ')
}

const getPublicFileUrl = path => {
	return `https://${process.env.VUE_APP_STORAGE_BUCKET_PUBLIC}/${path}`
}

const mimeTypes = {
	documents: [
		'text/rtf',
		'text/plain',
		'application/pdf',
		'application/msword',
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	],
}

/**
 * Generate the absolute URL for a website + path
 * Handles {website} with or without trailing slash `/`
 *
 * @param {string} website - the website URL
 * @param {string} [path] - the path to append to the website URL
 */
export function websiteUrl(website, path = '/') {
	return new URL(path, website).toString()
}

export {
	heyGovFeatures,
	appUrl,
	copyToClipboard,
	shadeColor,
	jLink,
	setting,
	isEmail,
	urlIsImage,
	urlIsDocument,
	urlIsVideo,
	sendEvent,
	tracker,
	validEmailOrPhone,
	truncateString,
	truncateWords,
	firstParagraphOrWords,
	enrichContent,
	generateId,
	numberToCurrency,
	handleResponseError,
	formatAddress,
	getPublicFileUrl,
	mimeTypes,
}
