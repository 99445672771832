<template>
	<div class="element-edit">
		<!-- Live Preview -->
		<div class="pb-3" v-if="livePreviewFlag">
			<div class="d-flex justify-content-between align-items-center">
				<div class="fs-5">
					<font-awesome-icon :icon="['fas', 'video']" class="text-danger" />
					<div style="width: 10px; height: 10px" class="spinner-grow text-danger mb-1" role="status">
						<span class="sr-only">Loading...</span>
					</div>
					Live element preview
				</div>
			</div>

			<div class="row me-0 ms-0">
				<div :class="'body col-md-' + current.col">
					<label class="fs-6 text-black text-uppercase mb-1" v-if="current.type !== 'NameInputElement'">
						{{ current.label }}
						<span v-if="current.required" class="file-required text-danger">*</span>
						<div title="Redacted" v-if="current.redacted" class="file-redacted">*</div>
					</label>

					<component :is="current.type" :currentField="current" class="element"></component>

					<small>
						{{ current.help }}
					</small>
				</div>
			</div>
		</div>
		<!-- #Live Preview -->

		<!-- Settings -->
		<form id="settings">
			<div class="body">
				<div class="row">
					<div class="col-md-12">
						<!-- Label -->
						<div class="form-group mb-2" v-if="displayLabel()">
							<label for="label" class="ps-2"> Label </label>
							<input class="form-control" id="label" v-model="current.label" />
						</div>

						<!-- NameInputProperties -->
						<NameInputElementProperties v-if="current.type === 'NameInputElement'" :current="current" />

						<template v-if="current.type === 'TextElement'">
							<div class="row align-items-center mb-2">
								<div class="col">
									<label for="text-editor" class="form-label mb-0">Text editor</label>
								</div>
								<div class="col-auto">
									<button
										class="btn btn-outline-dark btn-sm"
										type="button"
										data-bs-toggle="offcanvas"
										data-bs-target="#variablesOffcanvas"
									>
										<font-awesome-icon :icon="['fas', 'code']" class="me-1" /> Variables
									</button>
								</div>
							</div>

							<vue-editor
								id="text-editor"
								class="mb-3"
								v-model="current.value"
								useCustomImageHandler
								@image-added="handleImageAdded"
								:customModules="customModulesForEditor"
								:editorOptions="editorSettings"
								:editor-toolbar="customToolbar"
							></vue-editor>
						</template>

						<!-- Placeholder -->
						<div class="form-group mb-3" v-if="displayPlaceholder()">
							<label for="field-placeholder" class="ms-2">Placeholder</label>
							<input
								id="field-placeholder"
								class="form-control"
								v-model="current.placeholder"
								maxlength="250"
								placeholder="Placeholder text when input is empty"
							/>
						</div>

						<!-- PaymentElement properties -->
						<PaymentElementProperties
							v-if="current.type === 'PaymentElement'"
							:current="current"
							:activeDepartments="activeDepartments"
						/>

						<!-- YearElement settings -->
						<div class="row mb-2" v-if="current.type === 'YearElement'">
							<!-- Min -->
							<div class="form-group col-md-6 mb-2">
								<label for="min" class="ms-2">Min year</label>
								<div class="form-check form-check-inline ms-1 float-end">
									<input
										type="checkbox"
										v-model="current.data.useDynamicMinYear"
										class="form-check-input"
										id="useDynamicMinYear"
										role="button"
									/>
									<label class="form-check-label" for="useDynamicMinYear" role="button">
										dynamic min year
									</label>
								</div>
								<input
									v-if="!current.data.useDynamicMinYear"
									type="number"
									class="form-control"
									v-model="current.data.minYear"
									min="1900"
									max="2999"
									id="minYear"
								/>

								<select
									v-if="current.data.useDynamicMinYear"
									v-model="current.data.minYearDynamic"
									name=""
									class="form-select"
									id=""
								>
									<template v-for="(dateElement, index) in getDateElements()">
										<option :key="index" :value="'date-year-' + dateElement.id">
											{{ dateElement.label }} value
										</option>
									</template>
									<option value="current">Current year</option>
								</select>
							</div>
							<!-- Max-->
							<div class="form-group col-md-6 mb-2">
								<label for="max" class="ms-2">Max year</label>
								<div class="form-check form-check-inline ms-1 float-end">
									<input
										type="checkbox"
										v-model="current.data.useDynamicMaxYear"
										class="form-check-input"
										id="useDynamicMaxYear"
										role="button"
									/>
									<label class="form-check-label" for="useDynamicMaxYear" role="button">
										dynamic max year
									</label>
								</div>
								<input
									v-if="!current.data.useDynamicMaxYear"
									type="number"
									class="form-control"
									v-model="current.data.maxYear"
									min="1900"
									max="2999"
									id="maxYear"
								/>

								<select
									v-if="current.data.useDynamicMaxYear"
									v-model="current.data.maxYearDynamic"
									name=""
									class="form-select"
									id=""
								>
									<template v-for="(dateElement, index) in getDateElements()">
										<option :key="index" :value="'date-year-' + dateElement.id">
											{{ dateElement.label }}
										</option>
									</template>
									<option value="current">Current year</option>
								</select>
							</div>
							<div class="form-group">
								<div class="form-check form-check-inline ms-1">
									<input
										type="checkbox"
										v-model="current.data.minToMax"
										class="form-check-input"
										id="minToMax"
										role="button"
									/>
									<label class="form-check-label" for="minToMax" role="button">
										display years from Min year to Max year in dropdown
									</label>
								</div>
							</div>
						</div>
						<!-- #YearElement settings -->

						<!-- DateElement settings -->
						<div class="row mb-2" v-if="current.type === 'DatePickerElement'">
							<!-- Min -->
							<div class="form-group col-md-6">
								<label for="max" class="ms-2"> Min date </label>
								<div class="form-check form-check-inline ms-1 float-end">
									<input
										type="checkbox"
										v-model="current.data.useDynamicMinDate"
										class="form-check-input"
										id="useDynamicMinDate"
										role="button"
									/>
									<label class="form-check-label" for="useDynamicMinDate" role="button">
										dynamic min date
									</label>
								</div>
								<input
									v-if="!current.data.useDynamicMinDate"
									type="date"
									class="form-control"
									v-model="current.data.minDate"
								/>

								<select
									v-if="current.data.useDynamicMinDate"
									v-model="current.data.minDateDynamic"
									name=""
									class="form-select"
									id=""
								>
									<template v-for="(dateElement, index) in getDateElements()">
										<option :key="index" :value="'date-' + dateElement.id">
											{{ dateElement.label }}
										</option>
									</template>
									<option value="today">Today's date</option>
								</select>
							</div>

							<!-- Max -->
							<div class="form-group col-md-6">
								<label for="max" class="ms-2"> Max date </label>
								<div class="form-check form-check-inline ms-1 float-end">
									<input
										type="checkbox"
										v-model="current.data.useDynamicMaxDate"
										class="form-check-input"
										id="useDynamicMaxDate"
										role="button"
									/>
									<label class="form-check-label" for="useDynamicMaxDate" role="button">
										dynamic max date
									</label>
								</div>
								<input
									v-if="!current.data.useDynamicMaxDate"
									type="date"
									class="form-control"
									v-model="current.data.maxDate"
								/>

								<select
									v-if="current.data.useDynamicMaxDate"
									v-model="current.data.maxDateDynamic"
									name=""
									class="form-select"
									id=""
								>
									<template v-for="(dateElement, index) in getDateElements()">
										<option :key="index" :value="'date-' + dateElement.id">
											{{ dateElement.label }}
										</option>
									</template>
									<option value="today">Today's date</option>
								</select>
							</div>
						</div>

						<!-- NumberInputElement settings -->
						<div class="row mb-3" v-if="current.type === 'NumberInputElement'">
							<div class="form-group col-md-6">
								<label for="min">Min:</label>
								<input type="number" class="form-control" v-model="current.data.min" id="min" />
							</div>

							<div class="form-group col-md-6">
								<label for="max">Max:</label>
								<input type="number" class="form-control" v-model="current.data.max" id="max" />
							</div>
						</div>

						<!-- Help & size -->
						<div class="row mb-3">
							<!-- Help -->
							<div class="form-group col-md-8" v-if="current.type !== 'TextElement'">
								<label for="field-help" class="ms-2"> Help text </label>
								<input
									id="field-help"
									class="form-control"
									v-model="current.help"
									placeholder="Help text that appears under the input"
									maxlength="250"
								/>
							</div>

							<!-- Size -->
							<div class="form-group col-md-4">
								<label for="form-field-size" class="form-label">Element size</label>

								<select class="form-select" id="form-field-size" v-model="current.col">
									<option value="2">1/6 of screen (col-2)</option>
									<option value="3">1/4 of screen (col-3)</option>
									<option value="4">1/3 of screen (col-4)</option>
									<option value="6">1/2 of screen (col-6)</option>
									<option value="8">2/3 of screen (col-8)</option>
									<option value="9">3/4 of screen (col-9)</option>
									<option value="10">5/6 of screen (col-10)</option>
									<option value="12">Full screen (col-12)</option>
								</select>
							</div>
						</div>
						<!-- #Help & size -->

						<!-- Required -->
						<div class="form-check form-check-inline ms-1" v-if="displayRequired()">
							<input
								type="checkbox"
								v-model="current.required"
								class="form-check-input"
								id="required"
								role="button"
							/>
							<label class="form-check-label" for="required" role="button"> required</label>
						</div>

						<!-- Redacted -->
						<div class="form-check form-check-inline ms-1" v-if="displayRedacted()">
							<input
								type="checkbox"
								v-model="current.redacted"
								class="form-check-input"
								id="redacted"
								role="button"
							/>
							<label class="form-check-label" for="redacted" role="button"> redacted</label>
						</div>

						<!-- Pre-filled with Account Info -->
						<div v-if="displayPrefilled()" class="form-check form-check-inline ms-1">
							<input
								type="checkbox"
								v-model="current.data.prefillWithAccount"
								class="form-check-input"
								id="prefill"
								role="button"
							/>
							<label
								v-if="current.type === 'DatePickerElement'"
								class="form-check-label"
								for="prefill"
								role="button"
							>
								Pre-fill with account DOB</label
							>
							<label v-else class="form-check-label" for="prefill" role="button">
								Pre-fill with account info</label
							>
						</div>

						<!-- Display name label -->
						<div v-if="current.type === 'NameInputElement'" class="form-check form-check-inline ms-1">
							<input
								type="checkbox"
								v-model="current.data.displayLabel"
								class="form-check-input"
								id="nameLabel"
								role="button"
							/>
							<label class="form-check-label" for="nameLabel" role="button"> Show label</label>
						</div>

						<!-- Element Options Accordion -->
						<div
							class="options shadow-sm rounded mt-3"
							v-if="
								current.type === 'RadioButtonElement' ||
									current.type === 'SelectListElement' ||
									current.type === 'CheckboxElement'
							"
						>
							<div
								class="p-3 rounded d-flex justify-content-between align-items-center bg-light"
								data-bs-toggle="collapse"
								data-bs-target="#options"
								aria-expanded="false"
								aria-controls="options"
								role="button"
								v-show="
									current.type === 'RadioButtonElement' ||
										current.type === 'SelectListElement' ||
										current.type === 'CheckboxElement'
								"
							>
								<h5 class="mb-0">Options</h5>
								<font-awesome-icon :icon="['fas', 'chevron-down']" />
							</div>

							<div class="collapse show" id="options">
								<!-- Option settings -->
								<div class="p-3" v-if="!removingOption">
									<div class="row">
										<div
											class="col-md-5 d-flex align-items-center ms-2"
											@dblclick="showOptionValues = !showOptionValues"
										>
											Label
										</div>
										<div
											class="col-md-5 d-flex align-items-center ms-2"
											v-if="showOptionValues"
											@dblclick="displayAllOptions = !displayAllOptions"
										>
											Value
										</div>
									</div>
									<!-- Option list -->
									<template v-for="(option, optionIndex) in current.options">
										<div :key="`div-option-${optionIndex}`" class="mt-2" v-if="!option.removed">
											<div class="row align-items-center">
												<div class="col-md-4">
													<input
														type="text"
														name="optionName"
														v-model="option.optionLabel"
														v-on="createOptionValue(option)"
														class="form-control form-control-sm"
													/>
												</div>
												<div
													class="col-3"
													v-if="current.type === 'CheckboxElement' || 'RadioButtonElement'"
												>
													<div class="form-check">
														<input
															type="checkbox"
															v-model="option.addAdditionalValue"
															class="form-check-input"
															:id="'additionalValue-' + option.optionValue"
														/>
														<label
															class="form-check-label"
															:for="'additionalValue-' + option.optionValue"
														>
															Additional value</label
														>
													</div>
												</div>
												<div
													class="col-md-3"
													@dblclick="displayAllOptions = !displayAllOptions"
													v-if="showOptionValues"
												>
													<input
														type="text"
														name="optionValue"
														:value="option.optionValue"
														class="form-control"
														readonly
													/>
												</div>
												<div class="col-md-2 d-flex align-items-center">
													<a
														@click="removeOption(optionIndex, option)"
														class="btn btn-sm btn-icon-danger"
													>
														<font-awesome-icon :icon="['fas', 'times']" />
													</a>
												</div>
											</div>
										</div>
									</template>
									<div class="mt-3 bg-danger-lighter p-3 rounded shadow-sm" v-if="displayAllOptions">
										<h6>
											<font-awesome-icon :icon="['fas', 'trash']" class="fa-fw text-danger" />
											Trashed options
										</h6>
										<hr />
										<small v-if="checkIsRemovedOptionsExists(current.options)"
											>⚠️ Trashed options are stored and not deleted permanently because they
											contain data from some form request</small
										>
										<div v-if="!checkIsRemovedOptionsExists(current.options)">
											There is no trashed options!
										</div>
										<template v-for="(option, optionIndex) in current.options">
											<div
												:key="`div-option-${optionIndex}`"
												class="mt-2"
												v-if="option.removed && displayAllOptions"
											>
												<div class="row">
													<div class="col-md-5">
														<input
															type="text"
															disabled="true"
															name="optionName"
															v-model="option.optionLabel"
															v-on="createOptionValue(option)"
															class="form-control"
														/>
													</div>
													<div class="col-md-5" v-if="showOptionValues">
														<input
															type="text"
															disabled="true"
															name="optionValue"
															:value="option.optionValue"
															class="form-control"
															readonly
														/>
													</div>
													<div class="col-md-2">
														<a
															@click="restoreOption(option)"
															class="btn btn-sm btn-icon-info"
															title="Restore option"
														>
															<font-awesome-icon :icon="['fas', 'undo']" />
														</a>
													</div>
												</div>
											</div>
										</template>
									</div>
									<!-- #Option list -->

									<!-- Action buttons -->
									<div class="d-flex align-baseline justify-content-between mt-3">
										<a
											type="button"
											@click="addOptionItem()"
											class="btn btn-sm btn-big-icon btn-primary m-1"
										>
											New option <font-awesome-icon :icon="['fas', 'plus-circle']" />
										</a>
										<div class="form-check form-switch">
											<input
												type="checkbox"
												class="form-check-input"
												v-model="displayAllOptions"
												id="allElementOptions"
												role="button"
											/>
											<label class="form-check-label" role="button" for="allElementOptions">
												Trashed options
											</label>
										</div>
									</div>
								</div>
								<!-- Option remove -->
								<div class="bg-danger-lighter" v-if="removingOption">
									<div class="pt-3">
										<h5 class="text-center">
											Are you sure you want to delete '{{ optionToRemove.optionLabel }}' option?
										</h5>
										<br />
										<p class="text-center" v-if="!optionToRemove.editValue">
											This option has been used in previous requests and may impair their
											functionality.<br />
											Type
											<span class="font-weight-bold text-uppercase">
												{{ optionToRemove.optionValue }}
											</span>
											into next field to remove this option
											<input
												type="text"
												name="removeOptionForce"
												id="removeOptionForce"
												v-model="removeOptionInput"
											/>
										</p>
									</div>
									<div class="d-flex justify-content-end align-content-center px-3 pb-3">
										<a class="btn btn-link" @click="cancelRemoveOption()"> Cancel </a>
										<button
											v-if="optionToRemove.editValue"
											class="btn btn-danger"
											@click="confirmRemoveOption()"
										>
											Remove
										</button>
										<button
											v-if="!optionToRemove.editValue"
											class="btn btn-danger"
											:disabled="isOptionRemoveBtnDisabled"
											@click="confirmRemoveOption()"
										>
											Remove
										</button>
									</div>
								</div>
								<!-- #Option remove -->
							</div>
						</div>
						<!-- #Element Options Accordion -->

						<!-- Element Table Columns Accordion -->
						<div class="table-columns shadow-sm rounded mt-3" v-if="current.type === 'TableElement'">
							<div
								class="p-3 rounded d-flex justify-content-between align-items-center bg-light"
								data-bs-toggle="collapse"
								data-bs-target="#table-columns"
								aria-expanded="false"
								aria-controls="table-columns"
								role="button"
							>
								<h5 class="mb-0">Table columns</h5>
								<font-awesome-icon :icon="['fas', 'chevron-down']" />
							</div>
							<div class="body collapse show" id="table-columns">
								<div class="p-3">
									<div class="row mb-5">
										<div class="form-group col-md-6">
											<label
												for="add-button-text"
												class="ms-2"
												@dblclick="showColumnProps = !showColumnProps"
											>
												Add button text
												<font-awesome-icon
													:icon="['fas', 'info-circle']"
													class="fa-fw text-dark"
													title="Name of button for adding new items"
												/>
											</label>
											<input
												type="text"
												name="add-button-text"
												id="add-button-text"
												v-model="current.data.addButtonText"
												class="form-control"
											/>
										</div>
										<div class="form-group col-md-3">
											<label for="add-button-text" class="ms-2">
												Max table lines
											</label>
											<input
												type="number"
												name="max-table-lines"
												id="max-table-lines"
												v-model="current.data.maxLines"
												class="form-control"
											/>
										</div>
										<div class="form-group col-md-3">
											<label for="emptyColumnsPdf" class="ms-2">
												Empty columns PDF
												<font-awesome-icon
													:icon="['fas', 'info-circle']"
													class="fa-fw text-dark"
													title="Used for empty PDF"
												/>
											</label>
											<input
												type="number"
												min="0"
												max="20"
												name="emptyColumnsPdf"
												id="emptyColumnsPdf"
												v-model="current.data.emptyColumnsPdf"
												class="form-control"
											/>
										</div>
									</div>

									<template v-for="(column, columnIndex) in current.data.tableColumns">
										<div
											:key="`div-option-${columnIndex}`"
											class="mt-4 shadow-sm border rounded p-3"
										>
											<div class="row position-relative">
												<div class="position-absolute">
													<a
														@click="removeColumnItem(columnIndex)"
														class="btn btn-sm btn-icon-danger position-absolute"
														style="right: 0; top: -40px"
													>
														<font-awesome-icon :icon="['fas', 'times']" />
													</a>
												</div>
												<div class="col-md-4">
													<div class="form-group">
														<label class="ms-2">Column</label>
														<input
															type="text"
															name="optionValue"
															v-model="column.label"
															v-on="createColumnProp(column)"
															class="form-control"
														/>
													</div>
												</div>
												<div class="col-md-4" v-if="showColumnProps">
													<div class="form-grou">
														<label class="ms-2">Prop</label>
														<input
															type="text"
															name="optionName"
															:value="column.prop"
															class="form-control"
															readonly
														/>
													</div>
												</div>
												<div class="col-md-4">
													<div class="form-group">
														<label class="ms-2">Type</label>
														<select
															class="form-select"
															v-model="column.inputType"
															name="optionValue"
														>
															<option value="text" selected>Text</option>
															<option value="date">Date</option>
															<option value="number">Number</option>
															<option value="email">Email</option>
															<option value="tel">Phone</option>
														</select>
													</div>
												</div>
												<div class="col-md-4">
													<div class="form-group">
														<label class="ms-2">Join with</label>
														<select
															class="form-select"
															v-model="column.joinWith"
															name="optionValue"
														>
															<option value="">Choose...</option>
															<option value="single">Single</option>
															<template v-for="col in getColumnsWithoutSetJoin(column)">
																<option :key="'col-with-' + col.prop" :value="col.prop"
																	>With {{ col.label }}</option
																>
															</template>
														</select>
													</div>
												</div>

												<div class="col-md-2 d-flex align-items-center"></div>
											</div>
											<div class="row" v-if="column.inputType === 'text'">
												<div class="col-md-12">
													<div>
														<small class="ms-2 mt-2">Type of column input value</small>
														<div class="ms-2">
															<div class="form-check form-check-inline">
																<input
																	class="form-check-input"
																	type="radio"
																	:name="'textType' + '-' + column.prop"
																	:id="'defaultText' + '-' + column.prop"
																	value="default"
																	v-model="column.valueType"
																/>
																<label
																	class="form-check-label"
																	:for="'defaultText' + '-' + column.prop"
																	>Default</label
																>
															</div>
															<div class="form-check form-check-inline">
																<input
																	class="form-check-input"
																	type="radio"
																	:name="'textType' + '-' + column.prop"
																	:id="'predefinedSelect' + '-' + column.prop"
																	value="predefinedSelect"
																	v-model="column.valueType"
																/>
																<label
																	class="form-check-label"
																	:for="'predefinedSelect' + '-' + column.prop"
																	>Predefined Select</label
																>
															</div>
														</div>
														<div v-if="column.valueType !== 'default'">
															<div class="form-group mb-0">
																<input
																	type="text"
																	class="form-control"
																	v-model="column.predefinedValues"
																	:name="'predefinedValues' + '-' + column.prop"
																/>
															</div>
															<small>
																<font-awesome-icon
																	:icon="['fas', 'info-circle']"
																	class="fa-fw text-primary"
																/>
																Separate values with semicolon ( ; )
															</small>
														</div>
													</div>
												</div>
											</div>
										</div>
									</template>

									<a
										type="button"
										@click="addColumnItem()"
										class="btn btn-sm btn-big-icon btn-primary m-1 mt-3"
									>
										New column <font-awesome-icon :icon="['fas', 'plus-circle']" />
									</a>
								</div>
							</div>
						</div>
						<!-- #Element Table Columns Accordion -->

						<!-- Advanced Features Accordion -->
						<div class="advanced-features shadow-sm rounded mt-3">
							<div
								class="p-3 rounded d-flex justify-content-between align-items-center bg-light"
								data-bs-toggle="collapse"
								data-bs-target="#advanced-features"
								aria-expanded="false"
								aria-controls="advanced-features"
								role="button"
							>
								<h5 class="mb-0">Advanced features</h5>
								<font-awesome-icon :icon="['fas', 'chevron-down']" />
							</div>
							<div class="body collapse" id="advanced-features">
								<div class="row p-3">
									<!-- Class - additional class to applay on element -->
									<div class="form-group col-md-6 mb-2">
										<label for="class" class="ms-2">Class</label>
										<input
											class="form-control"
											v-model="current.class"
											placeholder="enter class for element (optional)"
										/>
									</div>

									<!-- Where to display element (form, pdf, both) -->
									<div class="col-md-6 mb-2">
										<label>Where to display element</label>
										<div>
											<div class="form-check form-check-inline">
												<input
													class="form-check-input"
													type="radio"
													name="display"
													id="displayForm"
													value="form"
													v-model="current.display"
												/>
												<label class="form-check-label" for="displayForm">Form only</label>
											</div>
											<div class="form-check form-check-inline">
												<input
													class="form-check-input"
													type="radio"
													name="display"
													id="displayPdf"
													value="pdf"
													v-model="current.display"
												/>
												<label class="form-check-label" for="displayPdf">PDF only</label>
											</div>
											<div class="form-check form-check-inline">
												<input
													class="form-check-input"
													type="radio"
													name="display"
													id="displayBoth"
													value="both"
													v-model="current.display"
												/>
												<label class="form-check-label" for="displayBoth">Both</label>
											</div>
										</div>
									</div>

									<!-- Prepend - prepend for element -->
									<div
										class="form-group col-md-6 mb-2"
										v-if="
											current.type === 'TextInputElement' || current.type === 'NumberInputElement'
										"
									>
										<label for="prepend" class="ms-2">Prepend</label>
										<input
											class="form-control"
											v-model="current.data.prepend"
											placeholder="Prepend for element"
										/>
									</div>

									<!-- Append - prepend for element -->
									<div
										class="form-group col-md-6 mb-2"
										v-if="
											current.type === 'TextInputElement' || current.type === 'NumberInputElement'
										"
									>
										<label for="append" class="ms-2">Append</label>
										<input
											class="form-control"
											v-model="current.data.append"
											placeholder="Append for element"
										/>
									</div>

									<!-- Upload Element - set awailable document types -->
									<div class="col-md-12 mb-2" v-if="current.type === 'FileUploadElement'">
										<div class="form-group">
											<label for="accept_type" class="ms-2">Accept Type</label>
											<input class="form-control" v-model="current.data.accept_type" />
											<small>
												<font-awesome-icon
													:icon="['fas', 'info-circle']"
													class="fa-fw text-dark"
												/>
												.jpg, .pdf, or .doc, Audio files audio/*, Video files video/*, Image
												files image/*
											</small>
										</div>
									</div>

									<!-- NumberInputElement advanced features -->
									<div class="col-md-12 mb-2" v-if="current.type === 'NumberInputElement'">
										<hr />
										<!-- Standard Number or Input type numeric -->
										<div class="form-group">
											<div class="form-check">
												<input
													type="checkbox"
													v-model="current.data.standardNumberInput"
													class="form-check-input"
													id="standardNumberInput"
													role="button"
												/>
												<label role="button" class="form-check-label" for="standardNumberInput">
													standard</label
												>
												<small>
													<font-awesome-icon
														:icon="['fas', 'info-circle']"
														class="fa-fw text-primary"
													/>
													(If checked using standard number input if not using inputmode
													numeric )
												</small>
											</div>
										</div>

										<div class="form-group">
											<div class="form-check form-check-inline mt-2">
												<input
													type="checkbox"
													v-model="current.data.useDynamicValue"
													class="form-check-input"
													id="useDynamicValue"
													role="button"
												/>
												<label class="form-check-label" for="useDynamicValue" role="button">
													use dynamic value
												</label>
											</div>
										</div>

										<div class="row">
											<div class="col-6" v-if="current.data.useDynamicValue">
												<label for="">Element</label>
												<select
													v-model="current.data.valueElement"
													name=""
													class="form-select"
													id=""
												>
													<template v-for="(dateElement, index) in getDateElements()">
														<option :key="index" :value="'date-year-' + dateElement.id">
															{{ dateElement.label }}
														</option>
													</template>
													<option value="current">Current year</option>
												</select>
											</div>
											<div class="col-6" v-if="current.data.useDynamicValue">
												<label for="">Value calculation</label>
												<select
													v-model="current.data.valueCalculation"
													name=""
													class="form-select"
													id=""
												>
													<option value="days-from-date-to-now">Days from date</option>
													<option value="years-from-date-to-now">Years from date</option>
												</select>
											</div>
										</div>
									</div>

									<!-- Multiple select or multiple upload-->
									<div
										class="col-md-12 mb-2"
										v-if="
											current.type === 'SelectListElement' || current.type === 'FileUploadElement'
										"
									>
										<div class="form-check ms-2">
											<input
												type="checkbox"
												v-model="current.data.multiple"
												class="form-check-input"
												id="multiple"
												role="button"
											/>
											<label class="form-check-label" for="multiple" role="button">
												multiple</label
											>
										</div>
									</div>

									<!-- Check radio inline -->
									<div
										class="col-md-12 mb-2"
										v-if="
											current.type === 'RadioButtonElement' || current.type === 'CheckboxElement'
										"
									>
										<div class="form-check">
											<input
												type="checkbox"
												v-model="current.data.check_radio_inline"
												class="form-check-input"
												id="check_radio_inline"
											/>
											<label class="form-check-label" for="check_radio_inline">
												display options inline
											</label>
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- #Advanced Features Accordion -->

						<!-- Move element to another step Accordion -->
						<div class="advanced-features shadow-sm rounded mt-3">
							<div
								class="p-3 rounded d-flex justify-content-between align-items-center bg-light"
								data-bs-toggle="collapse"
								data-bs-target="#move-element"
								aria-expanded="false"
								aria-controls="move-element"
								role="button"
							>
								<h5 class="mb-0">Move element</h5>
								<font-awesome-icon :icon="['fas', 'chevron-down']" />
							</div>
							<div class="body collapse" id="move-element">
								<div class="row p-3">
									<!-- Step where to move element -->
									<div class="form-group col-md-12 mb-2">
										<label for="class" class="ms-2">Select step where to move element</label>
										<select class="form-select" v-model="stepToMoveID">
											<option value="">Chose Step</option>
											<template v-for="(step, index) in steps">
												<option
													v-if="current.form_step_id !== step.id"
													:key="index"
													:value="step.id"
													>{{ step.name }}</option
												>
											</template>
										</select>
										<a class="btn btn-primary mt-2 float-end" @click="moveElementToStep()">
											Move
										</a>
									</div>
								</div>
							</div>
						</div>
						<!-- #Move element to another step Accordion -->
						<!-- Remove Element Accordion -->
						<div class="remove shadow-sm rounded mt-3" v-if="!isNewElement">
							<div
								class="p-3 rounded d-flex justify-content-between align-items-center bg-danger-lighter"
								data-bs-toggle="collapse"
								data-bs-target="#remove"
								aria-expanded="false"
								aria-controls="remove"
								@click="removeElementInput = ''"
								role="button"
							>
								<h5 class="text-danger mb-0">Remove element</h5>
								<font-awesome-icon :icon="['fas', 'chevron-down']" class="fa-fw text-danger" />
							</div>
							<div class="body collapse" id="remove">
								<div class="p-3">
									<h5 class="text-center">Are you sure want to remove this element?</h5>
									<br />
									<p class="text-center" v-if="elementUsedInRequests">
										This element has been used in previous requests and may impair their
										functionality.<br />
										Type <code>DELETE</code> into next field to remove this element
										<input
											type="text"
											name="removeOptionForce"
											id="removeOptionForce"
											v-model="removeElementInput"
										/>
									</p>

									<div class="d-flex justify-content-end align-items-center">
										<a
											class="btn btn-link"
											data-bs-toggle="collapse"
											data-bs-target="#remove"
											aria-expanded="false"
											aria-controls="remove"
											@click="removeElementInput = ''"
										>
											Cancel
										</a>
										<button
											type="button"
											class="btn btn-danger"
											:disabled="removeElementInput !== 'DELETE'"
											v-if="elementUsedInRequests"
											@click="removeElement"
										>
											Remove
										</button>
										<a class="btn btn-danger" @click="removeElement" v-else>
											Remove
										</a>
									</div>
								</div>
							</div>
						</div>
						<!-- #Remove Element Accordion -->
					</div>
				</div>
			</div>
		</form>
		<!-- #Settings -->

		<!-- Element conditions switch -->
		<div class="form-check form-switch mb-3 mt-3">
			<input
				type="checkbox"
				role="button"
				class="form-check-input"
				v-model="conditionalLogic"
				id="conditionalLogic"
			/>
			<label role="button" class="form-check-label" for="conditionalLogic">{{
				current.type === 'PaymentElement' ? 'Payment logic' : 'Conditional logic'
			}}</label>
		</div>
		<!-- #Element conditions switch -->

		<!-- Element Coinditions -->
		<div class="shadow-sm rounded" v-if="current.type !== 'PaymentElement' && conditionalLogic">
			<div class="p-3">
				<span><font-awesome-icon :icon="['fas', 'microchip']" class="" /> Conditional logic</span>
			</div>
			<div class="p-3 pt-0">
				<form :model="current">
					<div class="fs-6 mb-3">
						in this section we specify conditions when some element need to appear or when some element is
						required, based on another element. ⚠️ be careful with multiple conditions.
					</div>
					<div class="row">
						<div class="col-md-3">Type</div>
						<div class="col-md-3">Element</div>
						<div class="col-md-2">Condition</div>
						<div class="col-md-2">Value</div>
					</div>

					<template v-for="(condition, conditionIndex) in current.data.conditions">
						<div :key="`div-option-${conditionIndex}`" class="mt-2">
							<div class="row">
								<div class="col-md-3 d-flex align-items-center">
									<select class="form-select" v-model="condition.type">
										<option value="visible-if" selected>Visible If</option>
										<option value="required-if" disabled>Required If (soon)</option>
									</select>
								</div>
								<div class="col-md-3 d-flex align-items-center">
									<select class="form-select" v-model="condition.element">
										<option
											v-for="(el, elIndex) in fieldConditionElements(formElements)"
											:key="elIndex"
											:value="el.id"
										>
											{{ conditionElementString(el) }}
										</option>
									</select>
								</div>

								<div class="col-md-2 d-flex align-items-center">
									<select
										class="form-select"
										@change="condition.conditionValue = ''"
										v-model="condition.condition"
									>
										<option value="equal" selected>=</option>
										<option value="notEqual">!=</option>
										<option value="empty">Empty</option>
										<option value="notEmpty">Not Empty</option>
									</select>
								</div>

								<div class="col-md-2 d-flex align-items-center">
									<input
										v-if="conditionValueField('text', condition)"
										type="text"
										name="optionValue"
										v-model="condition.conditionValue"
										class="form-control"
									/>
									<select
										v-if="conditionValueField('select', condition)"
										class="form-select"
										v-model="condition.conditionValue"
									>
										<option
											v-for="(condV, condI) in conditionElementOptions(condition)"
											:key="condI"
											:value="condV.optionValue"
										>
											{{ concatString(condV.optionLabel, 48) }}
										</option>
									</select>
								</div>

								<div class="col-md-2 d-flex align-items-center">
									<a
										@click="removeElementCondition(conditionIndex)"
										class="btn btn-sm btn-icon-danger"
										title="Remove condition"
									>
										<font-awesome-icon :icon="['fas', 'times']" />
									</a>
								</div>
							</div>
						</div>
					</template>
					<div class="mt-4">
						<a @click="addElementCondition()" class="btn btn-primary btn-sm">
							<font-awesome-icon :icon="['fas', 'plus-circle']" class="fa-fw" />
							New condition
						</a>
					</div>
				</form>
			</div>
		</div>
		<!-- #Element Coinditions -->

		<!-- Element Payment Conditions -->
		<div class="shadow-sm rounded" v-if="current.type === 'PaymentElement' && conditionalLogic">
			<div class="p-3">
				<span><font-awesome-icon :icon="['fas', 'microchip']" class="fa-fw ms-2" /> Payment conditions</span>
			</div>
			<div class="p-3">
				<div class="col-md-12">
					<div class="fs-6">
						in this section we add payment conditions for form, for example if form payment depend on fields
						we specify that here . ⚠️ be careful with multiple conditions.
					</div>
				</div>
				<div class="col-md-12">
					<button @click="addPaymentElementPriceCondition()" class="btn btn-primary btn-sm mt-2">
						<font-awesome-icon :icon="['fas', 'plus-circle']" />
						Payment condition
					</button>
				</div>
				<form class="col-md-12">
					<template v-for="(priceCondition, priceConditionIndex) in this.current.data.priceConditions">
						<div :key="`div-option-${priceConditionIndex}`" class="mt-3">
							<div class="p-3 border border-primary rounded">
								<div class="row">
									<div class="col-md-12 position-relative">
										<a
											@click="removePaymentElementPriceCondition(priceConditionIndex)"
											title="Remove payment condition"
											class="btn btn-sm btn-icon-danger remove-price-condition"
										>
											<font-awesome-icon :icon="['fas', 'times']" />
										</a>
									</div>

									<!-- Price condition - TYPE -->
									<div class="col-md-7">
										<div class="form-group mb-2">
											<label for="priceCondition">Type</label>

											<select class="form-select form-select-sm" v-model="priceCondition.type">
												<option value="price-if" selected>
													Price If - Set price if some condition is true
												</option>
												<option value="add-price-if">
													Add price If - Additional price to be added if condition is true
												</option>
												<option value="increase-price-if">
													Increase price If - Increase price by percentage if condition is
													true
												</option>
												<option value="discount-if" disabled>
													Discount If (soon) - ℹ️ add discount on price if condition true
												</option>
											</select>
										</div>
									</div>

									<!-- Price condition - ELEMENT -->
									<div class="col-md-5">
										<div class="form-group mb-2">
											<label for="priceCondition">Element</label>

											<select class="form-select form-select-sm" v-model="priceCondition.element">
												<option
													v-for="(el, elIndex) in fieldConditionElements(formElements)"
													:key="elIndex"
													:value="el.id"
												>
													{{ conditionElementString(el) }}
												</option>
											</select>
										</div>
									</div>

									<!-- Price condition - ELEMENT TABLE COLUMNS -->
									<div class="col-md-3" v-if="checkIsSelectedElementTable(priceCondition.element)">
										<div class="form-group">
											<label for="priceCondition">Column</label>

											<select class="form-select form-select-sm" v-model="priceCondition.column">
												<option
													v-for="(el, elIndex) in getTableColumns(priceCondition.element)"
													:key="elIndex"
													:value="el.prop"
												>
													{{ concatString(el.label, 48) }}
												</option>
											</select>
										</div>
									</div>

									<!-- Price condition - CONDITION -->
									<div class="col-md-3">
										<div class="form-group">
											<label for="priceCondition">Condition</label>

											<select
												class="form-select form-select-sm"
												v-model="priceCondition.condition"
											>
												<option value="equal" selected>=</option>
												<option value="notEqual">!=</option>
												<option value="empty">Empty</option>
												<option value="notEmpty">Not Empty</option>
												<option
													v-if="checkIsSelectedElementNumber(priceCondition.element)"
													value="equalToValue"
													>Equal to value</option
												>
											</select>
										</div>
									</div>

									<!-- Price condition - CONDITION VALUE -->
									<div class="col-md-3">
										<div class="form-group">
											<label for="priceCondition">Condition Value</label>

											<input
												v-if="conditionValueField('text', priceCondition)"
												type="text"
												name="optionValue"
												v-model="priceCondition.conditionValue"
												class="form-control form-control-sm"
											/>
											<select
												v-if="conditionValueField('select', priceCondition)"
												class="form-select form-select-sm"
												v-model="priceCondition.conditionValue"
											>
												<option
													v-for="(condV, condI) in conditionElementOptions(priceCondition)"
													:key="condI"
													:value="condV.optionValue"
												>
													{{ concatString(condV.optionLabel, 48) }}
												</option>
											</select>
										</div>
									</div>

									<!-- Price condition - Add another for table column -->
									<div
										v-if="checkIsSelectedElementTable(priceCondition.element)"
										class="col-md-12 d-flex align-items-center mb-2 mt-3"
									>
										<a
											class="btn btn-primary btn-sm"
											@click="addLogicCondition(priceCondition)"
											title="Additional column logic"
										>
											<font-awesome-icon :icon="['fas', 'plus-circle']" class="fa-fw" />
											Add aditional table logic
										</a>
									</div>
								</div>

								<!-- Additional logic for Table fields -->

								<div class="row mb-2" v-if="checkIsSelectedElementTable(priceCondition.element)">
									<template
										v-for="(logicCondition, logicConditionIndex) in priceCondition.logicConditions"
									>
										<div class="col-md-12" :key="logicConditionIndex">
											<div class="row">
												<!-- Table COLIMN-->
												<div class="col-md-3">
													<div class="form-group">
														<label>Column</label>
														<select class="form-select" v-model="logicCondition.column">
															<option
																v-for="(el, elIndex) in getTableColumns(
																	priceCondition.element
																)"
																:key="elIndex"
																:value="el.prop"
															>
																{{ concatString(el.label, 48) }}
															</option>
														</select>
													</div>
												</div>
												<!-- Table CONDITION -->
												<div class="col-md-3">
													<div class="form-group">
														<label>Condition</label>
														<select class="form-select" v-model="logicCondition.condition">
															<option value="equal" selected>=</option>
															<option value="notEqual">!=</option>
															<option value="empty">Empty</option>
															<option value="notEmpty">Not Empty</option>
														</select>
													</div>
												</div>
												<!-- Table CONDITION VALUE -->
												<div class="col-md-2">
													<div class="form-group">
														<label>Value</label>
														<input
															v-if="conditionValueField('text', priceCondition)"
															type="text"
															name="optionValue"
															v-model="logicCondition.conditionValue"
															class="form-control"
														/>

														<select
															v-if="conditionValueField('select', priceCondition)"
															class="form-select"
															v-model="logicCondition.conditionValue"
														>
															<option
																v-for="(condV, condI) in conditionElementOptions(
																	priceCondition
																)"
																:key="condI"
																:value="condV.optionValue"
															>
																{{ concatString(condV.optionLabel, 48) }}
															</option>
														</select>
													</div>
												</div>
												<!-- Table LOGIC -->
												<div class="col-md-2">
													<div class="form-group">
														<label>Logic</label>
														<select class="form-select" v-model="logicCondition.operation">
															<option value="" selected>chose...</option>
															<option
																value="&&"
																:disabled="isOperationAvailable(priceCondition, '&&')"
															>
																AND
															</option>
															<option
																value="||"
																:disabled="isOperationAvailable(priceCondition, '||')"
															>
																OR
															</option>
														</select>
													</div>
												</div>
												<div class="col-md-2 d-flex align-items-end">
													<a
														class="btn btn-sm btn-icon-danger"
														@click="
															removeLogicCondition(priceCondition, logicConditionIndex)
														"
													>
														<font-awesome-icon :icon="['fas', 'times']" />
													</a>
												</div>
											</div>
										</div>
									</template>
								</div>
							</div>

							<div v-if="priceCondition.condition !== 'equalToValue'" class="row">
								<!-- Price condition - CONDITION PRICE -->
								<div class="col-md-3">
									<div class="form-group price-condition-price-wrapper">
										<div v-if="priceCondition.type === 'increase-price-if'" class="input-group">
											<input
												class="form-control"
												type="number"
												min="1"
												max="1000"
												name="optionValue"
												v-model="priceCondition.price"
												required
											/>

											<span class="input-group-text" id="currency-addon">%</span>
										</div>
										<div v-else class="input-group">
											<span
												v-if="current.data.currency === 'usd'"
												class="input-group-text"
												id="currency-addon"
												>$</span
											>

											<input
												class="form-control"
												type="number"
												step="0.01"
												name="optionValue"
												v-model="priceCondition.price"
												required
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</template>
				</form>
			</div>
		</div>
		<!-- #Element Payment Conditions -->
		<!-- Ofcanvas with variables -->
		<div
			class="offcanvas offcanvas-lg offcanvas-end"
			tabindex="-1"
			id="variablesOffcanvas"
			aria-labelledby="variablesOffcanvasLabel"
		>
			<div class="offcanvas-header bg-neutral-50">
				<h5 class="offcanvas-title" id="variablesOffcanvasLabel">Available variables</h5>

				<button
					type="button"
					class="btn-close text-reset"
					data-bs-dismiss="offcanvas"
					aria-label="Close"
				></button>
			</div>
			<div v-if="current.type === 'TextElement'" class="offcanvas-body">
				<p class="mb-2">
					Copy the variable with parentheses and paste in the text where you want the value to appear.
				</p>

				<div class="card-table">
					<div class="">
						<table class="table table-hover">
							<thead>
								<tr>
									<th class="w-50">Element</th>
									<th class="w-50">Variabe</th>
								</tr>
							</thead>
							<tbody>
								<template v-for="element in formElements">
									<template v-if="displayVariableElement(element)">
										<tr :key="'el-variable-' + element.id">
											<td>
												{{ element.label }}
											</td>
											<td>
												<span
													role="button"
													title="Display element value"
													@click="copyVariableToClipboard(printVariable(element))"
													class="badge bg-success-lighter text-success me-1"
													>{{ printVariable(element) }}</span
												>

												<span
													v-if="element.type === 'DatePickerElement'"
													title="Calculate years from this date"
													@click="copyVariableToClipboard(printDateVariable(element))"
													role="button"
													class="badge bg-success-lighter text-success me-1 mt-2"
													>{{ printDateVariable(element) }}</span
												>
											</td>
										</tr>
									</template>
								</template>
								<tr>
									<td>Current year</td>
									<td>
										<span
											title="Display current year"
											@click="copyVariableToClipboard('{{ year }}')"
											role="button"
											class="badge bg-success-lighter text-success me-1"
											>{{ '{{' }} year }}</span
										>
									</td>
								</tr>
								<tr>
									<td>Next year</td>
									<td>
										<span
											title="Display next year"
											@click="copyVariableToClipboard('{{ nextyear }}')"
											role="button"
											class="badge bg-success-lighter text-success me-1"
											>{{ '{{' }} nextyear }}</span
										>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.remove-price-condition {
	position: absolute;
	right: 5px;
	top: -40px;
}
.price-condition-price-wrapper {
	margin-top: -13px;
	padding-left: 10px;
}
</style>

<script>
import Vue from 'vue'
import heyGovApi from '@/api.js'
import TextInputElement from './TextInputElement'
import TextareaInputElement from './TextareaInputElement'
import NumberInputElement from './NumberInputElement'
import SelectListElement from './SelectListElement'
import RadioButtonElement from './RadioButtonElement'
import CheckboxElement from './CheckboxElement'
import DatePickerElement from './DatePickerElement'
import FileUploadElement from './FileUploadElement'
import EmailInputElement from './EmailInputElement'
import PhoneInputElement from './PhoneInputElement'
import TableElement from './TableElement'
import YearElement from './YearElement'
import SignatureElement from './SignatureElement'
import PaymentElement from './PaymentElement'
import AddressInputElement from './AddressInputElement'
import NameInputElement from './NameInputElement'
import NameInputElementProperties from '@/components/form-builder/NameInputElementProperties'
import PaymentElementProperties from '@/components/form-builder/PaymentElementProperties'
import TextElement from './TextElement'
import TimeElement from './TimeElement'
import { mapState, mapGetters } from 'vuex'
import { VueEditor, Quill } from 'vue2-editor'

import QuillResize from 'quill-resize-module'

Quill.register('modules/resize', QuillResize)

export default {
	name: 'ElementProperties',
	components: {
		TextInputElement,
		TextareaInputElement,
		NumberInputElement,
		SelectListElement,
		RadioButtonElement,
		CheckboxElement,
		DatePickerElement,
		FileUploadElement,
		EmailInputElement,
		PhoneInputElement,
		TableElement,
		YearElement,
		SignatureElement,
		PaymentElement,
		AddressInputElement,
		NameInputElement,
		TimeElement,
		TextElement,
		VueEditor,
		NameInputElementProperties,
		PaymentElementProperties,
	},
	data() {
		return {
			showOptionValues: false,
			showColumnProps: false,
			conditionalLogic: false,
			formRequests: [],
			removingOption: false,
			optionToRemove: null,
			removeOptionInput: '',
			isOptionRemoveBtnDisabled: true,
			displayAllOptions: false,
			removeElementInput: '',
			customToolbar: [
				[{ header: [false, 1, 2, 3, 4, 5, 6] }],
				['bold', 'italic', 'underline', 'strike'], // toggled buttons
				[{ align: '' }, { align: 'center' }, { align: 'right' }],
				[{ list: 'ordered' }, { list: 'bullet' }],
				[{ color: [] }, { background: [] }], // dropdown with defaults from theme
				['link', 'image'],
			],
			customModulesForEditor: [{ alias: 'QuillResize', module: QuillResize }],
			editorSettings: {
				modules: {
					QuillResize: { modules: ['Resize', 'DisplaySize'] },
				},
			},
			stepToMoveID: '',
		}
	},
	props: ['current', 'formElements', 'livePreviewFlag', 'form', 'stepElements', 'isNewElement', 'steps'],
	computed: {
		...mapState(['j', 'departments']),
		...mapGetters(['currentRole', 'activeDepartments']),
		// Check is element for removing used in form requests
		elementUsedInRequests() {
			let elementExist = false
			this.formRequests.forEach(request => {
				Object.keys(request.answers).map(key => {
					if (this.current.id === parseInt(key)) {
						elementExist = true
					}
				})
			})
			return elementExist
		},
	},
	methods: {
		// This function take string and wordSeparator as parameter, clean string and return in format str_test
		regenerateString: function(string, wordSeparator) {
			var regeneratedString = ''
			var stringLower = string.toLowerCase()
			regeneratedString = stringLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e')
			regeneratedString = regeneratedString.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a')
			regeneratedString = regeneratedString.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o')
			regeneratedString = regeneratedString.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u')
			regeneratedString = regeneratedString.replace(/đ/gi, 'd')
			regeneratedString = regeneratedString.replace(/\s*$/g, '')
			regeneratedString = regeneratedString.replace(/[^a-zA-Z0-9 ]/g, '')
			regeneratedString = regeneratedString.replace(/\s+/g, wordSeparator)
			regeneratedString = regeneratedString.slice(0, 32)
			return regeneratedString
		},

		// Add new option item for Select List, Checkbox, Radio button
		addOptionItem() {
			this.current.options.push({
				optionLabel: '',
				optionValue: 'v',
				selected: false,
				editValue: true,
				removed: false,
				addAdditionalValue: false,
				additionalValue: '',
			})
		},

		// Remove option item for Select List, Checkbox, Radio button
		removeOption: function(index, option) {
			this.removingOption = true
			this.optionToRemove = { ...option, index }
		},

		// Cancel removing option
		cancelRemoveOption() {
			this.removingOption = false
			this.optionToRemove = null
			this.removeOptionInput = ''
		},

		// Confirm removing option
		confirmRemoveOption() {
			if (this.optionToRemove.editValue) {
				this.current.options.splice(this.optionToRemove.index, 1)
				this.optionToRemove = null
				this.removingOption = false
			} else {
				this.current.options[this.optionToRemove.index].removed = true
				this.removingOption = false
				this.optionToRemove = null
			}
		},

		// Restore option
		restoreOption(option) {
			option.removed = false
		},

		// Check confirmation delete word
		checkRemoveWord() {
			if (this.removeOptionInput === this.optionToRemove?.optionValue?.toUpperCase()) {
				this.isOptionRemoveBtnDisabled = false
			} else {
				this.isOptionRemoveBtnDisabled = true
			}
		},

		// Add new column object in tableColumns array
		addColumnItem() {
			this.current.data.tableColumns.push({
				prop: '',
				label: '',
				inputType: 'text',
				valueType: 'default',
				predefinedValues: '',
				width: '',
				editProp: true,
				conditions: [],
				removed: false,
			})
		},

		// Remove column item from Table
		removeColumnItem: function(index) {
			this.current.data.tableColumns.splice(index, 1)
		},

		// Create column prop based on label value
		// TODO: when to change column edit prop to false - when there is form requiest for that form
		createColumnProp(column) {
			if (column.editProp) {
				column.prop = this.regenerateString(column.label, '_')
			}
		},

		// Create option prop for Radio Buttons and Checkboxes
		createOptionValue(option) {
			if (option.editValue) {
				option.optionValue = this.regenerateString(option.optionLabel, '_')
			}
		},

		// Display only part of string
		concatString(string, size) {
			if (string && string.length > size) {
				return string.slice(0, size) + '...'
			} else {
				return string
			}
		},

		// Condition elements, remove current element from options
		fieldConditionElements(elements) {
			let newElements = [...elements]
			let indexCurrentElement = 1

			newElements.forEach((e, i) => {
				if (e.id === this.current.id) {
					indexCurrentElement = i
				}
			})

			newElements.splice(indexCurrentElement, 1)
			return newElements
		},

		// Add Payment Element price conditions
		addPaymentElementPriceCondition() {
			let id = Date.now()

			if (this.formElements?.length > 1) {
				if (!('priceConditions' in this.current.data)) {
					this.current.data = {
						...this.current.data,
						priceConditions: [
							{
								id,
								type: 'price-if',
								element: '',
								column: '',
								condition: 'equal',
								conditionValue: '',
								price: '',
								logicConditions: [],
							},
						],
					}
				} else {
					let conditionObject = {
						id,
						type: 'price-if',
						element: '',
						column: '',
						condition: 'equal',
						conditionValue: '',
						price: '',
						logicConditions: [],
					}

					this.current?.data?.priceConditions?.push(conditionObject)
				}
			} else {
				Vue.toasted.show(`There is no other elements in form to make price conditions!`)
			}
		},

		// Remove Payment Element price condition
		removePaymentElementPriceCondition(index) {
			this.current.data.priceConditions.splice(index, 1)
		},

		// In element conditions add select input for conditionValue with element options if element have options
		conditionElementOptions(condition) {
			let options = []
			if (condition.element != '') {
				let element = this.formElements.find(x => x.id === condition.element)
				if (element.options !== null) {
					element.options.forEach(option => {
						options.push(option)
					})
				}
			}
			return options
		},

		// Add element condition
		addElementCondition() {
			let id = Date.now()
			if (this.formElements.length > 1) {
				if (!('conditions' in this.current.data)) {
					this.current.data = {
						...this.current.data,
						conditions: [
							{
								id,
								type: 'visible-if',
								element: '',
								condition: 'equal',
								conditionValue: '',
							},
						],
					}
				} else {
					let conditionObject = {
						id,
						type: 'visible-if',
						element: '',
						condition: 'equal',
						conditionValue: '',
					}
					this.current.data.conditions.push(conditionObject)
				}
			} else {
				Vue.toasted.show(`There is no other elements in form to make conditions!`)
			}
		},

		// Remove element condition
		removeElementCondition(index) {
			this.current.data.conditions.splice(index, 1)
		},

		// Show element value field based on element type (text or select)
		conditionValueField(fieldType, condition) {
			let showField = false
			if (condition.element != null && condition.element != '') {
				let element = this.formElements.find(x => x.id === condition.element)

				if (
					fieldType === 'text' &&
					(element.type === 'TextInputElement' ||
						element.type === 'EmailInputElement' ||
						element.type === 'PhoneInputElement' ||
						element.type === 'NumberInputElement' ||
						(element.type === 'YearElement' &&
							(element.data.useDynamicMinYear || !element.data.useDynamicMaxYear)) ||
						element.type === 'TableElement' ||
						element.type === 'TextareaInputElement') &&
					(condition.condition === 'equal' || condition.condition === 'notEqual')
				) {
					showField = true
				} else if (
					fieldType === 'select' &&
					(element.type === 'RadioButtonElement' ||
						element.type === 'CheckboxElement' ||
						element.type === 'SelectListElement' ||
						(element.type === 'YearElement' &&
							!element.data.useDynamicMinYear &&
							!element.data.useDynamicMaxYear)) &&
					(condition.condition === 'equal' || condition.condition === 'notEqual')
				) {
					showField = true
				}
			}

			return showField
		},

		// Payment element price condition get Table columns
		getTableColumns(elementId) {
			let columns = []
			if (elementId) {
				this.formElements.forEach(e => {
					if (e.id === elementId) {
						columns = e.data.tableColumns
					}
				})
			}
			return columns
		},

		// Check is element table or not
		checkIsSelectedElementTable(elementId) {
			let isTable = false
			if (elementId) {
				this.formElements.forEach(e => {
					if (elementId === e.id && e.type === 'TableElement') {
						isTable = true
					}
				})
			}
			return isTable
		},

		// Check is element number
		checkIsSelectedElementNumber(elementId) {
			let isNumber = false
			if (elementId) {
				this.formElements.forEach(e => {
					if (elementId === e.id && e.type === 'NumberInputElement') {
						isNumber = true
					}
				})
			}
			return isNumber
		},

		// Add logic conditions for table element
		addLogicCondition(element) {
			if (element && 'logicConditions' in element) {
				element.logicConditions.push({
					operation: '',
					column: '',
					condition: 'notEmpty',
					conditionValue: '',
				})
			}
		},

		/*
		  Available operations - generate available operations for table conditions.
		  When first condition is 'OR' or 'AND' all conditions need to have that logic
		 */
		isOperationAvailable(condition, operator) {
			let operationAvailable = false
			if (condition.logicConditions.length > 1) {
				if (condition.logicConditions[0].operation !== operator) {
					operationAvailable = true
				} else {
					operationAvailable = false
				}
			}
			return operationAvailable
		},

		// Remove logic condition for table element
		removeLogicCondition(condition, index) {
			condition.logicConditions.splice(index, 1)
		},

		loadFormRequests() {
			heyGovApi.get(`/${this.j.slug}/form-requests?form_id=${this.form.id}`).then(
				({ data }) => {
					this.formRequests = data
					if (
						this.current.type === 'CheckboxElement' ||
						this.current.type === 'RadioButtonElement' ||
						this.current.type === 'SelectListElement'
					) {
						if (this.form.requests_count > 0) {
							this.current.options.forEach(option => {
								option.editValue = this.checkIsOptionInRequests(data, option, this.current.id)
							})
						}
					}
				},
				error => {
					Vue.toasted.error(`Error loading form request ~ ${error}`)
				}
			)
		},

		// Check is value used in some requests
		checkIsOptionInRequests(requests, option, elementId) {
			let isValueEditable = true

			requests.forEach(request => {
				Object.keys(request.answers).map(function(key) {
					if (option.optionValue === request.answers[key] && parseInt(key) === elementId) {
						isValueEditable = false
					}
				})
			})
			return isValueEditable
		},

		// Remove Element from step
		removeElement() {
			const error = this.checkFieldInConditions(this.current)
			if (error) {
				Vue.toasted.show(error, { type: 'error' })
				return
			}

			if (this.elementUsedInRequests) {
				// Just set removed to true
				heyGovApi
					.put(`/${this.j.slug}/form-fields/${this.current.id}`, {
						...this.current,
						removed: true,
					})
					.then(
						() => {
							this.current.removed = true
							Vue.toasted.show(`"${this.current.label}" element is removed!`)
						},
						error => {
							Vue.toasted.show(error, { type: 'error' })
						}
					)
			} else {
				// Remove from database
				heyGovApi.delete(`/${this.j.slug}/form-fields/${this.current.id}`).then(
					() => {
						this.stepElements.splice(this.current.order_number, 1)
						Vue.toasted.show(`"${this.current.label}" element is removed!`)
					},
					error => {
						Vue.toasted.error(error.message)
					}
				)
			}
			this.$emit('elementRemoved')
		},

		// Check is field exist and is somewhere in conditions before deleting
		checkFieldInConditions(field) {
			let errorString = ''

			this.formElements.forEach(el => {
				let conditions = el.data.conditions || el.data.priceConditions

				if (conditions) {
					conditions.forEach(cond => {
						if (cond.element === field.id) {
							if (errorString === '') {
								errorString +=
									'An element is a condition of element/s and cannot be deleted (delete condition first). Elements:' +
									el.label
							} else {
								errorString += ' and ' + el.label + ''
							}
						}
					})
				}
			})

			return errorString
		},

		// Create bether string for selecting elements in conditions
		conditionElementString(el) {
			let elementString = ''
			let substring = ''

			if (el.type === 'TextElement') {
				el.value = el.value.replace(/<\/?[^>]+(>|$)/g, '')
			}

			elementString = this.concatString(el.label + substring, 48)

			return elementString
		},

		handleImageAdded: function(file, Editor, cursorLocation, resetUploader) {
			// An example of using FormData
			// NOTE: Your key could be different such as:
			// formData.append('file', file)

			var formData = new FormData()
			formData.append('file', file)
			formData.append('id', this.form.id)

			heyGovApi.post(`/${this.j.slug}/form-steps/upload-image`, formData).then(
				({ data }) => {
					let url = data.url // Get url from response
					Editor.insertEmbed(cursorLocation, 'image', url)
					resetUploader()
				},
				error => {
					Vue.toasted.error(`Error uploading image (${error.message})`)
				}
			)
		},

		// Check is trashed options exists
		checkIsRemovedOptionsExists(options) {
			let removedExists = false
			options.forEach(option => {
				if (option.removed) {
					removedExists = true
				}
			})
			return removedExists
		},

		// Display label
		displayLabel() {
			return this.current.type !== 'TextElement'
		},

		// Display placeholder
		displayPlaceholder() {
			return [
				'TextInputElement',
				'EmailInputElement',
				'PhoneInputElement',
				'AddressInputElement',
				'TextareaInputElement',
			].includes(this.current.type)
		},

		// Display required
		displayRequired() {
			return !['TextElement', 'PaymentElement'].includes(this.current.type)
		},

		// Display prefilled
		displayPrefilled() {
			return [
				'SignatureElement',
				'DatePickerElement',
				'NameInputElement',
				'PhoneInputElement',
				'AddressInputElement',
				'EmailInputElement',
			].includes(this.current.type)
		},

		// Display redacted
		displayRedacted() {
			return !['TextElement', 'PaymentElement'].includes(this.current.type)
		},

		displayVariableTable() {
			return ['TextElement'].includes(this.current.type)
		},

		displayVariableElement(element) {
			return !['TextElement', 'PaymentElement'].includes(element.type)
		},

		// Get DatePicker elements from form
		getDateElements() {
			return this.formElements.filter(element => element.name === 'DatePicker' && element.id !== this.current.id)
		},

		moveElementToStep() {
			if (this.stepToMoveID !== null) {
				this.$emit('moveToStep', { stepId: this.stepToMoveID, element: this.current })
			} else {
				Vue.toasted.show(`You need to select step where to move this element`)
			}
		},

		getColumnsWithoutSetJoin(currentCol) {
			return this.current.data.tableColumns.filter(col => {
				if (col.joinWith && col.joinWith === 'single' && col.prop !== currentCol.prop) {
					return col
				}
			})
		},

		printVariable(element) {
			return '{{ field' + element.id + ' }}'
		},

		printDateVariable(element) {
			return '{{ dateToYears(' + element.label + ') }}'
		},

		async copyVariableToClipboard(mytext) {
			try {
				await navigator.clipboard.writeText(mytext)
				Vue.toasted.show(`${mytext} copied to cipboard`)
			} catch ($e) {
				//alert('Cannot copy')
			}
		},
	},
	created() {
		this.$store.dispatch('loadDepartments')
		this.loadFormRequests()
	},
	updated() {
		this.checkRemoveWord()
		if (
			!this.conditionalLogic &&
			(this.current.data?.conditions?.length > 0 || this.current.data?.priceConditions?.length > 0)
		) {
			this.conditionalLogic = true
		}
	},
}
</script>
