<template>
	<div class="page-meeting-agenda">
		<div class="xx-row xx-justify-content-center">
			<div class="xx-col-lg-8">
				<div class="card mb-4">
					<div class="card-header">
						<div class="d-flex align-items-center justify-content-between">
							<h5 class="my-0">Agenda</h5>

							<div>
								<div class="btn-group">
									<div class="btn-group" role="group">
										<button
											class="btn btn-sm btn-outline-primary dropdown-toggle"
											type="button"
											data-bs-toggle="dropdown"
											aria-expanded="false"
											:disabled="!meeting.agenda_items.length"
										>
											<font-awesome-icon :icon="['fas', 'file-export']" class="me-2" />
											Export
										</button>
										<ul class="dropdown-menu">
											<li>
												<a
													class="dropdown-item"
													:href="`${apiUrl + j.slug}/meetings/${meeting.pid}/agenda-pdf`"
													target="_blank"
												>
													<font-awesome-icon :icon="['fas', 'file-pdf']" class="me-2" />
													Agenda
												</a>
											</li>
											<li>
												<a
													class="dropdown-item"
													:href="
														`${apiUrl + j.slug}/meetings/${meeting.pid}/agenda-packet-pdf`
													"
													target="_blank"
												>
													<font-awesome-icon :icon="['fas', 'file-pdf']" class="me-2" />
													Agenda Packet
												</a>
											</li>
											<li><hr class="dropdown-divider" /></li>
											<li>
												<a class="dropdown-item" @click="openSettingsModal">
													<font-awesome-icon :icon="['fas', 'gear']" class="me-2" />
													Template settings
												</a>
											</li>
										</ul>
									</div>

									<div class="btn-group" role="group">
										<button
											class="btn btn-sm btn-outline-primary dropdown-toggle"
											type="button"
											data-bs-toggle="dropdown"
											aria-expanded="false"
											:disabled="!meeting.agenda_items.length"
										>
											<font-awesome-icon :icon="['fas', 'print']" class="me-2" />
											Print
										</button>
										<ul class="dropdown-menu">
											<li>
												<a
													class="dropdown-item"
													:href="
														`${apiUrl + j.slug}/meetings/${meeting.pid}/agenda-html?print=1`
													"
													target="_blank"
												>
													<font-awesome-icon :icon="['fas', 'file-pdf']" class="me-2" />
													Agenda
												</a>
											</li>
											<li>
												<a
													class="dropdown-item"
													:href="
														`${apiUrl + j.slug}/meetings/${
															meeting.pid
														}/agenda-packet-pdf?print=1`
													"
													target="_blank"
												>
													<font-awesome-icon :icon="['fas', 'file-pdf']" class="me-2" />
													Agenda Packet
												</a>
											</li>
											<li><hr class="dropdown-divider" /></li>
											<li>
												<a class="dropdown-item" @click="openSettingsModal">
													<font-awesome-icon :icon="['fas', 'gear']" class="me-2" />
													Template settings
												</a>
											</li>
										</ul>
									</div>
								</div>

								<div class="btn-group ms-3">
									<button
										v-if="meeting.agenda_status === 'draft'"
										class="btn btn-sm btn-outline-primary"
										@click="openSendForReviewModal"
										:disabled="!meeting.agenda_items.length"
									>
										<font-awesome-icon :icon="['fas', 'paper-plane']" class="me-2" />
										Send for review
									</button>
									<button
										v-if="['draft', 'in_review'].includes(meeting.agenda_status)"
										class="btn btn-sm btn-outline-primary"
										@click="alert('Not implemented yet')"
										:disabled="!meeting.agenda_items.length"
									>
										<font-awesome-icon :icon="['fas', 'upload']" class="me-2" />
										Publish agenda
									</button>
									<button
										v-if="meeting.agenda_status === 'public'"
										class="btn btn-sm btn-outline-primary"
										@click="alert('Not implemented yet')"
										:disabled="!meeting.agenda_items.length"
									>
										<font-awesome-icon :icon="['fas', 'share']" class="me-2" />
										Share
									</button>
								</div>
							</div>
						</div>
					</div>

					<div v-if="meeting.agenda_items.length" class="card-body">
						<div v-if="states.agenda_help" class="bg-warning-50 p-3 rounded-1 mb-4">
							<div class="row">
								<div class="col">
									<h6 class="mb-3">
										🙋 Your agenda is now imported! But first, let's review it to make sure it's
										correct.
									</h6>
								</div>
								<div class="col-auto">
									<button class="btn-close" @click="states.agenda_help = false"></button>
								</div>
							</div>

							<p>
								Look through the individual numbered line items below. Check for three things: (1) the
								verbiage is correct; (2) no agenda item is missing; (3) The meeting structure/hierarchy
								is correct.
							</p>

							<hr class="bg-warning-100" />

							<p class="mb-0">
								<small class="badge bg-warning-50 text-warning-300">Pro tip</small> You can re-order and
								indent agenda items by dragging and dropping them.
								<a
									href="https://townweb.notion.site/Create-and-edit-Agenda-items-fb3152b74c3e4699b75c52d473c23618"
									target="clerk-minutes-help"
									>How to manage agendas</a
								>
							</p>
						</div>

						<div
							v-if="isStaff && meeting.agenda_status === 'draft'"
							class="bg-warning-50 rounded-1 p-3 mb-3"
						>
							<h5 class="mb-3">🙋 Agenda is in DRAFT mode</h5>
							<p class="mb-2">
								Add yourself a few agenda items, or request from others. <u>How collaboration works</u>
							</p>
							<p class="mb-1">
								Pending requests:
							</p>
							<ul class="nicer-list">
								<li>Dustin Overbeck: requested changes for "That agenda item"</li>
								<li>Parks & Rec: sent request "do you wanna add something on the agenda?"</li>
							</ul>
							<p class="mb-0">
								When you feel everything is ready, either ask a department for review or publish it, how
								you prefer.
							</p>
						</div>
						<div
							v-else-if="isStaff && meeting.agenda_status === 'in_review'"
							class="bg-primary-50 rounded-1 p-3 mb-3"
						>
							<h5 class="mb-3">🙋 Agenda is in REVIEW mode</h5>
							<p class="mb-2">
								The agenda was sent for review to <code>{department.name}</code>. You can still make
								changes, and they will be logged in the meeting timeline.
							</p>
							<p class="mb-0">
								Votes so far from the department:
								<span class="badge bg-success-50 text-success-400">2 OK</span>,
								<span class="badge bg-danger-50 text-danger-400">1 NO</span>,
								<span class="badge bg-warning-50 text-warning-400">1 REQUESTED CHANGES</span> and
								<span class="badge bg-neutral-50 text-dark">4 PENDING</span>.
							</p>
						</div>

						<agenda-items-list
							:items="meeting.agenda_items"
							:parent_id="0"
							:settings="settings"
							:allowApprove="canApproveMeetingItems"
							:requestChangesStatus="requestChangesStatus"
							:allowAddSubItem="meeting.agenda_status !== 'public'"
							@updateOrder="saveAgendaItemsOrder"
							@editItem="handleEditItemClick"
							@removeItem="removeAgendaItem"
							@requestChanges="handleRequestChanges"
							@approve="handleApproveItem"
							@moveToAnotherMeeting="handleMoveToAnotherMeeting"
							@addSubItem="addAgendaSubItem"
							class="mb-3"
						/>

						<p class="card-text text-center">
							<button
								class="btn btn-outline-primary me-3"
								@click="addAgendaItem(0, meeting.agenda_items.filter(i => i.parent_id === 0).length)"
							>
								<font-awesome-icon :icon="['fas', 'plus']" /> Add agenda item
							</button>
							<button
								v-if="requestChangesStatus !== 'hidden'"
								:disabled="requestChangesStatus === 'disabled'"
								@click="openRequestItemModal"
								class="btn btn-outline-primary"
							>
								<font-awesome-icon :icon="['fas', 'paper-plane']" class="me-1" /> Request Agenda Item
							</button>
						</p>
					</div>
					<div
						v-else
						class="card-body file-drop text-center py-6"
						@dragover="dragover"
						@dragleave="dragleave"
						@drop="dropMeetingAgendaFile"
					>
						<p class="lead text-neutral-500 mb-4">Let's create the agenda for this meeting</p>

						<p v-if="states.agenda_file_path === 'idle'" class="card-text">
							<button class="btn btn-sm btn-outline-primary" @click="addAgendaItem(0, 0)">
								<font-awesome-icon :icon="['fas', 'plus']" /> Add first agenda item
							</button>
							<template v-if="requestChangesStatus !== 'hidden'">
								<span class="mx-3">or</span>
								<button
									:disabled="requestChangesStatus === 'disabled'"
									@click="openRequestItemModal"
									class="btn btn-sm btn-outline-primary"
								>
									<font-awesome-icon :icon="['fas', 'paper-plane']" class="me-1" /> Request Agenda
									Item
								</button>
							</template>
							<span class="mx-3">or</span>
							<label for="meeting-agenda-file" class="decoration-underline text-primary cursor-pointer">
								<font-awesome-icon :icon="['fas', 'file-import']" class="me-1" />
								<u>Import existing PDF agenda</u>
							</label>
						</p>
						<p v-else-if="states.agenda_file_path === 'loading'" class="card-text text-info">
							<span class="spinner-border spinner-border-sm me-1"></span> Processing agenda
							<small class="text-neutral-400">(1-2 minutes max)</small>
						</p>

						<div v-if="agendaUploadError" class="alert alert-danger">
							<strong>Agenda processing error:</strong> {{ agendaUploadError }}
						</div>

						<input type="file" id="meeting-agenda-file" class="d-none" @change="handleMeetingAgendaFile" />
					</div>

					<div
						v-if="meeting.agenda_file_path"
						class="card-footer d-flex align-items-center justify-content-between"
					>
						<a :href="getPublicFileUrl(meeting.agenda_file_path)" target="_blank"
							><font-awesome-icon :icon="['fas', 'file-pdf']" class="me-1" />
							{{ meeting.agenda_file_path.split('/').pop() }}
						</a>
						<button class="btn btn-sm text-danger" @click="meetingAgendaRemove">
							Remove uploaded agenda
						</button>
					</div>
					<div v-else class="card-footer">
						🙋
						<a
							href="https://townweb.notion.site/Create-and-edit-Agenda-items-fb3152b74c3e4699b75c52d473c23618"
							target="clerk-minutes-help"
							>How to create the best agendas</a
						>
					</div>
				</div>
			</div>
		</div>

		<div class="modal fade" id="modal-agenda-item-add" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<add-agenda-item-form @submit="handleAddItemSubmit" :parent-id="newItemParentId" />
			</div>
		</div>

		<div class="modal fade" id="modal-agenda-item-edit" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<edit-agenda-item-form
					v-if="editableAgendaItem"
					:defaultItem="editableAgendaItem"
					:isForModal="true"
					@submit="handleEditItemSubmit"
					@close="handleCloseEditItemForm"
					@request-file="handleRequestFileOpen"
					@file-upload="handleFileUpload"
				/>
			</div>
		</div>

		<div class="modal fade" id="modal-agenda-item-request" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<request-agenda-item-form
					:title="`Request agenda item for '${meeting.title}'`"
					@submit="handleRequestItemSubmit"
					@close="handleRequestItemClose"
				/>
			</div>
		</div>

		<div class="modal fade" id="modal-agenda-item-request-changes" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<request-agenda-item-changes-form
					v-if="editableAgendaItem"
					:title="`Request changes for agenda item '${editableAgendaItem.title}'`"
					:defaultSpeakerId="editableAgendaItem.speaker_id"
					@submit="handleRequestChangesSubmit"
					@close="handleCloseRequestChangesForm"
				/>
			</div>
		</div>

		<div class="modal fade" id="modal-agenda-item-request-file" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<request-agenda-file-form
					v-if="editableAgendaItem"
					@submit="handleRequestFileSubmit"
					@close="handleRequestFileClose"
					:title="`Request file for '${editableAgendaItem.title}'`"
				/>
			</div>
		</div>

		<div class="modal fade" id="modal-meeting-settings" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog modal-xl">
				<meeting-settings-form
					:categoryId="meeting.categories.length > 0 ? meeting.categories[0] : null"
					:categories="meetingCategories"
					:settings="settings"
					:loading="settingsLoading"
					:meetingPid="meeting.pid"
					@submit="handleSettingsSubmit"
					@close="handleCloseSettingsForm"
					@setCategory="handleSetCategory"
				/>
			</div>
		</div>

		<div class="modal fade" id="modal-send-for-review" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<send-for-review-form
					:meeting="meeting"
					@submit="handleSendForReviewSubmit"
					@close="handleCloseSendForReviewForm"
				/>
			</div>
		</div>

		<div class="modal fade" id="modal-move-to-meeting" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<move-to-meeting-modal
					v-if="moveToMeetingItem"
					:currentMeetingId="meeting.id"
					@submit="handleMoveToMeetingSubmit"
					@close="handleCloseMoveToMeetingModal"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'
import { Modal } from 'bootstrap'

import heyGovApi, { hgApi } from '@/api.js'
import { sendEvent, getPublicFileUrl, handleResponseError, truncateString } from '@/utils.js'

import AgendaItemsList from '@/views/Meetings/AgendaItemsList.vue'
import EditAgendaItemForm from '@/views/Meetings/EditAgendaItemForm/EditAgendaItemForm.vue'
import AddAgendaItemForm from '@/views/Meetings/AddAgendaItemForm/AddAgendaItemForm.vue'
import RequestAgendaItemForm from '@/views/Meetings/RequestItemForm/RequestItemForm.vue'
import MeetingSettingsForm from './MeetingSettingsForm.vue'
import SendForReviewForm from './SendForReviewForm/SendForReviewForm.vue'
import MoveToMeetingModal from './MoveToMeetingModal.vue'

export default {
	name: 'MeetingAgenda',
	components: {
		AgendaItemsList,
		EditAgendaItemForm,
		AddAgendaItemForm,
		RequestAgendaItemForm,
		RequestAgendaFileForm: RequestAgendaItemForm,
		RequestAgendaItemChangesForm: RequestAgendaItemForm,
		MeetingSettingsForm,
		SendForReviewForm,
		MoveToMeetingModal,
	},
	metaInfo() {
		return {
			title: `Agenda for ${this.meeting?.title || this.$route.params.meetingId} - Meetings`,
		}
	},
	props: {
		meeting: {
			type: Object,
			required: true,
		},
		meetingPlayer: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			meetingCategories: [],
			settings: {},
			editableAgendaItem: null,
			states: {
				agenda_file_path: 'idle',
				agenda_help: false,
			},
			agendaUploadError: '',
			settingsLoading: true,
			moveToMeetingItem: null,
			newItemParentId: null,
		}
	},
	computed: {
		...mapState(['j', 'apiUrl']),
		...mapGetters(['auth', 'isStaff']),
		canApproveMeetingItems() {
			if (!this.meeting.department_id) {
				return true
			}
			return this.j.roles && this.j.roles.some(role => role.department_id === this.meeting.department_id)
		},
		requestChangesStatus() {
			const now = new Date()
			const meetingStart = new Date(this.meeting.starts_at)

			// hide if meeting is public or in the past
			if (this.meeting.agenda_status === 'public' || now > meetingStart) {
				return 'hidden'
			}

			// if meeting has cut-off time and it's past that time, disable
			if (this.meeting.agenda_cut_off_hours) {
				const cutoffTime = new Date(meetingStart.getTime() - this.meeting.agenda_cut_off_hours * 60 * 60 * 1000)
				if (now > cutoffTime) {
					return 'disabled'
				}
			}

			// otherwise show
			return 'show'
		},
	},
	mounted() {
		this.$modalAgendaItemAdd = new Modal(document.getElementById('modal-agenda-item-add'))
		this.$modalAgendaItemEdit = new Modal(document.getElementById('modal-agenda-item-edit'))
		this.$modalAgendaItemRequest = new Modal(document.getElementById('modal-agenda-item-request'))
		this.$modalAgendaItemRequestChanges = new Modal(document.getElementById('modal-agenda-item-request-changes'))
		this.$modalAgendaItemRequestFile = new Modal(document.getElementById('modal-agenda-item-request-file'))
		this.$modalMeetingSettings = new Modal(document.getElementById('modal-meeting-settings'))
		this.$modalSendForReview = new Modal(document.getElementById('modal-send-for-review'))
		this.$modalMoveToMeeting = new Modal(document.getElementById('modal-move-to-meeting'))

		if (this.meetingPlayer.position === 'default') {
			this.$emit('playerStyles', {
				zIndex: 99,
				position: 'fixed',
				width: `400px`,
				right: '16px',
				bottom: '16px',
			})
		}

		this.loadCategories()
	},
	methods: {
		getPublicFileUrl,
		dragover(event) {
			event.preventDefault()

			if (!event.currentTarget.classList.contains('dragover')) {
				event.currentTarget.classList.add('dragover')
			}
		},
		dragleave(event) {
			event.currentTarget.classList.remove('dragover')
		},
		dropMeetingAgendaFile(event) {
			event.preventDefault()
			this.dragleave(event)

			if (event.dataTransfer.files.length) {
				this.uploadMeetingAgenda(event.dataTransfer.files[0])
			} else {
				alert('No files dropped 🤷')
			}
		},
		handleMeetingAgendaFile($event) {
			this.uploadMeetingAgenda($event.target.files[0])
		},
		validateFileBeforeUpload(file, allowImages = false) {
			const allowedFiles = [
				'application/pdf', // pdf
				'application/msword', // doc
				'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // docx
				'text/plain', // txt
			]

			if (file.size / 1024 / 1024 > 30) {
				return 'File size is too big (max 30MB)'
			}

			const isDoc = allowedFiles.includes(file.type)
			const isImg = file.type.startsWith('image/')

			if (allowImages) {
				if (!isDoc && !isImg) {
					return 'Only documents and images are allowed 🤷'
				}
			} else {
				if (!isDoc) {
					return 'Only PDF and Word documents are allowed 🤷'
				}
			}

			return ''
		},
		uploadMeetingAgenda(file) {
			const allowedAgendaFileTyes = [
				'application/pdf', // pdf
				//'application/msword', // doc
				'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // docx
			]

			if (file.size < 1) {
				sendEvent('meeting.agenda_upload_error', {
					feature: 'Meetings',
					meeting_id: this.meeting.pid,
					meeting: this.meeting.title,
					error: 'size too small',
					file: file.name,
					size: file.size,
					type: file.type,
				})

				alert('File size is too small, is the file empty?')
				return
			} else if (file.size / 1024 / 1024 > 30) {
				sendEvent('meeting.agenda_upload_error', {
					feature: 'Meetings',
					meeting_id: this.meeting.pid,
					meeting: this.meeting.title,
					error: 'size too big',
					file: file.name,
					size: file.size,
					type: file.type,
				})

				alert('File size is too big (max 30MB)')
				return
			} else if (!allowedAgendaFileTyes.includes(file.type)) {
				sendEvent('meeting.agenda_upload_error', {
					feature: 'Meetings',
					meeting_id: this.meeting.pid,
					meeting: this.meeting.title,
					error: 'format not supported',
					file: file.name,
					size: file.size,
					type: file.type,
				})

				alert('Only PDF/DOCX agendas are accepted 🤷')
				return
			}

			this.states.agenda_file_path = 'loading'
			this.agendaUploadError = ''

			sendEvent('meeting.agenda_upload_start', {
				feature: 'Meetings',
				meeting_id: this.meeting.pid,
				meeting: this.meeting.title,
				file: file.name,
				size: file.size,
				type: file.type,
			})

			// prepare file data
			var form = new FormData()
			form.append('file', file)

			heyGovApi
				.post(`${this.j.slug}/meetings/${this.meeting.pid}/upload-agenda-file?await_processing=1`, form)
				.then(
					({ data }) => {
						this.meeting.agenda_status = 'public'
						this.meeting.agenda_file_path = data.agenda_file_path
						this.meeting.agenda_text = data.agenda_text
						this.meeting.agenda_items.push(...data.agenda_items)

						this.states.agenda_help = true

						sendEvent('meeting.agenda_imported', {
							feature: 'Meetings',
							meeting_id: this.meeting.pid,
							meeting: this.meeting.title,
							file: file.name,
							size: file.size,
							type: file.type,
						})
					},
					error => {
						if (error.response?.status >= 400 && error.response?.status < 600) {
							this.agendaUploadError =
								error.response?.data?.message || error.response?.statusText || error.message
						} else {
							handleResponseError('Error processing agenda ({error})')(error)
						}

						sendEvent('meeting.agenda_processing_error', {
							feature: 'Meetings',
							meeting_id: this.meeting.pid,
							meeting: this.meeting.title,
							file: file.name,
							size: file.size,
							type: file.type,
							error: this.agendaUploadError || error.message,
						})
					}
				)
				.finally(() => {
					this.states.agenda_file_path = 'idle'
				})
		},

		// TODO return debounce (but for http request ONLY, otherwise we would have weird freezes)
		async saveAgendaItemsOrder(agendaItems) {
			const prev = this.meeting.agenda_items
			try {
				this.meeting.agenda_items = agendaItems // optimistic update
				const request = agendaItems.map(item => {
					return {
						id: item.id,
						parent_id: item.parent_id,
						order: item.order,
					}
				})
				await heyGovApi.post(`${this.j.slug}/meetings/${this.meeting.pid}/agenda-items/order`, request)
				Vue.toasted.success('Items order is saved')
			} catch (error) {
				handleResponseError('Error saving items order ({error})')(error)
				this.meeting.agenda_items = prev // rollback optimistic update
			}
		},

		meetingAgendaRemove() {
			if (confirm('Agenda PDF and all items will be removed, is this ok?')) {
				heyGovApi.post(`${this.j.slug}/meetings/${this.meeting.pid}/remove-agenda`).then(() => {
					this.meeting.agenda_file_path = null
					this.meeting.agenda_status = 'draft'
					this.meeting.agenda_text = ''
					this.meeting.agenda_items = []

					Vue.toasted.show('Agenda is removed')
				}, handleResponseError('Error removing agenda ({error})'))
			}
		},

		addAgendaItem(parent_id, order) {
			this.newItemParentId = parent_id
			this.newItemOrder = order
			this.$modalAgendaItemAdd.show()
		},

		openRequestItemModal() {
			this.$modalAgendaItemRequest.show()
		},

		async handleRequestItemSubmit({ comment, speaker_id }, after) {
			try {
				await heyGovApi.post(`${this.j.slug}/meetings/${this.meeting.pid}/agenda-item-request`, {
					comment: comment,
					to_speaker_id: speaker_id,
				})
				Vue.toasted.success('Agenda item request submitted successfully')
			} catch (error) {
				handleResponseError('Error submitting agenda item request ({error})')(error)
			} finally {
				after()
				this.handleRequestItemClose()
			}
		},

		handleRequestItemClose() {
			this.$modalAgendaItemRequest.hide()
		},

		async handleAddItemSubmit({ files, ...item }, after) {
			try {
				const { data: created } = await heyGovApi.post(
					`${this.j.slug}/meetings/${this.meeting.pid}/agenda-items`,
					{
						...item,
						parent_id: this.newItemParentId,
						order: this.newItemOrder,
					}
				)
				this.meeting.agenda_items.push(created)
				Vue.toasted.success('Agenda item is added')

				sendEvent('meeting.agenda_item_created', {
					feature: 'Meetings',
					meeting_id: this.meeting.pid,
					meeting: this.meeting.title,
					agenda_item_id: created.id,
				})

				if (!files.length) {
					return
				}

				const form = new FormData()
				for (const file of files) {
					const errStr = this.validateFileBeforeUpload(file, true)
					if (errStr) {
						alert(errStr)
						return
					}
					form.append('files', file)
				}

				const { data: createdFiles } = await heyGovApi.post(
					`${this.j.slug}/meetings/${this.meeting.pid}/agenda-items/${created.id}/files`,
					form
				)
				created.files.push(...createdFiles)
				Vue.toasted.success('Agenda item files are uploaded')
			} catch (error) {
				handleResponseError('Error adding agenda item ({error})')(error)
			} finally {
				this.newItemParentId = null
				this.newItemOrder = 0
				this.$modalAgendaItemAdd.hide()
				after()
			}
		},

		handleCloseEditItemForm() {
			this.$modalAgendaItemEdit.hide()
			this.editableAgendaItem = null
		},

		async handleEditItemSubmit({ id, ...fields }, after) {
			try {
				const resp = await heyGovApi.put(`${this.j.slug}/meetings/${this.meeting.pid}/agenda-items/${id}`, {
					...fields,
					status: 'ok', // Auto-approve when edited by meeting manager
				})

				this.meeting.agenda_items = this.meeting.agenda_items.map(item => {
					if (item.id === id) {
						return resp.data
					}
					return item
				})

				Vue.toasted.success('Agenda item updated')
			} catch (error) {
				handleResponseError('Error editing agenda item ({error})')(error)
			} finally {
				after()
				this.handleCloseEditItemForm()
			}
		},

		handleEditItemClick(item) {
			this.editableAgendaItem = item
			this.$modalAgendaItemEdit.show()
		},

		removeAgendaItem(item) {
			const msg = item.subitems?.length
				? '🚨 This agenda item has subitems. Are you sure you want to remove it?'
				: 'For sure remove this agenda item?'

			if (confirm(msg)) {
				heyGovApi.delete(`${this.j.slug}/meetings/${this.meeting.pid}/agenda-items/${item.id}`).then(() => {
					this.meeting.agenda_items = this.meeting.agenda_items.filter(i => i.id !== item.id)
					Vue.toasted.show('Agenda item is removed')
				}, handleResponseError('Error removing agenda item ({error})'))
			}
		},

		async handleRequestChangesSubmit({ comment, speaker_id }, after) {
			try {
				const resp = await heyGovApi.post(
					`${this.j.slug}/meetings/${this.meeting.pid}/agenda-item-request-changes`,
					{
						comment: comment,
						to_speaker_id: speaker_id,
						agenda_item_id: this.editableAgendaItem.id,
					}
				)

				// find and replace the updated agenda item
				const { data: updatedAgendaItem } = resp
				this.meeting.agenda_items = this.meeting.agenda_items.map(item => {
					if (item.id === updatedAgendaItem.id) {
						return updatedAgendaItem
					}
					return item
				})

				Vue.toasted.success('Agenda item change request submitted successfully')
			} catch (error) {
				handleResponseError('Error submitting agenda item change request ({error})')(error)
			} finally {
				after()
				this.$modalAgendaItemRequestChanges.hide()
			}
		},

		handleCloseRequestChangesForm() {
			this.$modalAgendaItemRequestChanges.hide()
		},

		handleRequestChanges(item) {
			this.editableAgendaItem = item
			this.$modalAgendaItemRequestChanges.show()
		},

		async handleApproveItem({ id }) {
			try {
				const { data } = await heyGovApi.put(`${this.j.slug}/meetings/${this.meeting.pid}/agenda-items/${id}`, {
					status: 'ok',
				})

				const updatedItem = this.meeting.agenda_items.find(i => i.id === id)
				if (updatedItem) {
					updatedItem.status = data.status
				}

				Vue.toasted.success('Agenda item approved successfully')
			} catch (error) {
				handleResponseError('Error approving agenda item ({error})')(error)
			}
		},

		handleRequestFileOpen() {
			this.$modalAgendaItemEdit.hide()
			this.$modalAgendaItemRequestFile.show()
		},

		async handleRequestFileSubmit({ comment, speaker_id }, after) {
			try {
				await heyGovApi.post(`${this.j.slug}/meetings/${this.meeting.pid}/agenda-item-request-changes`, {
					comment: comment,
					to_speaker_id: speaker_id,
					agenda_item_id: this.editableAgendaItem.id,
				})
				Vue.toasted.success('File request submitted successfully')
			} catch (error) {
				handleResponseError('Error submitting file request ({error})')(error)
			} finally {
				after()
				this.handleRequestFileClose()
			}
		},

		handleRequestFileClose() {
			this.$modalAgendaItemRequestFile.hide()
			this.$modalAgendaItemEdit.show()
		},

		async handleFileUpload({ files, itemId }, after) {
			if (!files.length) return

			const form = new FormData()
			for (const file of files) {
				const errStr = this.validateFileBeforeUpload(file, true)
				if (errStr) {
					alert(errStr)
					return
				}
				form.append('files', file)
			}

			try {
				const { data: newFilePaths } = await heyGovApi.post(
					`${this.j.slug}/meetings/${this.meeting.pid}/agenda-items/${itemId}/files`,
					form
				)

				const existing = this.meeting.agenda_items.find(item => item.id === itemId)
				existing.files.push(...newFilePaths)

				Vue.toasted.success('Agenda item files have been updated')
			} catch (error) {
				handleResponseError('Error uploading agenda item files ({error})')(error)
			} finally {
				after()
			}
		},
		alert(msg) {
			window.alert(msg)
		},
		openSendForReviewModal() {
			this.$modalSendForReview.show()
		},
		openSettingsModal() {
			this.$modalMeetingSettings.show()
		},
		handleCloseSettingsForm() {
			this.$modalMeetingSettings.hide()
		},
		async handleSendForReviewSubmit({ department_id, comment }, after) {
			try {
				await heyGovApi.post(`${this.j.slug}/meetings/${this.meeting.pid}/send-for-review`, {
					department_id,
					comment,
				})

				Vue.toasted.success('Agenda sent for review successfully')
				this.$modalSendForReview.hide()
				this.meeting.agenda_status = 'in_review'
			} catch (error) {
				handleResponseError('Error sending agenda for review ({error})')(error)
			} finally {
				after()
			}
		},
		handleCloseSendForReviewForm() {
			this.$modalSendForReview.hide()
		},

		async loadCategories() {
			this.settingsLoading = true
			try {
				const response = await hgApi(`${this.j.slug}/meetings/categories?expand=template`)
				if (!response.ok) {
					throw new Error(response.statusText || 'Server error')
				}
				this.meetingCategories = await response.json()
			} catch (error) {
				handleResponseError('Error loading meeting categories ({error})')(error)
			} finally {
				this.settingsLoading = false
			}
		},

		async handleSettingsSubmit(fields) {
			this.settingsLoading = true
			try {
				await heyGovApi.put(`${this.$store.state.j.slug}/meetings/categories/${fields.id}`, {
					template_agenda_footer_note: fields.template_agenda_footer_note,
					template_agenda_header_note: fields.template_agenda_header_note,
					template_agenda_item_speaker: fields.template_agenda_item_speaker,
					template_agenda_item_text: fields.template_agenda_item_text,
					template_content_font: fields.template_content_font,
					template_header_alignment: fields.template_header_alignment,
					template_items_numbering_style: fields.template_items_numbering_style,
					template_logo: fields.template_logo,
					template_subitems_numbering_style: fields.template_subitems_numbering_style,
					template_titles_font: fields.template_titles_font,
				})
				this.settings = fields
				Vue.toasted.success('Meeting settings updated')
				this.$modalMeetingSettings.hide()
			} catch (error) {
				handleResponseError('Error updating meeting settings ({error})')(error)
			} finally {
				this.settingsLoading = false
			}
		},

		async handleSetCategory(categoryId) {
			this.settingsLoading = true
			try {
				await heyGovApi.put(`${this.$store.state.j.slug}/meetings/${this.meeting.pid}`, {
					categories: [categoryId],
				})
				this.settings = this.meetingCategories.find(c => c.id === categoryId)
				this.meeting.categories = [categoryId]
				Vue.toasted.success('Meeting category updated')
			} catch (error) {
				handleResponseError('Error updating meeting category ({error})')(error)
			} finally {
				this.settingsLoading = false
			}
		},

		handleMoveToAnotherMeeting(item) {
			this.moveToMeetingItem = item
			this.$modalMoveToMeeting.show()
		},

		handleCloseMoveToMeetingModal() {
			this.$modalMoveToMeeting.hide()
			this.moveToMeetingItem = null
		},

		async handleMoveToMeetingSubmit(toMeeting) {
			try {
				await heyGovApi.post(`${this.j.slug}/meetings/${this.meeting.pid}/agenda-items/move`, {
					to_meeting_id: toMeeting.id,
					agenda_item_id: this.moveToMeetingItem.id,
				})

				this.meeting.agenda_items = this.meeting.agenda_items.filter(
					item => item.id !== this.moveToMeetingItem.id
				)

				Vue.toasted.success(
					`Agenda item moved successfully to "${truncateString(toMeeting.title, 20)}" Meeting`,
					{
						action: {
							text: `View Meeting`,
							onClick: () => this.$router.push(`/${this.j.slug}/meetings/${toMeeting.pid}/agenda`),
						},
						className: 'allow-html',
					}
				)

				this.handleCloseMoveToMeetingModal()
			} catch (error) {
				Vue.toasted.error('Error moving agenda item')
				console.error('Error moving agenda item:', error)
			}
		},

		addAgendaSubItem(parentId) {
			this.newItemParentId = parentId
			this.newItemOrder = this.meeting.agenda_items.filter(i => i.parent_id === parentId).length
			this.$modalAgendaItemAdd.show()
		},
	},

	watch: {
		'$route.params.meetingId'() {
			this.$emit('loadMeeting')
		},
		meetingCategories() {
			if (this.meeting.categories.length === 0) {
				return // settings are only allowed when meeting has a category
			}
			const category_id = this.meeting.categories[0] // it's only one category
			this.settings = this.meetingCategories.find(c => c.id === category_id)
		},
	},
}
</script>
