<template>
	<router-link
		class="notification py-2 d-block"
		:class="{ 'not-clicked': !notification.clicked }"
		:to="getNotificationLink(notification)"
		@click.native="notificationClicked(notification)"
	>
		<div class="row align-items-center">
			<div class="col-auto">
				<img
					v-if="people[notification.by_person_id]"
					:src="
						`https://www.gravatar.com/avatar/${
							people[notification.by_person_id].email_hash
						}?s=100&d=${encodeURIComponent('https://files.layered.market/neutral-2.png')}`
					"
					width="32"
					class="rounded-circle"
					alt="Person"
				/>
				<img
					v-else
					src="https://files.heygov.com/assets/heygov-logo-mark-180.png"
					width="32"
					class="rounded-circle"
					alt="HeyGov"
				/>
			</div>
			<div class="col px-0">
				<small class="text-muted float-end ps-1 me-1">{{
					formatDistanceToNow(new Date(notification.created_at), { addSuffix: true })
				}}</small>
				<span class="text-wrap">{{ notification.text }}</span>
			</div>
		</div>
	</router-link>
</template>

<style lang="scss">
.notification {
	background-color: white;
	color: #333;
	line-height: 1.4;

	&.not-clicked {
		background-color: lighten(#ffb800, 45%);
	}

	&:hover {
		background-color: #e9ecef;
		color: #000;
	}

	&.not-clicked:hover {
		background-color: lighten(#ffb800, 43%);
	}
}
</style>

<script>
import { mapState } from 'vuex'
import { formatDistanceToNow } from 'date-fns'

import heyGovApi from '@/api.js'

export default {
	name: 'Notification',
	props: ['notification'],
	data() {
		return {}
	},
	computed: {
		...mapState(['j', 'people']),
	},
	methods: {
		formatDistanceToNow,
		getNotificationLink(notification) {
			let link = '/'

			if (notification.entity === 'message') {
				link += `${this.j.slug}/threads/${notification.data.thread.uuid}#message=${notification.entity_id}`
			} else if (notification.entity === 'form-request') {
				link += `${this.j.slug}/forms/${notification.data.form.slug}/request-review/${notification.entity_id}`
			} else if (notification.entity === 'venue-booking') {
				link += `${this.j.slug}/venues/${notification.data.venue.slug}/bookings/${notification.data.venueBooking.uuid}`
			} else if (notification.entity === 'payment') {
				link += `${this.j.slug}/payments/${notification.data.department.id}/payments#payment=${notification.entity_id}`
			} else {
				console.warn(`unknown entity "${notification.entity}" in notification`, notification)
			}

			return link
		},
		notificationClicked(notification) {
			notification.clicked = 1
			heyGovApi.put(`/${this.j.slug}/notifications/${notification.id}`, { clicked: 1 })
		},
	},
}
</script>
