<template>
	<div class="page-meeting-transcript">
		<div v-if="['not-started', 'upload-error', 'error'].includes(meeting.transcript_job_status)" class="card mb-4">
			<div class="card-header">
				<div class="d-flex align-items-center justify-content-between">
					<h5 class="my-0">Transcript &amp; Speakers</h5>
					<help-button
						url="https://townweb.notion.site/Upload-meeting-recording-audio-or-video-559e6058f77146c8b7037e9cd5add7aa"
					></help-button>
				</div>
			</div>
			<div class="card-body text-center py-6 file-drop">
				<p class="lead mb-4">This meeting doesn't have a recording yet</p>

				<p v-if="meeting.transcript_job_status === 'error'" class="card-text text-danger-400">
					The uploaded recording couldn't be transcribed. Please upload another one.
				</p>
				<p v-else class="card-text">
					Upload a recording on the right side 👉
				</p>
			</div>
		</div>

		<div v-else-if="meeting.transcript_job_status === 'uploading'" class="card mb-4">
			<div class="card-header">
				<div class="d-flex align-items-center justify-content-between">
					<h5 class="my-0">Transcript &amp; Speakers</h5>
					<help-button
						url="https://townweb.notion.site/Upload-meeting-recording-audio-or-video-559e6058f77146c8b7037e9cd5add7aa"
					></help-button>
				</div>
			</div>
			<div class="card-body py-6 text-center">
				<p class="my-2"><span class="spinner-border spinner-border-sm"></span> Uploading recording..</p>
				<p v-if="meeting.video_public_url" class="my-2 text-neutral-400">
					from {{ urlPart(meeting.video_public_url, 'hostname').replace('www.', '') }}, shouldn't take more
					than 5 minutes
				</p>
			</div>
		</div>

		<div v-else-if="['started', 'transcribed'].includes(meeting.transcript_job_status)">
			<div class="card">
				<div class="card-header">
					<div class="d-flex align-items-center justify-content-between">
						<h5 class="my-0">Transcript &amp; Speakers</h5>
						<help-button
							url="https://townweb.notion.site/Assign-speakers-for-your-meeting-90f25ad01de447cabbe25545d32255f0"
							text="Manage speakers"
						></help-button>
					</div>
				</div>

				<div class="card-body p-3">
					<div v-if="meeting.transcript_job_status === 'started'" class="text-center py-6">
						<p class="mb-2">
							<span class="spinner-border spinner-border-sm"></span> Processing the transcript
						</p>
						<p class="card-text text-neutral-400">Shouldn't take more than 5 minutes</p>
					</div>

					<div v-else class="meeting-transcript-lines">
						<div class="list-group rounded-1 mb-3">
							<div class="list-group-item">
								<span
									class="d-inline-block bg-success-50 text-success-400 text-center rounded me-1"
									style="width: 24px; height: 24px"
									><font-awesome-icon :icon="['fas', 'check']"
								/></span>
								Meeting recording of
								<strong>{{
									formatDuration({ minutes: Math.ceil(meeting.recording_duration_seconds / 60) })
								}}</strong>
								is transcribed
							</div>

							<div class="list-group-item">
								<template v-if="transcriptLinesLowConfidence.length">
									<div>
										<span
											class="d-inline-block bg-warning-50 text-warning-400 text-center rounded me-1"
											style="width: 24px; height: 24px"
											><font-awesome-icon :icon="['fas', 'info']"
										/></span>
										Transcript review needed for accurate minutes.
										<u
											v-if="!states.transcriptReviewMessage"
											class="text-neutral-500 cursor-pointer"
											@click="states.transcriptReviewMessage = true"
											>Read more</u
										>
										<span v-else>Some parts have low accuracy due to recording quality.</span>
									</div>

									<p v-if="states.transcriptReviewMessage" class="mt-2 mb-0">
										Please review and correct the
										<strong>{{
											pluralize('line', transcriptLinesLowConfidence.length, true)
										}}</strong>
										marked <u>Text confidence: Low</u> or <u>Text confidence: Poor</u>. Click the
										transcript line to edit.
									</p>
								</template>
								<template v-else-if="transcriptLinesGoodConfidence.length">
									<div>
										<span
											class="d-inline-block bg-primary-50 text-primary-400 text-center rounded me-1"
											style="width: 24px; height: 24px"
											><font-awesome-icon :icon="['fas', 'info']"
										/></span>
										This transcript is accurate enough to generate minutes.
										<u
											v-if="!states.transcriptReviewMessage"
											class="text-neutral-500 cursor-pointer"
											@click="states.transcriptReviewMessage = true"
											>Read more</u
										>
									</div>

									<p v-if="states.transcriptReviewMessage" class="mt-2 mb-0">
										If you plan to publish or share it, please review the
										<strong>{{ transcriptLinesGoodConfidence.length }} lines</strong> marked
										<u>Text confidence: Good</u> to ensure full accuracy.
									</p>
								</template>
								<template v-else>
									<span
										class="d-inline-block bg-success-50 text-success-400 text-center rounded me-1"
										style="width: 24px; height: 24px"
										><font-awesome-icon :icon="['fas', 'check']"
									/></span>
									Recording quality indicates that this transcript is accurate
								</template>
							</div>

							<div class="list-group-item">
								<template v-if="unidentifiedSpeakers.length">
									<span
										class="d-inline-block bg-primary-50 text-primary-400 text-center rounded me-1"
										style="width: 24px; height: 24px"
										><font-awesome-icon :icon="['fas', 'info']"
									/></span>
									{{ unidentifiedSpeakers.length }} out of
									<strong>{{ uniq(meeting.transcript.map(l => l.speaker)).length }} speakers</strong>
									are not yet identified
								</template>
								<template v-else>
									<span
										class="d-inline-block bg-success-50 text-success-400 text-center rounded me-1"
										style="width: 24px; height: 24px"
										><font-awesome-icon :icon="['fas', 'check']"
									/></span>
									All
									<strong>{{ uniq(meeting.transcript.map(l => l.speaker)).length }} speakers</strong>
									are identified
								</template>
							</div>
						</div>

						<hr class="bg-primary-100" />

						<div
							v-for="line in meeting.transcript"
							:key="line.id"
							class="transcript-line mb-3 on-parent"
							:class="{
								'transcript-line-current':
									meetingPlayer.currentTime >= line.timestamp &&
									meetingPlayer.currentTime < line.timestamp_end,
							}"
						>
							<div class="row transcript-line-meta mb-1">
								<div class="col">
									<span
										class="text-primary-300 cursor-pointer"
										@click="$emit('playerTimestamp', line.timestamp)"
									>
										{{ timestampToMinutes(line.timestamp) }}
									</span>
									<span class="text-neutral-300 mx-1">&middot;</span>
									<div class="d-inline-block dropdown">
										<span
											class="line-speaker"
											role="button"
											:id="`transcript-dropdown-${line.id}`"
											data-bs-toggle="dropdown"
											aria-expanded="false"
											@click="openSpeakerDropdown($event, line)"
										>
											<span
												v-if="
													line.person_id &&
														line.department_id &&
														people[line.person_id] &&
														departments
															.find(d => d.id === line.department_id)
															.people.find(p => p.person_id === line.person_id)
												"
												class="text-dark"
											>
												<span class="text-neutral-500">{{
													departments
														.find(d => d.id === line.department_id)
														.people.find(p => p.person_id === line.person_id).title
												}}</span>
												{{ people[line.person_id].last_name }}
												({{ departments.find(d => d.id === line.department_id).name }})
											</span>
											<span
												v-else-if="line.person_id && people[line.person_id]"
												class="text-dark"
											>
												<img
													:src="people[line.person_id].photo"
													width="16"
													height="16"
													class="rounded-circle"
													alt="Photo"
												/>
												{{ people[line.person_id].name }}
											</span>
											<code v-else-if="line.person_id" class="text-warning-400"
												>[{{ line.person_id }}]</code
											>
											<span v-else class="text-warning-400">Speaker {{ line.speaker + 1 }}</span>
										</span>
										<ul
											class="dropdown-menu dropdown-speakers"
											:aria-labelledby="`transcript-dropdown-${line.id}`"
										>
											<template v-if="states.openSpeakerDropdown === line.id">
												<li>
													<span
														class="dropdown-item-text d-flex align-items-center justify-content-between"
													>
														<span class="text-warning-400"
															>Speaker {{ line.speaker + 1 }}</span
														>
														<small
															class="cursor-pointer text-underline text-neutral-400"
															data-bs-toggle="modal"
															data-bs-target="#modal-change-speaker-for-line"
															@click="states.changeSpeakerLine = line"
														>
															Change speaker number
														</small>
													</span>
												</li>
												<li><hr class="dropdown-divider bg-primary-100 my-0" /></li>

												<template
													v-for="department in activeDepartments.filter(d => d.people.length)"
												>
													<li
														:key="department.id"
														class="dropdown-item-header"
														@click="toggleDepartment($event, department.id)"
													>
														<h6
															class="dropdown-header d-flex justify-content-between pt-2"
															:class="
																states.hiddenDropdownDepartments.includes(department.id)
																	? 'pb-2'
																	: 'pb-1'
															"
														>
															<span
																>{{ department.name }}
																<small
																	:class="
																		department.id === line.department_id
																			? 'text-success-400'
																			: 'text-neutral-300'
																	"
																	>({{ department.people.length }})</small
																>
															</span>
															<span>
																<router-link
																	:to="`/departments?department=${department.id}`"
																	class="btn-edit-department ms-1 me-2"
																	>Edit {{ department.type }}</router-link
																>
																<font-awesome-icon
																	:icon="[
																		'fas',
																		states.hiddenDropdownDepartments.includes(
																			department.id
																		)
																			? 'chevron-up'
																			: 'chevron-down',
																	]"
																	class="text-neutral-300"
																/>
															</span>
														</h6>
													</li>
													<template
														v-if="!states.hiddenDropdownDepartments.includes(department.id)"
													>
														<li
															v-for="member in department.people"
															:key="`${member.department_id}-${member.person_id}`"
														>
															<button
																class="dropdown-item"
																:class="{
																	'bg-success-50':
																		member.person_id === line.person_id &&
																		department.id === line.department_id,
																}"
																@click="
																	setTranscriptLineSpeaker(
																		line,
																		member.person_id,
																		department.id
																	)
																"
															>
																<span v-if="people[member.person_id]">{{
																	people[member.person_id].name
																}}</span>
																<span v-if="member.title">
																	-
																	<span class="text-primary-300">{{
																		member.title
																	}}</span></span
																>
															</button>
														</li>
													</template>
													<li :key="`${department.id}-divider`">
														<hr class="dropdown-divider bg-primary-50 my-0" />
													</li>
												</template>

												<!-- <li v-if="line.person_id">
													<span
														class="dropdown-item text-danger-300"
														role="button"
														@click="setTranscriptLineSpeaker(line, null)"
													>
														<font-awesome-icon :icon="['fas', 'times']" /> Remove assigned
														speaker
													</span>
												</li> -->

												<li class="dropdown-item-header">
													<h6
														class="dropdown-header d-flex justify-content-between pt-2 pb-1"
													>
														<span>Residents &amp; Others</span>
														<span>
															<router-link
																:to="`/${j.slug}/people`"
																class="btn-edit-department me-2"
																>Edit people</router-link
															>
														</span>
													</h6>
												</li>

												<li
													v-for="personId in meetingsSpeakers"
													:key="`tr-line-${line.id}-${personId}`"
												>
													<button
														class="dropdown-item"
														:class="{ 'bg-success-50': personId === line.person_id }"
														@click="setTranscriptLineSpeaker(line, personId)"
													>
														{{ people[personId] ? people[personId].name : personId }}
													</button>
												</li>

												<li><hr class="dropdown-divider bg-primary-100 my-0" /></li>

												<li>
													<span
														class="dropdown-item"
														role="button"
														data-bs-toggle="modal"
														data-bs-target="#modal-add-speaker"
														@click="newSpeakerForLine = line"
														>➕ Add speaker</span
													>
												</li>
											</template>
										</ul>
									</div>

									<template v-if="line.confidence">
										<span class="text-neutral-300 mx-1">&middot;</span>

										<span
											v-if="line.text_edits_count"
											class="text-success-200"
											:title="`Text confidence high (edited: ${line.text_edits_count})`"
											>✓</span
										>
										<span
											v-else-if="
												line.confidence >= 0.85 || line.text.length <= transcriptLineTextLength
											"
											class="text-success-200"
											:title="`Text confidence high (${Math.round(line.confidence * 100)}%)`"
											>✓</span
										>
										<span v-else-if="line.confidence >= 0.7" class="text-neutral-400"
											>Text confidence:
											<span
												class="text-primary-300"
												:title="`${Math.round(line.confidence * 100)}%`"
												>Good</span
											>
											<span
												class="inline-block ms-1 px-2 rounded bg-neutral-100 text-success-300 show-on-hover"
												role="button"
												@click="updateTranscriptLineText(line)"
												>Looks good</span
											>
										</span>
										<span v-else-if="line.confidence >= 0.45" class="text-neutral-400"
											>Text confidence:
											<span
												class="text-warning-300"
												:title="`${Math.round(line.confidence * 100)}%`"
												>Low</span
											>
											<span
												class="inline-block ms-1 px-2 rounded bg-neutral-100 text-success-300 show-on-hover"
												role="button"
												@click="updateTranscriptLineText(line)"
												>Looks good</span
											>
										</span>
										<span v-else class="text-neutral-400"
											>Text confidence:
											<span
												class="text-danger-300"
												:title="`${Math.round(line.confidence * 100)}%`"
												>Poor</span
											>
											<span
												class="inline-block ms-1 px-2 rounded bg-neutral-100 text-success-300 show-on-hover"
												role="button"
												@click="updateTranscriptLineText(line)"
												>Looks good</span
											>
										</span>
									</template>
								</div>

								<div class="col-auto">
									<span class="show-on-hover">
										<button class="btn-icon text-danger-200" @click="removeTranscriptLine(line)">
											<font-awesome-icon :icon="['fas', 'trash']" />
										</button>
									</span>
								</div>
							</div>

							<div
								class="transcript-line-editor"
								role="button"
								data-bs-toggle="modal"
								data-bs-target="#modal-edit-line"
								@click="states.editableLine = line"
							>
								{{ line.text }}
							</div>
						</div>

						<!-- TODO add different state for not having transcript lines and loading states -->
						<p v-if="!meeting.transcript.length" class="text-center text-neutral-400 my-4">
							Loading transcript..
						</p>
					</div>

					<!-- CHANGE SPEAKER NUMBER/PERSON_ID FOR LINE MODAL -->
					<div
						class="modal fade"
						id="modal-change-speaker-for-line"
						tabindex="-1"
						aria-hidden="true"
						data-bs-backdrop="static"
						data-bs-keyboard="false"
					>
						<div class="modal-dialog">
							<ChangeSpeakerForLineModal
								v-if="states.changeSpeakerLine"
								:options="getLineSpeakerNumbersWithPersons(meeting.transcript)"
								:line="states.changeSpeakerLine"
								@save="updateTranscriptLineSpeaker"
								@close="states.changeSpeakerLine = null"
							/>
						</div>
					</div>

					<!-- EDIT TRANSCRIPT LINE MODAL -->
					<div
						class="modal fade"
						id="modal-edit-line"
						tabindex="-1"
						aria-hidden="true"
						data-bs-backdrop="static"
						data-bs-keyboard="false"
					>
						<div class="modal-dialog">
							<EditTranscriptLineForm
								v-if="states.editableLine"
								:line="states.editableLine"
								@save="updateTranscriptLineText"
								@close="states.editableLine = null"
							/>
						</div>
					</div>
				</div>

				<div v-if="meeting.transcript.length" class="card-footer justify-content-end">
					<div class="dropdown">
						<button
							class="btn btn-sm btn-outline-primary dropdown-toggle"
							type="button"
							id="download-minutes"
							data-bs-toggle="dropdown"
							aria-expanded="false"
						>
							<font-awesome-icon :icon="['fas', 'file-export']" class="me-1" /> Download transcript
						</button>
						<ul class="dropdown-menu" aria-labelledby="download-minutes">
							<li>
								<a
									class="dropdown-item"
									:href="
										`${apiUrl + j.slug}/meetings/${meeting.pid}/transcript/export/csv?person_id=${
											auth.id
										}`
									"
									><font-awesome-icon :icon="['fas', 'file']" class="me-1" /> CSV</a
								>
							</li>
							<li>
								<a
									class="dropdown-item"
									:href="
										`${apiUrl + j.slug}/meetings/${meeting.pid}/transcript/export/txt?person_id=${
											auth.id
										}`
									"
									><font-awesome-icon :icon="['fas', 'file']" class="me-1" /> Text</a
								>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>

		<div v-else class="card">
			<div class="card-body">
				<pre>{{ meeting.transcript_job_status }}</pre>
			</div>
		</div>

		<div class="modal fade" id="modal-add-speaker" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title my-0">Add speaker</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<form @submit.prevent="createSpeakerAccount">
						<div class="modal-body">
							<div class="form-row mb-3">
								<label class="form-label" for="speaker-first-name">
									Speaker name <small class="text-danger-400">*</small>
								</label>
								<div class="row">
									<div class="col-6">
										<input
											type="text"
											class="form-control"
											id="speaker-first-name"
											v-model="newSpeaker.first_name"
											required
											placeholder="First name"
										/>
									</div>
									<div class="col-6">
										<input
											type="text"
											class="form-control"
											id="speaker-last-name"
											v-model="newSpeaker.last_name"
											required
											placeholder="Last name"
										/>
									</div>
								</div>
							</div>

							<div class="row">
								<div class="col-6 form-row mb-3">
									<label class="form-label" for="speaker-title">
										Title
									</label>
									<input
										type="text"
										class="form-control form-control-sm"
										id="speaker-title"
										v-model="newSpeaker.title"
										placeholder="Example: Mayor"
									/>
								</div>

								<div class="col-6 form-row mb-3">
									<label class="form-label" for="speaker-department">
										Board / Department
										<small v-if="newSpeaker.title" class="text-danger-400">*</small>
									</label>

									<select
										class="form-select form-select-sm"
										id="speaker-department"
										v-model="newSpeaker.department_id"
										:required="!!newSpeaker.title"
									>
										<option :value="null"></option>
										<option
											v-for="department in activeDepartments"
											:key="department.id"
											:value="department.id"
										>
											{{ department.name }}
										</option>
									</select>
								</div>
							</div>

							<p v-if="newSpeaker.department_id" class="mb-0">
								A new member will be added to
								<strong>{{ departments.find(d => d.id === newSpeaker.department_id).name }}</strong> and
								then assigned to all transcript lines for
								<code>Speaker {{ newSpeakerForLine.speaker + 1 }}</code
								>.
							</p>
							<p v-else-if="newSpeakerForLine" class="mb-0">
								A new profile will be created and assigned to all transcript lines for
								<code>Speaker {{ newSpeakerForLine.speaker + 1 }}</code
								>.
							</p>
						</div>
						<div class="modal-footer">
							<button class="btn btn-primary" :disabled="states.new_speaker === 'loading'">
								Add speaker
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
@import '@/assets/variables';

.btn-icon {
	border: 0;
	padding: 0;
	margin: 0;
	background-color: transparent;
}

.transcript-line {
	.line-speaker {
		border-radius: 10px;
		padding: 2px 6px;

		&.line-speaker-assigned {
			padding: 2px 6px 2px 2px;
		}

		&:hover {
			background-color: $primary-50;
		}
	}

	.transcript-line-editor {
		cursor: pointer;
		border-radius: 0.5rem;
		padding: 0.5rem;
		background-color: $neutral-50;

		&:hover {
			background-color: $neutral-100;
		}
	}

	textarea {
		background-color: $neutral-100;
		padding: 0.5rem;
		border-radius: 0.5rem;
		resize: none;

		&:focus-visible {
			outline: 0;
		}
	}

	&.transcript-line-current {
		.transcript-line-editor {
			background-color: $primary-50;
		}
	}

	.transcript-edit-actions {
		border-radius: 0 0 0.5rem 0.5rem;
	}

	.dropdown-speakers {
		min-width: 420px;

		.btn-edit-department {
			font-size: 0.8rem;
			color: $neutral-400;
			display: none;
		}

		.dropdown-item-header:hover {
			svg {
				background-color: $neutral-50;
			}

			.btn-edit-department {
				display: inline-block;
			}
		}
	}
}
</style>

<script>
import { Modal } from 'bootstrap'
import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'
import { uniq, uniqBy } from 'lodash-es'
import { formatDuration } from 'date-fns'
import pluralize from 'pluralize'

import heyGovApi, { hgApi } from '@/api.js'
import { handleResponseError, getPublicFileUrl } from '@/utils.js'
import { timestampToMinutes } from '@/lib/strings'

//import PersonLink from '@/components/PersonLink.vue'
import EditTranscriptLineForm from './EditTranscriptLineForm/EditTranscriptLineForm.vue'
import ChangeSpeakerForLineModal from './ChangeSpeakerForLineModal/ChangeSpeakerForLineModal.vue'

export default {
	name: 'MeetingTranscript',
	components: { EditTranscriptLineForm, ChangeSpeakerForLineModal },
	metaInfo() {
		return {
			title: `Transcript for ${this.meeting?.title || this.$route.params.meetingId} - Meetings`,
		}
	},
	props: {
		meeting: {
			type: Object,
			required: true,
		},
		meetingPlayer: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			states: {
				speakers: 'loading',
				new_speaker: 'idle',
				editableLine: null,
				changeSpeakerLine: null,
				hiddenDropdownDepartments: JSON.parse(localStorage.getItem(`hg-transcript-hidden-departments`) || '[]'),
				openSpeakerDropdown: null,
				transcriptReviewMessage: false,
			},
			transcriptionStatusTimer: null,

			// transcript length over which to calculate confidence
			transcriptLineTextLength: 32,

			meetingsSpeakers: [],

			$modalAddSpeaker: null,
			newSpeaker: this.getEmptySpeaker(),
			newSpeakerForLine: null,
		}
	},

	computed: {
		...mapGetters(['auth', 'isStaff', 'activeDepartments']),
		...mapState(['apiUrl', 'departments', 'j', 'people']),
		transcriptLinesLowConfidence() {
			return this.meeting.transcript.filter(
				line =>
					line.confidence !== null &&
					line.confidence < 0.7 &&
					line.text.length > this.transcriptLineTextLength &&
					line.text_edits_count === 0
			)
		},
		transcriptLinesGoodConfidence() {
			return this.meeting.transcript.filter(
				line =>
					line.confidence !== null &&
					line.confidence >= 0.7 &&
					line.confidence < 0.85 &&
					line.text.length > this.transcriptLineTextLength &&
					line.text_edits_count === 0
			)
		},
		unidentifiedSpeakers() {
			return uniq(this.meeting.transcript.filter(l => !l.person_id).map(l => l.speaker))
		},
	},

	created() {
		this.$store.dispatch('loadDepartments').then(departments => {
			const departmentMembers = departments.map(d => d.people).flat()
			this.$store.dispatch(
				'loadPeople',
				departmentMembers.map(dm => dm.person_id)
			)
		})

		this.loadJurisdictionSpeakers()

		if (this.meeting.transcript_job_status === 'transcribed') {
			this.$emit('loadTranscript')
		} else if (['started', 'uploading'].includes(this.meeting.transcript_job_status)) {
			this.transcriptionStatusTimer = setInterval(() => {
				this.checkTranscriptJobStatus()
			}, 10000)
		}
	},

	mounted() {
		this.$modalAddSpeaker = new Modal(document.getElementById('modal-add-speaker'))
		this.$modalEditLine = new Modal(document.getElementById('modal-edit-line'))
		this.$modalChangeSpeaker = new Modal(document.getElementById('modal-change-speaker-for-line'))
	},
	methods: {
		getPublicFileUrl,
		timestampToMinutes,
		uniq,
		formatDuration,
		pluralize,

		getLineSpeakerNumbersWithPersons(transcriptLines) {
			return uniqBy(
				transcriptLines.map(line => ({
					number: line.speaker,
					person: this.people[line.person_id],
				})),
				'number'
			)
		},

		emitPlaybackToSpeaker(speaker) {
			const firstLine = this.meeting.transcript.find(l => l.speaker === speaker.speaker)
			if (!firstLine || !firstLine.timestamp) {
				alert('timestamp not found for speaker')
				return
			}
			this.$emit('playerTimestamp', Math.round(firstLine.timestamp, 10))
		},

		async loadJurisdictionSpeakers() {
			const resp = await hgApi(`${this.j.slug}/meetings/speakers`)
			const people = await resp.json()

			people.forEach(personId => {
				if (!this.meetingsSpeakers.includes(personId)) {
					this.meetingsSpeakers.push(personId)
				}
			})

			this.$store.dispatch('loadPeople', people)
		},

		checkTranscriptJobStatus() {
			console.log(this.meeting.pid, 'checking transcript job status')

			heyGovApi(`${this.j.slug}/meetings/${this.meeting.pid}`)
				.then(({ data }) => {
					if (data.transcript_job_status === 'transcribed') {
						this.$emit('loadTranscript')

						clearInterval(this.transcriptionStatusTimer)
						Vue.toasted.success('Meeting transcript is ready')
					}
				})
				.catch(handleResponseError(`Couldn't check transcript status ({error})`))
		},

		// transcriptAssignSpeakers assigns person id to speaker number
		async transcriptAssignSpeakers(speakers) {
			// update person_id and department_id in database transcript lines
			try {
				const response = await hgApi(`${this.j.slug}/meetings/${this.meeting.pid}/transcript/assign-speakers`, {
					body: speakers,
				})

				if (response.ok) {
					if (speakers[0].person_id) {
						Vue.toasted.info(`${pluralize('Speaker', speakers.length)} assigned to transcript`)
					} else {
						Vue.toasted.show('Speaker removed from transcript')
					}
				} else {
					const { message } = await response.json()
					throw new Error(message || 'Server error')
				}
			} catch (error) {
				Vue.toasted.error(`Error assigning speakers to transcript (${error.message})`)
			}

			// update person_id and department_id in transcript lines, for instant UI change
			this.meeting.transcript.forEach(line => {
				const speaker = speakers.find(s => s.speaker === line.speaker)

				if (speaker) {
					line.person_id = speaker.person_id
					line.department_id = speaker.department_id
				}
			})
		},

		setTranscriptLineSpeaker(line, personId, departmentId = null) {
			if (line.person_id === personId && line.department_id === departmentId) {
				this.transcriptAssignSpeakers([{ speaker: line.speaker, person_id: null, department_id: null }])
			} else {
				this.transcriptAssignSpeakers([
					{ speaker: line.speaker, person_id: personId, department_id: departmentId },
				])
			}
		},

		async createSpeakerAccount() {
			this.states.new_speaker = 'loading'

			const errHandler = handleResponseError(`Couldn't add speaker ({error})`)

			if (this.newSpeaker.department_id) {
				try {
					const { data } = await heyGovApi.post(`${this.j.slug}/departments/people`, {
						...this.newSpeaker,
						role: 'WORKER',
					})

					// add member to department
					this.$store.dispatch('loadPerson', data.person_id)
					this.departments.forEach(department => {
						if (department.id === data.department_id) {
							department.people.push({
								department_id: data.department_id,
								person_id: data.person_id,
								title: data.title,
								role: 'WORKER',
							})
						}
					})

					// assign member to transcript line
					this.transcriptAssignSpeakers([
						{
							speaker: this.newSpeakerForLine.speaker,
							person_id: data.person_id,
							department_id: this.newSpeaker.department_id,
						},
					])

					this.states.new_speaker = 'idle'
					this.$modalAddSpeaker.hide()
				} catch (error) {
					errHandler(error)
				}
			} else {
				try {
					const { data } = await heyGovApi.post(`${this.j.slug}/people`, {
						first_name: this.newSpeaker.first_name.trim(),
						last_name: this.newSpeaker.last_name.trim(),
						email: this.newSpeaker.email,
						// we don't set title for person without department
					})

					// add person to list of non-dept speakers
					this.$store.dispatch('loadPerson', data.id)
					this.meetingsSpeakers.push(data.id)

					// assign person to transcript line
					this.transcriptAssignSpeakers([
						{
							speaker: this.newSpeakerForLine.speaker,
							person_id: data.id,
							department_id: null,
						},
					])

					this.states.new_speaker = 'idle'
					this.$modalAddSpeaker.hide()
				} catch (error) {
					errHandler(error)
				}
			}

			this.newSpeaker = this.getEmptySpeaker()
		},

		getEmptySpeaker() {
			return {
				first_name: '',
				last_name: '',
				title: '',
				department_id: null,
			}
		},

		updateTranscriptLineText(updatedLine) {
			const text = updatedLine.text.trim()

			if (text.length) {
				heyGovApi
					.put(`${this.j.slug}/meetings/${this.meeting.pid}/transcript/${updatedLine.id}`, { text })
					.then(() => {
						updatedLine.text = text
					}, handleResponseError("Couldn't save transcript line ({error})"))
			} else {
				alert(`Can't be empty, maybe delete the line?`)
			}

			// @ts-ignore
			const existingLine = this.meeting.transcript.find(line => line.id === updatedLine.id)
			if (existingLine) {
				// @ts-ignore
				existingLine.text = text
				existingLine.text_edits_count++
			}

			this.states.editableLine = null
			this.states.transcriptReviewMessage = false
		},

		async updateTranscriptLineSpeaker({ line, number, person }) {
			try {
				await heyGovApi.put(`${this.j.slug}/meetings/${this.meeting.pid}/transcript/${line.id}`, {
					speaker: number,
					person_id: person?.id || null,
				})

				line.speaker = number
				line.person_id = person?.id
			} catch (error) {
				handleResponseError("Couldn't save transcript line ({error})")(error)
			} finally {
				this.states.changeSpeakerLine = null
			}
		},

		removeTranscriptLine(transcriptLine) {
			if (confirm('Are you sure you want to delete this transcript line?')) {
				heyGovApi
					.delete(`${this.j.slug}/meetings/${this.meeting.pid}/transcript/${transcriptLine.id}`)
					.then(() => {
						this.meeting.transcript = this.meeting.transcript.filter(line => line.id !== transcriptLine.id)
					}, handleResponseError("Couldn't delete transcript line ({error})"))
			}
		},

		openSpeakerDropdown($event, transcriptLine) {
			this.states.openSpeakerDropdown = transcriptLine.id
		},
		toggleDepartment($event, departmentId) {
			$event.stopPropagation()
			$event.preventDefault()

			if (this.states.hiddenDropdownDepartments.includes(departmentId)) {
				this.states.hiddenDropdownDepartments = this.states.hiddenDropdownDepartments.filter(
					id => id !== departmentId
				)
			} else {
				this.states.hiddenDropdownDepartments.push(departmentId)
			}

			localStorage.setItem(
				`hg-transcript-hidden-departments`,
				JSON.stringify(this.states.hiddenDropdownDepartments)
			)
		},

		urlPart(url, part) {
			return URL.parse(url)[part] || ''
		},
	},
	beforeDestroy() {
		clearInterval(this.transcriptionStatusTimer)
	},
}
</script>
