<template>
	<div class="page-jurisdiction-settings">
		<h2>Your {{ j.type || 'municipality' }}</h2>

		<div class="card mb-5">
			<div class="card-body">
				<form @submit.prevent="saveJurisdictionData">
					<div class="row mb-3">
						<div class="col-5">
							<div class="form-group mb-3">
								<label class="form-label" for="town-name">{{ j.type || 'municipality' }} name</label>
								<input
									type="text"
									class="form-control form-control-sm"
									id="town-name"
									required
									v-model="j.name"
								/>
							</div>

							<div class="form-group mb-3">
								<label class="form-label" for="town-website">Website</label>
								<input
									type="url"
									class="form-control form-control-sm"
									id="town-website"
									required
									v-model="j.website"
								/>
							</div>

							<div class="form-group mb-3">
								<label class="form-label" for="town-email">{{ j.type || 'municipality' }} email</label>
								<input
									type="email"
									class="form-control form-control-sm"
									id="town-email"
									required
									v-model="j.email"
								/>
							</div>

							<div class="row">
								<div class="form-group col-6 mb-3">
									<label class="form-label" for="population"
										>{{ j.type || 'municipality' }} Population</label
									>
									<input
										type="number"
										class="form-control form-control-sm"
										id="population"
										v-model="j.population_count"
										placeholder="Population count"
									/>
								</div>
							</div>
						</div>

						<div class="col-7">
							<div class="form-group mb-3">
								<label class="form-label" for="jurisdiction-location"
									>{{ j.type || 'municipality' }} location on map</label
								>

								<gmap-autocomplete
									:select-first-on-enter="true"
									:options="autocompleteOptions"
									@place_changed="setLocation"
								>
									<template v-slot:default="slotProps">
										<input
											id="jurisdiction-location"
											ref="input"
											:value="j.location.city"
											:placeholder="`Search by ${j.type} name`"
											class="form-control form-control-sm"
											v-on="slotProps.listeners"
										/>
									</template>
								</gmap-autocomplete>
							</div>

							<p class="mb-1">
								📍
								<strong>{{ j.location.city }}, {{ j.location.county }}, {{ j.location.state }}</strong>
								<small class="ms-1 text-neutral-400"
									>({{ j.location.lat }}, {{ j.location.lng }})</small
								>
							</p>

							<muni-map class="mb-3" :height="350" @zoom_changed="updateLocationZoom"></muni-map>
						</div>
					</div>

					<p class="card-text text-center">
						<button class="btn btn-primary px-5" :disabled="states.jurisdiction === 'saving'">
							Save information
						</button>
					</p>
				</form>
			</div>
		</div>

		<template v-if="isStaff && j.features_options.virtual_assistant">
			<h5>Virtual Clerk</h5>

			<div class="card mb-5">
				<div class="card-body">
					<p>
						Personalize the
						<router-link :to="`/${j.slug}/virtual-assistant`">Virtual Clerk</router-link> for your
						{{ j.type }}.
					</p>

					<div class="row">
						<div class="col-6">
							<div class="form-group">
								<label class="form-label" for="ai-chat-name">Virtual Clerk Name</label>
								<input
									type="text"
									class="form-control form-control-sm"
									id="ai-chat-name"
									v-model="j.features_options.virtual_assistant.name"
									placeholder="Landon, Clerky, Siri, ..."
								/>
								<span class="form-text">(Optional) Add a custom name for your Virtual Clerk</span>
							</div>
						</div>
					</div>

					<hr class="my-4 bg-primary-100" />

					<h5>Channels</h5>
					<p class="mb-2">Choose over which channels residents can interact with the Virtual Clerk:</p>

					<div class="row row-cols row-cols-3 mb-3">
						<div class="col">
							<div class="bg-success-50 rounded-1 p-3 h-100">
								<div class="form-group mb-2">
									<div class="form-check form-switch">
										<input
											class="form-check-input"
											type="checkbox"
											role="switch"
											id="ai-chat-channel-heygov"
											checked
										/>
										<label class="form-check-label fw-bold" for="ai-chat-channel-heygov"
											>HeyGov Chat</label
										>
									</div>
								</div>

								<p class="mb-0">Enable the chat page in HeyGov apps.</p>
							</div>
						</div>

						<!-- <div class="col">
							<div class="bg-neutral-100 rounded-1 p-3 h-100">
								<div class="form-group mb-2">
									<div class="form-check form-switch">
										<input
											class="form-check-input"
											type="checkbox"
											role="switch"
											id="ai-chat-channel-widget"
											disabled
										/>
										<label class="form-check-label fw-bold" for="ai-chat-channel-widget"
											>Chat on {{ j.type }} website</label
										>
									</div>
								</div>

								<p class="mb-0">Add a chat bubble dialog on your website.</p>
							</div>
						</div> -->

						<div class="col">
							<div class="bg-neutral-100 rounded-1 p-3 h-100">
								<div class="form-group mb-2">
									<div class="form-check form-switch">
										<input
											class="form-check-input"
											type="checkbox"
											role="switch"
											id="ai-chat-channel-sms"
											disabled
										/>
										<label class="form-check-label fw-bold" for="ai-chat-channel-sms">Text</label>
									</div>
								</div>

								<p class="mb-0">Link a phone number to the assistant.</p>
							</div>
						</div>

						<div class="col">
							<div class="bg-success-50 rounded-1 p-3 h-100">
								<div class="form-group mb-2">
									<div class="form-check form-switch">
										<input
											class="form-check-input"
											type="checkbox"
											role="switch"
											id="ai-chat-channel-email"
											checked
										/>
										<label class="form-check-label fw-bold" for="ai-chat-channel-email"
											>Email</label
										>
									</div>
								</div>

								<p class="mb-0">
									Email the assistant at
									<code v-if="env !== 'production'">{{ j.slug }}@email-{{ env }}.heygov.com</code>
									<code v-else>{{ j.slug }}@email.heygov.com</code>
								</p>
							</div>
						</div>
					</div>

					<hr class="my-4 bg-primary-100" />

					<h5>Data sources</h5>
					<p>Choose where the Virtual Clerk gets data from:</p>

					<div class="row">
						<div class="col">
							<div class="form-group mb-3">
								<label class="form-label" for="ai-chat-data-website">Website content</label>

								<select
									class="form-select form-select-sm"
									id="ai-chat-data-website"
									v-model="j.features_options.virtual_assistant.sources.website"
								>
									<option :value="false">-</option>
									<option value="juniper">Juniper CMS (pages, files)</option>
									<option value="wordpress" disabled>WordPress</option>
									<option value="scraper" disabled>Website scrape</option>
								</select>
							</div>

							<div class="form-group">
								<label class="form-label" for="ai-chat-data-directory">Staff directory</label>

								<select
									class="form-select form-select-sm"
									id="ai-chat-data-directory"
									v-model="j.features_options.virtual_assistant.sources.directory"
								>
									<option :value="false">-</option>
									<option value="heygov">HeyGov Departments &amp; Members</option>
									<option value="town-web">Town Web Directory</option>
								</select>
							</div>
						</div>

						<div class="col-7">
							<div class="bg-neutral-50 border border-dashed rounded-1 text-center py-6">
								Add targeted data for special topics here.
							</div>
						</div>
					</div>

					<hr class="my-4 bg-primary-100" />

					<h5 class="mb-2">Capabilities</h5>

					<div class="row row-cols row-cols-3">
						<div class="col mb-4">
							<div
								class="rounded-1 p-3 h-100"
								:class="
									j.features_options.virtual_assistant.capabilities.includes('weather')
										? 'bg-success-50'
										: 'bg-neutral-100'
								"
							>
								<div class="form-group mb-2">
									<div class="form-check form-switch">
										<input
											class="form-check-input"
											type="checkbox"
											role="switch"
											id="ai-chat-capability-weather"
											value="weather"
											v-model="j.features_options.virtual_assistant.capabilities"
										/>
										<label class="form-check-label fw-bold" for="ai-chat-capability-weather"
											>Weather</label
										>
									</div>
								</div>

								<p class="mb-2">Offer real-time accurate weather forecast.</p>

								<p class="mb-1 text-neutral-400">Examples:</p>

								<ul class="nicer-list mb-0">
									<li>"Will it rain tomorrow"</li>
									<li>"what's the forecast for today"</li>
								</ul>
							</div>
						</div>

						<div class="col mb-4">
							<div
								class="rounded-1 p-3 h-100"
								:class="
									j.features_options.virtual_assistant.capabilities.includes('311-create')
										? 'bg-success-50'
										: 'bg-neutral-100'
								"
							>
								<div class="form-group mb-2">
									<div class="form-check form-switch">
										<input
											class="form-check-input"
											type="checkbox"
											role="switch"
											id="ai-chat-capability-311-create"
											value="311-create"
											v-model="j.features_options.virtual_assistant.capabilities"
										/>
										<label class="form-check-label fw-bold" for="ai-chat-capability-311-create"
											>311 requests: create</label
										>
									</div>
								</div>

								<p class="mb-2">
									Allow residents to submit 311 requests through chat, email or text.
								</p>

								<p class="mb-1 text-neutral-400">Examples:</p>

								<ul class="nicer-list mb-0">
									<li>"I saw a pothole in front of the library"</li>
									<li>"Please do something about the fallen tree on .."</li>
								</ul>
							</div>
						</div>

						<div class="col mb-4">
							<div
								class="rounded-1 p-3 h-100"
								:class="
									j.features_options.virtual_assistant.capabilities.includes('311-lookup')
										? 'bg-success-50'
										: 'bg-neutral-100'
								"
							>
								<div class="form-group mb-2">
									<div class="form-check form-switch">
										<input
											class="form-check-input"
											type="checkbox"
											role="switch"
											id="ai-chat-capability-311-lookup"
											value="311-lookup"
											v-model="j.features_options.virtual_assistant.capabilities"
										/>
										<label class="form-check-label fw-bold" for="ai-chat-capability-311-lookup"
											>311 requests: lookup</label
										>
									</div>
								</div>

								<p class="mb-2">
									Allow residents to check the status or progress of their 311 requests.
								</p>
								<p class="mb-1 text-neutral-400">Examples:</p>

								<ul class="nicer-list mb-0">
									<li>"What's the status of my ticket TREE-123?"</li>
									<li>"Is the pothole fixed?"</li>
								</ul>
							</div>
						</div>

						<div class="col mb-4">
							<div class="bg-danger-50 rounded-1 p-3 h-100">
								<div class="form-group mb-2">
									<div class="form-check form-switch">
										<input
											class="form-check-input"
											type="checkbox"
											role="switch"
											id="ai-chat-capability-311-lookup"
											disabled
										/>
										<label class="form-check-label fw-bold" for="ai-chat-capability-311-lookup"
											>Billing accounts</label
										>
									</div>
								</div>

								<p class="mb-2">
									<span class="badge bg-danger-100 text-danger-400">🔒 Requires HeyGov Pay</span>
									Offer bill info and account balance through the chat assistant.
								</p>
								<p class="mb-1 text-neutral-400">Examples:</p>

								<ul class="nicer-list mb-0">
									<li>"What's my last bill"</li>
									<li>"How much do I need to pay for utilities this month"</li>
								</ul>
							</div>
						</div>

						<div class="col mb-4">
							<div class="bg-danger-50 rounded-1 p-3 h-100">
								<div class="form-group mb-2">
									<div class="form-check form-switch">
										<input
											class="form-check-input"
											type="checkbox"
											role="switch"
											id="ai-chat-capability-venues"
											disabled
										/>
										<label class="form-check-label fw-bold" for="ai-chat-capability-venues"
											>Venues availability</label
										>
									</div>
								</div>

								<p class="mb-2">
									<span class="badge bg-danger-100 text-danger-400">🔒 Requires HeyReserve</span>
									Residents can check availability and book venues.
								</p>
								<p class="mb-1 text-neutral-400">Examples:</p>

								<ul class="nicer-list mb-0">
									<li>"Is there any pavilion available to rent in the main park next week?"</li>
									<li>"I need to park my boat, how do I get a boat slip"</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>

			<pre>{{ j.features_options.virtual_assistant }}</pre>
		</template>

		<div class="row mb-5">
			<div class="col-lg-11 col-xl-10">
				<div class="mb-3">
					<button
						class="btn btn-sm btn-outline-primary float-end"
						data-bs-toggle="modal"
						data-bs-target="#service-edit"
						@click="serviceSelected = serviceNew"
					>
						Add category
					</button>
					<h5>311 categories</h5>
				</div>

				<div class="card">
					<div class="card-body">
						<p>Categories for incoming 311 requests.</p>

						<div class="card-table">
							<table class="table table-hover">
								<thead>
									<tr>
										<th>Category</th>
										<th>Group</th>
										<th>Keywords</th>
										<th></th>
									</tr>
								</thead>
								<tbody v-if="services">
									<tr
										v-for="service in services.filter(s => s.status !== 'deleted')"
										:key="service.id"
										class="on-parent"
									>
										<td>{{ service.name }}</td>
										<td>{{ service.group }}</td>
										<td>{{ service.keywords }}</td>
										<td>
											<button
												class="btn btn-sm btn-outline-primary float-end show-on-hover"
												data-bs-toggle="modal"
												data-bs-target="#service-edit"
												@click="serviceSelected = service"
											>
												Edit
											</button>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>

		<h5>Integrations</h5>

		<div class="row row-cols-1 row-cols-md-2 mb-5">
			<div class="col my-2">
				<div class="card h-100">
					<div class="card-body">
						<h5>✉️ Email</h5>
						<p>Receive 311 requests from any citizen by email.</p>
						<p>Forward your public {{ j.type }} email to:</p>
						<ul class="list-group">
							<li class="list-group-item">
								<code>{{ j.slug }}@email.heygov.com</code>
							</li>
						</ul>
					</div>
				</div>
			</div>

			<div class="col my-2">
				<div class="card h-100">
					<div class="card-body">
						<h5>
							<img
								src="https://files.heygov.com/assets/heygov-logo-mark-180.png"
								height="20"
								alt="HeyGov widget"
							/>
							Site widget or embed
						</h5>
						<p class="mb-2">Citizens can send Issues through an easy-to-use widget on your website.</p>

						<p class="mb-1">
							<span v-if="widgetConnected">✅ Widget connected</span
							><span v-else class="text-muted">Widget not connected</span>
						</p>
					</div>
					<div class="card-footer">
						<p class="mb-0 text-end">
							<button
								class="btn btn-sm btn-outline-primary"
								data-bs-toggle="modal"
								data-bs-target="#widget-embed-code"
							>
								Integrate widget
							</button>
						</p>
					</div>
				</div>
			</div>

			<div class="col my-2">
				<div class="card h-100">
					<div class="card-body">
						<h5>
							<img src="/images/icon-messenger.svg" height="20" alt="HeyGov widget" /> Facebook Messenger
						</h5>
						<p class="mb-1">Connect your Facebook Messenger and handle inquiries through the HeyGov app.</p>
					</div>
					<div class="card-footer">
						<p class="mb-0 text-end text-muted">
							(coming soon)
							<button class="btn btn-sm btn-outline-dark" disabled title="Coming soon">
								Connect with Facebook
							</button>
						</p>
					</div>
				</div>
			</div>

			<div class="col my-2">
				<div class="card h-100">
					<div class="card-body">
						<h5>📞 Phone number</h5>
						<p class="mb-1">
							Forward your 3-1-1 number (or get a new number) to handle inquiries through the HeyGov app.
						</p>
					</div>
					<div class="card-footer">
						<p class="mb-0 text-end text-muted">
							(coming soon)
							<button class="btn btn-sm btn-outline-dark" disabled title="Coming soon">
								Start phone integration
							</button>
						</p>
					</div>
				</div>
			</div>

			<!-- <div class="col my-2">
				<div class="card h-100">
					<div class="card-body">
						<h5>
							<img src="https://www.diamondmaps.com/favicon.ico" height="20" alt="HeyGov widget" />
							Diamond Maps
						</h5>
						<p class="mb-1">
							<a href="https://www.diamondmaps.com/" target="_blank">Diamond Maps</a> is a simple
							cloud-based GIS/Mapping application designed to help municipalies and rural utilities map
							their sewer and water infrastructure and track maintenance.
						</p>
					</div>
					<div class="card-footer">
						<p class="mb-0 text-end text-muted">
							(coming soon)
							<button class="btn btn-sm btn-outline-dark" disabled title="Coming soon">
								Connect Diamond Maps
							</button>
						</p>
					</div>
				</div>
			</div> -->
		</div>

		<div class="card">
			<div class="card-header">
				<h5 class="my-0">API Keys</h5>
			</div>
			<div class="card-body">
				<p>API Keys give access to third-party services to read and write data in HeyGov.</p>

				<div class="card-table">
					<table class="table table-hover">
						<thead>
							<tr>
								<th>Name</th>
								<th>Key</th>
								<th>Owner</th>
								<th>Permissions</th>
								<th>Created</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="apiKey in apiKeys" :key="apiKey.id" class="on-parent">
								<td>{{ apiKey.name }}</td>
								<td>
									<code>{{ apiKey.key }}</code>
								</td>
								<td>
									<person-link :id="apiKey.person_id" :avatar="16"></person-link>
								</td>
								<td>
									<span
										v-for="permission in apiKey.permissions"
										:key="permission"
										class="badge bg-neutral-50 text-primary-400 me-1 mb-1"
										>{{ permission }}</span
									>
								</td>
								<td>{{ apiKey.created_at | dateLocal }}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>

		<div class="modal fade" id="service-edit" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<div v-if="serviceSelected" class="modal-content">
					<form @submit.prevent="saveService">
						<div class="modal-header">
							<h5 class="modal-title">{{ serviceSelected.id ? 'Edit category' : 'Add category' }}</h5>
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body">
							<div class="form-group">
								<label for="service-name">Category name</label>
								<input
									type="text"
									class="form-control"
									id="service-name"
									required
									v-model="serviceSelected.name"
								/>
							</div>
							<div class="form-group">
								<label for="service-group">Group</label>
								<input
									type="text"
									class="form-control"
									id="service-group"
									list="service-groups"
									required
									v-model="serviceSelected.group"
								/>

								<datalist id="service-groups">
									<option
										v-for="(servicesList, group) in servicesByGroup"
										:key="group"
										:value="group"
									/>
								</datalist>
							</div>
							<div class="form-group">
								<label for="service-keywords">Keywords</label>
								<input
									type="text"
									class="form-control"
									id="service-keywords"
									v-model="serviceSelected.keywords"
									aria-describedby="serviceKeywordsHelp"
								/>
								<small id="serviceKeywordsHelp" class="form-text text-muted"
									>Improves category search &amp; auto assign Issues into categories. Separate
									keywords by comma <code>,</code></small
								>
							</div>
						</div>
						<div class="modal-footer justify-content-between">
							<button v-if="serviceSelected.id" class="btn text-danger" @click.prevent="deleteService">
								Delete
							</button>
							<button class="btn btn-primary">
								{{ serviceSelected.id ? 'Save' : 'Create category' }}
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>

		<div class="modal fade" id="widget-embed-code" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog modal-lg">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">HeyGov widget</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						<p class="mb-2">HeyGov widget can be added in 2 forms on your website. Which one you prefer?</p>
						<div class="mb-3">
							<div class="form-check">
								<input
									type="radio"
									id="customRadioInline1"
									name="widget-type"
									v-model="widgetType"
									value="widget"
									class="form-check-input"
								/>
								<label class="form-check-label" for="customRadioInline1"
									>Popup that opens on a button click</label
								>
							</div>
							<div class="form-check">
								<input
									type="radio"
									id="customRadioInline2"
									name="widget-type"
									v-model="widgetType"
									value="embed"
									class="form-check-input"
								/>
								<label class="form-check-label" for="customRadioInline2"
									>Widget that always loads on page (ex: on Contact page)</label
								>
							</div>
						</div>

						<div v-if="widgetType === 'widget'">
							<p class="mb-2">Where should the button that opens the popup be placed?</p>
							<div class="mb-3">
								<div class="form-check">
									<input
										type="radio"
										id="customRadioInline3"
										name="widget-button-type"
										v-model="widgetButtonType"
										value="bottom-right"
										class="form-check-input"
									/>
									<label class="form-check-label" for="customRadioInline3">On bottom right</label>
								</div>
								<div class="form-check">
									<input
										type="radio"
										id="customRadioInline4"
										name="widget-button-type"
										v-model="widgetButtonType"
										value="middle-right"
										class="form-check-input"
									/>
									<label class="form-check-label" for="customRadioInline4">On middle right</label>
								</div>
								<div class="form-check">
									<input
										type="radio"
										id="customRadioInline5"
										name="widget-button-type"
										v-model="widgetButtonType"
										value="none"
										class="form-check-input"
									/>
									<label class="form-check-label" for="customRadioInline5">No button</label>
								</div>
							</div>
						</div>

						<h5>Code instructions</h5>
						<p class="mb-1">
							Place this line of code in <code>&lt;head&gt;</code> or at end of
							<code>&lt;body&gt;</code> HTML section in your website:
						</p>
						<textarea
							class="form-control form-control-code mb-3"
							v-model="widgetEmbedCode"
							readonly
							cols="3"
						></textarea>

						<div v-if="widgetType !== 'widget'">
							<p class="mb-1">
								Place this line of code in the page/section where you want the HeyGov widget to be
								embedded:
							</p>
							<textarea class="form-control form-control-code mb-3" readonly cols="1">
&lt;div class="heygov-embed"&gt;&lt;/div&gt;</textarea
							>
						</div>

						<h5>Need help?</h5>
						<p>
							If you need help with adding the widget on your website, please contact
							<a href="mailto:support@heygov.com" class="text-highlight">support@heygov.com</a>
						</p>
						<p>
							For more technical info and widget JavaScript API, please read the
							<a href="https://heygov.com/developers/widget" target="_blank"
								>Widget developer documentation</a
							>.
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'
import { Modal } from 'bootstrap'

import heyGovApi from '@/api.js'
import { handleResponseError } from '@/utils.js'

import MuniMap from '@/components/MuniMap.vue'
import PersonLink from '@/components/PersonLink.vue'

export default {
	name: 'JurisdictionSettings',
	components: { MuniMap, PersonLink },
	data() {
		return {
			state: 'idle',
			states: {
				jurisdiction: 'idle',
			},
			threads: [],
			people: [],
			serviceSelected: null,
			serviceNew: {
				name: null,
				keywords: null,
				group: null,
			},
			widgetType: 'widget',
			widgetButtonType: 'bottom-right',
			widgetConnected: false,
			widgetCheckTimer: null,
			$modalService: null,

			apiKeys: [],

			autocompleteOptions: {
				componentRestrictions: { country: 'us' },
				fields: ['address_components', 'geometry', 'place_id'],
				types: ['(cities)'],
			},
		}
	},
	created() {
		if (this.currentRole === 'ADMIN') {
			this.$store.dispatch('loadServices')

			this.loadApiKeys()
			this.widgetCheck()

			this.widgetCheckTimer = setInterval(() => {
				this.widgetCheck()
			}, 10000)
		} else {
			this.$router.push(`/${this.j.slug}`)
		}
	},
	mounted() {
		this.$modalService = new Modal(document.getElementById('service-edit'))
	},
	computed: {
		...mapState(['env', 'j', 'services']),
		...mapGetters(['currentRole', 'servicesByGroup', 'isStaff']),
		widgetEmbedCode() {
			const button =
				this.widgetType === 'widget' && this.widgetButtonType !== 'none'
					? ` data-heygov-button-style="${this.widgetButtonType}"`
					: ''
			return `<script src="https://edge.heygov.com/heygov.js" data-heygov-jurisdiction="${
				this.j.slug
			}"${button}><${'/'}script>`
		},
	},
	methods: {
		loadApiKeys() {
			heyGovApi.get(`${this.j.slug}/api-keys`).then(({ data }) => {
				this.apiKeys.push(...data)
			}, handleResponseError('Error loading API keys ({error})'))
		},

		setLocation(place) {
			const location = {
				lat: place.geometry.location.lat(),
				lng: place.geometry.location.lng(),
				place_id: place.place_id,
				zoom: this.j.location.zoom || 12,
			}

			place.address_components.forEach(component => {
				if (component.types.includes('administrative_area_level_1')) {
					location.state = component.short_name
				} else if (component.types.includes('administrative_area_level_2')) {
					location.county = component.short_name || component.long_name
				} else if (component.types.includes('country')) {
					location.country = component.short_name
				} else if (
					component.types.includes('locality') ||
					(!location.city && component.types.includes('administrative_area_level_3'))
				) {
					location.city = component.short_name || component.long_name
				}
			})

			this.$store.commit('updateCurrentJurisdiction', {
				location,
			})
		},
		updateLocationZoom(zoom) {
			this.$store.commit('updateCurrentJurisdiction', {
				location: { ...this.j.location, zoom },
			})
		},
		saveJurisdictionData() {
			this.states.jurisdiction = 'saving'

			this.$store.dispatch('saveCurrentJurisdiction').then(
				() => {
					this.states.jurisdiction = 'idle'
				},
				() => {
					this.states.jurisdiction = 'error'
				}
			)
		},

		saveService() {
			if (this.serviceSelected.id) {
				heyGovApi
					.put(`/${this.j.slug}/services/${this.serviceSelected.id}`, {
						name: this.serviceSelected.name,
						group: this.serviceSelected.group,
						keywords: this.serviceSelected.keywords,
					})
					.then(() => {
						this.$modalService.hide()
						Vue.toasted.show(`Category "${this.serviceSelected.name}" is saved`)
					}, handleResponseError('Error saving category ({error})'))
			} else {
				heyGovApi.post(`/${this.j.slug}/services`, this.serviceSelected).then(
					({ data }) => {
						this.$store.commit('addService', data)
						Vue.toasted.show(`Category "${this.serviceSelected.name}" is added`)

						// empty data from dialog & hide it
						this.$modalService.hide()
						this.serviceSelected.name = ''
						this.serviceSelected.group = ''
						this.serviceSelected.keywords = ''
					},
					error => {
						Vue.toasted.show(error, { type: 'error' })
					}
				)
			}
		},
		deleteService() {
			if (confirm('For sure remove this category?')) {
				heyGovApi.delete(`/${this.j.slug}/services/${this.serviceSelected.id}`).then(
					() => {
						this.serviceSelected.status = 'deleted'
						this.$modalService.hide()
						Vue.toasted.show(`Category "${this.serviceSelected.name}" is deleted`)
					},
					error => {
						Vue.toasted.show(error, { type: 'error' })
					}
				)
			}
		},
		widgetCheck() {
			heyGovApi.get(`/${this.j.slug}/stats/events/widget-show`).then(({ data }) => {
				if (data.length) {
					this.widgetConnected = true
					clearTimeout(this.widgetCheckTimer)
				}
			})
		},
	},
	beforeDestroy() {
		clearTimeout(this.widgetCheckTimer)
	},
}
</script>
