<template>
	<div class="page-payments-settings">
		<h3 class="mb-4">
			<router-link :to="`/${j.slug}/payments`">HeyGov Pay</router-link>
			<font-awesome-icon :icon="['fas', 'angle-right']" class="text-muted mx-1" /> Add payments to department
		</h3>

		<div class="row justify-content-center">
			<div class="col-md-10 col-lg-8 col-xl-7">
				<div class="card">
					<div class="card-header">
						<a
							href="https://townweb.notion.site/HeyGov-Pay-1d4c2c59440e43979b9f4000520eee04"
							target="heygov-help"
							class="float-end"
							><font-awesome-icon :icon="['fas', 'question-circle']" /> Help</a
						>
						<h5 class="my-0">Connect a payment provider</h5>
					</div>
					<div class="card-body">
						<p class="lead">
							Add a payments provider for the department that needs to handle online or in-person
							payments.
						</p>

						<form @submit.prevent="connectAccount">
							<div class="form-row mb-3">
								<label class="form-label" for="payment-dept">Department</label>

								<select class="form-select" id="payment-dept" v-model="newAccount.department">
									<option :value="null"> - Select department -</option>
									<option
										v-for="dept in activeDepartmentsWithPaymentPlatform"
										:key="dept.id"
										:value="dept.id"
										:disabled="dept.payments_platform"
										>{{ dept.name
										}}<span v-if="dept.payments_platform">
											(Connected to {{ paymentPlatforms[dept.payments_platform].name }})</span
										></option
									>
								</select>
							</div>

							<template v-if="newAccount.department">
								<div class="form-row mb-3">
									<label class="form-label" for="dept-legal-name"
										>Account/Department legal name</label
									>

									<input
										type="text"
										class="form-control"
										id="dept-legal-name"
										required
										minlength="5"
										v-model="newAccount.legal_name"
									/>
									<p class="form-text">
										This will be shown on receipts, reports and other official documents
									</p>
								</div>

								<!-- <div class="form-row mb-3">
									<label class="form-label"
										>Do you have a specific payment processor for this department?</label
									>

									<div>
										<div class="form-check form-check-inline">
											<input
												class="form-check-input"
												type="radio"
												id="payment-dept-type-connect"
												v-model="newAccount.flow"
												value="connect"
											/>
											<label class="form-check-label" for="payment-dept-type-connect">Yes</label>
										</div>
										<div class="form-check form-check-inline">
											<input
												class="form-check-input"
												type="radio"
												id="payment-dept-type-create"
												v-model="newAccount.flow"
												value="create"
											/>
											<label class="form-check-label" for="payment-dept-type-create">No</label>
										</div>
									</div>

									<p class="form-text">It shouldn't be the same processor as other departments.</p>
								</div> -->

								<template v-if="newAccount.flow === 'connect'">
									<div class="form-group mb-3">
										<label for="payment-provider" class="form-label">Payment processor</label>

										<div class="row row-cols-2 gx-3">
											<template v-for="(provider, platform) in paymentPlatforms">
												<div
													v-if="provider.flows.includes(newAccount.flow)"
													:key="platform"
													class="col mb-3"
												>
													<div
														class="card h-100 rounded-1 cursor-pointer border"
														:class="{
															'border-success bg-success-lighter':
																newAccount.platform === platform,
														}"
														@click="newAccount.platform = platform"
													>
														<div class="card-body py-2 px-3">
															<h6 class="mb-2">
																<font-awesome-icon
																	v-if="provider.faIcon"
																	:icon="provider.faIcon"
																	:style="{ color: provider.brandColor }"
																/>
																<img
																	v-else-if="provider.logo"
																	:src="provider.logo"
																	class="float-start me-1"
																	height="19"
																/>

																{{ provider.name }}
															</h6>

															<p class="card-text">
																{{ provider.description }}
															</p>
														</div>
													</div>
												</div>
											</template>

											<div v-if="newAccount.flow === 'connect'" class="col mb-3">
												<div
													class="card h-100 rounded-1 cursor-pointer border"
													:class="{
														'border-primary bg-info-lighter':
															newAccount.platform === 'other',
													}"
													@click="newAccount.platform = 'other'"
												>
													<div class="card-body py-2 px-3">
														<h6 class="mb-2">⚪️ Other</h6>

														<p class="card-text">
															Other payments processor
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>

									<template v-if="newAccount.flow === 'create' && newAccount.platform">
										<p class="card-text text-center">
											<button class="btn btn-primary" :disabled="!newAccount.legal_name">
												Create an account with {{ paymentPlatforms[newAccount.platform].name }}
											</button>
										</p>
									</template>
									<template
										v-else-if="newAccount.flow === 'connect' && newAccount.platform === 'other'"
									>
										<div class="alert alert-warning text-dark">
											ℹ️ We don't support connecting other payment processors yet. Please contact
											Atanas @ HeyGov to discuss your needs.
										</div>
									</template>
									<template
										v-else-if="newAccount.flow === 'connect' && newAccount.platform === 'stripe'"
									>
										<div class="form-row mb-3">
											<label class="form-label">Payment methods</label>

											<div class="form-check form-switch">
												<input
													class="form-check-input"
													type="checkbox"
													role="switch"
													checked
													disabled
												/>
												<label class="form-check-label">Cash/Check</label>
											</div>

											<div class="form-check form-switch">
												<input
													class="form-check-input"
													type="checkbox"
													role="switch"
													id="payment-card"
													value="card"
													disabled
													v-model="newAccount.payment_methods"
												/>
												<label class="form-check-label" for="payment-card">Card - Online</label>
											</div>

											<div class="form-check form-switch">
												<input
													class="form-check-input"
													type="checkbox"
													role="switch"
													id="payment-card_present"
													value="card_present"
													v-model="newAccount.payment_methods"
												/>
												<label class="form-check-label" for="payment-card_present"
													>Card - In person</label
												>
											</div>

											<div class="form-check form-switch">
												<input
													class="form-check-input"
													type="checkbox"
													role="switch"
													id="payment-us_bank_account"
													value="us_bank_account"
													v-model="newAccount.payment_methods"
												/>
												<label class="form-check-label" for="payment-us_bank_account"
													>ACH Direct Debit</label
												>
											</div>

											<p class="form-text">
												Select the payment methods you want to accept with this account.
											</p>
										</div>

										<ul class="nicer-list mb-3">
											<li>
												Stripe charges a
												<a href="https://stripe.com/pricing" target="_blank">processing fee</a>
												for each card payment
											</li>
											<li>A Stripe account can be connected to a single HeyGov department</li>
											<li>
												In the next step, you can connect an existing Stripe account, or create
												one
											</li>
										</ul>

										<p class="card-text text-center">
											<button class="btn btn-primary" :disabled="!newAccount.legal_name">
												Continue to Stripe →
											</button>
										</p>
									</template>
									<template
										v-else-if="newAccount.flow === 'connect' && newAccount.platform === 'paygov'"
									>
										<div class="form-row mb-3">
											<label class="form-label" for="paygov-account-id">PayGov.US Account</label>

											<div class="row">
												<div class="col">
													<input
														type="text"
														class="form-control"
														id="paygov-account-id"
														placeholder="TTID"
														v-model="newAccount.paygov.ttid"
														required
													/>
												</div>
												<div class="col">
													<input
														type="text"
														class="form-control"
														placeholder="API Password"
														v-model="newAccount.paygov.api"
														required
													/>
												</div>
											</div>
										</div>

										<p class="card-text text-center">
											<button class="btn btn-primary" :disabled="!newAccount.legal_name">
												Connect PayGov.US account
											</button>
										</p>
									</template>
									<template
										v-else-if="newAccount.flow === 'connect' && newAccount.platform === 'municipay'"
									>
										<div class="form-row mb-2">
											<label class="form-label" for="municipay-username"
												>MuniciPay Username</label
											>

											<input
												type="text"
												class="form-control"
												id="municipay-username"
												v-model="newAccount.municipay.username"
												required
											/>
										</div>
										<div class="form-row mb-2">
											<label class="form-label" for="municipay-usertoken"
												>MuniciPay User Token</label
											>

											<input
												type="text"
												class="form-control"
												id="municipay-usertoken"
												v-model="newAccount.municipay.userToken"
												required
											/>
										</div>
										<div class="form-row mb-2">
											<label class="form-label" for="municipay-paymentid"
												>MuniciPay Payment Item ID</label
											>

											<input
												type="text"
												class="form-control"
												id="municipay-paymentid"
												v-model="newAccount.municipay.paymentItemId"
												required
											/>
										</div>

										<p class="card-text text-center">
											<button class="btn btn-primary" :disabled="!newAccount.legal_name || 1">
												Connect MuniciPay account
											</button>
										</p>
									</template>
								</template>
								<template v-else-if="newAccount.flow === 'create'">
									<p>
										Continuing forward your account will be connected to a payment processor. It
										takes 2-3 days to be verified.
									</p>

									<p class="card-text text-center">
										<button class="btn btn-primary" :disabled="!newAccount.legal_name">
											Continue
										</button>
									</p>
								</template>
							</template>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import Vue from 'vue'

import heyGovApi from '@/api.js'
import { handleResponseError, sendEvent } from '@/utils.js'
import { paymentPlatforms } from '@/actions/payments.js'

export default {
	name: 'PaymentsSettings',
	metaInfo: {
		title: `Connect payment department`,
	},
	components: {},
	data() {
		return {
			paymentPlatforms,
			newAccount: {
				department: null,
				legal_name: '',
				flow: 'connect',
				platform: null,
				payment_methods: ['card', 'card_present'],

				paygov: {
					ttid: '',
					api: '',
				},
				municipay: {
					username: '',
					userToken: '',
					paymentItemId: '',
				},
			},
		}
	},
	computed: {
		...mapState(['j', 'apiUrl', 'people', 'departments']),
		...mapGetters(['currentRole', 'isStaff', 'activeDepartments']),
		activeDepartmentsWithPaymentPlatform() {
			const depts = this.activeDepartments.map(dept => {
				dept.payments_platform = null

				if (dept.stripe_account) {
					dept.payments_platform = 'stripe'
				} else if (dept.justifi_account) {
					dept.payments_platform = 'justifi'
				}

				return dept
			})

			return depts
		},
	},
	created() {
		if (this.currentRole === 'CITIZEN') {
			this.$router.push(`/${this.j.slug}/quick-pay`)
		} else {
			this.$store.dispatch('loadDepartments')

			sendEvent('Connect payment department', {
				feature: 'payments',
			})
		}
	},
	methods: {
		connectAccount() {
			const departmentFields = {
				legal_name: this.newAccount.legal_name,
			}

			if (this.newAccount.flow === 'create') {
				heyGovApi
					.post(
						`/${this.j.slug}/departments/${this.newAccount.department}/stripe-connect-url`,
						departmentFields
					)
					.then(({ data }) => {
						window.open(data.stripe_connect_url, '_blank')

						this.$router.push({
							name: 'Payments',
							params: {
								departmentId: this.newAccount.department,
							},
							query: {
								reloadDepartments: 1,
							},
						})
					}, handleResponseError(`Couldn't create Stripe account ({error})`))
			} else if (this.newAccount.flow === 'connect') {
				let redirect

				if (this.newAccount.platform === 'stripe') {
					const stripeOAuthUrl = new URL(
						`https://connect.stripe.com/oauth/authorize?response_type=code&scope=read_write`
					)

					stripeOAuthUrl.searchParams.set(
						'client_id',
						this.j.livemode ? 'ca_Jz6UEueKX2Dk45fVKJ8st7wvCvjEb81t' : 'ca_Jz6UZw4xAyKtpKtu1PAJA8d0qIAorZQg'
					)
					stripeOAuthUrl.searchParams.set('state', `${this.j.slug};${this.newAccount.department}`)

					if (!this.j.livemode) {
						stripeOAuthUrl.searchParams.set('redirect_uri', `${this.apiUrl}webhooks/stripe-oauth`)
					}

					departmentFields.payment_methods = this.newAccount.payment_methods

					redirect = stripeOAuthUrl.toString()
				} else if (this.newAccount.platform === 'paygov') {
					departmentFields.paygov_account = `${this.newAccount.paygov.ttid}:${this.newAccount.paygov.api}:1`
				} else if (this.newAccount.platform === 'municipay') {
					departmentFields.municipay_account = this.newAccount.municipay.username
				} else {
					alert('Unknown platform')
				}

				heyGovApi
					.put(`${this.j.slug}/departments/${this.newAccount.department}`, departmentFields)
					.then(response => {
						console.log(response)

						if (redirect) {
							Vue.toasted.show('Redirecting to Stripe')
							window.location = redirect
						} else {
							this.$router.push({
								name: 'Payments',
								params: {
									departmentId: this.newAccount.department,
								},
								query: {
									reloadDepartments: 1,
									message: `This department is now connected to ${
										paymentPlatforms[this.newAccount.platform].name
									}, and you can start accepting payments.`,
								},
							})
						}
					})
					.catch(handleResponseError)
			}
		},
	},
	watch: {
		'newAccount.flow'() {
			this.newAccount.platform = null
		},
		'newAccount.department'() {
			const dept = this.departments.find(dept => dept.id === this.newAccount.department)
			this.newAccount.legal_name = `${this.j.name} - ${dept.name}`
		},
	},
}
</script>
