<template>
	<div class="threads-page">
		<div class="row align-items-center justify-content-between mb-2">
			<div class="col-auto">
				<h2 class="my-0">311 Requests</h2>
			</div>
			<div class="col-auto">
				<a
					v-if="currentRole !== 'CITIZEN'"
					href="https://www.youtube.com/watch?v=kAz6pOolT9o"
					target="heygov-yt"
					class="d-inline-block bg-danger-50 text-neutral-500 px-3 py-2 rounded-1 me-3"
					>▶️ How to manage 311 requests</a
				>

				<router-link :to="`/${j.slug}/threads/create`" class="btn btn-primary btn-sm btn-big-icon"
					>Send request <font-awesome-icon :icon="['fas', 'plus-circle']"
				/></router-link>
			</div>
		</div>

		<div class="bar-filters border rounded bg-white p-1 mb-2">
			<div class="row align-items-center g-2">
				<div class="col-sm-12 col-lg-auto">
					<div class="form-check form-check-inline text-dark my-1 ms-2">
						<input
							type="radio"
							class="form-check-input"
							id="filter-status-all"
							v-model="filters.status"
							value="any"
						/>
						<label class="form-check-label" for="filter-status-all">All</label>
					</div>

					<div class="form-check form-check-inline text-secondary my-1">
						<input
							type="radio"
							class="form-check-input"
							id="filter-status-new"
							v-model="filters.status"
							value="new"
						/>
						<label class="form-check-label" for="filter-status-new">Received</label>
					</div>
					<div class="form-check form-check-inline text-primary my-1">
						<input
							type="radio"
							class="form-check-input"
							id="filter-status-working"
							v-model="filters.status"
							value="working"
						/>
						<label class="form-check-label" for="filter-status-working">In Progress</label>
					</div>
					<div class="form-check form-check-inline text-success my-1">
						<input
							type="radio"
							class="form-check-input"
							id="filter-status-resolved"
							v-model="filters.status"
							value="resolved"
						/>
						<label class="form-check-label" for="filter-status-resolved">Resolved</label>
					</div>
					<div
						v-if="['EDITOR', 'ADMIN'].includes(currentRole)"
						class="form-check form-check-inline text-danger my-1"
					>
						<input
							type="radio"
							class="form-check-input"
							id="filter-status-closed"
							v-model="filters.status"
							value="closed"
						/>
						<label class="form-check-label" for="filter-status-closed">Declined</label>
					</div>
				</div>

				<div v-if="currentRole !== 'CITIZEN'" class="col-sm-6 col-lg-2">
					<select class="form-select form-select-sm" v-model="filters.agency_id">
						<option value="null">Not assigned</option>
						<option value="any">Any department</option>
						<optgroup label="In department">
							<option
								v-for="department in activeDepartments"
								:key="department.id"
								:value="department.id"
								>{{ department.name }}</option
							>
						</optgroup>
					</select>
				</div>
			</div>
		</div>

		<div v-if="currentRole !== 'CITIZEN'" class="mb-3 bg-white rounded px-3 py-2 shadow-sm">
			<div class="row align-items-center">
				<div class="col">
					<!-- <input type="checkbox" id="form-requests-select" @change="selectAll" class="me-2" /> -->
					<label v-if="!selectedThreads.length" for="form-requests-select" class="text-muted"
						>Select requests to see actions</label
					>
					<span v-else>
						<span class="text-muted">{{ selectedThreads.length }} selected</span>
						<button
							v-if="selectedThreads.length > 1"
							class="btn btn-sm btn-secondary mx-2"
							data-bs-toggle="modal"
							data-bs-target="#threads-merge"
							@click="confirmMergeThreads"
						>
							Merge requests
						</button>
						<button class="btn btn-sm btn-outline-danger mx-2" @click="declineRequests">
							Decline requests
						</button>
						<button
							v-if="isStaff"
							class="btn btn-sm btn-danger mx-2"
							@click="deleteRequests(selectedThreads)"
						>
							Delete requests
						</button>
					</span>
				</div>
				<div class="col-auto">
					Showing {{ pag.perPage * (pag.page - 1) }}-{{ Math.min(pag.perPage * pag.page, pag.total) }} of
					{{ pag.total }}
				</div>
				<div class="col-auto ps-0">
					<button class="btn btn-sm px-2" @click="mapView = !mapView" title="Show/hide map">
						<font-awesome-icon :icon="['fas', 'map-marked-alt']" />
					</button>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col">
				<thread-card
					v-for="thread in threads"
					:key="`thread-id-${thread.id}`"
					:thread="thread"
					:setMapCenter="setMapCenter"
					@mouseover="thread._local.markerzIndex = markerzIndex++"
					:id="`thread-${thread.id}`"
					class="mt-0 mb-3"
					:class="{ 'opacity-50': !inFilters(thread), highlighted: thread._local.mapHover }"
					v-on:showImage="showImg($event)"
				/>

				<div v-if="!threads.length && state === 'idle'" class="bg-light rounded py-2 text-center my-4">
					<h4 class="pt-2 mt-3">No reported issues here!</h4>
					<img
						class="img-fluid"
						width="400"
						src="https://files.heygov.com/illustrations/illustration-threads-empty-state.png"
						alt="HeyGov 311 empty state"
					/>
				</div>

				<div class="text-center py-3">
					<span v-if="state === 'loading'" class="spinner-border" role="status"></span>
				</div>
			</div>

			<div v-if="mapView && threads.length" class="col-3 position-relative d-none d-lg-block">
				<div class="sticky-top" style="top: 80px;z-index:1">
					<muni-map
						:height="650"
						:adjustZoom="-1"
						:markers="threadsMarkers"
						:includeVenues="false"
					></muni-map>
				</div>
			</div>
		</div>

		<div class="modal fade" id="threads-merge" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog modal-lg">
				<div v-if="selectedThreads.length > 1" class="modal-content">
					<form @submit.prevent="mergeThreads">
						<div class="modal-header">
							<h5 class="modal-title">Merge multiple Issues into one</h5>
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body">
							<table class="table table-hover">
								<thead>
									<tr>
										<th>Name</th>
										<th
											v-for="thread in selectedThreads"
											:key="thread.id"
											v-html="
												thread.title || `<small class='text-muted'>No thread title..</small>`
											"
										></th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>ID</td>
										<td v-for="thread in selectedThreads" :key="thread.id">
											<code>{{ thread.id }}</code>
										</td>
									</tr>
									<tr>
										<td>Date</td>
										<td v-for="thread in selectedThreads" :key="thread.id">
											{{ new Date(thread.created_at).toLocaleString() }}
										</td>
									</tr>
									<tr>
										<td>Status</td>
										<td v-for="thread in selectedThreads" :key="thread.id">
											<span :class="[statuses[thread.status].class]">{{
												statuses[thread.status].name
											}}</span>
										</td>
									</tr>
									<tr>
										<td>Category</td>
										<td v-for="thread in selectedThreads" :key="thread.id">
											{{ categoryName(thread.service_id) }}
										</td>
									</tr>
									<tr>
										<td>Department</td>
										<td v-for="thread in selectedThreads" :key="thread.id">
											{{ departmentName(thread.agency_id) }}
										</td>
									</tr>
									<tr>
										<td>Location</td>
										<td v-for="thread in selectedThreads" :key="thread.id">
											{{ thread.location ? thread.location.name : null }}
										</td>
									</tr>
									<tr>
										<td>Sent from</td>
										<td v-for="thread in selectedThreads" :key="thread.id">
											<span class="text-capitalize">{{ thread.source }}</span>
										</td>
									</tr>
								</tbody>
								<tfoot>
									<tr>
										<th class="bg-light-warning">In case of conflict</th>
										<td v-for="thread in selectedThreads" :key="thread.id">
											<label
												><input
													type="radio"
													name="mergeMainId"
													:value="thread.id"
													v-model="mergeMainId"
												/>
												<span :class="{ 'text-success': thread.id == mergeMainId }">
													Preserve these values</span
												></label
											>
										</td>
									</tr>
								</tfoot>
							</table>
						</div>
						<div class="modal-footer justify-content-between">
							<button class="btn btn-link text-info">Cancel</button>
							<button class="btn btn-primary">Confirm</button>
						</div>
					</form>
				</div>
			</div>
		</div>
		<vue-easy-lightbox :visible="visible" :imgs="imgs" :index="index" @hide="handleHide"></vue-easy-lightbox>
	</div>
</template>

<style lang="scss">
.thread-statuses {
	.form-check-label::before {
		left: -1.3rem;
		border-color: currentColor;
	}
	.form-check-label::after {
		left: -1.3rem;
	}

	.form-check-input:checked ~ .form-check-label {
		font-weight: bold;
	}
	.form-check-input:checked ~ .form-check-label::before {
		border-color: currentColor;
		background-color: currentColor;
	}
	.text-dark .form-check-label::before {
		color: darkgray !important;
	}
	.text-secondary .form-check-label::before {
		color: var(--bs-secondary) !important;
	}
	.text-info .form-check-label::before {
		color: var(--bs-info) !important;
	}
	.text-primary .form-check-label::before {
		color: var(--bs-primary) !important;
	}
	.text-success .form-check-label::before {
		color: var(--bs-success) !important;
	}
	.text-danger .form-check-label::before {
		color: var(--bs-danger) !important;
	}
	/*
	.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
		//background-image: url("@/assets/checked.svg");
	}
*/
}
</style>

<script>
import Vue from 'vue'
import axios from 'axios'
import { mapGetters, mapState } from 'vuex'
import { uniq } from 'lodash'
import { Modal } from 'bootstrap'

import heyGovApi from '@/api.js'

import ThreadCard from '@/components/ThreadCard.vue'
import MuniMap from '@/components/MuniMap.vue'

export default {
	name: 'Threads',
	components: { ThreadCard, MuniMap },
	data() {
		return {
			state: 'idle',
			threads: [],
			pag: {
				total: 0,
				page: 1,
				perPage: 25,
			},
			filters: {
				status: this.$route.query.status || localStorage.getItem('heygov-threads-filter-status') || 'any',
				agency_id:
					this.$route.query.agency_id || localStorage.getItem('heygov-threads-filter-agency_id') || 'any',
			},
			$modalMerge: null,
			mapView: true,
			mergeMainId: null,
			mapCenter: { lat: 0, lng: 0 },
			markerzIndex: 1,
			reqCancelToken: null,
			visible: false, // For image preview
			index: 0, // For image prevoew
			imgs: [], // Images for preview
		}
	},
	created() {
		this.$store.dispatch('loadServices')
		this.$store.dispatch('loadDepartments')
		this.loadThreads()

		// set initial filters
		let agency_id = this.$route.query.agency_id || localStorage.getItem('heygov-threads-filter-agency_id') || false

		if (
			!agency_id &&
			['WORKER', 'EDITOR'].includes(this.currentRole) &&
			this.j.roles.filter(r => r.department_id).length
		) {
			agency_id = this.j.roles.filter(r => r.department_id)[0].department_id
		}

		if (agency_id && this.filters.agency_id != agency_id) {
			this.filters.agency_id = agency_id
		}

		this.mapCenter = { ...this.j.location }
	},
	mounted() {
		this.$modalMerge = new Modal(document.getElementById('threads-merge'))

		if (!('HeyGov' in window)) {
			let HeyGovScript = document.createElement('script')
			HeyGovScript.setAttribute('src', 'https://files.heygov.com/widget.js')
			HeyGovScript.setAttribute('data-heygov-jurisdiction', this.j.id)
			document.head.appendChild(HeyGovScript)

			if (this.$route.query.create) {
				setTimeout(() => {
					this.openHeyGov()
				}, 250)
			}
		} else if (this.$route.query.create) {
			this.openHeyGov()
		}
	},
	computed: {
		...mapState(['j', 'services', 'statuses', 'departments', 'people']),
		...mapGetters(['servicesByGroup', 'activeDepartments', 'currentRole', 'isStaff']),
		selectedThreads() {
			return this.threads.filter(t => t._local.selected)
		},
		threadsMarkers() {
			return this.threads
				.filter(t => t.location && t.location.lat)
				.map(t => {
					return {
						position: t.location,
						icon: this.threadMarkerIcon(t),
					}
				})
		},
	},
	methods: {
		openHeyGov() {
			window.HeyGov.toggle()
		},
		loadThreads() {
			this.state = 'loading'

			console.log('load threads')

			if (this.reqCancelToken) {
				this.reqCancelToken.cancel()
			}
			this.reqCancelToken = axios.CancelToken.source()

			heyGovApi
				.get(`${this.j.slug}/threads`, {
					cancelToken: this.reqCancelToken.token,
					params: this.filters,
				})
				.then(
					({ data, headers }) => {
						this.pag.total = headers['x-total']

						data = data.map(thread => {
							thread._local = {
								selected: false,
								hover: false,
								mapHover: false,
								markerzIndex: this.markerzIndex++,
							}

							return thread
						})

						const peopleIds = data.map(t => t.person_id).filter(Boolean)
						this.$store.dispatch('loadPeople', uniq(peopleIds))

						this.threads.push(...data)
						this.state = 'idle'
					},
					error => {
						if (!axios.isCancel(error)) {
							this.state = 'error'
							Vue.toasted.show(error, { type: 'error' })
						}
					}
				)
		},
		confirmMergeThreads() {
			this.mergeMainId = this.selectedThreads[0].id
		},
		mergeThreads() {
			const threadIds = this.selectedThreads.map(t => t.id).filter(id => id !== this.mergeMainId)
			const fieldsToMerge = [
				'title',
				'status',
				'service_id',
				'agency_id',
				'location',
				'source',
				'from_origin',
				'from_referer',
				'from_user_agent',
				'from_ip',
				'notified',
			]

			heyGovApi.post(`${this.j.slug}/threads/${this.mergeMainId}/merge`, { threadIds }).then(() => {
				const mainThread = this.threads.find(t => t.id === this.mergeMainId)

				this.selectedThreads.forEach(t => {
					// check which fields can be updated
					fieldsToMerge.forEach(field => {
						if (!mainThread[field] && t[field]) {
							mainThread[field] = t[field]
						}
					})

					// merge messages
					mainThread.messages.push(...t.messages)
				})

				this.threads = this.threads
					.filter(t => !threadIds.includes(t.id))
					.map(t => {
						t._local.selected = false
						return t
					})

				Vue.toasted.show('Issues are merged', {
					action: { text: 'View Issue', push: `/threads/${mainThread.uuid}` },
				})
				this.$modalMerge.hide()
			})
		},
		highlightThread(thread) {
			const $thread = document.getElementById(`thread-${thread.id}`)

			$thread.scrollIntoView({ behavior: 'smooth', block: 'center' })
			$thread.classList.add('bg-highlighted')

			setTimeout(() => {
				$thread.classList.remove('bg-highlighted')
			}, 1000)
		},
		inFilters(thread) {
			if (this.filters.status !== 'any' && this.filters.status !== thread.status) {
				return false
			}

			const filterDept = this.filters.agency_id === 'null' ? null : this.filters.agency_id

			if (filterDept !== 'any' && filterDept != thread.agency_id) {
				return false
			}

			return true
		},
		updatePageUrl() {
			this.$router.replace({ path: `/${this.j.slug}/threads`, query: { ...this.filters } })
		},
		categoryName(serviceId) {
			const foundService = this.services.find(s => s.id === serviceId)

			return foundService ? foundService.name : null
		},
		departmentName(departmentId) {
			const foundDepartment = this.departments.find(s => s.id === departmentId)

			return foundDepartment ? foundDepartment.name : null
		},
		setMapCenter(lat, lng) {
			this.mapCenter = { lat, lng }
		},
		threadMarkerIcon(thread) {
			return `https://files.heygov.com/assets/icon-map-pin-${thread.status}${
				thread._local.hover ? '-filled' : ''
			}.svg`
		},
		handleHide() {
			this.visible = false
		},
		showImg(event) {
			this.imgs = event.images.map(image => image.data.url)
			this.index = event.index
			this.visible = true
		},
		declineRequests() {
			alert('Functionality not available yet')
		},
		deleteRequests(threadsIds) {
			if (confirm('For sure to delete so many threads?')) {
				threadsIds.forEach(thread => {
					this.deleteThread(thread.id)
				})
			}
		},
		deleteThread(id) {
			heyGovApi.delete(`/${this.j.slug}/threads/${id}`).then(
				() => {
					this.threads = this.threads.filter(t => t.id !== id)
					Vue.toasted.error(`Reported issue deleted`)
				},
				error => {
					Vue.toasted.error(`Error deleting reported issues ~ ${error}`)
				}
			)
		},
	},
	watch: {
		filters: {
			deep: true,
			handler() {
				localStorage.setItem('heygov-threads-filter-status', this.filters.status)
				localStorage.setItem('heygov-threads-filter-agency_id', this.filters.agency_id)

				this.threads = []
				this.updatePageUrl()
				this.loadThreads()
			},
		},
	},
}
</script>
