<template>
	<div class="meeting-public-page" :style="generalStyle">
		<div class="mb-3">
			<router-link :to="{ name: 'EventsEmbed', params: { jurisdiction: j.slug }, query: $route.query }"
				><font-awesome-icon :icon="['fas', 'arrow-left']" class="me-1" /> Back</router-link
			>
		</div>

		<div v-if="states.meeting === 'loaded'">
			<div class="row align-items-center">
				<div class="col">
					<h2 class="my-0">{{ meeting.title }}</h2>
				</div>
				<div class="col-lg-auto">
					<add-to-calendar-dropdown :event="meeting" class="d-inline-block" />

					<a
						v-if="meeting.conferencing_link"
						:href="meeting.conferencing_link"
						target="_blank"
						class="btn btn-sm btn-primary ms-2"
					>
						<span v-if="meeting.conferencing_link.includes('zoom.us/')">Join Zoom meeting</span>
						<span v-else-if="meeting.conferencing_link.includes('meet.google.com/')"
							>Join Google Meet meeting</span
						>
						<span v-else>Join meeting</span>
					</a>
				</div>
			</div>

			<hr class="bg-primary-50 my-3" />

			<div class="row">
				<div class="col-lg-6 mb-3">
					<p class="mb-2">
						<span class="text-neutral-400">Date: </span>
						<strong>{{
							new Date(meeting.starts_at).toLocaleString('en-US', {
								dateStyle: 'long',
								timeZone: j.timezone,
							})
						}}</strong>
					</p>
					<p class="mb-0">
						<span class="text-neutral-400">Time: </span>
						<strong>{{
							new Date(meeting.starts_at).toLocaleString('en-US', {
								timeStyle: 'short',
								timeZone: j.timezone,
							})
						}}</strong>
					</p>
				</div>
				<div class="col-lg-6 mb-3">
					<p class="mb-2">
						<span class="text-neutral-400">Location: </span>
						<small class="text-neutral-300">-</small>
					</p>
					<p class="mb-0">
						<span class="text-neutral-400 me-1">
							{{ meeting.categories && meeting.categories.length > 1 ? 'Categories' : 'Category' }}:
						</span>
						<template v-if="meeting.categories && meeting.categories.length">
							{{
								meetingCategories
									.filter(c => meeting.categories.includes(c.id))
									.map(c => c.name)
									.join(', ')
							}}
						</template>
						<small v-else class="text-neutral-300">-</small>
					</p>
				</div>
			</div>

			<p v-if="meeting.description" class="card-text">
				<template v-if="meeting.description">{{ meeting.description }}</template>
				<small v-else class="text-neutral-300">(no meeting description)</small>
			</p>

			<hr class="bg-primary-50 my-3" />

			<div class="scrolling-tabs mb-3">
				<nav class="hide-scrollbar">
					<ul
						class="nav nav-pills nav-pills-sm hide-scrollbar"
						style="flex-wrap: nowrap; min-width: 100%; overflow-x: scroll;"
					>
						<li class="nav-item">
							<span class="nav-link" :class="{ active: tab == 'agenda' }" @click="setTab('agenda')"
								>Agenda</span
							>
						</li>
						<li v-if="meeting.state === 'past'" class="nav-item">
							<span class="nav-link" :class="{ active: tab == 'minutes' }" @click="setTab('minutes')"
								>Minutes</span
							>
						</li>
					</ul>
				</nav>
			</div>

			<div v-if="tab === 'agenda'">
				<div v-if="meeting.agenda_items.length" class="card mb-4">
					<div class="card-body">
						<div class="card-table">
							<table class="table table-hover">
								<thead>
									<tr>
										<th scope="col">Agenda Item</th>
										<th scope="col"></th>
									</tr>
								</thead>

								<tbody>
									<template v-for="(item, index) in meeting.agenda_items.filter(i => !i.parent_id)">
										<tr :key="item.id">
											<td>
												<p class="mb-0">
													<strong class="text-primary-300 me-2">{{ index + 1 }}.</strong>

													<span>{{ item.title }}</span>

													<span v-if="item.text" class="text-neutral-400 ms-1">
														- {{ item.text }}
													</span>
												</p>

												<p v-if="item.files.length" class="mt-2 mb-0">
													<a
														v-for="file in item.files"
														:key="file"
														:href="getPublicFileUrl(file)"
														target="_blank"
														class="d-inline-block px-2 bg-neutral-50 rounded border me-2 mb-1"
														>{{ file.split('/').at(-1) }}</a
													>
												</p>
											</td>
											<td>
												<small class="text-neutral-300">-</small>
											</td>
										</tr>

										<tr
											v-for="(subitem, index2) in meeting.agenda_items.filter(
												i => i.parent_id === item.id
											)"
											:key="subitem.id"
										>
											<td class="ps-5">
												<strong class="text-warning-400 me-2">{{ index2 + 1 }}.</strong>

												<span>{{ subitem.title }}</span>

												<span v-if="subitem.text" class="text-neutral-400 ms-1">
													- {{ subitem.text }}
												</span>
											</td>
											<td>
												<small class="text-neutral-300">-</small>
											</td>
										</tr>
									</template>
								</tbody>
							</table>
						</div>

						<!-- <pre>{{ meeting.agenda_items }}</pre> -->
					</div>
					<div class="card-footer">
						<a
							v-if="meeting.agenda_file_path || meeting.generated_agenda_file_path"
							:href="getPublicFileUrl(meeting.agenda_file_path || meeting.generated_agenda_file_path)"
							target="_blank"
							class="btn btn-sm btn-outline-dark me-2"
							><font-awesome-icon
								:icon="[
									'fas',
									(meeting.agenda_file_path || meeting.generated_agenda_file_path).endsWith('pdf')
										? 'file-pdf'
										: 'file',
								]"
								class="text-danger-400 me-1"
							/>
							Agenda</a
						>

						<a
							v-if="meeting.agenda_pack_file_path"
							:href="getPublicFileUrl(meeting.agenda_pack_file_path)"
							target="_blank"
							class="btn btn-sm btn-outline-dark me-2"
							><font-awesome-icon :icon="['fas', 'file-pdf']" class="text-danger-400 me-1" /> Agenda
							Packet</a
						>
					</div>
				</div>
				<iframe
					v-else-if="meeting.agenda_file_path"
					:src="getPublicFileUrl(meeting.agenda_file_path)"
					class="w-100"
					height="1000"
					frameborder="0"
				></iframe>
				<div v-else class="bg-neutral-50 rounded-1 text-center text-neutral-500 py-6">
					This meeting has no agenda yet. Please check back later.
				</div>
			</div>
			<div v-else-if="tab === 'minutes'" class="mb-4">
				<iframe
					v-if="['manual-upload', 'upload'].includes(meeting.minutes_status)"
					:src="getPublicFileUrl(meeting.meeting_minutes_file_path)"
					class="w-100"
					height="1000"
					frameborder="0"
				></iframe>
				<div v-else-if="meeting.minutes_status === 'public'" class="card">
					<div class="card-body">
						<h2 class="card-title text-center mt-4 mb-2">{{ meeting.title }}</h2>
						<h3 class="card-title text-center mb-5">Meeting minutes</h3>

						<div v-html="meeting.minutes_text"></div>
					</div>
				</div>
				<div v-else class="bg-neutral-50 rounded-1 text-center text-neutral-500 py-6">
					This meeting has no minutes yet. Please check back later.
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'

import heyGovApi, { hgApi } from '@/api.js'
import { getPublicFileUrl } from '@/utils.js'

import AddToCalendarDropdown from '@/components/events/AddToCalendarDropdown.vue'

export default {
	name: 'MeetingsEmbedPage',
	components: {
		AddToCalendarDropdown,
	},
	metaInfo() {
		return {
			title: `${this.meeting?.title || this.$route.params.id} - Meetings`,
		}
	},
	data() {
		return {
			states: {
				meeting: 'loading',
			},
			meetingCategories: [],
			meeting: null,
			tab: 'agenda',
			generalStyle: null,
		}
	},
	computed: {
		...mapState(['j']),
		hasRecording() {
			return this.meeting.video_file_path || this.meeting.audio_file_path
		},
	},
	created() {
		this.loadMeetingCategories()
		this.loadMeeting()

		// set Town Web styling
		if (this.j.style) {
			this.generalStyle = this.j.style.event || this.j.style
		}
	},
	methods: {
		getPublicFileUrl,

		loadMeeting() {
			this.states.meeting = 'loading'

			heyGovApi(`${this.j.slug}/meetings/${this.$route.params.id}?expand=agenda_items`)
				.then(({ data }) => {
					// process data for UI
					data.transcript = []

					this.meeting = data
					this.states.meeting = 'loaded'
				})
				.catch(error => {
					if (error.response?.status === 404) {
						this.errorMessage = 'Meeting not found'
					} else {
						this.errorMessage = error.response?.data?.message || 'Error loading meeting'
					}

					this.states.meeting = 'error'
				})
				.finally(() => {
					this.adjustIframeHeight()
				})
		},

		loadMeetingCategories() {
			hgApi(`${this.j.slug}/meetings/categories`).then(response => {
				if (response.ok) {
					response.json().then(categories => {
						this.meetingCategories.push(...categories)
					})
				} else {
					Vue.toasted.error('Failed to load meeting categories')
				}
			})
		},

		setTab(tab) {
			if (tab !== this.tab) {
				this.tab = tab

				setTimeout(() => {
					this.adjustIframeHeight()
				}, 250)
			}
		},

		formatTimestamp(seconds) {
			if (!seconds) {
				return '00:00'
			}
			const minutes = Math.floor(seconds / 60)
			const remainingSeconds = seconds % 60
			return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`
		},

		adjustIframeHeight() {
			const targetWindow = window.opener || window.parent

			return targetWindow.postMessage(
				{
					element: '.heygov-events',
					heygov_action: 'set-element-style',
					styles: {
						height: `${document.body.scrollHeight}px`,
					},
				},
				'*'
			)
		},
	},
}
</script>
