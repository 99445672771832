<template>
	<div class="page-jurisdiction-home">
		<div class="row">
			<div class="col-lg-7">
				<h3 class="text-primary mb-2">Hey {{ auth ? auth.name : 'there' }}, welcome to {{ j.name }}</h3>
			</div>
			<div class="col">
				<div v-if="weather" class="card card-weather mb-4">
					<div class="card-body py-2">
						<div class="row align-items-center">
							<div class="col-auto">
								<img
									:src="`https://openweathermap.org/img/wn/${weather.weather[0].icon}@2x.png`"
									:width="29"
								/>
							</div>
							<div class="col text-center">
								<p class="my-0 text-capitalize">{{ weather.weather[0].description }}</p>
							</div>
							<div class="col-auto">
								<h2 class="my-0">{{ Math.round(weather.main.temp) }}<sup>o</sup></h2>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div v-if="j.features.includes('issues') && threads.length" class="mb-4">
			<h5 class="mb-0">Latest resolved issues</h5>

			<div class="py-3 hide-scrollbar" style="overflow-x: auto">
				<div
					class="row"
					:class="[`row-cols-${threads.length}`]"
					:style="{ width: `${280 * threads.length}px` }"
				>
					<div v-for="thread in threads" :key="thread.id" class="col">
						<router-link :to="`/${j.slug}/threads/${thread.uuid}`" class="card card-link h-100">
							<div class="card-body pb-1">
								<p class="card-text">
									🟢 Resolved -
									<small class="text-muted">{{
										formatDistance(new Date(thread.created_at), new Date(), { addSuffix: true })
									}}</small>
								</p>
								<h6
									class="card-title mb-0"
									v-html="threadTitle(thread) || `<i class='text-muted'>No title</i>`"
								></h6>
							</div>
							<div class="card-footer border-0 pt-0 pb-1">
								<p class="card-text text-primary text-end mb-0">→</p>
							</div>
						</router-link>
					</div>
				</div>
			</div>
		</div>

		<div v-if="news.length" class="mb-3">
			<h5 class="mb-0">News</h5>

			<div class="py-3 hide-scrollbar" style="overflow-x: auto">
				<div class="row" :class="[`row-cols-${news.length}`]" :style="{ width: `${320 * news.length}px` }">
					<div v-for="item in news" :key="item.id" class="col">
						<a :href="item.link" target="heygov-site-link" class="card h-100 card-link">
							<!--<img v-if="item._embedded['wp:featuredmedia']" :src="item._embedded['wp:featuredmedia'][0].source_url" class="card-img-top" />-->
							<div class="card-body py-3">
								<h5 v-if="item.excerpt && item.excerpt.rendered" class="card-title">
									{{ item.title.rendered.replace('&\#8211;', '–').replace('&\#038;', '&') }}
								</h5>
								<h2 v-else class="card-title">
									{{ item.title.rendered.replace('&\#8211;', '–').replace('&\#038;', '&') }}
								</h2>

								<p v-if="item.excerpt && item.excerpt.rendered" class="card-text mb-2">
									{{ newsSummary(item.excerpt.rendered) }}
								</p>

								<p class="text-muted mb-0">
									<small>{{
										formatDistance(new Date(item.date), new Date(), { addSuffix: true })
									}}</small>
								</p>
							</div>
						</a>
					</div>
				</div>
			</div>
		</div>

		<div v-if="j.features.includes('forms') && forms.length" class="mb-4">
			<h5 class="mb-1">Need a license or permit?</h5>

			<div class="hide-scrollbar" style="overflow-x: auto">
				<div
					class="row gx-3"
					:class="[`row-cols-${forms.length + 1}`]"
					:style="{ width: `${280 * (forms.length + 1)}px` }"
				>
					<div class="col my-2" v-for="form in forms" :key="`form-${form.id}`">
						<form-card :form="form" :actionButton="true" class="h-100"></form-card>
					</div>
					<div v-if="forms.length > 4" class="col my-2">
						<router-link :to="`/${j.slug}/forms`" class="card">
							<div class="card-body">
								<p class="card-text text-center mb-1">
									<img
										src="https://files.heygov.com/assets/illustration-pablo-social-network.png"
										alt="HeyGov Forms"
										class="img-fluid mb-3"
									/>
								</p>
								<p class="card-text text-center">
									<span class="btn btn-sm btn-primary bg-info-lighter text-info"
										>Browse more forms</span
									>
								</p>
							</div>
						</router-link>
					</div>
				</div>
			</div>
		</div>

		<div v-if="j.features.includes('venues') && publicVenues.length" class="mb-4">
			<h5 class="mb-1">Book a venue</h5>

			<div
				v-if="homepageOptions.allVenues.includes(j.slug)"
				class="row gx-3 row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4"
			>
				<div class="col my-2" v-for="venue in publicVenues" :key="`form-${venue.id}`">
					<venue-card :venue="venue" class="h-100"></venue-card>
				</div>
			</div>
			<div v-else class="hide-scrollbar" style="overflow-x: auto">
				<div
					class="row gx-3"
					:class="[`row-cols-${publicVenues.slice(0, 5).length}`]"
					:style="{ width: `${280 * publicVenues.slice(0, 5).length}px` }"
				>
					<div class="col my-2" v-for="venue in publicVenues.slice(0, 4)" :key="`form-${venue.id}`">
						<venue-card :venue="venue" class="h-100"></venue-card>
					</div>

					<div v-if="publicVenues.length > 4" class="col my-2">
						<router-link :to="`/${j.slug}/venues`" class="card">
							<div class="card-body">
								<p class="card-text text-center mb-1">
									<img
										src="https://files.heygov.com/assets/illustration-pablo-social-network.png"
										alt="HeyGov Venues"
										class="img-fluid mb-3"
									/>
								</p>
								<p class="card-text text-center">
									<span class="btn btn-sm btn-primary bg-info-lighter text-info"
										>Browse more venues</span
									>
								</p>
							</div>
						</router-link>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Vue from 'vue'
import { formatDistance, formatDistanceToNow } from 'date-fns'

import heyGovApi from '@/api.js'
import { truncateWords } from '../utils'
import FormCard from '@/components/forms/FormCard.vue'
import VenueCard from '@/components/venues/VenueCard.vue'

export default {
	name: 'JurisdictionHome',
	components: { FormCard, VenueCard },
	data() {
		return {
			state: 'idle',
			error: '',
			threads: [],
			news: [],
			forms: [],
			weather: null,

			homepageOptions: {
				allVenues: ['heyville.org', 'sheboyganwi.gov'],
			},
		}
	},
	computed: {
		...mapGetters(['auth']),
		...mapState(['j', 'jurisdictions', 'departments', 'venues']),
		publicVenues() {
			return this.venues.filter(v => v.status === 'public' && !v.venue_group_id)
		},
	},
	created() {
		this.$store.dispatch('loadDepartments')

		this.loadThreads()
		this.loadInfos()

		this.loadForms()

		if (this.j?.features?.includes('venues')) {
			this.$store.dispatch('getVenues')
		}
	},
	methods: {
		formatDistance,
		formatDistanceToNow,

		loadThreads() {
			this.state = 'loading'

			if (this.j.features.includes('issues')) {
				heyGovApi.get(`${this.j.slug}/threads`, { params: { perPage: 4, status: 'resolved' } }).then(
					({ data }) => {
						this.threads.push(...data)
						this.state = 'idle'
					},
					error => {
						this.state = 'error'
						Vue.toasted.show(error, { type: 'error' })
					}
				)
			}
		},
		loadInfos() {
			heyGovApi.get(`${this.j.slug}/infos/news`).then(({ data }) => {
				this.news = data
			})
			heyGovApi.get(`${this.j.slug}/infos/weather`).then(({ data }) => {
				this.weather = data
			})
		},
		loadForms() {
			if (this.j.features.includes('forms')) {
				const params = {
					perPage: 4,
					status: 'public',
					orderBy: 'requests_count',
				}

				heyGovApi.get(`${this.j.slug}/forms`, { params }).then(({ data }) => {
					this.forms.push(...data)
				})
			}
		},
		threadTitle(thread) {
			if (thread.title) {
				return thread.title
			}

			const textMessages = thread.messages.filter(m => m.type === 'text')

			if (textMessages.length) {
				return truncateWords(textMessages[0].message, 15)
			}

			return null
		},
		newsSummary(html) {
			const $el = document.createElement('div')
			$el.innerHTML = html

			const text = $el.textContent || $el.innerText || ''

			const words = text.split(' ')

			return words.splice(0, 20).join(' ') + '…'
		},
	},
}
</script>
