<template>
	<div class="page-jurisdiction-settings">
		<h2 class="mb-3">Billing for {{ j.name }}</h2>

		<div class="row justify-content-center">
			<div class="col-md-10 col-lg-9">
				<div class="card">
					<div v-if="j.pricing === 'townweb'" class="card-body text-center">
						<img
							src="https://assets.website-files.com/5fd9ec20a182fdcd717d73c6/609c149075fd9aeb72cf78b7_Town_Web_Trademark-p-500.png"
							height="50"
							alt="Town Web"
							class="mb-4"
						/>

						<p>
							Billing for <strong>{{ j.name }}</strong
							>'s HeyGov account is managed through Town Web.
						</p>
						<p class="card-text">Please contact someone at Town Web for more information.</p>
					</div>
					<div v-else-if="j.stripe_customer_id" class="card-body">
						<p>Manage the plan &amp; invoices for your {{ j.type }}.</p>

						<p v-if="states.billing === 'loading'" class="text-center">
							<span class="spinner-border spinner-border-sm"></span>
							Loading..
						</p>

						<div v-else-if="!stripeSubscriptions.length">
							<p class="card-text">No subscriptions found.</p>
						</div>
						<div v-else>
							<div
								v-for="subscription in stripeSubscriptions"
								:key="subscription.id"
								class="p-3 rounded-1 bg-light mb-3"
							>
								<div class="row">
									<div class="col-auto border-end">
										<p class="subtitle mb-1">Status</p>
										<p class="mb-0">
											<span
												v-if="subscription.status === 'active'"
												class="badge bg-success-50 text-success-400"
												>Active</span
											>
											<span v-else class="badge bg-danger-50 text-danger-400 text-capitalize">{{
												subscription.status
											}}</span>
										</p>
									</div>

									<div class="col-auto border-end">
										<p class="subtitle mb-1">Plan</p>
										<p class="mb-0">
											<strong v-if="subscription.plan">{{
												stripeProducts.find(p => p.id === subscription.plan.product).name
											}}</strong>
											<strong v-else
												>Custom - {{ subscription.items.data.length }} products</strong
											>
										</p>
									</div>

									<div class="col-auto border-end">
										<p class="subtitle mb-1">Price</p>
										<p class="mb-0">
											<strong v-if="subscription.plan" :title="subscription.plan.nickname"
												>{{ (subscription.plan.amount / 100) | currency }}/{{
													subscription.plan.interval
												}}</strong
											>
											<strong v-else>Custom price</strong>
										</p>
									</div>

									<!-- <div class="col-auto border-end">
										<p class="subtitle mb-1">Started</p>
										<p class="mb-0">
											<strong>{{ subscription.start_date | dateLocal }}</strong>
										</p>
									</div> -->

									<div v-if="subscription.ended_at" class="col-auto">
										<p class="subtitle mb-1">Ended on</p>
										<p class="mb-0">
											<strong>{{ subscription.ended_at | dateLocal }}</strong>
										</p>
									</div>
									<div v-else-if="subscription.cancel_at" class="col-auto">
										<p class="subtitle mb-1">Cancels on</p>
										<p class="mb-0">
											<strong>{{ subscription.cancel_at | dateLocal }}</strong>
										</p>
									</div>
									<div v-else-if="subscription.canceled_at" class="col-auto">
										<p class="subtitle mb-1">Canceled on</p>
										<p class="mb-0">
											<strong>{{ subscription.canceled_at | dateLocal }}</strong>
										</p>
									</div>
									<div v-else class="col-auto">
										<p class="subtitle mb-1">Next invoice</p>
										<p class="mb-0">
											<strong>{{ subscription.current_period_end | dateLocal }}</strong>
										</p>
									</div>
								</div>

								<hr class="bg-primary-100" />

								<p v-if="subscription.description" class="mb-2">
									Description:
									<strong>{{ subscription.description }}</strong>
								</p>
								<p v-if="subscription.collection_method === 'send_invoice'" class="mb-2">
									Subscription payment:
									<strong
										>We will send an invoice that can be paid by card, ACH Direct Debit or
										check</strong
									>
								</p>
								<template v-else-if="subscription.collection_method === 'charge_automatically'">
									<p class="mb-2">
										Subscription payment:
										<strong>Your card on file will be charged automatically</strong>
									</p>
									<p class="mb-3">
										Payment method &amp; Payment plan:
										<strong
											v-if="
												stripeCustomer.invoice_settings.default_payment_method ||
													subscription.default_payment_method
											"
											class="text-uppercase"
										>
											<span
												v-if="
													paymentMethod(
														stripeCustomer.invoice_settings.default_payment_method ||
															subscription.default_payment_method
													).type === 'card'
												"
											>
												{{
													paymentMethod(
														stripeCustomer.invoice_settings.default_payment_method ||
															subscription.default_payment_method
													).card.brand
												}}
												****
												{{
													paymentMethod(
														stripeCustomer.invoice_settings.default_payment_method ||
															subscription.default_payment_method
													).card.last4
												}}
											</span>
											<span
												v-else-if="
													paymentMethod(
														stripeCustomer.invoice_settings.default_payment_method ||
															subscription.default_payment_method
													).type === 'us_bank_account'
												"
											>
												{{
													paymentMethod(
														stripeCustomer.invoice_settings.default_payment_method ||
															subscription.default_payment_method
													).us_bank_account.bank_name
												}}
												****
												{{
													paymentMethod(
														stripeCustomer.invoice_settings.default_payment_method ||
															subscription.default_payment_method
													).us_bank_account.last4
												}}
											</span>
											<code v-else>{{
												stripeCustomer.invoice_settings.default_payment_method ||
													subscription.default_payment_method
											}}</code>
										</strong>
										<a v-else :href="`${apiUrl + j.slug}/billing/subscriptions/${subscription.id}`"
											>Add card and subscribe now</a
										>
									</p>
								</template>
								<p v-else class="mb-2">
									Subscription payment:

									<strong>{{ subscription.collection_method }}</strong>
								</p>

								<table class="table table-hover">
									<thead>
										<tr>
											<th>Invoice</th>
											<th>Amount</th>
											<th>Date</th>
											<th>Due</th>
											<th>Status</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="invoice in subscription.invoices" :key="invoice.id">
											<td>{{ invoice.number }}</td>
											<td>{{ (invoice.amount_due / 100) | currency }}</td>
											<td>{{ invoice.created | dateLocal }}</td>
											<td>{{ invoice.due_date | dateLocal }}</td>
											<td>
												<span
													v-if="invoice.status === 'paid'"
													class="badge bg-success-50 text-success-400"
													>Paid</span
												>
												<a
													v-else-if="invoice.status === 'open'"
													:href="invoice.hosted_invoice_url"
													target="_blank"
													class="btn btn-sm btn-primary"
													>Pay invoice</a
												>
												<span
													v-else-if="invoice.status === 'void'"
													class="badge bg-neutral-100 text-dark"
													>VOID</span
												>
												<span
													v-else-if="invoice.status === 'uncollectible'"
													class="badge bg-neutral-100 text-dark"
													>Uncollectible</span
												>
												<span v-else class="badge bg-warning-50 text-warning-400">{{
													invoice.status
												}}</span>
											</td>
											<td>
												<a
													v-if="['open', 'paid'].includes(invoice.status)"
													:href="invoice.invoice_pdf"
													target="_blank"
													><font-awesome-icon
														:icon="['fas', 'file-pdf']"
														class="text-danger"
													/>
													View invoice</a
												>
											</td>
										</tr>

										<tr v-if="!subscription.invoices.length">
											<td colspan="6" class="text-center"><i>No invoices found.</i></td>
										</tr>
									</tbody>
								</table>

								<p class="card-text text-end">
									<a
										:href="`${apiUrl + j.slug}/billing/subscriptions/${subscription.id}`"
										class="btn btn-sm btn-primary"
										>Manage subscription</a
									>
								</p>
							</div>

							<p
								v-if="!clerkMinutesSubscription || stripeSubscriptions.length > 1"
								class="text-neutral-400"
							>
								If you pay by check at the address indicated on the invoice, please send us a
								confirmation by email to <code>heygov@heygov.com</code>.
							</p>
						</div>

						<div
							v-if="clerkMinutesSubscription || $route.query.refer"
							class="bg-primary-50 p-3 rounded-1 mt-5"
						>
							<h4 class="mb-3 text-primary-400">ClerkMinutes: Refer-a-clerk program</h4>

							<p class="mb-2">
								Efficient meeting management shouldn't be your secret alone. Share it with your
								colleagues and contacts in other municipalities.
							</p>
							<p class="mb-2">
								Our "Refer-a-Clerk" program is designed to bring more efficiency to your network. It's a
								win-win: You enjoy an extended free trial, and your colleagues get to try out a 30-day
								free trial of ClerkMinutes, instead of the standard 14-day trial!
							</p>
							<p class="mb-2">
								Every successful referral using your code rewards you with 30 days of free ClerkMinutes.
								So, refer 10 folks and you're looking at almost a year's worth of ClerkMinutes, on the
								house!
							</p>
							<p class="mb-0">
								<input
									type="url"
									class="form-control"
									readonly
									autoselect
									:value="`https://clerkminutes.com/try?ref=${j.slug}`"
								/>
							</p>
						</div>
					</div>
					<div v-else class="card-body text-center">
						<p class="card-text">The billing account is not set up yet for this account.</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import heyGovApi from '@/api.js'
import { handleResponseError } from '@/utils.js'

export default {
	name: 'JurisdictionBilling',
	data() {
		return {
			states: {
				billing: 'loading',
			},
			stripeCustomer: null,
			stripeSubscriptions: [],
			stripeProducts: [],
			stripePaymentMethods: [],

			invoices: [
				{
					id: 'in_1IYQZt2eZvKYlo2CJ9Z2Z2Z2',
					amount_due: 1,
					due_date: '2021-05-15T00:00:00Z',
					status: 'in-dev',
					hosted_invoice_url: 'https://pay.stripe.com/invoice/invst_1IYQZt2eZvKYlo2CJ9Z2Z2Z2',
				},
			],
		}
	},
	created() {
		if (this.currentRole === 'ADMIN') {
			if (this.j.stripe_customer_id) {
				this.loadBilling()
			}
		} else {
			this.$router.push(`/${this.j.slug}`)
		}
	},
	computed: {
		...mapState(['j', 'apiUrl']),
		...mapGetters(['currentRole']),
		clerkMinutesSubscription() {
			const subscriptionsWithPlans = this.stripeSubscriptions.filter(subscription => subscription.plan)

			return subscriptionsWithPlans.find(subscription => {
				return this.stripeProducts.find(p => p.id === subscription.plan.product).name
			})
		},
	},
	methods: {
		loadBilling() {
			this.states.billing = 'loading'

			heyGovApi.get(`${this.j.slug}/billing`).then(({ data }) => {
				this.stripeCustomer = data.customer
				this.stripeSubscriptions = data.subscriptions
				this.stripeProducts = data.products
				this.stripePaymentMethods = data.paymentMethods

				this.states.billing = 'loaded'
			}, handleResponseError('Error loading billing info ({error})'))
		},
		paymentMethod(id) {
			return this.stripePaymentMethods.find(pm => pm.id === id)
		},
	},
}
</script>
