<template>
	<div class="page-meeting-single">
		<div class="row align-items-center gx-3 mb-3">
			<div class="col">
				<h3 class="my-0">
					<router-link :to="`/${j.slug}/meetings`">Meetings</router-link>
					<font-awesome-icon :icon="['fas', 'angle-right']" class="text-muted ms-2" />
					{{ meeting ? meeting.title : $route.params.meetingId }}
				</h3>
			</div>
			<div v-if="meeting && meeting.state === 'upcoming'" class="col-auto">
				<add-to-calendar-dropdown :event="meeting" />
			</div>
			<div v-if="meeting && currentRole !== 'CITIZEN'" class="col-auto">
				<div class="btn-group" :class="{ 'opacity-50': $route.name === 'MeetingPublicPage' }">
					<button type="button" class="btn btn-sm btn-outline-dark" @click="editMeeting">Edit meeting</button>
					<button
						type="button"
						class="btn btn-sm btn-outline-dark dropdown-toggle dropdown-toggle-split"
						data-bs-toggle="dropdown"
						aria-expanded="false"
					>
						<span class="visually-hidden">More meeting options</span>
					</button>
					<ul class="dropdown-menu">
						<li>
							<button class="dropdown-item" @click="rescheduleMeeting">Reschedule meeting</button>
						</li>
						<li v-if="meeting.video_file_path || meeting.audio_file_path">
							<button class="dropdown-item text-danger-400" @click="removeRecording">
								Remove recording
							</button>
						</li>
						<template v-if="isStaff">
							<li><hr class="dropdown-divider" /></li>
							<li>
								<button class="dropdown-item" @click="moveMeeting">Move to other muni</button>
							</li>
							<li>
								<a
									:href="
										`https://console.cloud.google.com/storage/browser/files.heygov.com/${j.slug}/meetings/${meeting.pid}/`
									"
									target="_blank"
									class="dropdown-item"
									>Open files storage</a
								>
							</li>
						</template>
						<li><hr class="dropdown-divider" /></li>
						<li v-if="meeting.state === 'upcoming'">
							<button class="dropdown-item text-danger-400" @click="cancelMeeting">Cancel meeting</button>
						</li>
						<li>
							<button class="dropdown-item text-danger-400" @click="deleteMeeting">Delete meeting</button>
						</li>
					</ul>
				</div>
			</div>
		</div>

		<div v-if="meeting" class="row">
			<div v-if="meeting.conferencing_link" class="col-auto mb-3">
				<p>
					<font-awesome-icon :icon="['fas', 'video']" /> Meeting Link:<br />
					<a :href="meeting.conferencing_link" target="_blank">{{ meeting.conferencing_link }}</a>
					<template v-if="meeting.conferencing_link_note">
						<br />
						<small class="text-muted">{{ meeting.conferencing_link_note }}</small>
					</template>
				</p>
			</div>
			<div class="col"></div>
			<div class="col-auto mb-3" v-if="meeting.state === 'upcoming'">
				<p class="text-end">
					Time until meeting starts:<br />
					<span class="fs-5 fw-bold">{{ countdown }}</span>
				</p>
			</div>
		</div>

		<nav
			v-if="currentRole !== 'CITIZEN' && meeting"
			class="mb-3"
			:class="{ 'opacity-50': $route.name === 'MeetingPublicPage' }"
		>
			<ul
				class="nav nav-pills nav-pills-form hide-scrollbar mb-2"
				style="flex-wrap: nowrap;min-width: 100%;overflow-x: scroll;"
			>
				<li class="nav-item">
					<router-link
						:to="`/${j.slug}/meetings/${$route.params.meetingId}`"
						class="nav-link"
						:class="{ active: $route.name === 'Meeting' }"
						>Overview</router-link
					>
				</li>
				<li class="nav-item">
					<router-link
						:to="`/${j.slug}/meetings/${$route.params.meetingId}/agenda`"
						:class="{ active: $route.name === 'MeetingAgenda' }"
						class="nav-link"
					>
						Agenda
						<span v-if="meeting.agenda_items.length" class="badge bg-neutral-100 text-success-300">
							{{ meeting.agenda_items.length }}
						</span>
					</router-link>
				</li>
				<li class="nav-item">
					<router-link
						:to="`/${j.slug}/meetings/${$route.params.meetingId}/transcript`"
						class="nav-link"
						:class="{ active: $route.name === 'MeetingTranscript' }"
					>
						Transcript &amp; Speakers
						<span
							v-if="meeting.transcript_job_status === 'uploading'"
							class="badge bg-neutral-100 text-success-300"
						>
							<strong v-if="states.meeting_audio_video_progress" class="text-primary-300">
								{{ (states.meeting_audio_video_progress * 100).toFixed(1) }}%
							</strong>
						</span>
						<span v-else-if="meeting.transcript_job_status === 'error'">
							⚠️
						</span>
					</router-link>
				</li>
				<li class="nav-item">
					<router-link
						:to="`/${j.slug}/meetings/${$route.params.meetingId}/minutes`"
						class="nav-link"
						:class="{ active: $route.name === 'MeetingMinutes' }"
					>
						Minutes
					</router-link>
				</li>

				<li class="nav-item ms-auto">
					<router-link
						:to="`/${j.slug}/meetings/${$route.params.meetingId}/info`"
						class="nav-link"
						:class="{ active: $route.name === 'MeetingPublicPage' }"
						>Public page for meeting
					</router-link>
				</li>
			</ul>
		</nav>

		<div v-if="states.meeting === 'loading'" class="text-center py-5">
			<span class="spinner-border spinner-border-sm"></span> Loading meeting details..
		</div>
		<div v-else-if="states.meeting === 'loaded' && meeting">
			<div class="row">
				<div class="col">
					<router-view
						:meeting="meeting"
						:meetingPlayer="meetingPlayer"
						@updateMeeting="payload => updateMeeting(payload.fields, payload.message)"
						@playerStyles="setPlayerStyles"
						@playerTimestamp="payload => setPlayerTimestamp(payload, true)"
						@uploadMeetingAudioVideo="uploadMeetingAudioVideo"
						@submitRequestedAgendaItem="fetchAgendaItems"
						@submitRequestedChangesForAgendaItem="fetchAgendaItems"
						@loadTranscript="loadMeetingTranscript"
						@loadMeeting="loadMeeting"
					></router-view>
				</div>
				<div
					v-if="['Meeting', 'MeetingAgenda', 'MeetingTranscript', 'MeetingMinutes'].includes($route.name)"
					class="col-4"
				>
					<div class="position-sticky" style="top: 16px;">
						<div
							class="rounded-1 bg-neutral-200 mb-3"
							:class="{
								'border border-warning border-dashed':
									!meeting.video_file_path &&
									!meeting.audio_file_path &&
									meeting.state !== 'upcoming',
							}"
						>
							<video
								v-if="meeting.video_file_path"
								controls
								preload="metadata"
								:src="getPublicFileUrl(meeting.video_file_path)"
								class="ratio ratio-16x9 rounded-1"
								ref="meetingplayer"
								@timeupdate="playerTimeupdate"
							>
								<!-- <track
								label="English"
								kind="captions"
								srclang="en"
								src="http://localhost:8080/captions/me_123.vtt"
								default
							/> -->

								Your browser can't play this video format. You can
								<a :href="getPublicFileUrl(meeting.video_file_path)" download>
									download the video
								</a>
								and watch it directly on your computer.
							</video>
							<audio
								v-else-if="meeting.audio_file_path"
								controls
								preload="metadata"
								:src="getPublicFileUrl(meeting.audio_file_path)"
								class="rounded-1 w-100"
								ref="meetingplayer"
								@timeupdate="playerTimeupdate"
							>
								Your browser can't play this audio format. You can
								<a :href="getPublicFileUrl(meeting.audio_file_path)" download>
									download the audio
								</a>
								and listen directly on your computer.
							</audio>
							<div v-else-if="meeting.state === 'upcoming'" class="ratio ratio-16x9 text-center">
								<div class="d-flex align-items-center px-5">
									<div>
										<p class="mb-2">
											<font-awesome-icon :icon="['fas', 'video']" class="text-neutral-500" />
										</p>
										<p class="mb-0 text-neutral-400">
											Recordings can be added after the meeting takes place
										</p>
									</div>
								</div>
							</div>
							<div v-else class="ratio ratio-16x9 text-center">
								<div v-if="meeting.transcript_job_status === 'uploading'" class="pt-5">
									<p class="my-3">
										<span class="spinner-border spinner-border-sm"></span> Uploading recording
									</p>

									<p v-if="states.meeting_audio_video_progress">
										<strong class="text-primary-300"
											>{{ (states.meeting_audio_video_progress * 100).toFixed(1) }}%</strong
										>
									</p>
									<p v-else-if="meeting.video_public_url" class="text-neutral-400">
										from {{ urlPart(meeting.video_public_url, 'hostname').replace('www.', '') }},
										shouldn't take more than 5 minutes
									</p>
								</div>
								<div
									v-else
									class="d-flex justify-content-center align-items-center file-drop"
									@dragover="dragover"
									@dragleave="dragleave"
									@drop="dropMeetingAudioVideo"
								>
									<div>
										<p class="mb-3 text-neutral-500">
											<small
												><font-awesome-icon :icon="['fas', 'video']" class="me-1" /> Meeting
												recording</small
											>
										</p>

										<p class="my-0">
											<label
												for="meeting-audio-video-file"
												class="d-inline-block btn btn-sm btn-outline-primary"
											>
												<font-awesome-icon :icon="['fas', 'file-import']" class="me-1" />
												Upload audio or video
											</label>
										</p>

										<small class="d-block my-2 text-neutral-300">or</small>

										<button
											class="btn btn-sm btn-outline-primary"
											data-bs-toggle="modal"
											data-bs-target="#modal-recording-url"
										>
											<font-awesome-icon :icon="['fas', 'plus']" /> Add YouTube/Vimeo/Zoom link
										</button>

										<input
											type="file"
											id="meeting-audio-video-file"
											class="d-none"
											@change="handleMeetingAudioVideo"
											accept="audio/*,video/*"
										/>
									</div>
								</div>
							</div>
						</div>

						<div v-if="audioVideoFileError" class="alert alert-danger text-dark">
							⚠️ {{ audioVideoFileError }}
						</div>
						<div
							v-else-if="meeting.transcript_job_status === 'upload-error'"
							class="alert alert-danger text-dark"
						>
							⚠️ The recording upload failed. Please try again.
						</div>

						<div class="card">
							<ul class="card-nav nav nav-tabs">
								<li class="nav-item">
									<button
										class="nav-link py-3"
										:class="{ active: sidebarTab === 'activity' }"
										@click="setSidebarTab('activity')"
									>
										Activity
									</button>
								</li>
								<li class="nav-item">
									<button
										class="nav-link py-3"
										:class="{
											active: sidebarTab === 'transcript',
											'cursor-not-allowed': meeting.transcript_job_status !== 'transcribed',
										}"
										:disabled="meeting.transcript_job_status !== 'transcribed'"
										@click="setSidebarTab('transcript')"
									>
										Transcript
									</button>
								</li>
								<li class="nav-item">
									<button
										class="nav-link py-3"
										:class="{ active: sidebarTab === 'chat' }"
										@click="setSidebarTab('chat')"
									>
										Chat <small class="badge bg-danger-50 text-danger-400">beta</small>
									</button>
								</li>
							</ul>

							<div v-if="sidebarTab === 'activity'" class="card-body pt-0 px-3">
								<activity-timeline :activities="activities"></activity-timeline>
							</div>
							<div v-else-if="sidebarTab === 'transcript'" class="card-body p-0">
								<div class="bg-neutral-50">
									<div class="row align-items-center gx-2 px-3 py-1">
										<div class="col-auto">
											Jump to:
										</div>
										<div class="col">
											<select
												class="form-select form-select-sm"
												v-model="sidebarTabAgendaItemActive"
												@change="sidebarAgendaChange"
											>
												<option
													v-for="item in meeting.agenda_items"
													:key="item.id"
													:value="item.id"
													>{{ truncateString(item.title, 35) }} -
													{{ timestampToMinutes(item.timestamp_start) }}</option
												>
											</select>
										</div>
									</div>
								</div>

								<div style="overflow-y: auto; max-height: 460px">
									<div>
										<div
											v-for="line in meeting.transcript"
											:key="line.id"
											class="px-3 py-1"
											:class="
												meetingPlayer.currentTime > line.timestamp &&
												meetingPlayer.currentTime <= line.timestamp_end
													? 'bg-primary-50'
													: 'hover'
											"
											:ref="'transcript-line-' + line.id"
										>
											<p class="mb-1">
												<small
													class="badge badge-xs bg-primary-50 text-primary-400 me-2"
													role="button"
													@click="setPlayerTimestamp(line.timestamp, true)"
													>{{ timestampToMinutes(line.timestamp) }}</small
												>
												<small class="text-neutral-400">Speaker {{ line.speaker + 1 }}</small>
											</p>
											<p class="mb-0">{{ line.text }}</p>
										</div>
									</div>
								</div>
							</div>
							<div v-else-if="sidebarTab === 'chat'" class="card-body pt-0 px-3">
								<div class="meeting-messages mt-3" style="min-height: 200px">
									<div
										v-for="message in meetingMessages"
										:key="message.id || message.content"
										class="mb-3"
									>
										<div v-if="message.role === 'user'" class="text-end">
											<span class="d-inline-block px-2 rounded-1 bg-neutral-100">{{
												message.content
											}}</span>
										</div>
										<div v-else class="meeting-messages-assistant" v-html="message.content"></div>
									</div>
								</div>

								<form @submit.prevent="() => meetingChat()">
									<div class="input-group input-group-sm">
										<input
											type="text"
											class="form-control"
											v-model="meetingMessagesQuery"
											required
											minlength="2"
											placeholder="Ask anything about the meeting"
											:disabled="states.meetingChat === 'loading'"
										/>
										<button class="btn">
											<span
												v-if="states.meetingChat === 'loading'"
												class="spinner-border spinner-border-sm"
											></span>
											<font-awesome-icon v-else :icon="['fas', 'paper-plane']" />
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-else-if="states.meeting === 'error'" class="alert alert-danger mx-6">
			Error loading meeting ({{ errorMessage }})
		</div>
		<div v-else class="text-center py-5">
			Error loading meeting info
		</div>

		<div class="modal fade" id="modal-recording-url" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title my-0">Upload recording by link</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						<form @submit.prevent="addRecordingByUrl">
							<p>If you have a recording uploaded on YouTube, Vimeo, or Zoom, paste the link below.</p>

							<p>
								ℹ️ The recording link needs to be public, and not require a password or authentication.
							</p>

							<div class="form-row mb-4">
								<label class="form-label" for="recording-url">
									Recording URL
								</label>

								<input
									type="url"
									class="form-control"
									id="recording-url"
									v-model="recordingUrl"
									required
									placeholder="https://www.youtube.com/watch?v=..."
								/>
							</div>

							<p class="card-text text-center">
								<button class="btn btn-primary">Upload recording</button>
							</p>
						</form>
					</div>
				</div>
			</div>
		</div>

		<div class="modal fade" id="modal-edit-meeting" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<edit-meeting-form
					v-if="meeting"
					:defaultMeeting="meeting"
					@submit="handleEditMeetingSubmit"
					@close="$modalEditMeeting.hide()"
				/>
			</div>
		</div>

		<div class="modal fade" id="modal-reschedule-meeting" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<reschedule-meeting-modal v-if="meeting" :meeting="meeting" @submit="handleReschedule" />
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
@import '@/assets/variables';

.for-file-input {
	border: 1px dashed $neutral-200;
	background-color: $neutral-50;
	cursor: pointer;
	transition: background-color 0.2s ease-in-out, transform 0.2s ease-in-out;

	&:hover {
		background-color: $neutral-100;
		border-color: $neutral-300;
	}

	&.dragover {
		background-color: $warning-50;
		border-color: $warning-100;
		transform: scale(1.05);
	}
}

.meeting-audio-video-player {
	position: fixed;
	width: 350px;
	border: 1px solid $neutral-200;
	border-radius: 0.75rem;
	background-color: #fff;
}
</style>

<style lang="scss">
@import '@/assets/variables';

.minutes-text {
	//background-color: $neutral-50;

	p {
		margin-bottom: 0.8rem;
	}

	blockquote {
		background-color: #f0f9ff;
		border-left: 4px solid #e0f2fe;
		border-radius: 0.4rem;
		padding: 0.6rem 1rem;
		margin-bottom: 0.8rem;
	}
}
</style>

<script>
import axios from 'axios'
import { Modal } from 'bootstrap'
import { mapState, mapGetters } from 'vuex'
import Vue from 'vue'
import { Converter } from 'showdown'

import heyGovApi, { hgApi } from '@/api.js'
import { getPublicFileUrl, handleResponseError, sendEvent } from '@/utils.js'
import { truncateString } from '@/lib/strings.js'

import ActivityTimeline from '@/components/ActivityTimeline.vue'
import AddToCalendarDropdown from '@/components/events/AddToCalendarDropdown.vue'
import EditMeetingForm from './EditMeetingForm/EditMeetingForm.vue'
import RescheduleMeetingModal from './RescheduleMeetingModal.vue'

const converter = new Converter()
converter.setOption('simplifiedAutoLink', true)
converter.setOption('openLinksInNewWindow', true)

export default {
	name: 'Meeting',
	metaInfo() {
		return {
			title: `${this.meeting?.title || this.$route.params.meetingId} - Meetings`,
		}
	},
	components: { ActivityTimeline, AddToCalendarDropdown, EditMeetingForm, RescheduleMeetingModal },
	data() {
		return {
			states: {
				meeting: 'loading',
				agenda_file_path: 'idle',
				agenda_items: 'idle',

				meeting_audio_video_file: 'idle',
				meeting_audio_video_progress: 0,
				transcript: 'idle',

				meetingChat: 'idle',
			},
			errorMessage: '',

			meeting: null,
			minutesStatusTimer: null,
			errors: [],
			transcriptionStatusTimer: null,

			$modalMeetingAgenda: null,
			$modalMeetingAudioVideo: null,
			$modalEditMeeting: null,

			audioVideoFileError: '',
			recordingUrl: '',
			$modalRecordingUrl: null,

			// meeting AI chat
			meetingMessagesQuery: '',
			meetingMessages: [],

			meetingPlayer: {
				currentTime: 0,
				position: 'default',
				initialX: 0,
				initialY: 0,
				styles: {
					position: 'fixed',
					width: '300px',
					right: '10px',
					bottom: '10px',
				},
			},

			sidebarTab: 'activity',
			sidebarTabTranscriptLineActive: 0,
			sidebarTabAgendaItemActive: 0,

			activities: [],

			countdown: '',

			$modalRescheduleMeeting: null,
		}
	},
	computed: {
		...mapState(['j', 'account', 'apiUrl', 'people', 'departments']),
		...mapGetters(['currentRole', 'isStaff', 'auth']),
		transcriptTokens() {
			return Math.ceil((this.meeting?.transcript || '').trim().length / 4)
		},
	},
	created() {
		this.loadMeeting()

		if (this.auth || ['RequestedAgendaItemForm', 'RequestedAgendaItemChangesForm'].includes(this.$route.name)) {
			this.$store.dispatch('loadDepartments')

			sendEvent('meeting.view', {
				feature: 'Meetings',
				meeting_id: this.$route.params.meetingId,
			})
		} else if (this.$route.name !== 'MeetingPublicPage') {
			this.$router.replace({
				name: 'MeetingPublicPage',
				params: {
					jurisdiction: this.j.slug,
					meetingId: this.$route.params.meetingId,
				},
			})
		}
	},
	mounted() {
		this.$modalRecordingUrl = new Modal(document.getElementById('modal-recording-url'))
		this.$modalEditMeeting = new Modal(document.getElementById('modal-edit-meeting'))
		this.$modalRescheduleMeeting = new Modal(document.getElementById('modal-reschedule-meeting'))

		if (this.$route.query.ts) {
			setTimeout(() => {
				this.setPlayerTimestamp(Number(this.$route.query.ts))
			}, 1000)
		}
	},
	methods: {
		truncateString,

		async handleEditMeetingSubmit(fields, after) {
			await this.updateMeeting(fields, 'Meeting updated', after)
			this.$modalEditMeeting.hide()
		},

		getPublicFileUrl,

		async fetchAgendaItems() {
			try {
				const { data } = await heyGovApi(
					`${this.j.slug}/meetings/${this.$route.params.meetingId}?expand=agenda_items`
				)
				this.meeting.agenda_items = data.agenda_items.map(item => {
					item._editing = false
					return item
				})
			} catch (error) {
				handleResponseError(`Couldn't fetch agenda items ({error})`)(error)
			}
		},

		loadMeeting() {
			this.states.meeting = 'loading'

			heyGovApi(`${this.j.slug}/meetings/${this.$route.params.meetingId}?expand=agenda_items,venue,activity`)
				.then(({ data }) => {
					// process data for UI
					data.minutes_text = data.minutes_text || ''
					data.transcript = []

					this.meeting = data
					this.states.meeting = 'loaded'

					// set first Agenda Item as active in Transcript Sidebar
					if (this.meeting.agenda_items.length) {
						this.sidebarTabAgendaItemActive = this.meeting.agenda_items[0].id
					}

					// Set activities from the expanded data
					this.activities = data.activities || []

					// Load person data for activities that don't have it yet
					const personIds = new Set(this.activities.map(a => a.person_id).filter(Boolean))
					personIds.forEach(id => {
						this.$store.dispatch('loadPerson', id)
					})

					if (data.transcript_job_status === 'started') {
						this.transcriptionStatusTimer = setInterval(() => {
							this.checkTranscriptJobStatus()
						}, 5000)
					}

					if (data.minutes_status === 'generating') {
						this.minutesStatusTimer = setInterval(() => {
							this.checkMinutesJobStatus()
						}, 5000)
					}

					setInterval(this.updateCountdown, 1000)
				})
				.catch(error => {
					if (error.response?.status === 404) {
						this.errorMessage = 'Meeting not found'
					} else {
						this.errorMessage = error.response?.data?.message || 'Error loading meeting'
					}

					handleResponseError(`Couldn't load meeting details ({error})`)(error)
					this.states.meeting = 'error'
				})
		},

		loadMeetingTranscript() {
			if (!this.meeting.transcript.length) {
				hgApi(`${this.j.slug}/meetings/${this.meeting.pid}/transcript`)
					.then(response => response.json())
					.then(lines => {
						this.meeting.transcript.push(...lines)
					})
					.catch(handleResponseError('Failed to load transcript ({error})'))
			}
		},

		async updateMeeting(fields, successMessage = 'Meeting updated', after = () => {}) {
			let data = undefined
			try {
				const resp = await heyGovApi.put(`${this.j.slug}/meetings/${this.meeting.pid}`, fields)
				for (const key in fields) {
					Vue.set(this.meeting, key, fields[key])
				}
				Vue.toasted.success(successMessage)
				data = resp.data
			} catch (error) {
				handleResponseError('Error updating meeting ({error})')(error)
			} finally {
				after()
			}
			return data
		},

		editMeeting() {
			this.$modalEditMeeting.show()
		},

		rescheduleMeeting() {
			this.$modalRescheduleMeeting.show()

			sendEvent('meeting.reschedule_start', {
				feature: 'Meetings',
				meeting_id: this.meeting.pid,
				meeting: this.meeting.title,
			})
		},

		async handleReschedule({ starts_at_local, ends_at_local }, after) {
			try {
				// send local time so backend handles the timezines
				const data = await this.updateMeeting(
					{
						starts_at_local: starts_at_local,
						ends_at_local: ends_at_local,
					},
					'Meeting rescheduled successfully'
				)
				// use response to update non-local fields
				this.meeting.starts_at = data.starts_at
				this.meeting.ends_at = data.ends_at
				// hide modal
				this.$modalRescheduleMeeting.hide()

				sendEvent('meeting.reschedule_success', {
					feature: 'Meetings',
					meeting_id: this.meeting.pid,
					meeting: this.meeting.title,
				})

				await this.loadMeetingActivities()
			} catch (error) {
				handleResponseError('Error rescheduling meeting ({error})')(error)
			} finally {
				after()
			}
		},

		async loadMeetingActivities() {
			try {
				let path = `${this.j.slug}/meetings/${this.meeting.pid}/activities`
				let since_id = this.activities.length > 0 ? this.activities[0].id : undefined // first is the newest
				if (since_id) {
					path += `?since_id=${since_id}`
				}

				const { data } = await heyGovApi(path)
				this.activities.unshift(...data)

				this.$store.dispatch(
					'loadPeople',
					data.map(a => a.person_id)
				)
			} catch (error) {
				handleResponseError('Error loading meeting activities ({error})')(error)
			}
		},

		cancelMeeting() {
			Vue.toasted.error('Not implemented yet 🤷')

			sendEvent('meeting.cancel', {
				feature: 'Meetings',
				meeting_id: this.$route.params.meetingId,
				meeting: this.meeting.title,
				implemented: 'no',
			})
		},

		moveMeeting() {
			const newJurisdiction = prompt('Enter jurisdiction slug to move meeting to')

			if (newJurisdiction?.trim().length && newJurisdiction.trim() !== this.j.slug) {
				heyGovApi
					.post(`${this.j.slug}/meetings/${this.meeting.pid}/move`, {
						jurisdiction: newJurisdiction.trim(),
					})
					.then(() => {
						Vue.toasted.success('Meeting is moved')
						window.location = `/${newJurisdiction.trim()}/meetings/${this.meeting.pid}`
					}, handleResponseError('Error moving meeting ({error})'))
			}
		},

		deleteMeeting() {
			if (['uploading', 'started'].includes(this.meeting.transcript_job_status)) {
				alert('The meeting can\t be deleted while the recording is being processed')
			} else if (confirm(`Delete meeting and all its content?`)) {
				hgApi(`${this.j.slug}/meetings/${this.meeting.pid}`, {
					method: 'DELETE',
				}).then(response => {
					if (response.ok) {
						Vue.toasted.show('Meeting is deleted')
						this.$router.push(`/${this.j.slug}/meetings`)
					} else {
						alert(`Error deleting meeting (${response.statusText})`)
					}
				})
			}
		},

		setSidebarTab(tab) {
			this.sidebarTab = tab

			if (tab === 'transcript') {
				this.loadMeetingTranscript()
			}
		},

		playerTimeupdate(event) {
			this.meetingPlayer.currentTime = event.target.currentTime
		},
		setPlayerStyles(styles) {
			styles.zIndex = 999
			this.meetingPlayer.styles = styles
			this.meetingPlayer.position = 'default'
		},

		dragover(event) {
			event.preventDefault()

			if (!event.currentTarget.classList.contains('dragover')) {
				event.currentTarget.classList.add('dragover')
			}
		},
		dragleave(event) {
			event.currentTarget.classList.remove('dragover')
		},

		// recording upload
		dropMeetingAudioVideo(event) {
			event.preventDefault()
			this.dragleave(event)

			if (event.dataTransfer.files.length) {
				this.uploadMeetingAudioVideo(event.dataTransfer.files[0])
			} else {
				alert('No files dropped 🤷')
			}
		},
		handleMeetingAudioVideo($event) {
			this.uploadMeetingAudioVideo($event.target.files[0])
		},
		async uploadMeetingAudioVideo(file) {
			this.audioVideoFileError = ''

			if (!file.type.startsWith('audio/') && !file.type.startsWith('video/')) {
				Vue.toasted.error('Only audio or video files are allowed 🤷')
				sendEvent('meeting.recording_upload_error', {
					feature: 'Meetings',
					meeting_id: this.meeting.pid,
					meeting: this.meeting.title,
					source: 'file',
					error: 'format not supported',
					file: file.name,
					size: file.size,
					type: file.type,
				})
				return
			} else if (file.size < 1024 * 1024 * 3) {
				Vue.toasted.error('File is too small to be a meeting recording 🤷')
				sendEvent('meeting.recording_upload_error', {
					feature: 'Meetings',
					meeting_id: this.meeting.pid,
					meeting: this.meeting.title,
					source: 'file',
					error: 'file size too small',
					file: file.name,
					size: file.size,
					type: file.type,
				})
				return
			} else if (file.size > 1024 * 1024 * 1024 * 5) {
				Vue.toasted.error('File is too big 😬 please compress it to be under 5GB')
				sendEvent('meeting.recording_upload_error', {
					feature: 'Meetings',
					meeting_id: this.meeting.pid,
					meeting: this.meeting.title,
					source: 'file',
					error: 'file size too big',
					file: file.name,
					size: file.size,
					type: file.type,
				})
				return
			}

			this.meeting.transcript_job_status = 'uploading'

			const beforeUnloadHandler = event => {
				event.returnValue = 'If you leave this page, the upload will be interrupted'
			}

			window.addEventListener('beforeunload', beforeUnloadHandler)

			const uploadStartedAt = Date.now()

			sendEvent('meeting.recording_upload_start', {
				feature: 'Meetings',
				meeting_id: this.meeting.pid,
				meeting: this.meeting.title,
				source: 'file',
				file: file.name,
				size: file.size,
				type: file.type,
			})

			try {
				const uploadLinkResponse = await heyGovApi.post(
					`${this.j.slug}/meetings/${this.meeting.pid}/audio-video-link`,
					{
						name: file.name,
						size: file.size,
						type: file.type,
					}
				)

				await axios.put(uploadLinkResponse.data.uploadUrl, file, {
					headers: { 'Content-Type': file.type },
					onUploadProgress: p => {
						this.states.meeting_audio_video_progress = p.loaded / p.total
					},
				})

				const fields = {
					transcript_job_status: 'started',
				}

				if (file.type.startsWith('audio/')) {
					fields.audio_file_path = uploadLinkResponse.data.path
					Vue.toasted.success(`Audio file is uploaded`)
				} else {
					fields.video_file_path = uploadLinkResponse.data.path
					Vue.toasted.success(`Video file is uploaded`)
				}

				this.updateMeeting(fields, 'Transcription process is started')

				this.transcriptionStatusTimer = setInterval(() => {
					this.checkTranscriptJobStatus()
				}, 10000)

				sendEvent('meeting.recording_upload_completed', {
					feature: 'Meetings',
					meeting_id: this.meeting.pid,
					meeting: this.meeting.title,
					source: 'file',
					file: file.name,
					size: file.size,
					type: file.type,
					duration: Math.round((Date.now() - uploadStartedAt) / 1000),
				})
			} catch (error) {
				handleResponseError('Error uploading file ({error})')(error)
				this.meeting.transcript_job_status = 'not-started'
				this.audioVideoFileError = error.message

				sendEvent('meeting.recording_upload_error', {
					feature: 'Meetings',
					meeting_id: this.meeting.pid,
					meeting: this.meeting.title,
					source: 'file',
					error: error.message,
					file: file.name,
					size: file.size,
					type: file.type,
				})
			} finally {
				window.removeEventListener('beforeunload', beforeUnloadHandler)
			}
		},
		removeRecording() {
			if (confirm(`For sure remove all this?`)) {
				heyGovApi.delete(`${this.j.slug}/meetings/${this.meeting.pid}/audio-video-transcript`).then(() => {
					this.meeting.transcript_job_status = 'not-started'
					this.meeting.transcript_speakers = null
					this.meeting.audio_public_url = null
					this.meeting.audio_file_path = null
					this.meeting.video_public_url = null
					this.meeting.video_file_path = null
					this.transcript = []
				}, handleResponseError("Couldn't remove audio/video ({error})"))
			}
		},
		addRecordingByUrl() {
			this.recordingUrl = this.recordingUrl.trim()

			if (!URL.canParse(this.recordingUrl)) {
				Vue.toasted.error('Invalid URL')
				return
			}

			const parsedUrl = new URL(this.recordingUrl)

			const isYoutubeUrl = ['youtube.com', 'www.youtube.com', 'youtu.be'].includes(parsedUrl.hostname)
			const isVimeoUrl = parsedUrl.hostname.endsWith('vimeo.com')
			const isZoomUrl = parsedUrl.hostname.endsWith('zoom.us')

			if (!isYoutubeUrl && !isVimeoUrl && !isZoomUrl) {
				Vue.toasted.error('Only YouTube, Vimeo and Zoom recordings are supported')

				sendEvent('meeting.recording_upload_error', {
					feature: 'Meetings',
					meeting_id: this.meeting.pid,
					meeting: this.meeting.title,
					source: 'url',
					error: 'url not supported',
					url: this.recordingUrl,
				})

				return
			}

			const fields = {
				video_public_url: this.recordingUrl,
				transcript_job_status: 'uploading',
			}

			this.updateMeeting(fields, 'Recording upload is started')

			sendEvent('meeting.recording_upload_start', {
				feature: 'Meetings',
				meeting_id: this.meeting.pid,
				meeting: this.meeting.title,
				source: 'url',
				url: this.recordingUrl,
			})

			this.transcriptionStatusTimer = setInterval(() => {
				this.checkRecordingUploadStatus()
			}, 10000)

			this.$modalRecordingUrl.hide()
			this.recordingUrl = ''
		},
		urlPart(url, part) {
			return URL.parse(url)[part] || ''
		},

		async checkRecordingUploadStatus() {
			console.log(this.meeting.pid, 'checking recording upload status')
			const { data } = await heyGovApi(`${this.j.slug}/meetings/${this.meeting.pid}`)
			console.log(this.meeting.pid, `got transcript_job_status=${data.transcript_job_status}`)

			if (data.transcript_job_status === 'started') {
				this.meeting.transcript_job_status = data.transcript_job_status
				this.meeting.audio_file_path = data.audio_file_path
				this.meeting.video_file_path = data.video_file_path

				Vue.toasted.success('Meeting recording is uploaded')
				clearInterval(this.transcriptionStatusTimer)

				// start checking the transcript job
				this.transcriptionStatusTimer = setInterval(() => {
					this.checkTranscriptJobStatus()
				}, 10000)
			} else if (data.transcript_job_status === 'upload-error') {
				this.meeting.transcript_job_status = data.transcript_job_status
				this.meeting.video_public_url = null

				clearInterval(this.transcriptionStatusTimer)
				Vue.toasted.error("Couldn't upload the recording")
			}
		},

		async checkTranscriptJobStatus() {
			console.log(this.meeting.pid, 'checking transcript job status')
			const { data } = await heyGovApi(`${this.j.slug}/meetings/${this.meeting.pid}`)
			console.log(this.meeting.pid, `got transcript_job_status=${data.transcript_job_status}`)

			if (data.transcript_job_status === 'transcribed') {
				this.meeting.transcript_job_status = data.transcript_job_status
				this.meeting.transcript_speakers = data.transcript_speakers
				this.meeting.audio_file_path = data.audio_file_path
				this.meeting.video_file_path = data.video_file_path
				this.meeting.recording_duration_seconds = data.recording_duration_seconds

				Vue.toasted.success('Meeting transcript is ready')
				clearInterval(this.transcriptionStatusTimer)
			} else if (data.transcript_job_status === 'error') {
				this.meeting.transcript_job_status = data.transcript_job_status
				this.meeting.video_public_url = null
				this.meeting.audio_file_path = null

				clearInterval(this.transcriptionStatusTimer)
				Vue.toasted.error("Couldn't upload or process the recording")
			}
		},

		checkMinutesJobStatus() {
			//console.log('checking transribing job status')

			heyGovApi(`${this.j.slug}/meetings/${this.meeting.pid}`)
				.then(({ data }) => {
					//console.log('job status is:', data.transcript_job_status)

					if (['done', 'fresh-done'].includes(data.minutes_status)) {
						this.meeting.minutes_status = data.minutes_status

						Vue.toasted.success('🎉 Meeting minutes are ready')
						clearInterval(this.minutesStatusTimer)
					}
				})
				.catch(handleResponseError(`Couldn't get minutes job status ({error})`))
		},

		meetingChat(msg) {
			msg ||= this.meetingMessagesQuery

			this.states.meetingChat = 'loading'

			const params = new URLSearchParams({
				message: msg,
			})

			if (this.meetingMessages.length) {
				params.set('previous_response_id', this.meetingMessages.at(-1).id)
			}

			this.meetingMessages.push({
				role: 'user',
				content: msg,
			})

			sendEvent('meeting.chat', {
				feature: 'Meetings',
				meeting_id: this.meeting.pid,
				meeting: this.meeting.title,
			})

			hgApi(`${this.j.slug}/meetings/${this.meeting.pid}/ai-chat?${params.toString()}`)
				.then(response => response.json())
				.then(resp => {
					this.meetingMessages.push({
						id: resp.id,
						role: 'assistant',
						content: converter.makeHtml(resp.text),
					})

					this.states.meetingChat = 'idle'
				})

			this.meetingMessagesQuery = ''
		},

		timestampToMinutes(timestamp) {
			const hours = Math.floor(timestamp / 3600)
			const minutes = Math.floor(timestamp / 60) - hours * 60
			const seconds = Math.round(timestamp % 60)

			let ts = `${minutes}:${String(seconds).padStart(2, '0')}`

			if (hours > 0) {
				ts = `${hours}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
			}

			return ts
		},

		setPlayerTimestamp(timestamp, pressPlay = false) {
			console.log(this.meeting.pid, 'setPlayerTimestamp', timestamp, pressPlay)

			if (!this.$refs.meetingplayer) {
				return
			}

			// check if the video is loaded
			this.$refs.meetingplayer.addEventListener(
				'loadedmetadata',
				function() {
					this.currentTime = timestamp
				},
				false
			)

			this.$refs.meetingplayer.currentTime = Math.round(timestamp, 10)

			if (this.$refs.meetingplayer.paused && pressPlay) {
				this.$refs.meetingplayer.play()
			}
		},

		// TODO check timezones-related issues AND logic with cutoff-date
		updateCountdown() {
			const meetingStart = new Date(this.meeting.starts_at)
			const now = new Date()
			const diff = meetingStart - now

			if (diff > 0) {
				const days = Math.floor(diff / (1000 * 60 * 60 * 24))
				const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
				const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60))
				const seconds = Math.floor((diff % (1000 * 60)) / 1000)

				if (days > 0) {
					this.countdown = `${days} days`
				} else {
					this.countdown = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(
						seconds
					).padStart(2, '0')}`
				}
			} else {
				this.countdown = 'Meeting started'
			}
		},
		sidebarAgendaChange($event) {
			const agendaItem = this.meeting.agenda_items.find(item => item.id === Number($event.target.value))

			if (agendaItem?.timestamp_start) {
				this.setPlayerTimestamp(agendaItem.timestamp_start, true)
			}
		},
	},
	beforeDestroy() {
		clearInterval(this.transcriptionStatusTimer)
		clearInterval(this.minutesStatusTimer)
	},
	watch: {
		'meetingPlayer.currentTime'(time) {
			// check if the active agenda item should be updated
			if (this.meeting.agenda_items.length) {
				const agendaItem = this.meeting.agenda_items.find(
					item => time >= item.timestamp_start && time < item.timestamp_end
				)

				if (agendaItem && this.sidebarTabAgendaItemActive !== agendaItem.id) {
					this.sidebarTabAgendaItemActive = agendaItem.id
				}
			}

			// scroll the active transcript live into view
			if (this.sidebarTab === 'transcript') {
				const transcriptLine = this.meeting.transcript.find(
					line => time >= line.timestamp && time < line.timestamp_end
				)

				if (transcriptLine && this.sidebarTabTranscriptLineActive !== transcriptLine.id) {
					this.sidebarTabTranscriptLineActive = transcriptLine.id

					let $lineElement = this.$refs[`transcript-line-${transcriptLine.id}`]

					if ($lineElement && Array.isArray($lineElement) && $lineElement.length) {
						$lineElement = $lineElement[0]
					}

					if ($lineElement) {
						$lineElement.parentNode.parentNode.scrollTo({
							top: $lineElement.offsetTop - 104,
							behavior: 'smooth',
						})
					} else {
						console.warn(this.meeting.pid, 'ref not found', transcriptLine.id)
					}
				}
			}
		},
	},
}
</script>
