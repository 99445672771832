<template>
	<div v-if="venue" class="page-venue-booking" ref="pageVenueBooking">
		<div class="row justify-content-center">
			<div class="col-lg-8 col-md-10">
				<router-link
					v-if="isEmbed"
					:to="`/${j.slug}/venues/${venue.slug}/embed?date=${$route.params.date}`"
					class="d-inline-block btn btn-sm btn-outline-dark mb-3"
					>← Venue availability</router-link
				>
				<h3 v-else class="mb-3">
					<router-link :to="`/${j.slug}/venues`">Venues</router-link>
					<font-awesome-icon :icon="['fas', 'angle-right']" class="text-muted mx-2" />
					<router-link :to="`/${j.slug}/venues/${venue.slug}/info?date=${$route.params.date}`">{{
						venue.name
					}}</router-link>
					<font-awesome-icon :icon="['fas', 'angle-right']" class="text-muted ms-2" />
					Book for
					{{
						($route.params.date.includes(':') ? $route.params.date.split(':').at(0) : $route.params.date)
							| dateLocal
					}}
				</h3>

				<div class="card mb-4">
					<div class="row g-0">
						<div class="col-5">
							<img :src="venue.main_image" class="img-fluid rounded-start m-1" :alt="venue.name" />
						</div>
						<div class="col-7">
							<div class="card-body px-3 py-2">
								<h4 class="card-title">{{ venue.name }}</h4>

								<p class="card-text mb-0">
									<small class="text-secondary">From</small>
								</p>
								<p class="card-text mb-1">
									<strong>{{ bookingInfo.starts_at_date | dateTimeLocal }}</strong>
								</p>

								<p class="card-text mb-0">
									<small class="text-secondary">Until</small>
								</p>
								<p class="card-text mb-1">
									<strong>{{ bookingInfo.ends_at_date | dateTimeLocal }}</strong>
								</p>

								<div class="row">
									<div class="col">
										<p class="card-text mb-0">
											<small class="text-secondary">Price</small>
										</p>
										<p class="card-text mb-0">
											<strong>{{ bookingInfo.price | currency }}</strong>
										</p>
									</div>
									<div class="col">
										<p class="card-text mb-0">
											<small class="text-secondary">Deposit</small>
										</p>
										<p class="card-text mb-0">
											<strong>{{ bookingInfo.deposit | currency }}</strong>
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div v-if="auth">
					<div id="venue-booking-fields" class="card mb-4">
						<div class="card-header">
							<h6 class="my-0 text-neutral-400">Reservation info</h6>
						</div>
						<div class="card-body">
							<form @submit.prevent="fillBooking">
								<div class="form-group mb-3">
									<label class="form-label" for="booking-name">Your name</label>
									<input
										type="text"
										class="form-control"
										id="booking-name"
										v-model="booking.name"
										required
										placeholder="Full name please"
									/>
								</div>

								<div
									v-for="field in venue.fields.filter(f => elementIsVisible(f, venue.fields))"
									:key="`vf-${field.id}`"
								>
									<div v-if="field.type === 'TextElement'" v-html="field.value"></div>
									<div
										v-else-if="
											[
												'TextInputElement',
												'EmailInputElement',
												'PhoneInputElement',
												'DatePickerElement',
												'TimeElement',
												'RadioButtonElement',
												'SelectListElement',
												'TextareaInputElement',
												'NumberInputElement',
												'AddressInputElement',
												'CheckboxElement',
												'FileUploadElement',
												'SignatureElement',
											].includes(field.type)
										"
										class="form-group mb-3"
									>
										<label class="form-label" :for="`fsf-${field.id}`"
											>{{ field.label }}
											<small v-if="field.required" class="text-danger">*</small></label
										>

										<component
											:is="field.type"
											:currentField="field"
											:venueBooking="booking"
											class="element"
											@update="fieldsUpdate"
										></component>

										<span v-if="field.help" class="form-text">{{ field.help }}</span>
									</div>

									<pre v-else>{{ field }}</pre>
								</div>

								<div v-if="bookingError" class="alert alert-danger mb-3">{{ bookingError }}</div>

								<div v-if="states.booking === 'draft'" class="d-grid">
									<button class="btn btn-primary" :disabled="states.bookingFields === 'loading'">
										{{
											booking.price + booking.deposit > 0
												? 'Continue to payment'
												: 'Book now for free'
										}}
									</button>
								</div>
							</form>
						</div>
					</div>

					<div id="venue-booking-payment" class="card">
						<div class="card-header">
							<h6 class="my-0 text-neutral-400">Payment</h6>
						</div>
						<div class="card-body">
							<p class="mb-2">
								Price:
								<strong v-if="booking.price === bookingInfo.price">{{
									booking.price | currency
								}}</strong>
								<span v-else>
									<strike class="text-muted">{{ bookingInfo.price | currency }}</strike>
									<strong class="mx-2">{{ booking.price | currency }}</strong>
									<span
										v-for="priceCondition in priceRules.filter(p => !p.type.endsWith('-deposit'))"
										:key="priceCondition.id"
										class="badge bg-info-lighter text-info me-2"
										><strong>{{ priceConditionText(priceCondition) }}</strong> ({{
											priceCondition.name
										}})</span
									>
								</span>
							</p>
							<p class="mb-2">
								Deposit:
								<strong v-if="booking.deposit === bookingInfo.deposit">{{
									booking.deposit | currency
								}}</strong>
								<span v-else>
									<strike class="text-muted">{{ bookingInfo.deposit | currency }}</strike>
									<strong class="mx-2">{{ booking.deposit | currency }}</strong>
									<span
										v-for="priceCondition in priceRules.filter(p => p.type.endsWith('-deposit'))"
										:key="priceCondition.id"
										class="badge bg-info-lighter text-info me-2"
										><strong>{{ priceConditionText(priceCondition) }}</strong> ({{
											priceCondition.name
										}})</span
									>
								</span>
							</p>
							<p v-if="amountFee" class="mb-2">
								Payment fee: <strong>{{ amountFee | currency }}</strong>
								<small class="text-muted cursor-pointer ms-2" @click="states.feeInfo = !states.feeInfo"
									><span v-if="extraDisclaimers.includes(j.slug)" class="me-1">non-refundable</span>
									<font-awesome-icon :icon="['fas', 'info-circle']"
								/></small>
							</p>
							<p v-if="amountFee && states.feeInfo" class="text-muted mb-2">
								Payment fees are non-refundable. This fee is paid directly to the payment processor.
								Please refer to our Terms and Conditions for more info.
							</p>
							<p class="lead mb-0">
								Total:
								<strong>{{ priceTotal | currency }}</strong>
							</p>

							<form
								v-if="states.booking === 'payment' && payment.platform === 'stripe'"
								@submit.prevent="confirmPayment"
							>
								<div id="payment-element" class="my-3"></div>

								<div v-if="paymentError" class="alert alert-danger mb-3">{{ paymentError }}</div>

								<div class="d-grid">
									<button class="btn btn-primary" :disabled="states.payment === 'loading'">
										🔒 Pay {{ priceTotal | currency }}
										<span
											v-if="states.payment === 'loading'"
											class="spinner-border spinner-border-sm"
										></span>
									</button>
								</div>
							</form>
							<form
								v-else-if="states.booking === 'payment' && payment.platform === 'paygov'"
								method="post"
								name="form1"
								:action="
									j.livemode
										? 'https://pay.paygov.us/API/PostAPI.aspx'
										: 'https://qa-pay.paygov.us/API/PostAPI.aspx'
								"
								class="mt-3"
							>
								<input name="ttid" type="hidden" :value="paymentDept.paygov_account.split(':')[0]" />
								<input
									name="apiPassword"
									type="hidden"
									:value="paymentDept.paygov_account.split(':')[1]"
								/>
								<input name="paymentAmount" type="hidden" :value="payment.amount" />
								<input
									name="SuccessURL"
									type="hidden"
									:value="
										`${apiUrl + j.slug}/payments/${payment.uuid}/payment-status-succeeded?send=1`
									"
								/>
								<input name="OrderToken" type="hidden" :value="payment.source" />

								<div class="d-grid">
									<button
										class="btn btn-primary"
										:disabled="states.payment === 'loading'"
										@click="states.payment = 'loading'"
									>
										🔒 Pay {{ priceTotal | currency }}
										<span
											v-if="states.payment === 'loading'"
											class="spinner-border spinner-border-sm"
										></span>
									</button>
								</div>
							</form>
							<div
								v-else-if="states.booking === 'payment' && payment.platform === 'municipay'"
								class="alert alert-warning mt-3"
							>
								If you're not redirected to MuniciPay in a few seconds,
								<a :href="payment.metadata.url">click here to pay</a>.
							</div>
						</div>
					</div>
				</div>

				<div v-else class="card">
					<div class="card-body">
						<div v-if="booking">
							<p class="mb-3 text-center">First, what's your email address?</p>

							<login-form
								:redirectUrl="
									`/${j.slug}/venues/${venue.slug}/booking/${$route.params.date}/${$route.params.slot}`
								"
								:allowRegistration="true"
								loginMessage="Great, logged in to your account"
							></login-form>
						</div>

						<div v-else class="text-center">
							loading..
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'
import { differenceInDays, differenceInHours, format, parseISO } from 'date-fns'

import heyGovApi from '@/api.js'
import { appUrl, handleResponseError } from '@/utils.js'
import { calculatePrice, elementIsVisible } from '@/actions/forms.js'
import { paymentFee } from '@/actions/payments.js'

import LoginForm from '@/components/LoginForm.vue'
import TextInputElement from '@/components/form-builder/TextInputElement'
import TextareaInputElement from '@/components/form-builder/TextareaInputElement'
import NumberInputElement from '@/components/form-builder/NumberInputElement'
import SelectListElement from '@/components/form-builder/SelectListElement'
import RadioButtonElement from '@/components/form-builder/RadioButtonElement'
import CheckboxElement from '@/components/form-builder/CheckboxElement'
import DatePickerElement from '@/components/form-builder/DatePickerElement'
import TimeElement from '@/components/form-builder/TimeElement'
import FileUploadElement from '@/components/form-builder/FileUploadElement'
import EmailInputElement from '@/components/form-builder/EmailInputElement'
import PhoneInputElement from '@/components/form-builder/PhoneInputElement'
import SignatureElement from '@/components/form-builder/SignatureElement'
import AddressInputElement from '@/components/form-builder/AddressInputElement'

export default {
	metaInfo() {
		return {
			title: `Book ${this.venue?.name || this.$route.params.venueSlug}`,
		}
	},
	components: {
		LoginForm,
		AddressInputElement,
		SignatureElement,
		PhoneInputElement,
		EmailInputElement,
		FileUploadElement,
		DatePickerElement,
		TimeElement,
		CheckboxElement,
		TextInputElement,
		TextareaInputElement,
		NumberInputElement,
		SelectListElement,
		RadioButtonElement,
	},
	props: {
		isEmbed: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			state: 'idle',
			states: {
				booking: 'draft',
				bookingFields: 'idle',
				payment: 'idle',
				feeInfo: false,
			},
			venue: null,
			bookingInfo: {
				type: null,
				date: null,
				starts_at: null,
				ends_at: null,
				starts_at_date: null,
				ends_at_date: null,
				slot: null,
				price: 0,
				deposit: 0,
			},
			booking: {
				name: '',
				venue_slot_id: null,
				deposit: 0,
				price: 0,
				venue_fields: {},
				payment_id: null,
			},
			bookingError: '',

			payment: null,
			priceRules: [],
			paymentError: '',
			stripe: null,
			stripeElements: null,
		}
	},
	computed: {
		...mapState([
			'j',
			'apiUrl',
			'account',
			'departments',
			'stripePublishableTestKey',
			'stripePublishableKey',
			'extraDisclaimers',
		]),
		...mapGetters(['auth']),
		paymentDept() {
			return (this.departments || []).find(d => d.id === this.venue.department_id)
		},
		amountFee() {
			const amount = Number(this.booking.price) + Number(this.booking.deposit)
			let platform = ''
			let rate

			if (!this.paymentDept) {
				return 0
			} else if (this.paymentDept.stripe_account) {
				platform = 'stripe'
			} else if (this.paymentDept.justifi_account) {
				platform = 'justifi'
			} else if (this.paymentDept.paygov_account) {
				platform = 'paygov'
				rate = this.paymentDept.paygov_account.split(':')[2]
			} else if (this.paymentDept.municipay_account) {
				platform = 'municipay'
			}

			return amount > 0 ? paymentFee(platform, 'card', amount, rate) : 0
		},
		priceTotal() {
			// TODO other calculations based on fields & conditions

			return Number(this.booking.price) + Number(this.booking.deposit) + Number(this.amountFee)
		},
		bookingLinkPath() {
			let path = `${this.j.slug}/venues/${this.venue.slug}/bookings-info/${this.booking.uuid}?sent=1`

			if (this.isEmbed) {
				path += `&_embed=1`
			}

			return path
		},
	},
	created() {
		this.$store.dispatch('loadDepartments')
		this.loadVenue()
	},
	mounted() {
		if (!('Stripe' in window)) {
			let StripeScript = document.createElement('script')
			StripeScript.setAttribute('src', 'https://js.stripe.com/v3/')
			document.head.appendChild(StripeScript)
		}
	},
	methods: {
		elementIsVisible,

		loadVenue() {
			this.state = 'loading'

			heyGovApi.get(`${this.j.slug}/venues/${this.$route.params.venueSlug}?expand=fields`).then(
				({ data }) => {
					this.bookingInfo.date = this.$route.params.date

					if (data.reservation_mode === 'slots') {
						const slot = data.slots.find(slot => slot.id == this.$route.params.slot)
						this.bookingInfo.type = 'slot'
						this.bookingInfo.slot = slot

						this.bookingInfo.starts_at = slot.starts_at
						this.bookingInfo.ends_at = slot.ends_at
						this.bookingInfo.price = slot.price
						this.bookingInfo.deposit = slot.deposit

						if (slot.type === 'dates') {
							this.bookingInfo.starts_at_date = parseISO(slot.date_starts_at)
							this.bookingInfo.ends_at_date = parseISO(slot.date_ends_at)
						} else if (this.$route.params.date.includes(':')) {
							const dates = this.$route.params.date.split(':')
							this.bookingInfo.starts_at_date = parseISO(`${dates[0]} ${slot.starts_at}`)
							this.bookingInfo.ends_at_date = parseISO(`${dates[1]} ${slot.ends_at}`)

							let days = differenceInDays(
								new Date(`${dates[1]} 23:59:59`),
								new Date(`${dates[0]} 00:00:00`)
							)

							if (slot.ends_at > slot.starts_at) {
								days++
							}

							this.bookingInfo.price = slot.price * days
						} else {
							this.bookingInfo.starts_at_date = parseISO(`${this.$route.params.date} ${slot.starts_at}`)
							this.bookingInfo.ends_at_date = parseISO(`${this.$route.params.date} ${slot.ends_at}`)
						}
					} else {
						const times = this.$route.params.slot.split('-')
						this.bookingInfo.type = 'time'
						this.bookingInfo.starts_at = times[0]
						this.bookingInfo.ends_at = times[1]
						this.bookingInfo.starts_at_date = parseISO(`${this.$route.params.date} ${times[0]}`)
						this.bookingInfo.ends_at_date = parseISO(`${this.$route.params.date} ${times[1]}`)
						this.bookingInfo.deposit = Number(data.availability.deposit || 0)

						const hours = differenceInHours(this.bookingInfo.ends_at_date, this.bookingInfo.starts_at_date)

						if (hours >= data.availability.min && hours <= data.availability.max) {
							const dayName = format(this.bookingInfo.starts_at_date, 'E')
							const day = data.availability.days[dayName]
							this.bookingInfo.price = day.price * hours
						} else {
							Vue.toasted.error('Invalid booking time')
							this.state = 'error'
							return
						}
					}

					this.venue = data
					this.state = 'idle'

					if (this.auth) {
						this.startBooking()
					}
				},
				error => {
					Vue.toasted.error(error)
					this.state = 'error'
				}
			)
		},

		loadStripe() {
			if (!this.stripe) {
				this.stripe = new window.Stripe(
					this.j.livemode ? this.stripePublishableKey : this.stripePublishableTestKey,
					{
						stripeAccount: this.departments.find(d => d.id == this.venue.department_id).stripe_account,
					}
				)
			}
		},

		startBooking() {
			heyGovApi
				.post(`${this.j.slug}/venue-bookings`, {
					venue_id: this.venue.id,
					date: this.$route.params.date,
					slot: this.$route.params.slot,
				})
				.then(({ data }) => {
					// name is needed in frontend
					data.name = this.account?.name || this.auth.name || ''

					this.booking = data
					//this.startPayment()
				}, handleResponseError('Error starting the booking ({error})'))
		},

		fieldsUpdate({ field, value }) {
			// early exit
			if (!this.booking?.fields_answers || !this.venue?.fields) {
				return
			}

			// update booking answers, to store in db
			this.booking.fields_answers[field.id] = value

			// calculate price
			const { price, deposit, matchedRules } = calculatePrice(
				Number(this.bookingInfo.price),
				Number(this.bookingInfo.deposit),
				this.venue.price_conditions.filter(p => p.slot === null || p.slot === this.bookingInfo.slot?.id),
				this.venue.fields,
				this.booking.fields_answers
			)
			this.priceRules = matchedRules

			// if price is changed, update Payment ref
			if (this.booking.price != price || this.booking.deposit != deposit) {
				if (this.booking.price != price) {
					this.booking.price = price
				}
				if (this.booking.deposit != deposit) {
					this.booking.deposit = deposit
				}
				this.states.booking = 'draft'
				this.states.bookingFields = 'idle'
			}
		},

		fillBooking() {
			this.bookingError = ''
			let fieldErrors = []

			this.venue.fields.forEach(field => {
				this.booking.fields_answers[field.id] = field.value

				if (field.required && elementIsVisible(field, this.venue.fields)) {
					if (field.type === 'FileUploadElement' && !field.value.length) {
						fieldErrors.push(`You need to upload a file for "${field.label}"`)
					} else if (field.type === 'RadioButtonElement' && !field.value?.value) {
						fieldErrors.push(`You need to select an option for "${field.label}"`)
					} else if (field.type === 'CheckboxElement' && !field.value?.values.length) {
						fieldErrors.push(`You need to select an option for "${field.label}"`)
					} else if (!field.value) {
						fieldErrors.push(`${field.label} field is not filled`)
					}
				}
			})

			if (fieldErrors.length) {
				this.bookingError = fieldErrors.join(',\n')
				this.states.bookingFields = 'error'
				return
			}

			this.$store.dispatch('accountUpdateIfEmpty', {
				name: this.booking.name,
				//phone: this.booking.phone,
			})

			this.states.bookingFields = 'loading'

			if (this.booking.price + this.booking.deposit < 0.5) {
				heyGovApi
					.post(`${this.j.slug}/venue-bookings/${this.booking.uuid}/send-for-review`, {
						price: this.booking.price,
						deposit: this.booking.deposit,
						fields_answers: this.booking.fields_answers,
					})
					.then(
						() => {
							this.$router.push(`/${this.bookingLinkPath}`)
						},
						error => {
							this.bookingError = error.response?.data?.message || error.message
						}
					)
			} else {
				Promise.all([
					heyGovApi.put(`${this.j.slug}/venue-bookings/${this.booking.uuid}`, {
						fields_answers: this.booking.fields_answers,
						price: this.booking.price,
						deposit: this.booking.deposit,
					}),
					heyGovApi.post(`${this.j.slug}/payments?by=payer`, {
						department_id: this.venue.department_id,
						person_id: this.auth.id,
						amount: this.booking.price + this.booking.deposit,
						fee: this.amountFee,
						description: `${this.venue.name} - ${this.bookingInfo.starts_at_date.toLocaleDateString(
							'en-US',
							{ timeZone: this.j.timezone }
						)}`,
						source: this.booking.uuid,

						// stripe/justifi options
						capture_method: 'manual',
					}),
				]).then(
					([bookingResponse, paymentResponse]) => {
						//console.log('re x 2', bookingResponse, paymentResponse)
						console.log(bookingResponse.data.uuid, 'booking filled')
						this.payment = paymentResponse.data
						this.states.booking = 'payment'
						this.startPayment()
					},
					error => {
						this.bookingError = error.response?.data?.message || error.message
					}
				)
			}
		},

		priceConditionText(priceCondition) {
			let text = priceCondition.type === 'sub' ? '-' : '+'

			if (priceCondition.amountType === 'fixed') {
				text += `$${priceCondition.amount}`
			} else if (priceCondition.amountType === 'percentage') {
				text += `${priceCondition.amount}%`
			}

			return text
		},

		startPayment() {
			if (this.payment.platform === 'stripe') {
				this.states.payment = 'loading'
				this.loadStripe()

				this.stripeElements = this.stripe.elements({
					appearance: {
						theme: 'stripe',
					},
					clientSecret: this.payment.paymentIntent.client_secret,
				})

				this.paymentElement = this.stripeElements.create('payment')

				this.paymentElement.on('focus', () => {
					this.paymentError = ''
					this.states.payment = 'ready'
				})
				this.paymentElement.on('ready', () => {
					this.paymentError = ''
					this.states.payment = 'ready'
				})

				setTimeout(() => {
					this.paymentElement.mount('#payment-element')
				}, 250)
			} else if (this.payment.platform === 'justifi') {
				// handle justifi
			} else if (this.payment.platform === 'municipay') {
				window.location = this.payment.metadata.url
			}

			if (this.payment.platform !== 'municipay') {
				document.getElementById('venue-booking-payment').scrollIntoView({ behavior: 'smooth', block: 'center' })
			}
		},
		async confirmPayment() {
			this.states.payment = 'loading'

			if (this.payment.platform === 'stripe') {
				const result = await this.stripe.confirmPayment({
					elements: this.stripeElements,
					confirmParams: {
						payment_method_data: {
							billing_details: {
								name: this.account?.name,
								email: this.account?.email,
							},
						},

						// Make sure to change this to payment completion page
						return_url: appUrl(this.bookingLinkPath),
					},
					redirect: 'if_required',
				})

				if (result.error) {
					// Show error to in payment form
					this.paymentError = result.error.message
					this.states.payment = 'error'
				} else if (['requires_capture', 'succeeded'].includes(result.paymentIntent?.status)) {
					setTimeout(() => {
						this.$router.push(`/${this.bookingLinkPath}`)
					}, 600)
				} else {
					Vue.toasted.error(`Payment info in console ~ `)
					console.log(result)
				}
			} else if (this.payment.platform === 'justifi') {
				Vue.toasted.show("Can't handle JustiFi yet..")
			}
		},
	},
	filters: {
		slotLocalTime: function(value) {
			if (!value) return ''
			// Adding time to any date just to create date so it can convert to proper time format based on settings
			value = new Date('2022-06-08 ' + value)
			return value.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })
		},
	},
	watch: {
		auth(auth, oldAuth) {
			if (!oldAuth && auth) {
				this.startBooking()
			}
		},
	},
}
</script>
