<template>
	<div class="page-meeting-minutes">
		<div class="xx-row xx-justify-content-center">
			<div class="xx-col-lg-11 xx-col-xl-10">
				<div class="card card-minutes mb-4">
					<div class="card-header">
						<div class="d-flex align-items-center justify-content-between">
							<h5 class="my-0">
								Meeting minutes
							</h5>
							<help-button
								url="https://www.notion.so/townweb/Re-Generate-draft-minutes-08a8295cd1034e19ba5e1eb855d719de"
								text="Prepare the best minutes"
							></help-button>
						</div>
					</div>
					<div class="card-body">
						<h2 class="card-title text-center mt-4 mb-2">{{ meeting.title }}</h2>
						<h3 class="card-title text-center mb-5">Meeting minutes</h3>

						<iframe
							v-if="meeting.minutes_status === 'manual-upload'"
							:src="getPublicFileUrl(meeting.meeting_minutes_file_path)"
							width="100%"
							height="1000"
							frameborder="0"
						></iframe>

						<template v-else>
							<!-- MAKE LONGER OPTIONS -->
							<div
								v-if="states.makeLongerAdvancedOptions"
								class="border border-danger p-2 rounded-1 mb-3"
							>
								<p class="text-center">Options for "Make Longer" AI action</p>
								<p>
									<span class="font-monospace mx-2">
										Temperature
										<code>{{ Number(makeLongerOptions.temperature).toFixed(1) }}</code>
									</span>

									<input
										type="range"
										class="d-inline-block form-range form-range-sm"
										style="max-width: 200px"
										v-model="makeLongerOptions.temperature"
										min="0"
										max="1.5"
										step="0.1"
									/>
								</p>
								<p>Prompt</p>
								<textarea
									class="form-control form-control-sm mb-1"
									v-model="makeLongerOptions.prompt"
									rows="12"
								/>
							</div>

							<!-- MAKE SHORTER OPTIONS -->
							<div
								v-if="states.makeShorterAdvancedOptions"
								class="border border-danger p-2 rounded-1 mb-3"
							>
								<p class="text-center">Options for "Make Shorter" AI action</p>
								<p>
									<span class="font-monospace mx-2">
										Temperature
										<code>{{ Number(makeShorterOptions.temperature).toFixed(1) }}</code>
									</span>
									<input
										type="range"
										class="d-inline-block form-range form-range-sm"
										style="max-width: 200px"
										v-model="makeShorterOptions.temperature"
										min="0"
										max="1.5"
										step="0.1"
									/>
								</p>
								<p>Prompt</p>
								<textarea
									class="form-control form-control-sm mb-1"
									v-model="makeShorterOptions.prompt"
									rows="12"
								/>
							</div>

							<!-- DESCRIBE YOUR CHANGE OPTIONS -->
							<div
								v-if="states.describeYourChangeAdvancedOptions"
								class="border border-danger p-2 rounded-1 mb-3"
							>
								<p class="text-center">Options for "Describe Your Change" AI action</p>
								<p>
									<span class="font-monospace mx-2">
										Temperature
										<code>{{ Number(describeYourChangeOptions.temperature).toFixed(1) }}</code>
									</span>
									<input
										type="range"
										class="d-inline-block form-range form-range-sm"
										style="max-width: 200px"
										v-model="describeYourChangeOptions.temperature"
										min="0"
										max="1.5"
										step="0.1"
									/>
								</p>
								<p>Prompt</p>
								<textarea
									class="form-control form-control-sm mb-1"
									v-model="describeYourChangeOptions.prompt"
									rows="12"
								/>
							</div>

							<p
								v-if="meeting.minutes_status === 'missing'"
								class="lead text-center text-neutral-500 mb-5"
							>
								<i>This meeting has no minutes yet</i>
							</p>

							<editor
								v-else
								v-model="meeting.minutes_text"
								@input="triggerMinutesUpdated"
								@ai-action="handleAIAction"
								@make-longer-context-menu="handleAIActionContextMenu('make_longer')"
								@make-shorter-context-menu="handleAIActionContextMenu('make_shorter')"
								@describe-your-change-context-menu="handleAIActionContextMenu('describe_your_change')"
								:placeholder="
									meeting.minutes_status === 'generating'
										? 'Sit back, relax, and watch the minutes being written..'
										: 'Write the meeting minutes here'
								"
							/>

							<div
								v-if="['done', 'fresh-done'].includes(meeting.minutes_status)"
								class="row justify-content-between meeting-editor-toolbar mb-2"
							>
								<div class="col-auto">
									<button
										class="btn btn-sm btn-primary"
										:disabled="!states.minutesUpdated"
										@click="saveMinutes"
									>
										Save minutes
									</button>
								</div>
								<div class="col-auto">
									<div class="dropdown">
										<button
											class="btn btn-sm btn-outline-primary dropdown-toggle"
											type="button"
											id="download-minutes"
											data-bs-toggle="dropdown"
											aria-expanded="false"
										>
											Download minutes
										</button>
										<ul class="dropdown-menu" aria-labelledby="download-minutes">
											<li>
												<a
													class="dropdown-item"
													:href="
														`${apiUrl + j.slug}/meetings/${
															meeting.pid
														}/minutes/save/docx?person_id=${auth.id}`
													"
													@click="minutesExportAs('docx')"
													><font-awesome-icon
														:icon="['fas', 'file']"
														class="text-primary me-1"
													/>
													Word document</a
												>
											</li>
											<li>
												<button class="dropdown-item" @click="minutesExportAs('pdf', 'no')">
													<font-awesome-icon
														:icon="['fas', 'file-pdf']"
														class="text-danger me-1"
													/>
													PDF document
												</button>
											</li>
										</ul>
									</div>
								</div>
							</div>

							<hr v-if="['done', 'fresh-done'].includes(meeting.minutes_status)" class="bg-primary-100" />

							<div
								v-if="meeting.minutes_status === 'fresh-done'"
								class="bg-warning-50 border border-warning rounded-1 p-3 mx-6 my-5"
							>
								<button type="button" class="btn-close float-end" @click="feedbackClose"></button>
								<h4 class="mb-4">🙏 Let us know how we did</h4>

								<form @submit.prevent="feedbackSend">
									<div class="form-group">
										<label class="form-label">How good was the output for these minutes?</label>

										<div class="text-primary-400">
											<span
												v-for="star in [1, 2, 3, 4, 5]"
												:key="star"
												class="display-5 cursor-pointer"
												:class="
													star <= feedback.rating ? 'text-warning-300' : 'text-neutral-300'
												"
												@click="setRating(star)"
												>★</span
											>
										</div>
									</div>

									<div v-if="feedback.rating && feedback.rating < 5" class="mt-3">
										<div class="form-group mb-3">
											<label class="form-label">Tell us what happened</label>

											<div>
												<button
													v-for="(optionValue, optionKey) in feedbackOptions"
													:key="optionKey"
													class="btn btn-outline-dark btn-sm me-2 mb-2"
													:class="{
														'btn-primary': states.activeFeedbackOptions.includes(optionKey),
													}"
													type="button"
													@click="toggleActiveFeedbackOptions(optionKey)"
												>
													{{ optionValue.text }}
												</button>
											</div>
										</div>

										<div
											v-for="hint in feedbackHints"
											:key="hint"
											class="alert alert-info text-dark"
											v-html="hint"
										></div>

										<div
											v-if="states.activeFeedbackOptions.includes('other')"
											class="form-group mb-3"
										>
											<label class="form-label">
												<span v-if="feedback.rating > 3">
													What else needs to be improved for these minutes?
												</span>
												<span v-else>
													What do we need to fix on our end to improve the minutes?
												</span>
											</label>
											<textarea
												class="form-control"
												rows="3"
												v-model="feedback.message"
												placeholder="Your feedback helps us improve ClerkMinutes"
												required
											>
											</textarea>
										</div>

										<p class="text-center mb-0">
											<button type="submit" class="btn btn-primary" :disabled="!feedback.rating">
												Submit feedback
											</button>
										</p>
									</div>
								</form>
							</div>

							<div
								v-if="meeting.minutes_status !== 'public' && meeting.minutes_status !== 'generating'"
								class="bg-ai border rounded-1 px-3 py-4 my-5 mx-6"
								:class="{ 'cursor-pointer': !states.showGenerateMinutes }"
								@click="states.showGenerateMinutes = true"
							>
								<h5
									class="text-center my-0"
									@dblclick="
										states.generateDraftAdvancedOptions = !states.generateDraftAdvancedOptions
									"
								>
									✨
									{{
										meeting.minutes_status === 'missing'
											? 'Generate draft meeting minutes'
											: 'Re-generate minutes'
									}}
								</h5>

								<template v-if="states.showGenerateMinutes">
									<div class="onboarding bg-dasnger-50 p-3 mt-3">
										<div class="row align-items-center hover rounded-1 gap-0 mb-1">
											<div class="col-auto">
												<div
													class="py-1 px-2 rounded"
													:class="
														meeting.agenda_items.length > 2
															? 'bg-success-50'
															: 'bg-danger-50'
													"
												>
													<font-awesome-icon
														v-if="meeting.agenda_items.length > 2"
														:icon="['fas', 'check']"
														class="text-success-400 fa-fw"
													/>
													<font-awesome-icon
														v-else
														:icon="['fas', 'plus']"
														class="text-danger-400 fa-fw"
													/>
												</div>
											</div>
											<div class="col py-2">
												<h6 v-if="meeting.agenda_items.length > 2" class="mb-1">
													Agenda is looking good with {{ meeting.agenda_items.length }} items
												</h6>
												<h6 v-else class="mb-1">
													<router-link :to="`/${j.slug}/meetings/${meeting.pid}/agenda`"
														>Add at least 3 agenda items</router-link
													>
												</h6>
												<p class="mb-0">Minutes structure is based on the agenda items</p>
											</div>
										</div>
										<div class="row align-items-center hover rounded-1 gap-0 mb-1">
											<div class="col-auto">
												<div
													class="py-1 px-2 rounded"
													:class="
														meeting.transcript_job_status === 'transcribed'
															? 'bg-success-50'
															: 'bg-danger-50'
													"
												>
													<font-awesome-icon
														v-if="meeting.transcript_job_status === 'transcribed'"
														:icon="['fas', 'check']"
														class="text-success-400 fa-fw"
													/>
													<font-awesome-icon
														v-else
														:icon="['fas', 'plus']"
														class="text-danger-400 fa-fw"
													/>
												</div>
											</div>
											<div class="col py-2">
												<h6 class="mb-1">
													<template v-if="meeting.transcript_job_status === 'transcribed'">
														Recording transcript is ready
													</template>
													<router-link
														v-else
														:to="`/${j.slug}/meetings/${meeting.pid}/transcript`"
														>Add meeting recording</router-link
													>
												</h6>
												<h6 class="mb-1"></h6>
												<p class="mb-0">We'll use the transcript from the recording</p>
											</div>
										</div>
										<div class="row align-items-center hover rounded-1 gap-0 mb-1">
											<div class="col-auto">
												<div
													class="py-1 px-2 rounded"
													:class="
														meeting.transcript_job_status === 'transcribed'
															? 'bg-primary-50'
															: 'bg-danger-50'
													"
												>
													<font-awesome-icon
														v-if="meeting.transcript_job_status === 'transcribed'"
														:icon="['fas', 'info']"
														class="text-primary-400 fa-fw"
													/>
													<font-awesome-icon
														v-else
														:icon="['fas', 'question']"
														class="text-danger-400 fa-fw"
													/>
												</div>
											</div>
											<div class="col py-2">
												<h6 class="mb-1">Identify speakers in transcript</h6>
												<p class="mb-0">
													Speakers name are used for motions and quotes in the minutes
												</p>
											</div>
										</div>
									</div>

									<p class="text-center mb-2">
										Choose how you'd like your draft minutes to be created:
									</p>

									<p class="text-center">
										<label>Length:</label>
										<select
											class="d-inline-block form-select form-select-sm mx-2"
											style="max-width: 200px"
											v-model="generateMinutesOptions.length"
										>
											<option value="short-and-to-the-point">Short</option>
											<option value="medium-length">Medium</option>
											<option value="comprehensive">Make it long</option>
										</select>

										<label>Tone:</label>
										<select
											class="d-inline-block form-select form-select-sm mx-2"
											style="max-width: 200px"
											v-model="generateMinutesOptions.tone"
										>
											<option value="official-meeting-speak">Official meeting</option>
											<option value="formal">Make it sound more formal</option>
											<option value="professional-corporate">Professional</option>
											<option value="overly-energetic-and-enthusiastic">Enthusiastic</option>
											<option value="casual">Make it sound casual</option>
											<option value="in-southern-belle-speaking-with-a-southern-drawl"
												>Make it sound like a Southerner</option
											>
											<option value="like-spoken-by-a-true-texan"
												>Make it sound like spoken by a Texan</option
											>
											<option value="over-the-top-like-a-wisconsinite"
												>Make it sound like a Wisconsinite</option
											>
											<option
												v-if="jurisdictionsWithResolutionMinutes.includes(j.slug)"
												value="resolution-minutes"
												>Resolution minutes (BETA)</option
											>
										</select>
									</p>

									<p v-if="j.features.includes('clerkminutes')" class="text-center">
										Click “Generate” to start it. You can always tweak the options and re-generate.
									</p>
									<div v-else class="alert alert-danger text-dark">
										An active ClerkMinutes subscription is required to generate minutes.
										<router-link :to="`/${j.slug}/meetings/clerkminutes`"
											>View plans &amp; pricing</router-link
										>
									</div>

									<div
										v-if="isStaff && states.generateDraftAdvancedOptions"
										class="border border-danger p-2 rounded-1 mb-3"
									>
										<p class="text-center">
											Options for HeyGov:
											<select
												class="d-inline-block form-select form-select-sm mx-2"
												style="max-width: 160px"
												v-model="generateMinutesOptions.model"
											>
												<option value="claude-3-5-sonnet-20240620"
													>Anthropic: Claude 3.5 Sonnet (2024-06-20)</option
												>
												<option value="claude-3-5-sonnet-20241022"
													>Anthropic: Claude 3.5 Sonnet (2024-10-22)</option
												>
												<option value="gpt-4o-2024-08-06">OpenAI: GPT-4o (2024-08-06)</option>
												<option value="gpt-4o-2024-11-20">OpenAI: GPT-4o (2024-11-20)</option>
												<option value="o3-mini-2025-01-31">OpenAI: o3-mini</option>
											</select>

											<span class="font-monospace mx-2"
												>Temp
												<code>{{
													Number(generateMinutesOptions.temperature).toFixed(1)
												}}</code></span
											>

											<input
												type="range"
												class="d-inline-block form-range form-range-sm"
												style="max-width: 200px"
												v-model="generateMinutesOptions.temperature"
												min="0"
												max="1.5"
												step="0.1"
											/>
										</p>

										<textarea
											class="form-control form-control-sm mb-1"
											v-model="generateMinutesOptions.prompt"
											rows="12"
										></textarea>
										<a :href="minutesPromptUrl" target="_blank"><small>Show full prompt</small></a>
									</div>

									<p class="text-center mb-0">
										<button
											class="btn btn-primary"
											:disabled="
												meeting.agenda_items.length < 3 ||
													meeting.transcript_job_status !== 'transcribed' ||
													!j.features.includes('clerkminutes')
											"
											@click="generateDraftMinutes"
										>
											✨ Generate draft minutes
										</button>
									</p>
								</template>
							</div>

							<div
								class="actions-toolbar minutes-generating"
								:class="{ show: meeting.minutes_status === 'generating' }"
							>
								<div class="row align-items-center gx-3">
									<div class="col-auto">
										<div
											class="spinner-grow text-light"
											role="status"
											style="width: 44px; height: 44px"
										>
											<span class="visually-hidden">Loading...</span>
										</div>
									</div>
									<div class="col">
										Minutes are being generated right now
									</div>
									<div class="col-auto">
										<button
											class="btn btn-icon-danger bg-danger-400"
											@click="minutesAbortController.abort()"
										>
											<font-awesome-icon :icon="['fas', 'stop']" class="text-white" />
										</button>
									</div>
								</div>
							</div>

							<template v-if="meeting.minutes_status === 'missing'">
								<p class="text-center text-neutral-300">- or -</p>

								<div class="bg-light border border-dashed text-center rounded-1 p-4 mb-3 mx-6">
									<label for="meeting-minutes-file" class="btn btn-sm btn-outline-primary">
										<font-awesome-icon :icon="['fas', 'file-import']" class="me-1" />
										Upload minutes file
									</label>
								</div>
							</template>
						</template>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss">
.card-minutes:has(.ProseMirror-focused) {
	border: 1px solid #ccc;
}

.minutes-generating {
	position: fixed;
	bottom: -5rem;
	left: 50%;
	width: 500px;
	margin-left: -250px;
	transition: bottom 0.4s ease-in-out;
}

.minutes-generating.show {
	bottom: 1rem;
}
</style>

<script>
import { mapGetters, mapState } from 'vuex'
import Vue from 'vue'
import { Converter } from 'showdown'
import Editor from '../../components/editor/Editor.vue'

import { hgApi } from '@/api.js'
import { getPublicFileUrl, sendEvent } from '@/utils.js'

export default {
	name: 'MeetingMinutes',
	components: { Editor },
	metaInfo() {
		return {
			title: `Minutes for ${this.meeting?.title || this.$route.params.meetingId} - Meetings`,
		}
	},
	props: {
		meeting: {
			type: Object,
			required: true,
		},
		meetingPlayer: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			feedbackOptions: {
				agendaItemsMissing: {
					text: 'Missing or incorrect agenda items',
					hint:
						'Agenda items can be added, edited or re-ordered in the <strong>Agenda</strong> tab. <a href="https://townweb.notion.site/Create-and-edit-Agenda-items-fb3152b74c3e4699b75c52d473c23618" target="_blank">Learn more about agenda management</a>',
				},
				transcriptMissedDetails: {
					text: 'Short minutes, missed details',
					hint:
						'Have you tried the "Make longer" option? Select the minutes section that\'s shorter than you prefer, and click on <strong>AI Actions → Make longer</strong>. <a href="https://www.notion.so/townweb/Add-details-or-lengthen-your-minutes-with-AI-Actions-b08a30bab468441a9afcd3462f730dff" target="_blank">Learn more about AI Actions</a>',
				},
				speakerNameMissing: {
					text: 'No speaker names',
					hint:
						'Have you assigned all speakers in the <strong>Transcript</strong> tab? <a href="https://www.notion.so/townweb/Assign-speakers-for-your-meeting-90f25ad01de447cabbe25545d32255f0" target="_blank">Learn how here.<a>',
				},
				//factualErrors: { text: 'Minutes had factual errors', hint: '' },
				toneIncorrect: {
					text: 'Not the tone/style that I wanted',
					hint: `Tone/style can be changed by selecting the text, and using the <strong>"AI Actions → Rewrite in style.."</strong> option. <a href="https://www.notion.so/townweb/Add-details-or-lengthen-your-minutes-with-AI-Actions-b08a30bab468441a9afcd3462f730dff" target="_blank">Learn more about AI Actions</a>`,
				},
				other: { text: 'Other' },
			},
			states: {
				showGenerateMinutes: this.meeting.minutes_status === 'missing',
				generateDraftAdvancedOptions: false,
				minutesUpdated: false,
				makeLongerAdvancedOptions: false,
				makeShorterAdvancedOptions: false,
				describeYourChangeAdvancedOptions: false,
				activeFeedbackOptions: [],
			},
			minutesMarkdown: '',
			minutesAbortController: null,
			makeLongerOptions: {
				temperature: 1,
				prompt: `
You are ClerkGPT - an intelligent and helpful assistant that helps with {jurisdiction_type} meetings in {jurisdiction_name} while following these:
- If a motion was made, include it in a quote block, with the result, who made it and who seconded it. If names are not known, use placeholder ____. Add a extra line break after the quote block
- Always use the input data to accomplish this task and never invent any information
- Use the same language and style as the document itself
- The entire answer should be ready to be copied and pasted, without any preamble. Only return the longer version of the selected section, not the whole document
- format content with markdown

You will be provided with:
- Agenda items: what the clerks planned to discuss.
- Transcript: dialog from meeting transcribed from meeting recording.
- Meeting Minutes: minutes based on agenda items & transcript.
- Selection - a specific section of the minutes that needs editing.

Agenda items:
{agenda_items}
---
Transcript:
{transcript}
---
Meeting minutes:
{meeting_minutes}
---
Selection:
{selection}
---

The task: expand the selection to be at least double in size, and max 3x of the original. Use only the details from the transcript to do this.
`,
			},
			makeShorterOptions: {
				temperature: 1.1,
				prompt: `
You are ClerkGPT - an intelligent and helpful assistant.
Your task is to shorten certain sections in the meeting minutes document.
While doing so, you should always follow these guidelines:

- Use the input data to accomplish this task and never invent any information.
- Use the same language and style as the document itself.
- Use markdown.
- Entire answer should be ready to be copied and pasted, without any preamble.
- Only return the shorter version of the selected section, not the whole document.
- Shortened section must be twice shorter than original selection.

You will be provided with this input data:

1. Agenda items - what the clerks planned to discuss.
2. Transcript - the actual recording of their conversation.
3. Meeting minutes - a text document created based on the transcript.
4. Selection - a specific section of the meeting minutes document that needs to be shortened.

===

Agenda items:

{agenda_items}

---

Transcript:

{transcript}

---

Meeting minutes:

{meeting_minutes}

---

Selection:

{selection}`,
			},
			describeYourChangeOptions: {
				temperature: 1.1,
				prompt: `
You are ClerkGPT - an intelligent and helpful assistant.
Your task is to rewrite certain sections in the meeting minutes document.
While doing so, you should always follow these guidelines:

- Use the input data to accomplish this task and never invent any information.
- Use the same language as the document itself.
- Use markdown.
- Entire answer should be ready to be copied and pasted, without any preamble.
- Only return the rewritten version of the selected section, not the whole document.
- Selected section must rewritten exactly in a way, user described in the prompt.

You will be provided with this input data:

1. Agenda items - what the clerks planned to discuss.
2. Transcript - the actual recording of their conversation.
3. Meeting minutes - a text document created based on the transcript.
4. Selection - a specific section of the meeting minutes document that needs to be shortened.
5. Prompt - a description of how the selected section should be rewritten.

===

Agenda items:

{agenda_items}

---

Transcript:

{transcript}

---

Meeting minutes:

{meeting_minutes}

---

Selection:

{selection}

---

Prompt:

{prompt}
`,
			},
			generateMinutesOptions: {
				model: 'claude-3-5-sonnet-20240620',
				temperature: 1.1,
				length: 'comprehensive',
				tone: 'professional-corporate',
				prompt: `Here are the details for a {jurisdiction_type} meeting named "{meeting_title}" in {jurisdiction_name} which took place on {meeting_date}. Read the Agenda and Transcript carefully, because your task will be to create meeting minutes from both:

Meeting agenda:
{agenda_items}

Meeting transcript:
{transcript}

I want you to act as the {jurisdiction_type} clerk, who is in charge of writing meeting minutes for this meeting. Type up detailed notes of the meeting for the benefit of the public who was not present for the live meeting. Help us create {length} meeting minutes for the {meeting_title} in a {tone} way for each agenda item. You must include ALL details of what each speaker had discussed for that agenda item when creating the meeting minutes. Include also all nuances mentioned by each speaker for each individual agenda items that had discussion, such as what they were thinking- and saying out-loud, they questions and concerns, as well as any discussion and decisions that came from the dialogue:
- You should write the meeting minutes in past tense for each speaker;
- You must ensure that the text accurately reflects the meeting discussion without adding anything that was not discussed or inventing or hallucinating any ideas
- don't include the meeting title or general heading. Instead format the content in markdown format;
- the main agenda items should be created as second-level heading but using the exact same wording from the agenda. Then after it, the sub-items should be third-level heading. And finally, after each agenda item, its related meeting minutes for that section should be included directly after it;
- If a motion was made for an item on the agenda, include that motion in a blockquote. Include in the blockquote the decision of who made a motion and who seconded it. If names of the speakers are not known, use placeholder of four underscores. Here is an example of how the placeholder looks: ____. Add a extra line break after any blockquote;
- If there is an Agenda Item for which there was no discussion, you must still include the title of that agenda item in the meeting minutes. There is no need to put a summary of that item's minutes when there is no discussion about it.
- If there are any agenda items labeled as "Consent Agenda" which may have various sub-items underneath it, simply create a minutes of the main item from the agenda and do not create it for any individual sub-items minutes;
- Prefer paragraphs over bullet/numbered lists;`,
			},
			ratingTimeout: null,
			feedback: { message: '', rating: 0 },

			jurisdictionsWithResolutionMinutes: ['heyville.org', 'clerkminutes.com', 'cityofenglewood.org'],
		}
	},
	computed: {
		...mapGetters(['auth', 'isStaff']),
		...mapState(['j', 'apiUrl']),
		minutesPromptUrl() {
			const url = new URL(`${this.apiUrl + this.j.slug}/meetings/${this.meeting.pid}/minutes-full-prompt`)

			for (const param in this.generateMinutesOptions) {
				url.searchParams.set(param, this.generateMinutesOptions[param])
			}

			return url
		},
		feedbackHints() {
			return this.states.activeFeedbackOptions.map(k => this.feedbackOptions[k].hint).filter(Boolean)
		},
	},
	created() {
		setTimeout(() => {
			this.states.minutesUpdated = false
		}, 500)
	},
	mounted() {
		if (this.j.slug === 'cityofenglewood.org') {
			this.generateMinutesOptions.tone = 'resolution-minutes'
		}
	},
	methods: {
		getPublicFileUrl,

		/**
		 * Adds ot removes given feedback option from the activeFeedbackOptions array.
		 *
		 * @param {Object} feedbackOption - The selected string to be processed.
		 * @returns {void}
		 */
		toggleActiveFeedbackOptions(optionKey) {
			if (this.states.activeFeedbackOptions.includes(optionKey)) {
				this.states.activeFeedbackOptions = this.states.activeFeedbackOptions.filter(k => k != optionKey)
			} else {
				this.states.activeFeedbackOptions.push(optionKey)
			}
		},

		handleAIActionContextMenu(command) {
			if (!this.isStaff) {
				return
			}

			// disable all
			this.states.makeLongerAdvancedOptions = false
			this.states.makeShorterAdvancedOptions = false
			this.states.describeYourChangeAdvancedOptions = false

			// enable selected
			switch (command) {
				case 'make_longer':
					this.states.makeLongerAdvancedOptions = true
					break
				case 'make_shorter':
					this.states.makeShorterAdvancedOptions = true
					break
				case 'describe_your_change':
					this.states.describeYourChangeAdvancedOptions = true
					break
			}

			alert('AI Action options available at the top until you reload the page.')
		},

		async handleAIAction(payload) {
			const { command, prompt, fullText, selection, update } = payload

			sendEvent('meeting.minutes_ai_action_start', {
				feature: 'Meetings',
				meeting_id: this.meeting.pid,
				meeting: this.meeting.title,
				action: command,
			})

			const options = {
				make_longer: this.makeLongerOptions,
				make_shorter: this.makeShorterOptions,
				describe_your_change: this.describeYourChangeOptions,
			}[command]

			let response
			try {
				response = await hgApi(`${this.j.slug}/meetings/${this.meeting.pid}/minutes/regenerate-selection`, {
					json: {
						command: command,
						prompt: prompt,
						fullText: fullText,
						selection: selection,
						options: options,
					},
				})
			} catch (error) {
				Vue.toasted.error(`Failed to generate minutes (${error.message})`)
				console.error(error)
				return
			}

			const reader = response.body.getReader()
			const decoder = new TextDecoder()

			// eslint-disable-next-line no-constant-condition
			while (true) {
				let chunk
				try {
					chunk = await reader.read()
				} catch (error) {
					// @ts-ignore
					Vue.toasted.error(`Failed to generate minutes (${error.message})`)
					console.error(error)
					return
				}

				const { done, value } = chunk
				const decodedMD = decoder.decode(value, { stream: true })

				update(decodedMD, done) // send data back to the editor

				if (done) {
					break // <- end of loop
				}
			}

			sendEvent('meeting.minutes_ai_action_completed', {
				feature: 'Meetings',
				meeting_id: this.meeting.pid,
				meeting: this.meeting.title,
				action: command,
			})
		},

		generateDraftMinutes() {
			const previousStatus = this.meeting.minutes_status
			this.meeting.minutes_status = 'generating'
			this.meeting.minutes_text = ''
			this.minutesMarkdown = ''

			const converter = new Converter()
			converter.setOption('simplifiedAutoLink', true)
			converter.setOption('openLinksInNewWindow', true)

			this.minutesAbortController = new AbortController()

			hgApi(`${this.j.slug}/meetings/${this.meeting.pid}/generate-minutes-stream`, {
				body: this.generateMinutesOptions,
				signal: this.minutesAbortController.signal,
			})
				.then(response => {
					const reader = response.body.getReader()
					const decoder = new TextDecoder()

					const read = () => {
						reader
							.read()
							.then(({ done, value }) => {
								if (done) {
									this.meeting.minutes_status = 'fresh-done'
									// done

									this.states.showGenerateMinutes = false
									this.states.minutesUpdated = false

									Vue.toasted.success('Draft minutes are ready 🎉')

									sendEvent('meeting.generate_minutes_completed', {
										feature: 'Meetings',
										meeting_id: this.meeting.pid,
										meeting: this.meeting.title,
									})
								} else {
									const chunk = decoder.decode(value, { stream: true })

									this.minutesMarkdown += chunk

									this.meeting.minutes_text = converter.makeHtml(this.minutesMarkdown)

									read()
								}
							})
							.catch(error => {
								if (error.name === 'AbortError') {
									this.meeting.minutes_status = 'fresh-done'
									this.states.showGenerateMinutes = true
								} else {
									Vue.toasted.error(`Failed to generate minutes (${error.message})`)
									console.error(error)
								}
							})
					}

					read()
				})
				.catch(error => {
					this.meeting.minutes_status = previousStatus
					Vue.toasted.error(`Failed to generate minutes (${error.message})`)
					console.error(error)
				})

			sendEvent('meeting.generate_minutes_start', {
				feature: 'Meetings',
				meeting_id: this.meeting.pid,
				meeting: this.meeting.title,
			})
		},

		minutesUpdatedWarning(event) {
			// Recommended
			event.preventDefault()

			// Included for legacy support, e.g. Chrome/Edge < 119
			event.returnValue = true
		},
		triggerMinutesUpdated() {
			this.states.minutesUpdated = true
			window.addEventListener('beforeunload', this.minutesUpdatedWarning)
		},
		saveMinutes() {
			this.$emit('updateMeeting', {
				fields: {
					minutes_text: this.meeting.minutes_text,
				},
				message: 'Minutes are saved',
			})

			sendEvent('meeting.minutes_save', {
				feature: 'Meetings',
				meeting_id: this.meeting.pid,
				meeting: this.meeting.title,
			})

			this.states.minutesUpdated = false
			window.removeEventListener('beforeunload', this.minutesUpdatedWarning)
		},

		minutesExportAs(as, implemented = 'yes') {
			if (implemented === 'no') {
				Vue.toasted.error('not implemented yet')
			}

			sendEvent('meeting.minutes_export', {
				feature: 'Meetings',
				meeting_id: this.meeting.pid,
				meeting: this.meeting.title,
				saveas: as,
				implemented,
			})
		},

		setRating(minutes_rating) {
			minutes_rating = Number(minutes_rating)
			clearTimeout(this.ratingTimeout)
			this.feedback.rating = minutes_rating

			hgApi(`${this.j.slug}/meetings/${this.meeting.pid}`, {
				method: 'PUT',
				json: { minutes_rating },
			})

			if (minutes_rating === 5) {
				this.ratingTimeout = setTimeout(() => {
					this.$emit('updateMeeting', {
						fields: { minutes_status: 'done' },
						message: 'Thanks for your feedback 🤝',
					})
				}, 5000)
			}
		},

		feedbackClose() {
			this.$emit('updateMeeting', { fields: { minutes_status: 'done' }, message: 'Thanks for your feedback 😢' })
		},

		async feedbackSend() {
			let feedbackArr = this.states.activeFeedbackOptions.map(k => this.feedbackOptions[k].text)

			// replace "Other" with value from text-area if needed
			if (this.states.activeFeedbackOptions.includes('other')) {
				feedbackArr = feedbackArr.filter(option => option !== 'Other')
				feedbackArr.push(`"${this.feedback.message}"`)
			}

			const resp = await hgApi(`${this.j.slug}/meetings/${this.meeting.pid}/feedback`, {
				body: {
					rating: this.feedback.rating,
					message: feedbackArr.join('\n\n'),
				},
			})

			this.states.activeFeedbackOptions = []

			sendEvent('meeting.minutes_send_feedback', {
				feature: 'Meetings',
				meeting_id: this.meeting.pid,
				meeting: this.meeting.title,
				rating: this.feedback.rating,
			})

			if (resp.ok) {
				this.meeting.minutes_status = 'done'
				if (this.feedback.rating < 5) {
					this.states.showGenerateMinutes = true
				}
				Vue.toasted.success('Thanks for your feedback 🤝')
			} else {
				Vue.toasted.error('Failed to send feedback')
			}
		},
	},
	beforeRouteLeave(from, to, next) {
		if (this.states.minutesUpdated) {
			const answer = window.confirm(
				"You haven't saved the minutes changes you made? Are you sure you want to leave?"
			)

			if (answer) {
				next()
			} else {
				next(false)
			}
		} else {
			next()
		}
	},
	watch: {
		'generateMinutesOptions.tone'(tone) {
			console.log('Tone changed', tone)

			if (tone === 'resolution-minutes') {
				this.generateMinutesOptions.prompt = `Here are the details for a {jurisdiction_type} meeting named "{meeting_title}" in {jurisdiction_name} which took place on {meeting_date}. Read the Agenda and Transcript carefully, because your task will be to create meeting minutes from both:

Meeting agenda:
{agenda_items}

Meeting transcript:
{transcript}

I want you to act as the {jurisdiction_type} clerk, who is in charge of the meeting minutes for meeting "{meeting_title}".

For each Agenda item, format it as H2 text like this:
\`<h2 style="background-color: #CCCCCC;"><strong><u>ROLL CALL:</u></strong></h2>\`
Usually these meetings start with a "Call to Order". If so, mention who called the meeting to order, the exact time that the meeting (usually spoken outloud by the person who called the meeting to order), what meeting this is for (based on what the speaker says -- usually this will be the City Council Meeting, or something like that).

If there is a "Pledge of Allegiance" then mention which person led the Pledge of Allegiance, if known.
If this meeting includes a "Statement Regarding Open Public Meetings Act", please write out that statement as read by the clerk but without any quotes.

For the Roll Call style it like this:
<h2 style="background-color: #CCCCCC;"><strong><u>ROLL CALL:</u></strong></h2>

List the names of the individuals who are present like this:
<strong>Present:</strong> and then list each member who is present, along with </br> after their name to add a line break.

Then list the names of the other people who are present in this format:
<strong>Also Present:</strong> [Name A]; [Name B]; [Name C]; [Name D]; (Additional info if needed)

And lastly if there is anybody absent, then you can format like this:
<strong>Absent:</strong> and then list each member who is Absent, along with </br> after their name to add a line break.

For this municipality, you must use the style formally know as “Resolution Minutes” for the Ordinances and any Resolution agenda items, unless it is for an agenda item labeled "Consent Agenda". I will list the ideas and suggestions to make this ideal:

Firstly, you can recognize when to use the style of "Resolution Minutes" when you see in transcript where there is discussion about Ordinances or Resolutions and where the phrasing of several “WHEREAS” are used and where it ends with a “THEREFORE” phrase. I've given an example below of what these "Resolution Minutes" look and sound like for a Resolution and for an Ordinance.

Sometimes there will be Public Comments for these items and also for items when the agenda item includes the words "Public Hearing" and "Consent Agenda". Make sure to note the names of each person speaking, and give a bullet point summary of their main question(s) and suggetion(s) are. Place these Public Comments immediately after the Resolution Minutes.
Add address after somebody's who speaks in public (for things like public sessions or public comments). Example styles:
- Amy Bullock, 312 Howland Ave:
- Ricardo Whilby, 287 Shepard Ave:

Sometimes with the "Resolution Minutes", it seems like there needs to be a copy of the newly adopted Ordinance or Resolution needs to be included. If you feel like there should be something more formal added and you do not know what that it is, simply add a placeholder text in ALL CAPS AND BOLD:
[INSERT COPY OF ADOPTED RESOLUTION] or [INSERT COPY OF ADOPTED ORDINANCE]

And one more thing, for the formatting and styling of 'WHEREAS" and the "THEREFORE" phrase, please format that in bold text.

For Ordinances and Resolutions, which are added as a title because they are an Item coming directly from the Agenda, they should be formatted as follows:
1. Write the entire title in ALL CAPS and BOLD.
2. Include the hashtag (#) before the ordinance number.
3. Underline only the "ORDINANCE #[NUMBER]:" part if it's an Ordinance
4. Underline only the "RESOLUTION #[NUMBER]:" part if it's a Resolution

Use this HTML formatting for an Ordinance:
<span style="background-color: #CCCCCC; font-weight: bold;"><u>ORDINANCE #[NUMBER]:</u> {REST OF THE TITLE WITHOUT UNDERLINING}</span>

Use this HTML formatting for a Resolution:
<span style="background-color: #CCCCCC; font-weight: bold;"><u>RESOLUTION #[NUMBER]:</u> {REST OF THE TITLE WITHOUT UNDERLINING}</span>
For example:
<span style="background-color: #CCCCCC; font-weight: bold;"><u>ORDINANCE #23-21:</u> AN ORDINANCE TO AMEND AND SUPPLEMENT CHAPTER 250, TITLED "LAND USE," ARTICLE XVII ENTITLED, AFFORDABLE HOUSING</span>

Below are two example to use as inspiration:
<start example resolution minutes Number 1>
RESOLUTION #385-12-19-23: APPROVE PAYMENT OF BILLS AND CLAIMS

WHEREAS, The Chief Financial Officer has certified and submitted a consolidated bill and

claims list for payment as well as a consolidated list of prepaid items. The prepaid items include

emergency payments, wire transfers and regularly scheduled monthly payments that are paid between

bill and claims list dates; and

WHEREAS, all bills and claims listed herewith have been encumbered and sufficient funds are

available for payment; and

WHEREAS, the required signatures have all been obtained on each voucher on the attached list.

City Council Meeting Minutes December 19, 2023 Page 2 of 21

NOW, THEREFORE, BE IT RESOLVED, by the Council of the City of Englewood, that the

bills and claims on the submitted lists are hereby approved for payment in the total amount of

$3,707,530.03
</end example resolution minutes Number 1>

<start example ordinance Number 1>
ORDINANCE #23-31

AN ORDINANCE OF THE CITY OF ENGLEWOOD, COUNTY OF BERGEN, STATE

OF NEW JERSEY, ESTABLISHING CHAPTER 315 “PROCEDURE TO NAME

PUBLIC SPACES” OF THE GENERAL LEGISLATION OF THE CITY OF

ENGLEWOOD

WHEREAS, the City Council of the City of Englewood has many noted individuals and organizations worthy of honor; and

WHEREAS, it is a common practice to co-name an existing street, parks or public buildings to honor and commemorate an individual or organization; and

WHEREAS, the City of Englewood does not have guidelines on the process of co-naming streets, parks or buildings; and

WHEREAS, the City Council of the City of Englewood wishes to establish Chapter 315 “Procedure to Name Public Spaces” for such guidelines;

NOW THEREFORE, BE IT ORDAINED, by the City Council of the City of Englewood, in the County of Bergen and State of New Jersey, that Chapter 315 “Procedure to Name Public Spaces” is hereby established and made part of the Revised General Ordinances of the City of Englewood:
</end example ordinance Number 1>

For those non-ordinance or non-resolution agenda items, you can write a summary. However this summary must include ALL details of what each speaker had discussed for that agenda item when creating the meeting minutes. Include also all nuances mentioned by each speaker for each individual agenda items that had discussion, such as what they were thinking- and saying out-loud, they questions and concerns, as well as any discussion and decisions that came from the dialogue:
- You should write the meeting minutes in past tense for each speaker;
- You must ensure that the text accurately reflects the meeting discussion without adding anything that was not discussed or inventing or hallucinating any ideas
- don't include the meeting title or general heading. Instead format the content in markdown format;
- the main agenda items should be created as second-level heading but using the exact same wording from the agenda. Then after it, the sub-items should be third-level heading. And finally, after each agenda item, its related meeting minutes for that section should be included directly after it;
- If a motion was made for an item on the agenda, include that motion in a blockquote. Include in the blockquote the decision of who made a motion and who seconded it. Make their names with BOLD and underline the font. For this municipality, use "Motion by" and list the person and then "seconded by" and list the person. Do the wording so it looks similar to this:
<start Example of Motion>
- Motion by **Councilwoman David** seconded by **Councilman Wilson** to approve item/PO #23-02176 and 23-03792 Bills and Claims.
</end Example of Motion>

you can use formatting like this:
\`<span style="background-color: #CCCCCC; font-weight: bold; text-decoration: underline;">{name of person making a motion or seconding a motion}</span>\`

If names of the speakers who made a motion or second are not known, use the Speaker Name & Number from the transcript as a placeholder, like in this example:

Motion by {SPEAKER 69} seconded by {SPEAKER 420} to TABLE Resolutions #225-07-25-23, #226-07-25-23, and #248A-07-25-23.

Add a extra line break after any blockquote;

- If there is an agenda item that is tabled, please indicate that it was tabled. If it's known about when the item will re-appear (if known), and go ahead and include that info.
- Make sure to format the word "TABLE" in this way when it's mentioned inside of the motion that was made:

\`<span style="background-color: #CCCCCC; font-weight: bold; text-decoration: underline;">TABLE</span>\`

In the Motion and Second, if it includes a mention about a public hearing and a date, then change the formatting of the date to be bold and underlined. Here is an example:
\`<span style="background-color: #CCCCCC; font-weight: bold; text-decoration: underline;">Public Hearing Date</span>\`

- add the roll call at the end for each item that was voted on with a line directly underneath it. Put "ROLL CALL:" using H3 tag and style it like this:
\`<h3 style="background-color: #CCCCCC;"><strong><u>ROLL CALL:</u></strong></h3>\`

Show "Yes:" (in BOLD) with the list of council members who voted "yes" and then a line break. Underneath that on a new line, show "No:" (in BOLD) with the members voting no, if any.
And if anybody Abstained, then make a new line and show "Abstain:" (in BOLD) and include any member(s) who had abstained.

Use this example style for displaying the Roll Call:
<start Roll Call that shows Yes & No>
**ROLL CALL**: (make BOLD) and then create a new line

**Yes**: (in BOLD) Council Members David, Wilson and Cobb (then create new line)

**No**: (in BOLD) Council Members Rosenzweig, Wisotsky
</start Roll Call that shows Yes & No>

<start Roll Call with an Abstain>
ROLL CALL: (make BOLD) and then create a new line

Yes: Council Members Rosenzweig, Wisotsky, Wilson and Cobb (then create new line)

Abstain: Council Member David
</end Roll Call with an Abstain>

- If there is an Agenda Item for which there was no discussion, you must still include the title of that agenda item in the meeting minutes. There is no need to put a summary of that item's minutes when there is no discussion about it. You can simply write "This Item was not Discussed".
- If there is "Consent Agenda" listed on the agenda, then there will be many different sub-items. For this municipality, if there are items which were requested to be pulled from the Consent Agenda and discussed, please do so.
- For this municipality, they will allow for "Public Comment" for anything listed as a Consent Agenda Resolution. Therefore list the name of the speaker, and add their address. Include a bullet point sumary of what each of them discussed for their public comments.
- are any agenda items labeled as "Consent Agenda" which may have various sub-items underneath it, simply create a minutes of the main item from the agenda and do not create it for any individual sub-items minutes;
- Do not add any preamble to the output;

Additional notes for formatting:
1. when there is a section followed by a number, likely it needs to have the this section symbol (§) inserted, and not a dollar sign ($) or nothing. Therefore insert the section symbol like in this example: \`SECTION §250-126 AFFORDABLE HOUSING DEVELOPMENT FEES\``
			} else {
				this.generateMinutesOptions.prompt = `Here are the details for a {jurisdiction_type} meeting named "{meeting_title}" in {jurisdiction_name} which took place on {meeting_date}. Read the Agenda and Transcript carefully, because your task will be to create meeting minutes from both:

Meeting agenda:
{agenda_items}

Meeting transcript:
{transcript}

I want you to act as the {jurisdiction_type} clerk, who is in charge of writing meeting minutes for this meeting. Type up detailed notes of the meeting for the benefit of the public who was not present for the live meeting. Help us create {length} meeting minutes for the {meeting_title} in a {tone} way for each agenda item. You must include ALL details of what each speaker had discussed for that agenda item when creating the meeting minutes. Include also all nuances mentioned by each speaker for each individual agenda items that had discussion, such as what they were thinking- and saying out-loud, they questions and concerns, as well as any discussion and decisions that came from the dialogue:
- You should write the meeting minutes in past tense for each speaker;
- You must ensure that the text accurately reflects the meeting discussion without adding anything that was not discussed or inventing or hallucinating any ideas
- don't include the meeting title or general heading. Instead format the content in markdown format;
- the main agenda items should be created as second-level heading but using the exact same wording from the agenda. Then after it, the sub-items should be third-level heading. And finally, after each agenda item, its related meeting minutes for that section should be included directly after it;
- If a motion was made for an item on the agenda, include that motion in a blockquote. Include in the blockquote the decision of who made a motion and who seconded it. If names of the speakers are not known, use placeholder of four underscores. Here is an example of how the placeholder looks: ____. Add a extra line break after any blockquote;
- If there is an Agenda Item for which there was no discussion, you must still include the title of that agenda item in the meeting minutes. There is no need to put a summary of that item's minutes when there is no discussion about it.
- If there are any agenda items labeled as "Consent Agenda" which may have various sub-items underneath it, simply create a minutes of the main item from the agenda and do not create it for any individual sub-items minutes;`
			}
		},
	},
}
</script>
